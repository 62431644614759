import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  booleanAttribute,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  EBorderRadiusBoton,
  EColorBoton,
  EEstadoBoton,
  EFormaBoton,
  EModeloBoton,
  ETallaBoton,
  tallaIcono,
  tallaImagen,
  tallaRedondeo,
  transformModeloIcono,
  transformTallaIcono,
} from './models/enums';
import { SafePipe } from '../../pipes/safe.pipe';
import { Plus99Directive, TruncarTextoDirective } from '@destinux/destinux-directives';
import { IconoSvgComponent } from '../icono-svg/icono-svg.component';
import { EModeloIcono } from '../icono-svg/models/enums';

@Component({
  selector: 'destinux-boton',
  standalone: true,
  templateUrl: './boton.component.html',
  styleUrls: ['./boton.component.scss'],
  imports: [CommonModule, SafePipe, Plus99Directive, IconoSvgComponent, TruncarTextoDirective],
})
export class BotonComponent {
  //#region inputs
  @Input() iconoStart: string | null = null;
  @Input() iconoEnd: string | null = null;
  @Input() texto: string | null = null;
  @Input() textoComplementario: string | null = null;
  @Input() textoInferior: string | null = null;
  @Input() maxCaracteresTexto: number = 0;
  @Input() notificacion: number = 0;
  @Input() alturaBoton: string = '';
  @Input() anchuraBoton: string = '';
  @Input({ transform: booleanAttribute }) submitType: boolean = false;
  @Input() modelo: EModeloBoton = EModeloBoton.normal;
  @Input() forma: EFormaBoton = EFormaBoton.relleno;
  @Input() color: EColorBoton = EColorBoton.primario;
  @Input() estado: EEstadoBoton = EEstadoBoton.activado;
  @Input() talla: ETallaBoton = ETallaBoton.md;
  @Input() redondeo: EBorderRadiusBoton = EBorderRadiusBoton.redondo;
  //#endregion

  //#region output
  @Output() botonClick = new EventEmitter<boolean>();
  //#endregion

  //#region viewchild
  @ViewChild('boton', { static: true }) boton: ElementRef | undefined;
  //#endregion

  //#region variables
  public modelosBoton = EModeloBoton;
  public transformTallaIcono = transformTallaIcono;
  //#endregion

  //#region html
  getAlturaAnchura(){
    return `height: ${this.alturaBoton}; width: ${this.anchuraBoton};`;
  }

  getClaseBoton(){
    const clase = this.modelo == EModeloBoton.auxiliar
    ? ['c-boton--auxiliar'] 
    : ['c-boton', tallaRedondeo[[this.talla][this.redondeo]], this.aplicarModeloFormaYColor(), this.aplicarTallaPaddingYTipografia()];

    return clase;
  }

  public iconoModelo(){
    if(this.estado == EEstadoBoton.desactivado){
      return EModeloIcono.desactivado;
    }
    if(this.forma != EFormaBoton.relleno){
      return transformModeloIcono[this.color];
    }
    if(this.color == EColorBoton.advertencia || this.color == EColorBoton.primario){
      return transformModeloIcono[this.color];
    }
    return EModeloIcono.negativo;
  }  

  hndClick(): void {
    if (this.estado != EEstadoBoton.desactivado) {
      this.botonClick.emit(true);
    }
  }
  //#endregion

  //#region metodos
  aplicarModeloFormaYColor() {
    let variante = '';
    //INFO:para eliminar efecto :hover cuando está desactivado, se elimina modelo, forma y color activos.
    if (this.estado === EEstadoBoton.desactivado) {
      variante = this.forma == EFormaBoton.limpio 
      ? `c-boton--${this.estado}-sin-borde` 
      : `c-boton--${this.estado}`;
    } else {
      variante = `c-boton--${this.modelo}-${this.forma}-${this.color}`;
    }
    return variante;
  }

  aplicarTallaPaddingYTipografia(): string {
    let talla: string;    
    switch (this.modelo) {
      case EModeloBoton.icono:
        talla = tallaIcono[this.talla];
        break;
      case EModeloBoton.imagen:
        talla = tallaImagen[this.talla];
        break;
      default:
        talla = this.talla;
        break;
    }
    return talla;
  }

  //#endregion
}
