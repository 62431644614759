export enum ETipoErrores {
  DatosPeticionNoValidos = 1,
  Prohibido = 2,
  NoEncontrado = 3,
  SinResultados = 4,
  Error = 5,
  Warning = 6,
}

export enum EProductosService {
  Apartamentos = 'apartamentos/',
  Barcos = 'navieras/',
  Hoteles = '',
  Rentacar = '',
  Transfers = 'transfers/',
  Trenes = 'tren/',
  Vuelos = '',
}
