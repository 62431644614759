import { AfterViewChecked, Component, ContentChild, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { IconoSvgComponent } from "../icono-svg/icono-svg.component";
import { EnumsLib } from '../../utiles/enums';
import { ETipoModal, transformTipoIcono } from '../../enums/modal';
import { breakpoints } from '../../enums/breakpoints';
import { ETallaIcono } from '../icono-svg/models/enums';

@Component({
  selector: 'destinux-modal',
  standalone: true,
  imports: [CommonModule, DialogModule, IconoSvgComponent],
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, AfterViewChecked {
  //#region viewchild
  @ViewChild('cabecera') cabecera!: ElementRef;
  //#endregion

  //#region contentChild
  @ContentChild('content', { static: false }) content!: TemplateRef<any>;
  //#endregion
  
  //#region inputs
  @Input() set open(val: boolean){
    this.#open = val;
    this.openChange.emit(val);
  }
  get open() {
    return this.#open;
  }

  @Input() set tipo (val: ETipoModal) {
    this.#tipo = val;
    this.dialogBorder = `var(${val})`;
  }
  get tipo(){
    return this.#tipo;
  }
  @Input({required: true}) titulo: string = "Exito";
  @Input() cantidadPantallas: number = 0;
  @Input() pantallaActual: number = 0;
   @Input() icono: string | null = null;
  @Input() ancho: string = '75vw';
  @Input() set largo(val: string) {
    this.#alto = val;
    this.heightDialog = val;
  }
  get largo() {
    return this.#alto;
  }
  //#endregion

  //#region outputs
  @Output() openChange: EventEmitter<boolean> = new EventEmitter();
  @Output() cerrar: EventEmitter<any> = new EventEmitter();
  //#endregion

  //#region hostBinding
  @HostBinding('style.--dialogBorder') dialogBorder = `var(${this.tipo})`;
  @HostBinding('style.--heightDialog') heightDialog = this.largo || '100%';
  @HostBinding('style.--heightContenido') heightContenido = '85%';
  //#endregion

  //#region hostlisten
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getMovil();

  }
  //#endregion

  //#region variables
  public enums = EnumsLib;
  public movil: boolean = false;
  public tallaIcono: ETallaIcono = ETallaIcono.gd;

  #open: boolean = true;
  #tipo: ETipoModal = ETipoModal.exito;
  #alto: string = '100%';
  //#endregion

  //#region constructor
  constructor() {
    this.tipo = ETipoModal.exito;
  }
  //#endregion

  //#region oninit
  ngOnInit(): void {
    this.getMovil();
  }
  //#endregion

  ngAfterViewChecked(): void {
          
    if (this.cabecera) {
      this.heightContenido = `calc(100% - ${this.cabecera.nativeElement.clientHeight}px - .5rem)`;
    }
  }
  //#region metodos
  getMovil() {
    if (window.innerWidth >= breakpoints.md) {
      this.tallaIcono = ETallaIcono.gd;
      this.movil = false;
    } else {
      this.tallaIcono = ETallaIcono.md;
      this.movil = true;
    }
  }
  //#endregion

  //#region html
  public cerrarModal() {
    this.open = false;
    this.cerrar.emit(true);
  }

  public iconoModal(){
    if(this.icono){
      return this.icono;
    } 
    else{
      return transformTipoIcono[this.tipo];
    }
  }
  //#endregion
}
