<div class="novedad"> <!--TODO: traducciones-->
    <div class="g-flex g-flex--direction-column g-flex--direction-row@md g-flex--align-items-center g-flex--justify-content-center g-flex--grow-1 g-padding-right-xp">
        <p class="novedad__titulo">{{ titulo | translate | uppercase }}
            <span *ngIf="tituloNegrita" class="g-font--boton-gd-negrita g-font--boton-xg-negrita@md">{{ tituloNegrita | translate }}</span>
        </p>
        <destinux-boton texto="{{ textoBoton }}" [talla]="enums.ETallaBoton.pq" [forma]="enums.EFormaBoton.delineado" 
        [color]="enums.EColorBoton.negativo" (botonClick)="hndVerNovedad()"></destinux-boton>
    </div>  
    <div class="g-cursor-pointer" (click)="hndOcultar()">
        <destinux-icono-svg class="novedad__icono-cerrar--desktop" [urlIcono]="Icon.CerrarModal" 
        [talla]="enums.ETallaIcono.fill" [modelo]="enums.EModeloIcono.negativo" [excepcionGrosor]="enums.EGrosorIcono.cuatro"></destinux-icono-svg>    
        <destinux-icono-svg class="novedad__icono-cerrar--mobile" [urlIcono]="Icon.CerrarModal" 
        [talla]="enums.ETallaIcono.pq" [modelo]="enums.EModeloIcono.negativo" [excepcionGrosor]="enums.EGrosorIcono.cuatro"></destinux-icono-svg>    
    </div>
</div>