import { EModeloIcono, ETallaIcono } from "../../../components/icono-svg/models/enums";

export enum EModeloBoton {
  normal = 'normal',
  icono = 'icono',
  imagen = 'imagen',
  auxiliar = 'auxiliar',  
}
export enum EFormaBoton {
  relleno = 'relleno',
  delineado = 'delineado',
  limpio = 'limpio',  
}
export enum EColorBoton {
  primario = 'primario',
  secundario = 'secundario',
  advertencia = 'advertencia',
  error = 'error',
  info = 'info',
  exito = 'exito',
  destaque = 'destaque',  
  negativo = 'negativo'
}
export enum ETallaBoton {
  xp = "g-height-md g-padding-0 g-padding-left-xxp g-padding-right-xxp g-font--boton-xp",
  pq = "g-height-gd g-padding-2 g-padding-left-10 g-padding-right-10 g-font--boton-pq",
  md = "g-height-xg g-padding-xxp g-padding-left-xp g-padding-right-xp g-font--boton-md",
  gd = "height-gd g-padding-12 g-padding-left-xp g-padding-right-xp g-font--boton-gd",
  xg = "g-height-64 g-padding-xp g-font--boton-xg"
}
export enum EBorderRadiusBoton {
  rectangular,
  redondo,
}
export enum EEstadoBoton {
  activado = 'activado',
  desactivado = 'desactivado'
}

export const tallaIcono = {
  [ETallaBoton.xp]: "g-height-md g-width-md g-padding-0 g-padding-left-6 g-padding-right-6 g-font--boton-xp",
   [ETallaBoton.pq] : "g-height-gd g-width-gd g-padding-4 g-padding-left-xxp g-padding-right-xxp g-font--boton-pq",
   [ETallaBoton.md] : "g-height-xg g-width-xg g-padding-xxp g-font--boton-md",
   [ETallaBoton.gd] : "height-gd width-gd g-padding-12 g-font--boton-gd",
   [ETallaBoton.xg] : "g-height-64 g-width-64 g-padding-xp g-font--boton-xg"
}

export const tallaImagen = {
  [ETallaBoton.xp]: "g-height-md g-width-md g-font--boton-xp",
   [ETallaBoton.pq] : "g-height-gd g-width-gd g-font--boton-pq",
   [ETallaBoton.md] : "g-height-xg g-width-xg g-font--boton-md",
   [ETallaBoton.gd] : "height-gd width-gd g-font--boton-gd",
   [ETallaBoton.xg] : "g-height-64 g-width-64 g-font--boton-xg"
}

export const transformTallaIcono ={
   [ETallaBoton.xp] : ETallaIcono.xp,
   [ETallaBoton.pq] : ETallaIcono.pq,
   [ETallaBoton.md] : ETallaIcono.md,
   [ETallaBoton.gd] : ETallaIcono.gd,
   [ETallaBoton.xg] : ETallaIcono.xg
}

export const transformModeloIcono={
  [EColorBoton.advertencia]: EModeloIcono.advertencia,
  [EColorBoton.destaque]: EModeloIcono.destaque,
  [EColorBoton.error]: EModeloIcono.error,
  [EColorBoton.exito]: EModeloIcono.exito,
  [EColorBoton.info]: EModeloIcono.info,
  [EColorBoton.negativo]: EModeloIcono.negativo,
  [EColorBoton.primario]: EModeloIcono.primario,
  [EColorBoton.secundario]: EModeloIcono.secundario
}

export const tallaRedondeo={
  [[ETallaBoton.gd][EBorderRadiusBoton.redondo]] : 'g-border-radius-md',
  [[ETallaBoton.gd][EBorderRadiusBoton.rectangular]] :'g-border-radius-12',
  [[ETallaBoton.xg][EBorderRadiusBoton.redondo]] : 'g-border-radius-xg',
  [[ETallaBoton.xg][EBorderRadiusBoton.rectangular]]  : 'g-border-radius-12',
  [[ETallaBoton.md][EBorderRadiusBoton.redondo]] : 'g-border-radius-md',
  [[ETallaBoton.md][EBorderRadiusBoton.rectangular]] : 'g-border-radius-xxp',
  [[ETallaBoton.pq][EBorderRadiusBoton.redondo]] : 'g-border-radius-md',
  [[ETallaBoton.pq][EBorderRadiusBoton.rectangular]] : 'g-border-radius-xxp',
  [[ETallaBoton.xp][EBorderRadiusBoton.redondo]] : 'g-border-radius-md',
  [[ETallaBoton.xp][EBorderRadiusBoton.rectangular]] : 'g-border-radius-xxp',
}