/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { EIcono, EPosicion, ETitulos } from 'src/app/core/enums/alertas/swal';
import Swal, { SweetAlertCustomClass, SweetAlertResult } from 'sweetalert2';


@Injectable({
  providedIn: 'root',
})
export class AlertasSwalService {
  //#region declaración de variables
  urlRecursos = environment.recursos;
  #swalComun = Swal.mixin({
    width: 600,
    padding: '3em',
    color: '#358ebb',
    confirmButtonColor: '#358ebb',
    allowOutsideClick: false
  });

  #toast = Swal.mixin({
    toast: true,
    position: EPosicion.ArribaDerecha,
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast: any) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });
  //#endregion

  //#region constructor
  constructor(private _translateService: TranslateService) {}
  //#endregion

  //#region metodos
  alerta(
    titulo: string | ETitulos,
    icono: EIcono,
    texto: string,
    footer: string | undefined = undefined,
    cancel: string | undefined = undefined,
    textoBoton: string = 'OK',
    customClass: SweetAlertCustomClass | undefined = undefined
  ): Promise<SweetAlertResult> {
    const isCancel = cancel === undefined ? false : true;
    return this.#swalComun.fire({
      title: titulo.trim() != '' && titulo ? this._translateService.instant(titulo) : titulo,
      icon: icono,
      html: texto.trim() != '' && texto ? this._translateService.instant(texto) : texto,
      footer: footer,
      showCancelButton: isCancel,
      cancelButtonText: cancel,
      confirmButtonText: textoBoton,
      customClass: customClass
    });
  }

  toast(icono: EIcono, texto: string, posicion: EPosicion | undefined = undefined): Promise<SweetAlertResult>{  
    return this.#toast.fire({
      icon: icono,
      html: texto,
      position: posicion
    });
  }
  //#endregion

}
