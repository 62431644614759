/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiSelectModule } from 'primeng/multiselect';
import { IItem } from '../../utiles/Item';
import {
  ReactiveFormsModule,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { ControlValue } from '../../utiles/ControlValue';
import { IconoSvgComponent } from '../icono-svg/icono-svg.component';
import { EnumsLib } from '../../utiles/enums';

@Component({
  selector: 'destinux-selector-multiple-prime',
  standalone: true,
  imports: [
    CommonModule,
    MultiSelectModule,
    ReactiveFormsModule,
    FormsModule,
    DropdownModule,
    IconoSvgComponent
  ],
  templateUrl: './selector-multiple-prime.component.html',
  styleUrls: ['./selector-multiple-prime.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectorMultiplePrimeComponent),
      multi: true,
    },
  ],
})
export class SelectorMultiplePrimeComponent extends ControlValue {
  //#region inputs
  /** el objeto que se recorrera para mostrar las opciones en el selector
   * este es un campo obligatorio
   */
  @Input({ required: true}) objeto: IItem[] = [];
  /** para saber si se necesita un selector multiple o simple
   * este es un campo opcional
   */
  @Input() multiple: boolean = false;
  /** ruta de la imagen a mostrar, en el inicio del input del selector
   * este es un campo opcional
   */
  @Input() srcImg: string | null = null;
  /** para saber si se activa el input de busqueda o no
   * este es un campo opcional
   */
  @Input() busqueda: boolean = false;
  @Input() height: string = "100%";
  @Input() ancho: string = "100%";
  @Input() placeholder: string = "";
  @Input() opcionesCabecera: string | null = null;
  //para saber si se marca con borde rojo al fallar la validacion   
  @Input() hayFalloValidacion: boolean = false;
  //#endregion

  //#region declaracion de variables
  public isAbajo: boolean | null = null;
  public validacion: boolean = false;

  public enums = EnumsLib;
  //#endregion

  //#region output
  @Output() seleccionado: EventEmitter<IItem> = new EventEmitter();
  //#endregion

  //#region metodos html
  hndSelect(){
    this.seleccionado.emit(this.value);
  }

  onSelect() {
    this.isAbajo = null;
    const multiselectPanel = document.querySelector('.p-overlay');

    if (multiselectPanel) {
      const panelRect = multiselectPanel.getAttribute('style');
      const style = getComputedStyle(multiselectPanel);
      const trans = panelRect
        ?.split(';')
        .find((x) => x.includes('transform-origin'))
        ?.split(':')[1];
      console.log(style.transformOrigin.split(' ')[1]);

      if (trans == ' center bottom') {
        this.isAbajo = false;
      } else if (trans == ' center top') {
        this.isAbajo = true;
      }
    }
  }

  onClose() {
    this.isAbajo = null;
  }
  //#endregion
}
