import { EModeloIcono } from "../components/icono-svg/models/enums";

export enum ETipoInformacion{
    error = 'error',
    normal = 'normal'
}

export const TransformModeloIconoTipo = {
    [ETipoInformacion.error] : EModeloIcono.error,
    [ETipoInformacion.normal] : EModeloIcono.primario
}