import { Component, HostBinding, Input, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ControlValue } from '../../utiles/ControlValue';
import {
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { ETipoClase } from '@destinux/destinux-enums';
import { IItem } from '../../utiles/Item';
import { environment } from '@destinux-components/environment';

@Component({
  selector: 'destinux-radio-button-prime',
  standalone: true,
  imports: [CommonModule, RadioButtonModule, FormsModule],
  templateUrl: './radio-button-prime.component.html',
  styleUrls: ['./radio-button-prime.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonPrimeComponent),
      multi: true,
    },
  ],
})
export class RadioButtonPrimeComponent extends ControlValue {
  
  //#region inputs
  /** para la seleccion de estilo
   * campo opcional
   */
  @Input() tipo: ETipoClase = ETipoClase.Primario;
  /** nombre del grupo del radio
   * campo obligatorio
   */
  @Input({ required: true }) nameGroup: string = '';
  /** arreglo de los radios a mostrar
   * campo obligatorio
   */
  @Input({ required: true }) valores: IItem[] = [];
  /** desabilitar el radio o no
   * campo opcional
   */
  @Input() disabled: boolean = false;

  /** para agregarle una clase al checkbox  */
  @Input() classRadio: string = '';

  @Input() isHorizontal: boolean = false;

  //#endregion

  //#region hostbinding
  @HostBinding('style.--primario') primario = `url(${environment.recursos}${environment.baseIconosOriginales}formularios/icono_radio_seleccionado_primario.svg)`;
  @HostBinding('style.--radiobutton') radiobutton = `url(${environment.recursos}${environment.baseIconosOriginales}formularios/icono_radio_activo_primario.svg)`;
  @HostBinding('style.--secundario') secundario = `url(${environment.recursos}${environment.baseIconosOriginales}formularios/icono_radio_seleccionado_secundario.svg)`;
  @HostBinding('style.--desactivado') desactivado = `url(${environment.recursos}${environment.baseIconosOriginales}formularios/icono_radio_desactivado.svg)`;
  //#endregion

  getClaseRadio(valor: string): string {
    if (
      this.classRadio != '' &&
      this.value != undefined &&
      valor == this.value
    ) {
      return this.classRadio;
    } else {
      return '';
    }
  }
}
