/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentEnum } from '@env/environments.enum';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BYPASS_LOG } from '../interceptors/controlErrrores/control-errores.service';

@Injectable({
  providedIn: 'root',
})
export class ServiceHttpService {
  //#region declaración de variables
  #inicioAPI = '/api/';
  //#endregion

  //#region constructor
  constructor(private _http: HttpClient) {}
  //#endregion

  //#region metodos get y post con inicializador this.inicioAPI al comienzo de la url
  getApi<T>(url: string, rutaJsonDemo: string | null = null, options: any | null = null, omitirErrores: boolean = false): Observable<any> {
    if (environment.entorno == EnvironmentEnum.DEMO && rutaJsonDemo !== null) {
      return this._http.get<T>(rutaJsonDemo);
    } else {
      
      if(omitirErrores){
        if(!options){
          options = { context: new HttpContext().set(BYPASS_LOG, true) };
        }
        else{
        options.context = new HttpContext().set(BYPASS_LOG, true);
        }
      }
      return options === null ? this._http.get<T>(this.#inicioAPI + url) : this._http.get<T>(this.#inicioAPI + url, options);
    }
  }

  postApi<T>(url: string, body: any | null, rutaJsonDemo: string | null = null, options: any | null = null, omitirErrores: boolean = false): Observable<any> {
    if (environment.entorno == EnvironmentEnum.DEMO && rutaJsonDemo !== null) {
      return this._http.get<T>(rutaJsonDemo);
    } else {
      if(omitirErrores){
        if(!options){
          options = { context: new HttpContext().set(BYPASS_LOG, true) };
        }
        else{
        options.context = new HttpContext().set(BYPASS_LOG, true);
        }
      }
      return options === null ? this._http.post<T>(this.#inicioAPI + url, body) : this._http.post<T>(this.#inicioAPI + url, body, options);
    }
  }
  //#endregion

  //#region metodos get, post, put y delete
  get<T>(url: string, rutaJsonDemo: string | null = null, options: any | null = null, omitirErrores: boolean = false): Observable<any> {
    if (environment.entorno == EnvironmentEnum.DEMO && rutaJsonDemo !== null) {
      return this._http.get(rutaJsonDemo);
    } else {
      if(omitirErrores){
        if(!options){
          options = { context: new HttpContext().set(BYPASS_LOG, true) };
        }
        else{
        options.context = new HttpContext().set(BYPASS_LOG, true);
        }
      }
      return options === null ? this._http.get<T>(url) : this._http.get<T>(url,options);
      
    }
  }

  post<T>(url: string, body: any | null, rutaJsonDemo: string | null = null, options: any | null = null, omitirErrores: boolean = false): Observable<any> {
    if (environment.entorno == EnvironmentEnum.DEMO && rutaJsonDemo !== null) {
      return this._http.get(rutaJsonDemo);
    } else {
      if(omitirErrores){
        if(!options){
          options = { context: new HttpContext().set(BYPASS_LOG, true) };
        }
        else{
        options.context = new HttpContext().set(BYPASS_LOG, true);
        }
      }
      return options === null ? this._http.post<T>(url, body) : this._http.post<T>(url, body, options);
    }
  }

  put<T>(url: string, body: any | null, rutaJsonDemo: string | null = null, options: any | null = null, omitirErrores: boolean = false): Observable<any> {
    if (environment.entorno == EnvironmentEnum.DEMO && rutaJsonDemo !== null) {
      return this._http.get(rutaJsonDemo);
    } else {
      if(omitirErrores){
        if(!options){
          options = { context: new HttpContext().set(BYPASS_LOG, true) };
        }
        else{
        options.context = new HttpContext().set(BYPASS_LOG, true);
        }
      }
      return options === null ? this._http.put<T>(url, body) : this._http.put<T>(url, body, options);
    }
  }

  delete<T>(url: string, options: any | null = null, omitirErrores: boolean = false): Observable<any> {
    if(omitirErrores){
      if(!options){
        options = { context: new HttpContext().set(BYPASS_LOG, true) };
      }
      else{
      options.context = new HttpContext().set(BYPASS_LOG, true);
      }
    }
    return options === null ? this._http.delete<T>(url) : this._http.delete<T>(url, options);
  }
  //#endregion
}
