export interface IItem {
  clave: any;
  valor: string;
  valorEnviado?: string | null;
}

export class Item {
  public item: IItem[];

  constructor() {
    this.item = [];
  }

  transformarObjetoToItem<T>(
    objeto: T[],
    variableClave: string,
    variableValor: string
  ): IItem[] {
    objeto.forEach((obj) => {
        let clave = '';
        let valor = '';
      for (const ob in obj) {        
        if (ob == variableClave) {
          clave = <string>obj[ob];
        }
        if (ob == variableValor) {
          valor = <string>obj[ob];
        }
      }
      
      this.item.push({
        clave,
        valor,
      });
    });
    return this.item;
  }
}
