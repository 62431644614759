
<div [ngClass]="{'desplegable': true, 'desplegable--abierto': abierto }" (click)="hndToggleOpciones()">
    <div class="desplegable__cabecera">
        <destinux-icono-svg [talla]="enums.ETallaIcono.md" [urlIcono]="desplegable.cabecera?.imgCabecera || ''"></destinux-icono-svg>
        <span>{{ desplegable.cabecera ? ( desplegable.cabecera.titulo | translate | uppercase ) : '' }}</span>
        <destinux-icono-svg *ngIf="!abierto" class="g-margin-left-auto" [talla]="enums.ETallaIcono.pq" 
            [urlIcono]="Icon.Plus"></destinux-icono-svg>
        <destinux-icono-svg *ngIf="abierto" class="g-margin-left-auto" [talla]="enums.ETallaIcono.pq" 
            [urlIcono]="Icon.Menos"></destinux-icono-svg>
    </div>
    <ng-container *ngIf="abierto">           
        <div class="g-padding-bottom-xxp">
            <div *ngFor="let opcion of desplegable.menu">
                <p class="desplegable__opcion" [routerLink]="opcion.ruta" (click)="hndOpcionElegida($event, opcion)">
                    {{ opcion.nombre | translate }}
                </p>                    
            </div>
        </div>
    </ng-container>
</div>
