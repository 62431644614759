export class constantes {
  public static clases = [
    {
      codigo: 'Todas',
      nombre: 'auto-reserva.trenes.disponibilidad.form-busqueda.clases.todas'
    },
    {
      codigo: 'Turista',
      nombre: 'auto-reserva.trenes.disponibilidad.form-busqueda.clases.turista'
    },
    {
      codigo: 'Preferente',
      nombre: 'auto-reserva.trenes.disponibilidad.form-busqueda.clases.preferente'
    }
  ];
  public static adultos = [1, 2, 3, 4];

  public static tipoIdentificacion = [
    { name: 'DNI', tipo: 0 },
    { name: 'Pasaporte', tipo: 1 }
  ];

  public static tarjetas = [
    {
      code: '0',
      name: 'Dorada'
    },
    {
      code: '1',
      name: 'Joven'
    },
    {
      code: '2',
      name: '+Renfe Joven'
    },
    {
      code: '3',
      name: '+Renfe'
    }
  ];
  public static tipoAsientos = [
    { codigoTraduccion: 'auto-reserva.trenes.prereserva.asientos.indiferente', value: null },
    { codigoTraduccion: 'auto-reserva.trenes.prereserva.asientos.ventanilla', value: '0' },
    { codigoTraduccion: 'auto-reserva.trenes.prereserva.asientos.pasillo', value: '1' },
    { codigoTraduccion: 'auto-reserva.trenes.prereserva.asientos.ventanilla-aislada', value: '2' },
    { codigoTraduccion: 'auto-reserva.trenes.prereserva.asientos.mesa-compartida', value: '3' }
  ];

  public static valoresSiNo = [
    { codigoTraduccion: 'auto-reserva.trenes.prereserva.si', value: true },
    { codigoTraduccion: 'auto-reserva.trenes.prereserva.no', value: false }
  ];

  public static proveedores = [
    {
      conector: 0,
      nombre: 'Renfe'
    }
  ];
  public static estaciones = [
    {
      cdgoEstacion: 'MADRI',
      cdgoAdmon: '0071',
      nmroPrioridad: 1,
      descEstacion: null,
      desgEstacion: 'MADRID (TODAS)',
      cdgoUic: null,
      clave: '0071,MADRI,null',
      desgEstacionPlano: 'MADRID (TODAS)',
      activo: true
    },
    {
      cdgoEstacion: '60000',
      cdgoAdmon: '0071',
      nmroPrioridad: 2,
      descEstacion: null as string | null,
      desgEstacion: 'MADRID-PUERTA DE ATOCHA - ALMUDENA GRANDES',
      cdgoUic: '00600',
      clave: '0071,60000,00600',
      desgEstacionPlano: 'MADRID-PUERTA DE ATOCHA - ALMUDENA GRANDES',
      activo: true
    },
    {
      cdgoEstacion: 'BARCE',
      cdgoAdmon: '0071',
      nmroPrioridad: 3,
      descEstacion: null,
      desgEstacion: 'BARCELONA (TODAS)',
      cdgoUic: null,
      clave: '0071,BARCE,null',
      desgEstacionPlano: 'BARCELONA (TODAS)',
      activo: true
    },
    {
      cdgoEstacion: '71801',
      cdgoAdmon: '0071',
      nmroPrioridad: 4,
      descEstacion: null,
      desgEstacion: 'BARCELONA-SANTS',
      cdgoUic: '71801',
      clave: '0071,71801,71801',
      desgEstacionPlano: 'BARCELONA-SANTS',
      activo: true
    },
    {
      cdgoEstacion: '17000',
      cdgoAdmon: '0071',
      nmroPrioridad: 5,
      descEstacion: null,
      desgEstacion: 'MADRID-CHAMARTIN-CLARA CAMPOAMOR',
      cdgoUic: '17000',
      clave: '0071,17000,17000',
      desgEstacionPlano: 'MADRID-CHAMARTIN-CLARA CAMPOAMOR',
      activo: true
    },
    {
      cdgoEstacion: 'VALEN',
      cdgoAdmon: '0071',
      nmroPrioridad: 6,
      descEstacion: null,
      desgEstacion: 'VALENCIA (TODAS)',
      cdgoUic: null,
      clave: '0071,VALEN,null',
      desgEstacionPlano: 'VALENCIA (TODAS)',
      activo: true
    },
    {
      cdgoEstacion: '51003',
      cdgoAdmon: '0071',
      nmroPrioridad: 7,
      descEstacion: null,
      desgEstacion: 'SEVILLA-SANTA JUSTA',
      cdgoUic: '51003',
      clave: '0071,51003,51003',
      desgEstacionPlano: 'SEVILLA-SANTA JUSTA',
      activo: true
    },
    {
      cdgoEstacion: 'BILBA',
      cdgoAdmon: '0071',
      nmroPrioridad: 8,
      descEstacion: null,
      desgEstacion: 'BILBAO (TODAS)',
      cdgoUic: null,
      clave: '0071,BILBA,null',
      desgEstacionPlano: 'BILBAO (TODAS)',
      activo: true
    },
    {
      cdgoEstacion: 'SANTA',
      cdgoAdmon: '0071',
      nmroPrioridad: 9,
      descEstacion: null,
      desgEstacion: 'SANTANDER (TODAS)',
      cdgoUic: null,
      clave: '0071,SANTA,null',
      desgEstacionPlano: 'SANTANDER (TODAS)',
      activo: true
    },
    {
      cdgoEstacion: 'PADRO',
      cdgoAdmon: '0071',
      nmroPrioridad: 10,
      descEstacion: null,
      desgEstacion: 'PADRON (TODAS)',
      cdgoUic: '23021',
      clave: '0071,PADRO,23021',
      desgEstacionPlano: 'PADRON (TODAS)',
      activo: true
    },
    {
      cdgoEstacion: 'REDON',
      cdgoAdmon: '0071',
      nmroPrioridad: 10,
      descEstacion: null,
      desgEstacion: 'REDONDELA (TODAS)',
      cdgoUic: null,
      clave: '0071,REDON,null',
      desgEstacionPlano: 'REDONDELA (TODAS)',
      activo: true
    },
    {
      cdgoEstacion: '03216',
      cdgoAdmon: '0071',
      nmroPrioridad: 11,
      descEstacion: null,
      desgEstacion: 'VALENCIA JOAQUIN SOROLLA',
      cdgoUic: '03216',
      clave: '0071,03216,03216',
      desgEstacionPlano: 'VALENCIA JOAQUIN SOROLLA',
      activo: true
    },
    {
      cdgoEstacion: 'ZARAG',
      cdgoAdmon: '0071',
      nmroPrioridad: 12,
      descEstacion: null,
      desgEstacion: 'ZARAGOZA (TODAS)',
      cdgoUic: null,
      clave: '0071,ZARAG,null',
      desgEstacionPlano: 'ZARAGOZA (TODAS)',
      activo: true
    },
    {
      cdgoEstacion: '04040',
      cdgoAdmon: '0071',
      nmroPrioridad: 13,
      descEstacion: null,
      desgEstacion: 'ZARAGOZA-DELICIAS',
      cdgoUic: '04040',
      clave: '0071,04040,04040',
      desgEstacionPlano: 'ZARAGOZA-DELICIAS',
      activo: true
    },
    {
      cdgoEstacion: '50500',
      cdgoAdmon: '0071',
      nmroPrioridad: 14,
      descEstacion: null,
      desgEstacion: 'CORDOBA',
      cdgoUic: '50500',
      clave: '0071,50500,50500',
      desgEstacionPlano: 'CORDOBA',
      activo: true
    },
    {
      cdgoEstacion: '54413',
      cdgoAdmon: '0071',
      nmroPrioridad: 15,
      descEstacion: null,
      desgEstacion: 'MALAGA MARIA ZAMBRANO',
      cdgoUic: '54413',
      clave: '0071,54413,54413',
      desgEstacionPlano: 'MALAGA MARIA ZAMBRANO',
      activo: true
    },
    {
      cdgoEstacion: '10600',
      cdgoAdmon: '0071',
      nmroPrioridad: 16,
      descEstacion: null,
      desgEstacion: 'VALLADOLID',
      cdgoUic: '10600',
      clave: '0071,10600,10600',
      desgEstacionPlano: 'VALLADOLID',
      activo: true
    },
    {
      cdgoEstacion: '60911',
      cdgoAdmon: '0071',
      nmroPrioridad: 17,
      descEstacion: null,
      desgEstacion: 'ALICANTE/ALACANT',
      cdgoUic: '60911',
      clave: '0071,60911,60911',
      desgEstacionPlano: 'ALICANTE/ALACANT',
      activo: true
    },
    {
      cdgoEstacion: '78400',
      cdgoAdmon: '0071',
      nmroPrioridad: 18,
      descEstacion: null,
      desgEstacion: 'LLEIDA',
      cdgoUic: '78400',
      clave: '0071,78400,78400',
      desgEstacionPlano: 'LLEIDA',
      activo: true
    },
    {
      cdgoEstacion: '79300',
      cdgoAdmon: '0071',
      nmroPrioridad: 19,
      descEstacion: null,
      desgEstacion: 'GIRONA',
      cdgoUic: '79300',
      clave: '0071,79300,79300',
      desgEstacionPlano: 'GIRONA',
      activo: true
    },
    {
      cdgoEstacion: '37200',
      cdgoAdmon: '0071',
      nmroPrioridad: 20,
      descEstacion: null,
      desgEstacion: 'CIUDAD REAL',
      cdgoUic: '37200',
      clave: '0071,37200,37200',
      desgEstacionPlano: 'CIUDAD REAL',
      activo: true
    },
    {
      cdgoEstacion: 'SEGOV',
      cdgoAdmon: '0071',
      nmroPrioridad: 21,
      descEstacion: null,
      desgEstacion: 'SEGOVIA (TODAS)',
      cdgoUic: null,
      clave: '0071,SEGOV,null',
      desgEstacionPlano: 'SEGOVIA (TODAS)',
      activo: true
    },
    {
      cdgoEstacion: '18000',
      cdgoAdmon: '0071',
      nmroPrioridad: 22,
      descEstacion: null,
      desgEstacion: 'MADRID - ATOCHA CERCANÍAS',
      cdgoUic: '18000',
      clave: '0071,18000,18000',
      desgEstacionPlano: 'MADRID - ATOCHA CERCANIAS',
      activo: true
    },
    {
      cdgoEstacion: '08004',
      cdgoAdmon: '0071',
      nmroPrioridad: 23,
      descEstacion: null,
      desgEstacion: 'SEGOVIA GUIOMAR',
      cdgoUic: '08004',
      clave: '0071,08004,08004',
      desgEstacionPlano: 'SEGOVIA GUIOMAR',
      activo: true
    },
    {
      cdgoEstacion: 'TARRA',
      cdgoAdmon: '0071',
      nmroPrioridad: 24,
      descEstacion: null,
      desgEstacion: 'TARRAGONA (TODAS)',
      cdgoUic: null,
      clave: '0071,TARRA,null',
      desgEstacionPlano: 'TARRAGONA (TODAS)',
      activo: true
    },
    {
      cdgoEstacion: '65000',
      cdgoAdmon: '0071',
      nmroPrioridad: 25,
      descEstacion: null,
      desgEstacion: 'VALENCIA-ESTACIO DEL NORD',
      cdgoUic: '65000',
      clave: '0071,65000,65000',
      desgEstacionPlano: 'VALENCIA-ESTACIO DEL NORD',
      activo: true
    },
    {
      cdgoEstacion: '92102',
      cdgoAdmon: '0071',
      nmroPrioridad: 26,
      descEstacion: null,
      desgEstacion: 'TOLEDO',
      cdgoUic: '92102',
      clave: '0071,92102,92102',
      desgEstacionPlano: 'TOLEDO',
      activo: true
    },
    {
      cdgoEstacion: '80100',
      cdgoAdmon: '0071',
      nmroPrioridad: 27,
      descEstacion: null,
      desgEstacion: 'PAMPLONA/IRUÑA',
      cdgoUic: '80100',
      clave: '0071,80100,80100',
      desgEstacionPlano: 'PAMPLONA/IRUNA',
      activo: true
    },
    {
      cdgoEstacion: '15100',
      cdgoAdmon: '0071',
      nmroPrioridad: 28,
      descEstacion: null,
      desgEstacion: 'LEON',
      cdgoUic: '15100',
      clave: '0071,15100,15100',
      desgEstacionPlano: 'LEON',
      activo: true
    },
    {
      cdgoEstacion: '60600',
      cdgoAdmon: '0071',
      nmroPrioridad: 29,
      descEstacion: null,
      desgEstacion: 'ALBACETE-LOS LLANOS',
      cdgoUic: '60600',
      clave: '0071,60600,60600',
      desgEstacionPlano: 'ALBACETE-LOS LLANOS',
      activo: true
    },
    {
      cdgoEstacion: '04104',
      cdgoAdmon: '0071',
      nmroPrioridad: 30,
      descEstacion: null,
      desgEstacion: 'CAMP TARRAGONA',
      cdgoUic: '04104',
      clave: '0071,04104,04104',
      desgEstacionPlano: 'CAMP TARRAGONA',
      activo: true
    },
    {
      cdgoEstacion: '31400',
      cdgoAdmon: '0071',
      nmroPrioridad: 31,
      descEstacion: null,
      desgEstacion: 'SANTIAGO DE COMPOSTELA',
      cdgoUic: '31400',
      clave: '0071,31400,31400',
      desgEstacionPlano: 'SANTIAGO DE COMPOSTELA',
      activo: true
    },
    {
      cdgoEstacion: '31412',
      cdgoAdmon: '0071',
      nmroPrioridad: 32,
      descEstacion: null,
      desgEstacion: 'A CORUÑA',
      cdgoUic: '31412',
      clave: '0071,31412,31412',
      desgEstacionPlano: 'A CORUNA',
      activo: true
    },
    {
      cdgoEstacion: '30100',
      cdgoAdmon: '0071',
      nmroPrioridad: 34,
      descEstacion: null,
      desgEstacion: 'SALAMANCA',
      cdgoUic: '30100',
      clave: '0071,30100,30100',
      desgEstacionPlano: 'SALAMANCA',
      activo: true
    },
    {
      cdgoEstacion: '22100',
      cdgoAdmon: '0071',
      nmroPrioridad: 35,
      descEstacion: null,
      desgEstacion: 'OURENSE',
      cdgoUic: '22100',
      clave: '0071,22100,22100',
      desgEstacionPlano: 'OURENSE',
      activo: true
    },
    {
      cdgoEstacion: '65300',
      cdgoAdmon: '0071',
      nmroPrioridad: 36,
      descEstacion: null,
      desgEstacion: 'CASTELLO',
      cdgoUic: '65300',
      clave: '0071,65300,65300',
      desgEstacionPlano: 'CASTELLO',
      activo: true
    },
    {
      cdgoEstacion: '61200',
      cdgoAdmon: '0071',
      nmroPrioridad: 37,
      descEstacion: null,
      desgEstacion: 'MURCIA',
      cdgoUic: '61200',
      clave: '0071,61200,61200',
      desgEstacionPlano: 'MURCIA',
      activo: true
    },
    {
      cdgoEstacion: '51405',
      cdgoAdmon: '0071',
      nmroPrioridad: 38,
      descEstacion: null,
      desgEstacion: 'CADIZ',
      cdgoUic: '51405',
      clave: '0071,51405,51405',
      desgEstacionPlano: 'CADIZ',
      activo: true
    },
    {
      cdgoEstacion: '14100',
      cdgoAdmon: '0071',
      nmroPrioridad: 39,
      descEstacion: null,
      desgEstacion: 'PALENCIA',
      cdgoUic: '14100',
      clave: '0071,14100,14100',
      desgEstacionPlano: 'PALENCIA',
      activo: true
    },
    {
      cdgoEstacion: 'CUENC',
      cdgoAdmon: '0071',
      nmroPrioridad: 40,
      descEstacion: null,
      desgEstacion: 'CUENCA (TODAS)',
      cdgoUic: null,
      clave: '0071,CUENC,null',
      desgEstacionPlano: 'CUENCA (TODAS)',
      activo: true
    },
    {
      cdgoEstacion: '03208',
      cdgoAdmon: '0071',
      nmroPrioridad: 41,
      descEstacion: null,
      desgEstacion: 'CUENCA FERNANDO ZOBEL',
      cdgoUic: '03208',
      clave: '0071,03208,03208',
      desgEstacionPlano: 'CUENCA FERNANDO ZOBEL',
      activo: true
    },
    {
      cdgoEstacion: 'VIGO-',
      cdgoAdmon: '0071',
      nmroPrioridad: 42,
      descEstacion: null,
      desgEstacion: 'VIGO (TODAS)',
      cdgoUic: null,
      clave: '0071,VIGO-,null',
      desgEstacionPlano: 'VIGO (TODAS)',
      activo: true
    },
    {
      cdgoEstacion: '51300',
      cdgoAdmon: '0071',
      nmroPrioridad: 43,
      descEstacion: null,
      desgEstacion: 'JEREZ DE LA FRONTERA',
      cdgoUic: '51300',
      clave: '0071,51300,51300',
      desgEstacionPlano: 'JEREZ DE LA FRONTERA',
      activo: true
    },
    {
      cdgoEstacion: '04307',
      cdgoAdmon: '0071',
      nmroPrioridad: 44,
      descEstacion: null,
      desgEstacion: 'FIGUERES VILAFANT',
      cdgoUic: '04307',
      clave: '0071,04307,04307',
      desgEstacionPlano: 'FIGUERES VILAFANT',
      activo: true
    },
    {
      cdgoEstacion: '11208',
      cdgoAdmon: '0071',
      nmroPrioridad: 45,
      descEstacion: null,
      desgEstacion: 'VITORIA/GASTEIZ',
      cdgoUic: '11208',
      clave: '0071,11208,11208',
      desgEstacionPlano: 'VITORIA/GASTEIZ',
      activo: true
    },
    {
      cdgoEstacion: '15211',
      cdgoAdmon: '0071',
      nmroPrioridad: 46,
      descEstacion: null,
      desgEstacion: 'OVIEDO',
      cdgoUic: '15211',
      clave: '0071,15211,15211',
      desgEstacionPlano: 'OVIEDO',
      activo: true
    },
    {
      cdgoEstacion: '11511',
      cdgoAdmon: '0071',
      nmroPrioridad: 47,
      descEstacion: null,
      desgEstacion: 'SAN SEBASTIAN/DONOSTIA',
      cdgoUic: '11511',
      clave: '0071,11511,11511',
      desgEstacionPlano: 'SAN SEBASTIAN/DONOSTIA',
      activo: true
    },
    {
      cdgoEstacion: '37300',
      cdgoAdmon: '0071',
      nmroPrioridad: 48,
      descEstacion: null,
      desgEstacion: 'PUERTOLLANO',
      cdgoUic: '37300',
      clave: '0071,37300,37300',
      desgEstacionPlano: 'PUERTOLLANO',
      activo: true
    },
    {
      cdgoEstacion: '51100',
      cdgoAdmon: '0071',
      nmroPrioridad: 49,
      descEstacion: null,
      desgEstacion: 'SEVILLA-SAN BERNARDO',
      cdgoUic: '51100',
      clave: '0071,51100,51100',
      desgEstacionPlano: 'SEVILLA-SAN BERNARDO',
      activo: true
    },
    {
      cdgoEstacion: '51400',
      cdgoAdmon: '0071',
      nmroPrioridad: 50,
      descEstacion: null,
      desgEstacion: 'PUERTO DE SANTA MARIA',
      cdgoUic: '51400',
      clave: '0071,51400,51400',
      desgEstacionPlano: 'PUERTO DE SANTA MARIA',
      activo: true
    },
    {
      cdgoEstacion: '51406',
      cdgoAdmon: '0071',
      nmroPrioridad: 52,
      descEstacion: null,
      desgEstacion: 'SAN FERNANDO-BAHIA SUR',
      cdgoUic: '51406',
      clave: '0071,51406,51406',
      desgEstacionPlano: 'SAN FERNANDO-BAHIA SUR',
      activo: true
    },
    {
      cdgoEstacion: '13200',
      cdgoAdmon: '0071',
      nmroPrioridad: 53,
      descEstacion: null,
      desgEstacion: 'BILBAO-ABANDO INDALECIO PRIETO',
      cdgoUic: '13200',
      clave: '0071,13200,13200',
      desgEstacionPlano: 'BILBAO-ABANDO INDALECIO PRIETO',
      activo: true
    },
    {
      cdgoEstacion: '70600',
      cdgoAdmon: '0071',
      nmroPrioridad: 54,
      descEstacion: null,
      desgEstacion: 'CALATAYUD',
      cdgoUic: '70600',
      clave: '0071,70600,70600',
      desgEstacionPlano: 'CALATAYUD',
      activo: true
    },
    {
      cdgoEstacion: '14223',
      cdgoAdmon: '0071',
      nmroPrioridad: 55,
      descEstacion: null,
      desgEstacion: 'SANTANDER',
      cdgoUic: '14223',
      clave: '0071,14223,14223',
      desgEstacionPlano: 'SANTANDER',
      activo: true
    },
    {
      cdgoEstacion: '11014',
      cdgoAdmon: '0071',
      nmroPrioridad: 56,
      descEstacion: null,
      desgEstacion: 'BURGOS-ROSA MANZANO',
      cdgoUic: '11014',
      clave: '0071,11014,11014',
      desgEstacionPlano: 'BURGOS-ROSA MANZANO',
      activo: true
    },
    {
      cdgoEstacion: '05000',
      cdgoAdmon: '0071',
      nmroPrioridad: 57,
      descEstacion: null,
      desgEstacion: 'GRANADA',
      cdgoUic: '05000',
      clave: '0071,05000,05000',
      desgEstacionPlano: 'GRANADA',
      activo: true
    },
    {
      cdgoEstacion: '71500',
      cdgoAdmon: '0071',
      nmroPrioridad: 58,
      descEstacion: null,
      desgEstacion: 'TARRAGONA',
      cdgoUic: '71500',
      clave: '0071,71500,71500',
      desgEstacionPlano: 'TARRAGONA',
      activo: true
    },
    {
      cdgoEstacion: '22308',
      cdgoAdmon: '0071',
      nmroPrioridad: 59,
      descEstacion: null,
      desgEstacion: 'VIGO GUIXAR',
      cdgoUic: '22308',
      clave: '0071,22308,22308',
      desgEstacionPlano: 'VIGO GUIXAR',
      activo: true
    },
    {
      cdgoEstacion: '30200',
      cdgoAdmon: '0071',
      nmroPrioridad: 60,
      descEstacion: null,
      desgEstacion: 'ZAMORA',
      cdgoUic: '30200',
      clave: '0071,30200,30200',
      desgEstacionPlano: 'ZAMORA',
      activo: true
    },
    {
      cdgoEstacion: '60400',
      cdgoAdmon: '0071',
      nmroPrioridad: 61,
      descEstacion: null,
      desgEstacion: 'ALCAZAR DE SAN JUAN',
      cdgoUic: '60400',
      clave: '0071,60400,60400',
      desgEstacionPlano: 'ALCAZAR DE SAN JUAN',
      activo: true
    },
    {
      cdgoEstacion: 'GIJON',
      cdgoAdmon: '0071',
      nmroPrioridad: 62,
      descEstacion: null,
      desgEstacion: 'GIJON (TODAS)',
      cdgoUic: null,
      clave: '0071,GIJON,null',
      desgEstacionPlano: 'GIJON (TODAS)',
      activo: true
    },
    {
      cdgoEstacion: '15410',
      cdgoAdmon: '0071',
      nmroPrioridad: 63,
      descEstacion: null,
      desgEstacion: 'GIJON',
      cdgoUic: '15410',
      clave: '0071,15410,15410',
      desgEstacionPlano: 'GIJON',
      activo: true
    },
    {
      cdgoEstacion: '08223',
      cdgoAdmon: '0071',
      nmroPrioridad: 64,
      descEstacion: null,
      desgEstacion: 'VIGO URZAIZ',
      cdgoUic: '08223',
      clave: '0071,08223,08223',
      desgEstacionPlano: 'VIGO URZAIZ',
      activo: true
    },
    {
      cdgoEstacion: '81100',
      cdgoAdmon: '0071',
      nmroPrioridad: 65,
      descEstacion: null,
      desgEstacion: 'LOGROÑO',
      cdgoUic: '81100',
      clave: '0071,81100,81100',
      desgEstacionPlano: 'LOGRONO',
      activo: true
    },
    {
      cdgoEstacion: '81202',
      cdgoAdmon: '0071',
      nmroPrioridad: 66,
      descEstacion: null,
      desgEstacion: 'TUDELA DE NAVARRA',
      cdgoUic: '81202',
      clave: '0071,81202,81202',
      desgEstacionPlano: 'TUDELA DE NAVARRA',
      activo: true
    },
    {
      cdgoEstacion: 'ANTEQ',
      cdgoAdmon: '0071',
      nmroPrioridad: 67,
      descEstacion: null,
      desgEstacion: 'ANTEQUERA (TODAS)',
      cdgoUic: null,
      clave: '0071,ANTEQ,null',
      desgEstacionPlano: 'ANTEQUERA (TODAS)',
      activo: true
    },
    {
      cdgoEstacion: '10400',
      cdgoAdmon: '0071',
      nmroPrioridad: 68,
      descEstacion: null,
      desgEstacion: 'AVILA',
      cdgoUic: '10400',
      clave: '0071,10400,10400',
      desgEstacionPlano: 'AVILA',
      activo: true
    },
    {
      cdgoEstacion: '23004',
      cdgoAdmon: '0071',
      nmroPrioridad: 69,
      descEstacion: null,
      desgEstacion: 'PONTEVEDRA',
      cdgoUic: '23004',
      clave: '0071,23004,23004',
      desgEstacionPlano: 'PONTEVEDRA',
      activo: true
    },
    {
      cdgoEstacion: '02003',
      cdgoAdmon: '0071',
      nmroPrioridad: 70,
      descEstacion: null,
      desgEstacion: 'ANTEQUERA-SANTA ANA',
      cdgoUic: '02003',
      clave: '0071,02003,02003',
      desgEstacionPlano: 'ANTEQUERA-SANTA ANA',
      activo: true
    },
    {
      cdgoEstacion: '43019',
      cdgoAdmon: '0071',
      nmroPrioridad: 71,
      descEstacion: null,
      desgEstacion: 'HUELVA',
      cdgoUic: '43019',
      clave: '0071,43019,43019',
      desgEstacionPlano: 'HUELVA',
      activo: true
    },
    {
      cdgoEstacion: '50300',
      cdgoAdmon: '0071',
      nmroPrioridad: 72,
      descEstacion: null,
      desgEstacion: 'LINARES-BAEZA',
      cdgoUic: '50300',
      clave: '0071,50300,50300',
      desgEstacionPlano: 'LINARES-BAEZA',
      activo: true
    },
    {
      cdgoEstacion: '56312',
      cdgoAdmon: '0071',
      nmroPrioridad: 73,
      descEstacion: null,
      desgEstacion: 'ALMERIA',
      cdgoUic: '56312',
      clave: '0071,56312,56312',
      desgEstacionPlano: 'ALMERIA',
      activo: true
    },
    {
      cdgoEstacion: '74200',
      cdgoAdmon: '0071',
      nmroPrioridad: 74,
      descEstacion: null,
      desgEstacion: 'HUESCA',
      cdgoUic: '74200',
      clave: '0071,74200,74200',
      desgEstacionPlano: 'HUESCA',
      activo: true
    },
    {
      cdgoEstacion: '03100',
      cdgoAdmon: '0071',
      nmroPrioridad: 75,
      descEstacion: null,
      desgEstacion: 'JAEN',
      cdgoUic: '03100',
      clave: '0071,03100,03100',
      desgEstacionPlano: 'JAEN',
      activo: true
    },
    {
      cdgoEstacion: '61307',
      cdgoAdmon: '0071',
      nmroPrioridad: 76,
      descEstacion: null,
      desgEstacion: 'CARTAGENA',
      cdgoUic: '61307',
      clave: '0071,61307,61307',
      desgEstacionPlano: 'CARTAGENA',
      activo: true
    },
    {
      cdgoEstacion: '11200',
      cdgoAdmon: '0071',
      nmroPrioridad: 77,
      descEstacion: null,
      desgEstacion: 'MIRANDA DE EBRO',
      cdgoUic: '11200',
      clave: '0071,11200,11200',
      desgEstacionPlano: 'MIRANDA DE EBRO',
      activo: true
    },
    {
      cdgoEstacion: '55020',
      cdgoAdmon: '0071',
      nmroPrioridad: 78,
      descEstacion: null,
      desgEstacion: 'ALGECIRAS',
      cdgoUic: '55020',
      clave: '0071,55020,55020',
      desgEstacionPlano: 'ALGECIRAS',
      activo: true
    },
    {
      cdgoEstacion: '64100',
      cdgoAdmon: '0071',
      nmroPrioridad: 79,
      descEstacion: null,
      desgEstacion: 'XATIVA',
      cdgoUic: '64100',
      clave: '0071,64100,64100',
      desgEstacionPlano: 'XATIVA',
      activo: true
    },
    {
      cdgoEstacion: '65312',
      cdgoAdmon: '0071',
      nmroPrioridad: 80,
      descEstacion: null,
      desgEstacion: 'VINAROS',
      cdgoUic: '65312',
      clave: '0071,65312,65312',
      desgEstacionPlano: 'VINAROS',
      activo: true
    },
    {
      cdgoEstacion: 'IRUN-',
      cdgoAdmon: '0071',
      nmroPrioridad: 81,
      descEstacion: null,
      desgEstacion: 'IRUN-HENDAYA (TODAS)',
      cdgoUic: null,
      clave: '0071,IRUN-,null',
      desgEstacionPlano: 'IRUN-HENDAYA (TODAS)',
      activo: true
    },
    {
      cdgoEstacion: '11600',
      cdgoAdmon: '0071',
      nmroPrioridad: 82,
      descEstacion: null,
      desgEstacion: 'IRUN',
      cdgoUic: '11600',
      clave: '0071,11600,11600',
      desgEstacionPlano: 'IRUN',
      activo: true
    },
    {
      cdgoEstacion: '03309',
      cdgoAdmon: '0071',
      nmroPrioridad: 83,
      descEstacion: null,
      desgEstacion: 'VILLENA AV',
      cdgoUic: '03309',
      clave: '0071,03309,03309',
      desgEstacionPlano: 'VILLENA AV',
      activo: true
    },
    {
      cdgoEstacion: '35400',
      cdgoAdmon: '0071',
      nmroPrioridad: 84,
      descEstacion: null,
      desgEstacion: 'CACERES',
      cdgoUic: '35400',
      clave: '0071,35400,35400',
      desgEstacionPlano: 'CACERES',
      activo: true
    },
    {
      cdgoEstacion: 'GUADA',
      cdgoAdmon: '0071',
      nmroPrioridad: 85,
      descEstacion: null,
      desgEstacion: 'GUADALAJARA (TODAS)',
      cdgoUic: null,
      clave: '0071,GUADA,null',
      desgEstacionPlano: 'GUADALAJARA (TODAS)',
      activo: true
    },
    {
      cdgoEstacion: '60902',
      cdgoAdmon: '0071',
      nmroPrioridad: 86,
      descEstacion: null,
      desgEstacion: 'VILLENA',
      cdgoUic: '60902',
      clave: '0071,60902,60902',
      desgEstacionPlano: 'VILLENA',
      activo: true
    },
    {
      cdgoEstacion: '04007',
      cdgoAdmon: '0071',
      nmroPrioridad: 87,
      descEstacion: null,
      desgEstacion: 'GUADALAJARA - YEBES',
      cdgoUic: '04007',
      clave: '0071,04007,04007',
      desgEstacionPlano: 'GUADALAJARA - YEBES',
      activo: true
    },
    {
      cdgoEstacion: '23008',
      cdgoAdmon: '0071',
      nmroPrioridad: 88,
      descEstacion: null,
      desgEstacion: 'VILAGARCIA DE AROUSA',
      cdgoUic: '23008',
      clave: '0071,23008,23008',
      desgEstacionPlano: 'VILAGARCIA DE AROUSA',
      activo: true
    },
    {
      cdgoEstacion: '14213',
      cdgoAdmon: '0071',
      nmroPrioridad: 89,
      descEstacion: null,
      desgEstacion: 'TORRELAVEGA',
      cdgoUic: '14213',
      clave: '0071,14213,14213',
      desgEstacionPlano: 'TORRELAVEGA',
      activo: true
    },
    {
      cdgoEstacion: 'PTE G',
      cdgoAdmon: '0071',
      nmroPrioridad: 90,
      descEstacion: null,
      desgEstacion: 'PUENTE GENIL (TODAS)',
      cdgoUic: null,
      clave: '0071,PTE G,null',
      desgEstacionPlano: 'PUENTE GENIL (TODAS)',
      activo: true
    },
    {
      cdgoEstacion: '02002',
      cdgoAdmon: '0071',
      nmroPrioridad: 91,
      descEstacion: null,
      desgEstacion: 'PUENTE GENIL-HERRERA',
      cdgoUic: '02002',
      clave: '0071,02002,02002',
      desgEstacionPlano: 'PUENTE GENIL-HERRERA',
      activo: true
    },
    {
      cdgoEstacion: '60905',
      cdgoAdmon: '0071',
      nmroPrioridad: 92,
      descEstacion: null,
      desgEstacion: 'ELDA-PETRER',
      cdgoUic: '60905',
      clave: '0071,60905,60905',
      desgEstacionPlano: 'ELDA-PETRER',
      activo: true
    },
    {
      cdgoEstacion: 'LISBO',
      cdgoAdmon: '0094',
      nmroPrioridad: 93,
      descEstacion: null,
      desgEstacion: 'LISBOA (TODAS)',
      cdgoUic: null,
      clave: '0094,LISBO,null',
      desgEstacionPlano: 'LISBOA (TODAS)',
      activo: true
    },
    {
      cdgoEstacion: '65311',
      cdgoAdmon: '0071',
      nmroPrioridad: 94,
      descEstacion: null,
      desgEstacion: 'BENICARLO-PEÑISCOLA',
      cdgoUic: '65311',
      clave: '0071,65311,65311',
      desgEstacionPlano: 'BENICARLO-PENISCOLA',
      activo: true
    },
    {
      cdgoEstacion: '11400',
      cdgoAdmon: '0071',
      nmroPrioridad: 95,
      descEstacion: null,
      desgEstacion: 'ZUMARRAGA',
      cdgoUic: '11400',
      clave: '0071,11400,11400',
      desgEstacionPlano: 'ZUMARRAGA',
      activo: true
    },
    {
      cdgoEstacion: '81108',
      cdgoAdmon: '0071',
      nmroPrioridad: 96,
      descEstacion: null,
      desgEstacion: 'CALAHORRA',
      cdgoUic: '81108',
      clave: '0071,81108,81108',
      desgEstacionPlano: 'CALAHORRA',
      activo: true
    },
    {
      cdgoEstacion: '30002',
      cdgoAdmon: '0071',
      nmroPrioridad: 97,
      descEstacion: null,
      desgEstacion: 'PLASENCIA',
      cdgoUic: '30002',
      clave: '0071,30002,30002',
      desgEstacionPlano: 'PLASENCIA',
      activo: true
    },
    {
      cdgoEstacion: '20200',
      cdgoAdmon: '0071',
      nmroPrioridad: 98,
      descEstacion: null,
      desgEstacion: 'PONFERRADA',
      cdgoUic: '20200',
      clave: '0071,20200,20200',
      desgEstacionPlano: 'PONFERRADA',
      activo: true
    },
    {
      cdgoEstacion: '55007',
      cdgoAdmon: '0071',
      nmroPrioridad: 99,
      descEstacion: null,
      desgEstacion: 'RONDA',
      cdgoUic: '55007',
      clave: '0071,55007,55007',
      desgEstacionPlano: 'RONDA',
      activo: true
    },
    {
      cdgoEstacion: '60500',
      cdgoAdmon: '0071',
      nmroPrioridad: 100,
      descEstacion: null,
      desgEstacion: 'VILLARROBLEDO',
      cdgoUic: '60500',
      clave: '0071,60500,60500',
      desgEstacionPlano: 'VILLARROBLEDO',
      activo: true
    },
    {
      cdgoEstacion: '60800',
      cdgoAdmon: '0071',
      nmroPrioridad: 101,
      descEstacion: null,
      desgEstacion: 'ALMANSA',
      cdgoUic: '60800',
      clave: '0071,60800,60800',
      desgEstacionPlano: 'ALMANSA',
      activo: true
    },
    {
      cdgoEstacion: '50100',
      cdgoAdmon: '0071',
      nmroPrioridad: 102,
      descEstacion: null,
      desgEstacion: 'MANZANARES',
      cdgoUic: '50100',
      clave: '0071,50100,50100',
      desgEstacionPlano: 'MANZANARES',
      activo: true
    },
    {
      cdgoEstacion: '50102',
      cdgoAdmon: '0071',
      nmroPrioridad: 103,
      descEstacion: null,
      desgEstacion: 'VALDEPEÑAS',
      cdgoUic: '50102',
      clave: '0071,50102,50102',
      desgEstacionPlano: 'VALDEPENAS',
      activo: true
    },
    {
      cdgoEstacion: '37704',
      cdgoAdmon: '0071',
      nmroPrioridad: 104,
      descEstacion: null,
      desgEstacion: 'VILLANUEVA DE CORDOBA-LOS PEDROCHES',
      cdgoUic: '37704',
      clave: '0071,37704,37704',
      desgEstacionPlano: 'VILLANUEVA DE CORDOBA-LOS PEDROCHES',
      activo: true
    },
    {
      cdgoEstacion: '51103',
      cdgoAdmon: '0071',
      nmroPrioridad: 106,
      descEstacion: null,
      desgEstacion: 'DOS HERMANAS',
      cdgoUic: '51103',
      clave: '0071,51103,51103',
      desgEstacionPlano: 'DOS HERMANAS',
      activo: true
    },
    {
      cdgoEstacion: '94404',
      cdgoAdmon: '0094',
      nmroPrioridad: 107,
      descEstacion: null,
      desgEstacion: 'LISBOA-ESTACION ORIENTE',
      cdgoUic: '00404',
      clave: '0094,94404,00404',
      desgEstacionPlano: 'LISBOA-ESTACION ORIENTE',
      activo: true
    },
    {
      cdgoEstacion: '51203',
      cdgoAdmon: '0071',
      nmroPrioridad: 108,
      descEstacion: null,
      desgEstacion: 'LEBRIJA',
      cdgoUic: '51203',
      clave: '0071,51203,51203',
      desgEstacionPlano: 'LEBRIJA',
      activo: true
    },
    {
      cdgoEstacion: '62103',
      cdgoAdmon: '0071',
      nmroPrioridad: 109,
      descEstacion: null,
      desgEstacion: 'ELCHE PARQUE/ELX PARC',
      cdgoUic: '62103',
      clave: '0071,62103,62103',
      desgEstacionPlano: 'ELCHE PARQUE/ELX PARC',
      activo: true
    },
    {
      cdgoEstacion: '61303',
      cdgoAdmon: '0071',
      nmroPrioridad: 110,
      descEstacion: null,
      desgEstacion: 'BALSICAS-MAR MENOR',
      cdgoUic: '61303',
      clave: '0071,61303,61303',
      desgEstacionPlano: 'BALSICAS-MAR MENOR',
      activo: true
    },
    {
      cdgoEstacion: '80108',
      cdgoAdmon: '0071',
      nmroPrioridad: 111,
      descEstacion: null,
      desgEstacion: 'TAFALLA',
      cdgoUic: '80108',
      clave: '0071,80108,80108',
      desgEstacionPlano: 'TAFALLA',
      activo: true
    },
    {
      cdgoEstacion: '65200',
      cdgoAdmon: '0071',
      nmroPrioridad: 112,
      descEstacion: null,
      desgEstacion: 'SAGUNT/SAGUNTO',
      cdgoUic: '65200',
      clave: '0071,65200,65200',
      desgEstacionPlano: 'SAGUNT/SAGUNTO',
      activo: true
    },
    {
      cdgoEstacion: '35206',
      cdgoAdmon: '0071',
      nmroPrioridad: 113,
      descEstacion: null,
      desgEstacion: 'NAVALMORAL DE LA MATA',
      cdgoUic: '35206',
      clave: '0071,35206,35206',
      desgEstacionPlano: 'NAVALMORAL DE LA MATA',
      activo: true
    },
    {
      cdgoEstacion: '21010',
      cdgoAdmon: '0071',
      nmroPrioridad: 114,
      descEstacion: null,
      desgEstacion: 'FERROL',
      cdgoUic: '21010',
      clave: '0071,21010,21010',
      desgEstacionPlano: 'FERROL',
      activo: true
    },
    {
      cdgoEstacion: '01007',
      cdgoAdmon: '0071',
      nmroPrioridad: 115,
      descEstacion: null,
      desgEstacion: 'OSUNA',
      cdgoUic: '01007',
      clave: '0071,01007,01007',
      desgEstacionPlano: 'OSUNA',
      activo: true
    },
    {
      cdgoEstacion: '70807',
      cdgoAdmon: '0071',
      nmroPrioridad: 116,
      descEstacion: null,
      desgEstacion: 'ZARAGOZA GOYA',
      cdgoUic: '70807',
      clave: '0071,70807,70807',
      desgEstacionPlano: 'ZARAGOZA GOYA',
      activo: true
    },
    {
      cdgoEstacion: '37606',
      cdgoAdmon: '0071',
      nmroPrioridad: 117,
      descEstacion: null,
      desgEstacion: 'BADAJOZ',
      cdgoUic: '37606',
      clave: '0071,37606,37606',
      desgEstacionPlano: 'BADAJOZ',
      activo: true
    },
    {
      cdgoEstacion: '20300',
      cdgoAdmon: '0071',
      nmroPrioridad: 118,
      descEstacion: null,
      desgEstacion: 'MONFORTE DE LEMOS',
      cdgoUic: '20300',
      clave: '0071,20300,20300',
      desgEstacionPlano: 'MONFORTE DE LEMOS',
      activo: true
    },
    {
      cdgoEstacion: '35200',
      cdgoAdmon: '0071',
      nmroPrioridad: 119,
      descEstacion: null,
      desgEstacion: 'TALAVERA DE LA REINA',
      cdgoUic: '35200',
      clave: '0071,35200,35200',
      desgEstacionPlano: 'TALAVERA DE LA REINA',
      activo: true
    },
    {
      cdgoEstacion: '60406',
      cdgoAdmon: '0071',
      nmroPrioridad: 120,
      descEstacion: null,
      desgEstacion: 'SOCUELLAMOS',
      cdgoUic: '60406',
      clave: '0071,60406,60406',
      desgEstacionPlano: 'SOCUELLAMOS',
      activo: true
    },
    {
      cdgoEstacion: '20309',
      cdgoAdmon: '0071',
      nmroPrioridad: 121,
      descEstacion: null,
      desgEstacion: 'LUGO',
      cdgoUic: '20309',
      clave: '0071,20309,20309',
      desgEstacionPlano: 'LUGO',
      activo: true
    },
    {
      cdgoEstacion: '10500',
      cdgoAdmon: '0071',
      nmroPrioridad: 123,
      descEstacion: null,
      desgEstacion: 'MEDINA DEL CAMPO',
      cdgoUic: '10500',
      clave: '0071,10500,10500',
      desgEstacionPlano: 'MEDINA DEL CAMPO',
      activo: true
    },
    {
      cdgoEstacion: '65402',
      cdgoAdmon: '0071',
      nmroPrioridad: 124,
      descEstacion: null,
      desgEstacion: "L'ALDEA-AMPOSTA-TORTOSA",
      cdgoUic: '65402',
      clave: '0071,65402,65402',
      desgEstacionPlano: "L'ALDEA-AMPOSTA-TORTOSA",
      activo: true
    },
    {
      cdgoEstacion: '79400',
      cdgoAdmon: '0071',
      nmroPrioridad: 125,
      descEstacion: null,
      desgEstacion: 'BARCELONA-ESTACIO DE FRANÇA',
      cdgoUic: '79400',
      clave: '0071,79400,79400',
      desgEstacionPlano: 'BARCELONA-ESTACIO DE FRANCA',
      activo: true
    },
    {
      cdgoEstacion: '06006',
      cdgoAdmon: '0071',
      nmroPrioridad: 126,
      descEstacion: null,
      desgEstacion: 'LORCA SUTULLENA',
      cdgoUic: '06006',
      clave: '0071,06006,06006',
      desgEstacionPlano: 'LORCA SUTULLENA',
      activo: true
    },
    {
      cdgoEstacion: '62002',
      cdgoAdmon: '0071',
      nmroPrioridad: 127,
      descEstacion: null,
      desgEstacion: 'ORIHUELA-MIGUEL HERNANDEZ',
      cdgoUic: '62002',
      clave: '0071,62002,62002',
      desgEstacionPlano: 'ORIHUELA-MIGUEL HERNANDEZ',
      activo: true
    },
    {
      cdgoEstacion: '55018',
      cdgoAdmon: '0071',
      nmroPrioridad: 128,
      descEstacion: null,
      desgEstacion: 'SAN ROQUE-LA LINEA',
      cdgoUic: '55018',
      clave: '0071,55018,55018',
      desgEstacionPlano: 'SAN ROQUE-LA LINEA',
      activo: true
    },
    {
      cdgoEstacion: '60505',
      cdgoAdmon: '0071',
      nmroPrioridad: 129,
      descEstacion: null,
      desgEstacion: 'LA RODA DE ALBACETE',
      cdgoUic: '60505',
      clave: '0071,60505,60505',
      desgEstacionPlano: 'LA RODA DE ALBACETE',
      activo: true
    },
    {
      cdgoEstacion: '35001',
      cdgoAdmon: '0071',
      nmroPrioridad: 130,
      descEstacion: null,
      desgEstacion: 'LEGANES',
      cdgoUic: '35001',
      clave: '0071,35001,35001',
      desgEstacionPlano: 'LEGANES',
      activo: true
    },
    {
      cdgoEstacion: '81200',
      cdgoAdmon: '0071',
      nmroPrioridad: 131,
      descEstacion: null,
      desgEstacion: 'CASTEJON DE EBRO',
      cdgoUic: '81200',
      clave: '0071,81200,81200',
      desgEstacionPlano: 'CASTEJON DE EBRO',
      activo: true
    },
    {
      cdgoEstacion: '60200',
      cdgoAdmon: '0071',
      nmroPrioridad: 132,
      descEstacion: null,
      desgEstacion: 'ARANJUEZ',
      cdgoUic: '60200',
      clave: '0071,60200,60200',
      desgEstacionPlano: 'ARANJUEZ',
      activo: true
    },
    {
      cdgoEstacion: '50403',
      cdgoAdmon: '0071',
      nmroPrioridad: 133,
      descEstacion: null,
      desgEstacion: 'ANDUJAR',
      cdgoUic: '50403',
      clave: '0071,50403,50403',
      desgEstacionPlano: 'ANDUJAR',
      activo: true
    },
    {
      cdgoEstacion: '31205',
      cdgoAdmon: '0071',
      nmroPrioridad: 134,
      descEstacion: null,
      desgEstacion: 'A GUDIÑA',
      cdgoUic: '31205',
      clave: '0071,31205,31205',
      desgEstacionPlano: 'A GUDINA',
      activo: true
    },
    {
      cdgoEstacion: '37500',
      cdgoAdmon: '0071',
      nmroPrioridad: 135,
      descEstacion: null,
      desgEstacion: 'MERIDA',
      cdgoUic: '37500',
      clave: '0071,37500,37500',
      desgEstacionPlano: 'MERIDA',
      activo: true
    },
    {
      cdgoEstacion: '14202',
      cdgoAdmon: '0071',
      nmroPrioridad: 136,
      descEstacion: null,
      desgEstacion: 'REINOSA',
      cdgoUic: '14202',
      clave: '0071,14202,14202',
      desgEstacionPlano: 'REINOSA',
      activo: true
    },
    {
      cdgoEstacion: '08240',
      cdgoAdmon: '0071',
      nmroPrioridad: 137,
      descEstacion: null,
      desgEstacion: 'MEDINA DEL CAMPO ALTA VELOCIDAD',
      cdgoUic: null,
      clave: '0071,08240,null',
      desgEstacionPlano: 'MEDINA DEL CAMPO ALTA VELOCIDAD',
      activo: true
    },
    {
      cdgoEstacion: '94401',
      cdgoAdmon: '0094',
      nmroPrioridad: 138,
      descEstacion: null,
      desgEstacion: 'LISBOA-SANTA APOLONIA',
      cdgoUic: '00401',
      clave: '0094,94401,00401',
      desgEstacionPlano: 'LISBOA-SANTA APOLONIA',
      activo: true
    },
    {
      cdgoEstacion: '22300',
      cdgoAdmon: '0071',
      nmroPrioridad: 139,
      descEstacion: null,
      desgEstacion: 'REDONDELA',
      cdgoUic: '22300',
      clave: '0071,22300,22300',
      desgEstacionPlano: 'REDONDELA',
      activo: true
    },
    {
      cdgoEstacion: '51401',
      cdgoAdmon: '0071',
      nmroPrioridad: 140,
      descEstacion: null,
      desgEstacion: 'PUERTO REAL',
      cdgoUic: '51401',
      clave: '0071,51401,51401',
      desgEstacionPlano: 'PUERTO REAL',
      activo: true
    },
    {
      cdgoEstacion: '65318',
      cdgoAdmon: '0071',
      nmroPrioridad: 141,
      descEstacion: null,
      desgEstacion: 'BENICASSIM',
      cdgoUic: '65318',
      clave: '0071,65318,65318',
      desgEstacionPlano: 'BENICASSIM',
      activo: true
    },
    {
      cdgoEstacion: '03213',
      cdgoAdmon: '0071',
      nmroPrioridad: 142,
      descEstacion: null,
      desgEstacion: 'REQUENA UTIEL',
      cdgoUic: '03213',
      clave: '0071,03213,03213',
      desgEstacionPlano: 'REQUENA UTIEL',
      activo: true
    },
    {
      cdgoEstacion: '01005',
      cdgoAdmon: '0071',
      nmroPrioridad: 143,
      descEstacion: null,
      desgEstacion: 'MARCHENA',
      cdgoUic: '01005',
      clave: '0071,01005,01005',
      desgEstacionPlano: 'MARCHENA',
      activo: true
    },
    {
      cdgoEstacion: '94452',
      cdgoAdmon: '0094',
      nmroPrioridad: 144,
      descEstacion: null,
      desgEstacion: 'COIMBRA-B',
      cdgoUic: '00452',
      clave: '0094,94452,00452',
      desgEstacionPlano: 'COIMBRA-B',
      activo: true
    },
    {
      cdgoEstacion: '10200',
      cdgoAdmon: '0071',
      nmroPrioridad: 145,
      descEstacion: null,
      desgEstacion: 'VILLALBA DE GUADARRAMA',
      cdgoUic: '10200',
      clave: '0071,10200,10200',
      desgEstacionPlano: 'VILLALBA DE GUADARRAMA',
      activo: true
    },
    {
      cdgoEstacion: '43011',
      cdgoAdmon: '0071',
      nmroPrioridad: 146,
      descEstacion: null,
      desgEstacion: 'LA PALMA DEL CONDADO',
      cdgoUic: '43011',
      clave: '0071,43011,43011',
      desgEstacionPlano: 'LA PALMA DEL CONDADO',
      activo: true
    },
    {
      cdgoEstacion: '01009',
      cdgoAdmon: '0071',
      nmroPrioridad: 147,
      descEstacion: null,
      desgEstacion: 'PEDRERA',
      cdgoUic: '01009',
      clave: '0071,01009,01009',
      desgEstacionPlano: 'PEDRERA',
      activo: true
    },
    {
      cdgoEstacion: '14114',
      cdgoAdmon: '0071',
      nmroPrioridad: 148,
      descEstacion: null,
      desgEstacion: 'AGUILAR DE CAMPOO',
      cdgoUic: '14114',
      clave: '0071,14114,14114',
      desgEstacionPlano: 'AGUILAR DE CAMPOO',
      activo: true
    },
    {
      cdgoEstacion: '20100',
      cdgoAdmon: '0071',
      nmroPrioridad: 149,
      descEstacion: null,
      desgEstacion: 'ASTORGA',
      cdgoUic: '20100',
      clave: '0071,20100,20100',
      desgEstacionPlano: 'ASTORGA',
      activo: true
    },
    {
      cdgoEstacion: '35105',
      cdgoAdmon: '0071',
      nmroPrioridad: 150,
      descEstacion: null,
      desgEstacion: 'TORRIJOS',
      cdgoUic: '35105',
      clave: '0071,35105,35105',
      desgEstacionPlano: 'TORRIJOS',
      activo: true
    },
    {
      cdgoEstacion: '71700',
      cdgoAdmon: '0071',
      nmroPrioridad: 151,
      descEstacion: null,
      desgEstacion: 'VILANOVA I LA GELTRU',
      cdgoUic: '71700',
      clave: '0071,71700,71700',
      desgEstacionPlano: 'VILANOVA I LA GELTRU',
      activo: true
    },
    {
      cdgoEstacion: '20211',
      cdgoAdmon: '0071',
      nmroPrioridad: 152,
      descEstacion: null,
      desgEstacion: 'O BARCO DE VALDEORRAS',
      cdgoUic: '20211',
      clave: '0071,20211,20211',
      desgEstacionPlano: 'O BARCO DE VALDEORRAS',
      activo: true
    },
    {
      cdgoEstacion: '65304',
      cdgoAdmon: '0071',
      nmroPrioridad: 153,
      descEstacion: null,
      desgEstacion: 'ORPESA',
      cdgoUic: '65304',
      clave: '0071,65304,65304',
      desgEstacionPlano: 'ORPESA',
      activo: true
    },
    {
      cdgoEstacion: '56200',
      cdgoAdmon: '0071',
      nmroPrioridad: 154,
      descEstacion: null,
      desgEstacion: 'GUADIX',
      cdgoUic: '56200',
      clave: '0071,56200,56200',
      desgEstacionPlano: 'GUADIX',
      activo: true
    },
    {
      cdgoEstacion: '05019',
      cdgoAdmon: '0071',
      nmroPrioridad: 155,
      descEstacion: null,
      desgEstacion: 'ANTEQUERA-CIUDAD',
      cdgoUic: '05019',
      clave: '0071,05019,05019',
      desgEstacionPlano: 'ANTEQUERA-CIUDAD',
      activo: true
    },
    {
      cdgoEstacion: '51200',
      cdgoAdmon: '0071',
      nmroPrioridad: 156,
      descEstacion: null,
      desgEstacion: 'UTRERA',
      cdgoUic: '51200',
      clave: '0071,51200,51200',
      desgEstacionPlano: 'UTRERA',
      activo: true
    },
    {
      cdgoEstacion: '50506',
      cdgoAdmon: '0071',
      nmroPrioridad: 157,
      descEstacion: null,
      desgEstacion: 'PALMA DEL RIO',
      cdgoUic: '50506',
      clave: '0071,50506,50506',
      desgEstacionPlano: 'PALMA DEL RIO',
      activo: true
    },
    {
      cdgoEstacion: '34010',
      cdgoAdmon: '0071',
      nmroPrioridad: 159,
      descEstacion: null,
      desgEstacion: 'PEÑARANDA DE BRACAMONTE',
      cdgoUic: '34010',
      clave: '0071,34010,34010',
      desgEstacionPlano: 'PENARANDA DE BRACAMONTE',
      activo: true
    },
    {
      cdgoEstacion: '12100',
      cdgoAdmon: '0071',
      nmroPrioridad: 160,
      descEstacion: null,
      desgEstacion: 'SEGOVIA',
      cdgoUic: '12100',
      clave: '0071,12100,12100',
      desgEstacionPlano: 'SEGOVIA',
      activo: true
    },
    {
      cdgoEstacion: '22200',
      cdgoAdmon: '0071',
      nmroPrioridad: 161,
      descEstacion: null,
      desgEstacion: 'GUILLAREI',
      cdgoUic: '22200',
      clave: '0071,22200,22200',
      desgEstacionPlano: 'GUILLAREI',
      activo: true
    },
    {
      cdgoEstacion: '60300',
      cdgoAdmon: '0071',
      nmroPrioridad: 162,
      descEstacion: null,
      desgEstacion: 'VILLACAÑAS',
      cdgoUic: '60300',
      clave: '0071,60300,60300',
      desgEstacionPlano: 'VILLACANAS',
      activo: true
    },
    {
      cdgoEstacion: '50207',
      cdgoAdmon: '0071',
      nmroPrioridad: 163,
      descEstacion: null,
      desgEstacion: 'VILCHES',
      cdgoUic: '50207',
      clave: '0071,50207,50207',
      desgEstacionPlano: 'VILCHES',
      activo: true
    },
    {
      cdgoEstacion: '60402',
      cdgoAdmon: '0071',
      nmroPrioridad: 164,
      descEstacion: null,
      desgEstacion: 'CAMPO DE CRIPTANA',
      cdgoUic: '60402',
      clave: '0071,60402,60402',
      desgEstacionPlano: 'CAMPO DE CRIPTANA',
      activo: true
    },
    {
      cdgoEstacion: '15009',
      cdgoAdmon: '0071',
      nmroPrioridad: 165,
      descEstacion: null,
      desgEstacion: 'SAHAGUN',
      cdgoUic: '15009',
      clave: '0071,15009,15009',
      desgEstacionPlano: 'SAHAGUN',
      activo: true
    },
    {
      cdgoEstacion: '65003',
      cdgoAdmon: '0071',
      nmroPrioridad: 166,
      descEstacion: null,
      desgEstacion: 'VALENCIA-CABANYAL',
      cdgoUic: '65003',
      clave: '0071,65003,65003',
      desgEstacionPlano: 'VALENCIA-CABANYAL',
      activo: true
    },
    {
      cdgoEstacion: '20305',
      cdgoAdmon: '0071',
      nmroPrioridad: 167,
      descEstacion: null,
      desgEstacion: 'SARRIA',
      cdgoUic: '20305',
      clave: '0071,20305,20305',
      desgEstacionPlano: 'SARRIA',
      activo: true
    },
    {
      cdgoEstacion: '61009',
      cdgoAdmon: '0071',
      nmroPrioridad: 168,
      descEstacion: null,
      desgEstacion: 'CALASPARRA',
      cdgoUic: '61009',
      clave: '0071,61009,61009',
      desgEstacionPlano: 'CALASPARRA',
      activo: true
    },
    {
      cdgoEstacion: '20213',
      cdgoAdmon: '0071',
      nmroPrioridad: 169,
      descEstacion: null,
      desgEstacion: 'A RUA-PETIN',
      cdgoUic: '20213',
      clave: '0071,20213,20213',
      desgEstacionPlano: 'A RUA-PETIN',
      activo: true
    },
    {
      cdgoEstacion: '35203',
      cdgoAdmon: '0071',
      nmroPrioridad: 170,
      descEstacion: null,
      desgEstacion: 'OROPESA DE TOLEDO',
      cdgoUic: '35203',
      clave: '0071,35203,35203',
      desgEstacionPlano: 'OROPESA DE TOLEDO',
      activo: true
    },
    {
      cdgoEstacion: '15203',
      cdgoAdmon: '0071',
      nmroPrioridad: 171,
      descEstacion: null,
      desgEstacion: 'MIERES-PUENTE',
      cdgoUic: '15203',
      clave: '0071,15203,15203',
      desgEstacionPlano: 'MIERES-PUENTE',
      activo: true
    },
    {
      cdgoEstacion: '51110',
      cdgoAdmon: '0071',
      nmroPrioridad: 172,
      descEstacion: null,
      desgEstacion: 'SEVILLA-VIRGEN DEL ROCIO',
      cdgoUic: '51110',
      clave: '0071,51110,51110',
      desgEstacionPlano: 'SEVILLA-VIRGEN DEL ROCIO',
      activo: true
    },
    {
      cdgoEstacion: '61005',
      cdgoAdmon: '0071',
      nmroPrioridad: 173,
      descEstacion: null,
      desgEstacion: 'HELLIN',
      cdgoUic: '61005',
      clave: '0071,61005,61005',
      desgEstacionPlano: 'HELLIN',
      activo: true
    },
    {
      cdgoEstacion: '67200',
      cdgoAdmon: '0071',
      nmroPrioridad: 174,
      descEstacion: null,
      desgEstacion: 'TERUEL',
      cdgoUic: '67200',
      clave: '0071,67200,67200',
      desgEstacionPlano: 'TERUEL',
      activo: true
    },
    {
      cdgoEstacion: '81002',
      cdgoAdmon: '0071',
      nmroPrioridad: 175,
      descEstacion: null,
      desgEstacion: 'HARO',
      cdgoUic: '81002',
      clave: '0071,81002,81002',
      desgEstacionPlano: 'HARO',
      activo: true
    },
    {
      cdgoEstacion: '11500',
      cdgoAdmon: '0071',
      nmroPrioridad: 176,
      descEstacion: null,
      desgEstacion: 'TOLOSA',
      cdgoUic: '11500',
      clave: '0071,11500,11500',
      desgEstacionPlano: 'TOLOSA',
      activo: true
    },
    {
      cdgoEstacion: '50400',
      cdgoAdmon: '0071',
      nmroPrioridad: 177,
      descEstacion: null,
      desgEstacion: 'ESPELUY',
      cdgoUic: '50400',
      clave: '0071,50400,50400',
      desgEstacionPlano: 'ESPELUY',
      activo: true
    },
    {
      cdgoEstacion: '65400',
      cdgoAdmon: '0071',
      nmroPrioridad: 178,
      descEstacion: null,
      desgEstacion: 'TORTOSA',
      cdgoUic: '65400',
      clave: '0071,65400,65400',
      desgEstacionPlano: 'TORTOSA',
      activo: true
    },
    {
      cdgoEstacion: '50407',
      cdgoAdmon: '0071',
      nmroPrioridad: 179,
      descEstacion: null,
      desgEstacion: 'VILLA DEL RIO',
      cdgoUic: '50407',
      clave: '0071,50407,50407',
      desgEstacionPlano: 'VILLA DEL RIO',
      activo: true
    },
    {
      cdgoEstacion: '11300',
      cdgoAdmon: '0071',
      nmroPrioridad: 180,
      descEstacion: null,
      desgEstacion: 'ALTSASU/ALSASUA-ESTACION',
      cdgoUic: '11300',
      clave: '0071,11300,11300',
      desgEstacionPlano: 'ALTSASU/ALSASUA-ESTACION',
      activo: true
    },
    {
      cdgoEstacion: '70302',
      cdgoAdmon: '0071',
      nmroPrioridad: 181,
      descEstacion: null,
      desgEstacion: 'SIGUENZA',
      cdgoUic: '70302',
      clave: '0071,70302,70302',
      desgEstacionPlano: 'SIGUENZA',
      activo: true
    },
    {
      cdgoEstacion: '82100',
      cdgoAdmon: '0071',
      nmroPrioridad: 182,
      descEstacion: null,
      desgEstacion: 'SORIA',
      cdgoUic: '82100',
      clave: '0071,82100,82100',
      desgEstacionPlano: 'SORIA',
      activo: true
    },
    {
      cdgoEstacion: '69110',
      cdgoAdmon: '0071',
      nmroPrioridad: 183,
      descEstacion: null,
      desgEstacion: 'GANDIA',
      cdgoUic: '69110',
      clave: '0071,69110,69110',
      desgEstacionPlano: 'GANDIA',
      activo: true
    },
    {
      cdgoEstacion: '37311',
      cdgoAdmon: '0071',
      nmroPrioridad: 184,
      descEstacion: null,
      desgEstacion: 'CABEZA DE BUEY',
      cdgoUic: '37311',
      clave: '0071,37311,37311',
      desgEstacionPlano: 'CABEZA DE BUEY',
      activo: true
    },
    {
      cdgoEstacion: '31200',
      cdgoAdmon: '0071',
      nmroPrioridad: 185,
      descEstacion: null,
      desgEstacion: 'PUEBLA DE SANABRIA SALIDA',
      cdgoUic: '31200',
      clave: '0071,31200,31200',
      desgEstacionPlano: 'PUEBLA DE SANABRIA SALIDA',
      activo: true
    },
    {
      cdgoEstacion: '66100',
      cdgoAdmon: '0071',
      nmroPrioridad: 186,
      descEstacion: null,
      desgEstacion: 'CUENCA',
      cdgoUic: '66100',
      clave: '0071,66100,66100',
      desgEstacionPlano: 'CUENCA',
      activo: true
    },
    {
      cdgoEstacion: '61304',
      cdgoAdmon: '0071',
      nmroPrioridad: 187,
      descEstacion: null,
      desgEstacion: 'TORRE-PACHECO',
      cdgoUic: '61304',
      clave: '0071,61304,61304',
      desgEstacionPlano: 'TORRE-PACHECO',
      activo: true
    },
    {
      cdgoEstacion: '99853',
      cdgoAdmon: '0071',
      nmroPrioridad: 188,
      descEstacion: null,
      desgEstacion: 'VIELHA-BUS',
      cdgoUic: null,
      clave: '0071,99853,null',
      desgEstacionPlano: 'VIELHA-BUS',
      activo: true
    },
    {
      cdgoEstacion: '78301',
      cdgoAdmon: '0071',
      nmroPrioridad: 189,
      descEstacion: null,
      desgEstacion: 'MONZON-RIO CINCA',
      cdgoUic: '78301',
      clave: '0071,78301,78301',
      desgEstacionPlano: 'MONZON-RIO CINCA',
      activo: true
    },
    {
      cdgoEstacion: '30110',
      cdgoAdmon: '0071',
      nmroPrioridad: 190,
      descEstacion: null,
      desgEstacion: 'SALAMANCA-LA ALAMEDILLA',
      cdgoUic: '30110',
      clave: '0071,30110,30110',
      desgEstacionPlano: 'SALAMANCA-LA ALAMEDILLA',
      activo: true
    },
    {
      cdgoEstacion: '61012',
      cdgoAdmon: '0071',
      nmroPrioridad: 191,
      descEstacion: null,
      desgEstacion: 'CIEZA',
      cdgoUic: '61012',
      clave: '0071,61012,61012',
      desgEstacionPlano: 'CIEZA',
      activo: true
    },
    {
      cdgoEstacion: '30000',
      cdgoAdmon: '0071',
      nmroPrioridad: 192,
      descEstacion: null,
      desgEstacion: 'MONFRAGUE',
      cdgoUic: '30000',
      clave: '0071,30000,30000',
      desgEstacionPlano: 'MONFRAGUE',
      activo: true
    },
    {
      cdgoEstacion: '50504',
      cdgoAdmon: '0071',
      nmroPrioridad: 193,
      descEstacion: null,
      desgEstacion: 'POSADAS',
      cdgoUic: '50504',
      clave: '0071,50504,50504',
      desgEstacionPlano: 'POSADAS',
      activo: true
    },
    {
      cdgoEstacion: '71100',
      cdgoAdmon: '0071',
      nmroPrioridad: 194,
      descEstacion: null,
      desgEstacion: 'ZARAGOZA MIRAFLORES',
      cdgoUic: '71100',
      clave: '0071,71100,71100',
      desgEstacionPlano: 'ZARAGOZA MIRAFLORES',
      activo: true
    },
    {
      cdgoEstacion: '66212',
      cdgoAdmon: '0071',
      nmroPrioridad: 195,
      descEstacion: null,
      desgEstacion: 'VALENCIA SANT ISIDRE',
      cdgoUic: '66212',
      clave: '0071,66212,66212',
      desgEstacionPlano: 'VALENCIA SANT ISIDRE',
      activo: true
    },
    {
      cdgoEstacion: '51205',
      cdgoAdmon: '0071',
      nmroPrioridad: 196,
      descEstacion: null,
      desgEstacion: 'JEREZ AEROPUERTO',
      cdgoUic: '51205',
      clave: '0071,51205,51205',
      desgEstacionPlano: 'JEREZ AEROPUERTO',
      activo: true
    },
    {
      cdgoEstacion: '56004',
      cdgoAdmon: '0071',
      nmroPrioridad: 197,
      descEstacion: null,
      desgEstacion: 'JODAR-UBEDA',
      cdgoUic: '56004',
      clave: '0071,56004,56004',
      desgEstacionPlano: 'JODAR-UBEDA',
      activo: true
    },
    {
      cdgoEstacion: '50600',
      cdgoAdmon: '0071',
      nmroPrioridad: 198,
      descEstacion: null,
      desgEstacion: 'LORA DEL RIO',
      cdgoUic: '50600',
      clave: '0071,50600,50600',
      desgEstacionPlano: 'LORA DEL RIO',
      activo: true
    },
    {
      cdgoEstacion: '62003',
      cdgoAdmon: '0071',
      nmroPrioridad: 199,
      descEstacion: null,
      desgEstacion: 'CALLOSA DE SEGURA',
      cdgoUic: '62003',
      clave: '0071,62003,62003',
      desgEstacionPlano: 'CALLOSA DE SEGURA',
      activo: true
    },
    {
      cdgoEstacion: '81110',
      cdgoAdmon: '0071',
      nmroPrioridad: 200,
      descEstacion: null,
      desgEstacion: 'ALFARO',
      cdgoUic: '81110',
      clave: '0071,81110,81110',
      desgEstacionPlano: 'ALFARO',
      activo: true
    },
    {
      cdgoEstacion: '71600',
      cdgoAdmon: '0071',
      nmroPrioridad: 201,
      descEstacion: null,
      desgEstacion: 'SANT VICENÇ DE CALDERS',
      cdgoUic: '71600',
      clave: '0071,71600,71600',
      desgEstacionPlano: 'SANT VICENC DE CALDERS',
      activo: true
    },
    {
      cdgoEstacion: '62102',
      cdgoAdmon: '0071',
      nmroPrioridad: 202,
      descEstacion: null,
      desgEstacion: 'ELCHE/ELX CARRUS',
      cdgoUic: '62102',
      clave: '0071,62102,62102',
      desgEstacionPlano: 'ELCHE/ELX CARRUS',
      activo: true
    },
    {
      cdgoEstacion: '71802',
      cdgoAdmon: '0071',
      nmroPrioridad: 203,
      descEstacion: null,
      desgEstacion: 'BARCELONA-PASSEIG DE GRACIA',
      cdgoUic: '71802',
      clave: '0071,71802,71802',
      desgEstacionPlano: 'BARCELONA-PASSEIG DE GRACIA',
      activo: true
    },
    {
      cdgoEstacion: '79009',
      cdgoAdmon: '0071',
      nmroPrioridad: 204,
      descEstacion: null,
      desgEstacion: 'BARCELONA-CLOT-ARAGO',
      cdgoUic: '79009',
      clave: '0071,79009,79009',
      desgEstacionPlano: 'BARCELONA-CLOT-ARAGO',
      activo: true
    },
    {
      cdgoEstacion: '70200',
      cdgoAdmon: '0071',
      nmroPrioridad: 205,
      descEstacion: null,
      desgEstacion: 'GUADALAJARA',
      cdgoUic: '70200',
      clave: '0071,70200,70200',
      desgEstacionPlano: 'GUADALAJARA',
      activo: true
    },
    {
      cdgoEstacion: '20216',
      cdgoAdmon: '0071',
      nmroPrioridad: 206,
      descEstacion: null,
      desgEstacion: 'SAN CLODIO-QUIROGA',
      cdgoUic: '20216',
      clave: '0071,20216,20216',
      desgEstacionPlano: 'SAN CLODIO-QUIROGA',
      activo: true
    },
    {
      cdgoEstacion: '23011',
      cdgoAdmon: '0071',
      nmroPrioridad: 207,
      descEstacion: null,
      desgEstacion: 'PADRON',
      cdgoUic: '23011',
      clave: '0071,23011,23011',
      desgEstacionPlano: 'PADRON',
      activo: true
    },
    {
      cdgoEstacion: '50200',
      cdgoAdmon: '0071',
      nmroPrioridad: 208,
      descEstacion: null,
      desgEstacion: 'SANTA CRUZ DE MUDELA',
      cdgoUic: '50200',
      clave: '0071,50200,50200',
      desgEstacionPlano: 'SANTA CRUZ DE MUDELA',
      activo: true
    },
    {
      cdgoEstacion: '79309',
      cdgoAdmon: '0071',
      nmroPrioridad: 209,
      descEstacion: null,
      desgEstacion: 'FIGUERES',
      cdgoUic: '79309',
      clave: '0071,79309,79309',
      desgEstacionPlano: 'FIGUERES',
      activo: true
    },
    {
      cdgoEstacion: '54400',
      cdgoAdmon: '0071',
      nmroPrioridad: 210,
      descEstacion: null,
      desgEstacion: 'BOBADILLA',
      cdgoUic: '54400',
      clave: '0071,54400,54400',
      desgEstacionPlano: 'BOBADILLA',
      activo: true
    },
    {
      cdgoEstacion: '37407',
      cdgoAdmon: '0071',
      nmroPrioridad: 211,
      descEstacion: null,
      desgEstacion: 'DON BENITO',
      cdgoUic: '37407',
      clave: '0071,37407,37407',
      desgEstacionPlano: 'DON BENITO',
      activo: true
    },
    {
      cdgoEstacion: '71400',
      cdgoAdmon: '0071',
      nmroPrioridad: 212,
      descEstacion: null,
      desgEstacion: 'REUS',
      cdgoUic: '71400',
      clave: '0071,71400,71400',
      desgEstacionPlano: 'REUS',
      activo: true
    },
    {
      cdgoEstacion: '35303',
      cdgoAdmon: '0071',
      nmroPrioridad: 213,
      descEstacion: null,
      desgEstacion: 'CAÑAVERAL',
      cdgoUic: '35303',
      clave: '0071,35303,35303',
      desgEstacionPlano: 'CANAVERAL',
      activo: true
    },
    {
      cdgoEstacion: '37406',
      cdgoAdmon: '0071',
      nmroPrioridad: 214,
      descEstacion: null,
      desgEstacion: 'VILLANUEVA DE LA SERENA',
      cdgoUic: '37406',
      clave: '0071,37406,37406',
      desgEstacionPlano: 'VILLANUEVA DE LA SERENA',
      activo: true
    },
    {
      cdgoEstacion: '70806',
      cdgoAdmon: '0071',
      nmroPrioridad: 215,
      descEstacion: null,
      desgEstacion: 'ZARAGOZA PORTILLO',
      cdgoUic: '70806',
      clave: '0071,70806,70806',
      desgEstacionPlano: 'ZARAGOZA PORTILLO',
      activo: true
    },
    {
      cdgoEstacion: '13106',
      cdgoAdmon: '0071',
      nmroPrioridad: 216,
      descEstacion: null,
      desgEstacion: 'LLODIO',
      cdgoUic: '13106',
      clave: '0071,13106,13106',
      desgEstacionPlano: 'LLODIO',
      activo: true
    },
    {
      cdgoEstacion: '20111',
      cdgoAdmon: '0071',
      nmroPrioridad: 217,
      descEstacion: null,
      desgEstacion: 'BEMBIBRE',
      cdgoUic: '20111',
      clave: '0071,20111,20111',
      desgEstacionPlano: 'BEMBIBRE',
      activo: true
    },
    {
      cdgoEstacion: '10203',
      cdgoAdmon: '0071',
      nmroPrioridad: 218,
      descEstacion: null,
      desgEstacion: 'EL ESCORIAL',
      cdgoUic: '10203',
      clave: '0071,10203,10203',
      desgEstacionPlano: 'EL ESCORIAL',
      activo: true
    },
    {
      cdgoEstacion: '35005',
      cdgoAdmon: '0071',
      nmroPrioridad: 219,
      descEstacion: null,
      desgEstacion: 'ILLESCAS',
      cdgoUic: '35005',
      clave: '0071,35005,35005',
      desgEstacionPlano: 'ILLESCAS',
      activo: true
    },
    {
      cdgoEstacion: '06003',
      cdgoAdmon: '0071',
      nmroPrioridad: 220,
      descEstacion: null,
      desgEstacion: 'TOTANA',
      cdgoUic: '06003',
      clave: '0071,06003,06003',
      desgEstacionPlano: 'TOTANA',
      activo: true
    },
    {
      cdgoEstacion: '35002',
      cdgoAdmon: '0071',
      nmroPrioridad: 221,
      descEstacion: null,
      desgEstacion: 'FUENLABRADA',
      cdgoUic: '35002',
      clave: '0071,35002,35002',
      desgEstacionPlano: 'FUENLABRADA',
      activo: true
    },
    {
      cdgoEstacion: '37402',
      cdgoAdmon: '0071',
      nmroPrioridad: 222,
      descEstacion: null,
      desgEstacion: 'CASTUERA',
      cdgoUic: '37402',
      clave: '0071,37402,37402',
      desgEstacionPlano: 'CASTUERA',
      activo: true
    },
    {
      cdgoEstacion: '23009',
      cdgoAdmon: '0071',
      nmroPrioridad: 223,
      descEstacion: null,
      desgEstacion: 'CATOIRA',
      cdgoUic: '23009',
      clave: '0071,23009,23009',
      desgEstacionPlano: 'CATOIRA',
      activo: true
    },
    {
      cdgoEstacion: '08224',
      cdgoAdmon: '0071',
      nmroPrioridad: 224,
      descEstacion: null,
      desgEstacion: 'REDONDELA AV',
      cdgoUic: '08224',
      clave: '0071,08224,08224',
      desgEstacionPlano: 'REDONDELA AV',
      activo: true
    },
    {
      cdgoEstacion: '69011',
      cdgoAdmon: '0071',
      nmroPrioridad: 225,
      descEstacion: null,
      desgEstacion: 'ALCOY/ALCOI',
      cdgoUic: '69011',
      clave: '0071,69011,69011',
      desgEstacionPlano: 'ALCOY/ALCOI',
      activo: true
    },
    {
      cdgoEstacion: '60203',
      cdgoAdmon: '0071',
      nmroPrioridad: 226,
      descEstacion: null,
      desgEstacion: 'VILLASEQUILLA',
      cdgoUic: '60203',
      clave: '0071,60203,60203',
      desgEstacionPlano: 'VILLASEQUILLA',
      activo: true
    },
    {
      cdgoEstacion: '94002',
      cdgoAdmon: '0071',
      nmroPrioridad: 227,
      descEstacion: null,
      desgEstacion: 'DAIMIEL',
      cdgoUic: '94002',
      clave: '0071,94002,94002',
      desgEstacionPlano: 'DAIMIEL',
      activo: true
    },
    {
      cdgoEstacion: '69104',
      cdgoAdmon: '0071',
      nmroPrioridad: 228,
      descEstacion: null,
      desgEstacion: 'CULLERA',
      cdgoUic: '69104',
      clave: '0071,69104,69104',
      desgEstacionPlano: 'CULLERA',
      activo: true
    },
    {
      cdgoEstacion: '07004',
      cdgoAdmon: '0071',
      nmroPrioridad: 229,
      descEstacion: null,
      desgEstacion: 'AGUILAS',
      cdgoUic: '07004',
      clave: '0071,07004,07004',
      desgEstacionPlano: 'AGUILAS',
      activo: true
    },
    {
      cdgoEstacion: '11000',
      cdgoAdmon: '0071',
      nmroPrioridad: 230,
      descEstacion: null,
      desgEstacion: 'VENTA DE BAÑOS',
      cdgoUic: '11000',
      clave: '0071,11000,11000',
      desgEstacionPlano: 'VENTA DE BANOS',
      activo: true
    },
    {
      cdgoEstacion: '50202',
      cdgoAdmon: '0071',
      nmroPrioridad: 231,
      descEstacion: null,
      desgEstacion: 'ALMURADIEL-VISO DEL MARQUES',
      cdgoUic: '50202',
      clave: '0071,50202,50202',
      desgEstacionPlano: 'ALMURADIEL-VISO DEL MARQUES',
      activo: true
    },
    {
      cdgoEstacion: '03001',
      cdgoAdmon: '0071',
      nmroPrioridad: 232,
      descEstacion: null,
      desgEstacion: 'MENGIBAR-ARTICHUELA (APD-CGD)',
      cdgoUic: '03001',
      clave: '0071,03001,03001',
      desgEstacionPlano: 'MENGIBAR-ARTICHUELA (APD-CGD)',
      activo: true
    },
    {
      cdgoEstacion: '10409',
      cdgoAdmon: '0071',
      nmroPrioridad: 233,
      descEstacion: null,
      desgEstacion: 'AREVALO',
      cdgoUic: '10409',
      clave: '0071,10409,10409',
      desgEstacionPlano: 'AREVALO',
      activo: true
    },
    {
      cdgoEstacion: '11602',
      cdgoAdmon: '0087',
      nmroPrioridad: 234,
      descEstacion: null,
      desgEstacion: 'HENDAYA',
      cdgoUic: '00293',
      clave: '0087,11602,00293',
      desgEstacionPlano: 'HENDAYA',
      activo: true
    },
    {
      cdgoEstacion: '37305',
      cdgoAdmon: '0071',
      nmroPrioridad: 235,
      descEstacion: null,
      desgEstacion: 'ALMADENEJOS-ALMADEN',
      cdgoUic: '37305',
      clave: '0071,37305,37305',
      desgEstacionPlano: 'ALMADENEJOS-ALMADEN',
      activo: true
    },
    {
      cdgoEstacion: '92201',
      cdgoAdmon: '0071',
      nmroPrioridad: 236,
      descEstacion: null,
      desgEstacion: 'DENIA-BUS',
      cdgoUic: null,
      clave: '0071,92201,null',
      desgEstacionPlano: 'DENIA-BUS',
      activo: true
    },
    {
      cdgoEstacion: '94004',
      cdgoAdmon: '0071',
      nmroPrioridad: 237,
      descEstacion: null,
      desgEstacion: 'ALMAGRO',
      cdgoUic: '94004',
      clave: '0071,94004,94004',
      desgEstacionPlano: 'ALMAGRO',
      activo: true
    },
    {
      cdgoEstacion: '20400',
      cdgoAdmon: '0071',
      nmroPrioridad: 238,
      descEstacion: null,
      desgEstacion: 'BETANZOS-INFESTA',
      cdgoUic: '20400',
      clave: '0071,20400,20400',
      desgEstacionPlano: 'BETANZOS-INFESTA',
      activo: true
    },
    {
      cdgoEstacion: '23010',
      cdgoAdmon: '0071',
      nmroPrioridad: 239,
      descEstacion: null,
      desgEstacion: 'PONTECESURES',
      cdgoUic: '23010',
      clave: '0071,23010,23010',
      desgEstacionPlano: 'PONTECESURES',
      activo: true
    },
    {
      cdgoEstacion: '64104',
      cdgoAdmon: '0071',
      nmroPrioridad: 240,
      descEstacion: null,
      desgEstacion: 'ALZIRA',
      cdgoUic: '64104',
      clave: '0071,64104,64104',
      desgEstacionPlano: 'ALZIRA',
      activo: true
    },
    {
      cdgoEstacion: '37603',
      cdgoAdmon: '0071',
      nmroPrioridad: 241,
      descEstacion: null,
      desgEstacion: 'MONTIJO',
      cdgoUic: '37603',
      clave: '0071,37603,37603',
      desgEstacionPlano: 'MONTIJO',
      activo: true
    },
    {
      cdgoEstacion: '79004',
      cdgoAdmon: '0071',
      nmroPrioridad: 242,
      descEstacion: null,
      desgEstacion: 'BARCELONA-SANT ANDREU COMTAL',
      cdgoUic: '79004',
      clave: '0071,79004,79004',
      desgEstacionPlano: 'BARCELONA-SANT ANDREU COMTAL',
      activo: true
    },
    {
      cdgoEstacion: '11109',
      cdgoAdmon: '0071',
      nmroPrioridad: 244,
      descEstacion: null,
      desgEstacion: 'BRIVIESCA',
      cdgoUic: '11109',
      clave: '0071,11109,11109',
      desgEstacionPlano: 'BRIVIESCA',
      activo: true
    },
    {
      cdgoEstacion: '61015',
      cdgoAdmon: '0071',
      nmroPrioridad: 245,
      descEstacion: null,
      desgEstacion: 'ARCHENA-FORTUNA',
      cdgoUic: '61015',
      clave: '0071,61015,61015',
      desgEstacionPlano: 'ARCHENA-FORTUNA',
      activo: true
    },
    {
      cdgoEstacion: '22201',
      cdgoAdmon: '0071',
      nmroPrioridad: 246,
      descEstacion: null,
      desgEstacion: 'O PORRIÑO',
      cdgoUic: '22201',
      clave: '0071,22201,22201',
      desgEstacionPlano: 'O PORRINO',
      activo: true
    },
    {
      cdgoEstacion: '54403',
      cdgoAdmon: '0071',
      nmroPrioridad: 247,
      descEstacion: null,
      desgEstacion: 'EL CHORRO',
      cdgoUic: '54403',
      clave: '0071,54403,54403',
      desgEstacionPlano: 'EL CHORRO',
      activo: true
    },
    {
      cdgoEstacion: '06002',
      cdgoAdmon: '0071',
      nmroPrioridad: 248,
      descEstacion: null,
      desgEstacion: 'ALHAMA DE MURCIA',
      cdgoUic: '06002',
      clave: '0071,06002,06002',
      desgEstacionPlano: 'ALHAMA DE MURCIA',
      activo: true
    },
    {
      cdgoEstacion: '71204',
      cdgoAdmon: '0071',
      nmroPrioridad: 249,
      descEstacion: null,
      desgEstacion: 'CASPE',
      cdgoUic: '71204',
      clave: '0071,71204,71204',
      desgEstacionPlano: 'CASPE',
      activo: true
    },
    {
      cdgoEstacion: '74213',
      cdgoAdmon: '0071',
      nmroPrioridad: 250,
      descEstacion: null,
      desgEstacion: 'JACA',
      cdgoUic: '74213',
      clave: '0071,74213,74213',
      desgEstacionPlano: 'JACA',
      activo: true
    },
    {
      cdgoEstacion: '70103',
      cdgoAdmon: '0071',
      nmroPrioridad: 251,
      descEstacion: null,
      desgEstacion: 'ALCALA DE HENARES',
      cdgoUic: '70103',
      clave: '0071,70103,70103',
      desgEstacionPlano: 'ALCALA DE HENARES',
      activo: true
    },
    {
      cdgoEstacion: '81109',
      cdgoAdmon: '0071',
      nmroPrioridad: 252,
      descEstacion: null,
      desgEstacion: 'RINCON DE SOTO',
      cdgoUic: '81109',
      clave: '0071,81109,81109',
      desgEstacionPlano: 'RINCON DE SOTO',
      activo: true
    },
    {
      cdgoEstacion: '94438',
      cdgoAdmon: '0094',
      nmroPrioridad: 253,
      descEstacion: null,
      desgEstacion: 'POMBAL',
      cdgoUic: '00438',
      clave: '0094,94438,00438',
      desgEstacionPlano: 'POMBAL',
      activo: true
    },
    {
      cdgoEstacion: '51409',
      cdgoAdmon: '0071',
      nmroPrioridad: 254,
      descEstacion: null,
      desgEstacion: 'CADIZ-ESTADIO',
      cdgoUic: '51409',
      clave: '0071,51409,51409',
      desgEstacionPlano: 'CADIZ-ESTADIO',
      activo: true
    },
    {
      cdgoEstacion: '65208',
      cdgoAdmon: '0071',
      nmroPrioridad: 255,
      descEstacion: null,
      desgEstacion: 'VILA-REAL',
      cdgoUic: '65208',
      clave: '0071,65208,65208',
      desgEstacionPlano: 'VILA-REAL',
      activo: true
    },
    {
      cdgoEstacion: '78200',
      cdgoAdmon: '0071',
      nmroPrioridad: 256,
      descEstacion: null,
      desgEstacion: 'TARDIENTA',
      cdgoUic: '78200',
      clave: '0071,78200,78200',
      desgEstacionPlano: 'TARDIENTA',
      activo: true
    },
    {
      cdgoEstacion: '15122',
      cdgoAdmon: '0071',
      nmroPrioridad: 257,
      descEstacion: null,
      desgEstacion: 'POLA DE LENA',
      cdgoUic: '15122',
      clave: '0071,15122,15122',
      desgEstacionPlano: 'POLA DE LENA',
      activo: true
    },
    {
      cdgoEstacion: '40100',
      cdgoAdmon: '0071',
      nmroPrioridad: 258,
      descEstacion: null,
      desgEstacion: 'ZAFRA',
      cdgoUic: '40100',
      clave: '0071,40100,40100',
      desgEstacionPlano: 'ZAFRA',
      activo: true
    },
    {
      cdgoEstacion: '50002',
      cdgoAdmon: '0071',
      nmroPrioridad: 259,
      descEstacion: null,
      desgEstacion: 'CINCO CASAS',
      cdgoUic: '50002',
      clave: '0071,50002,50002',
      desgEstacionPlano: 'CINCO CASAS',
      activo: true
    },
    {
      cdgoEstacion: '37308',
      cdgoAdmon: '0071',
      nmroPrioridad: 260,
      descEstacion: null,
      desgEstacion: 'GUADALMEZ-LOS PEDROCHES',
      cdgoUic: '37308',
      clave: '0071,37308,37308',
      desgEstacionPlano: 'GUADALMEZ-LOS PEDROCHES',
      activo: true
    },
    {
      cdgoEstacion: '18002',
      cdgoAdmon: '0071',
      nmroPrioridad: 261,
      descEstacion: null,
      desgEstacion: 'MADRID-NUEVOS MINISTERIOS',
      cdgoUic: '18002',
      clave: '0071,18002,18002',
      desgEstacionPlano: 'MADRID-NUEVOS MINISTERIOS',
      activo: true
    },
    {
      cdgoEstacion: '35301',
      cdgoAdmon: '0071',
      nmroPrioridad: 262,
      descEstacion: null,
      desgEstacion: 'MIRABEL',
      cdgoUic: '35301',
      clave: '0071,35301,35301',
      desgEstacionPlano: 'MIRABEL',
      activo: true
    },
    {
      cdgoEstacion: '50507',
      cdgoAdmon: '0071',
      nmroPrioridad: 263,
      descEstacion: null,
      desgEstacion: 'PEÑAFLOR',
      cdgoUic: '50507',
      clave: '0071,50507,50507',
      desgEstacionPlano: 'PENAFLOR',
      activo: true
    },
    {
      cdgoEstacion: '60900',
      cdgoAdmon: '0071',
      nmroPrioridad: 265,
      descEstacion: null,
      desgEstacion: 'LA ENCINA',
      cdgoUic: '60900',
      clave: '0071,60900,60900',
      desgEstacionPlano: 'LA ENCINA',
      activo: true
    },
    {
      cdgoEstacion: '05013',
      cdgoAdmon: '0071',
      nmroPrioridad: 266,
      descEstacion: null,
      desgEstacion: 'LOJA-SAN FRANCISCO',
      cdgoUic: '05013',
      clave: '0071,05013,05013',
      desgEstacionPlano: 'LOJA-SAN FRANCISCO',
      activo: true
    },
    {
      cdgoEstacion: '65314',
      cdgoAdmon: '0071',
      nmroPrioridad: 267,
      descEstacion: null,
      desgEstacion: 'ULLDECONA -ALCANAR-LA SENIA',
      cdgoUic: '65314',
      clave: '0071,65314,65314',
      desgEstacionPlano: 'ULLDECONA -ALCANAR-LA SENIA',
      activo: true
    },
    {
      cdgoEstacion: '37410',
      cdgoAdmon: '0071',
      nmroPrioridad: 268,
      descEstacion: null,
      desgEstacion: 'GUAREÑA',
      cdgoUic: '37410',
      clave: '0071,37410,37410',
      desgEstacionPlano: 'GUARENA',
      activo: true
    },
    {
      cdgoEstacion: '55001',
      cdgoAdmon: '0071',
      nmroPrioridad: 269,
      descEstacion: null,
      desgEstacion: 'CAMPILLOS',
      cdgoUic: '55001',
      clave: '0071,55001,55001',
      desgEstacionPlano: 'CAMPILLOS',
      activo: true
    },
    {
      cdgoEstacion: '22402',
      cdgoAdmon: '0094',
      nmroPrioridad: 270,
      descEstacion: null,
      desgEstacion: 'VALENÇA DO MINHO',
      cdgoUic: '22402',
      clave: '0094,22402,22402',
      desgEstacionPlano: 'VALENCA DO MINHO',
      activo: true
    },
    {
      cdgoEstacion: '23002',
      cdgoAdmon: '0071',
      nmroPrioridad: 271,
      descEstacion: null,
      desgEstacion: 'ARCADE',
      cdgoUic: '23002',
      clave: '0071,23002,23002',
      desgEstacionPlano: 'ARCADE',
      activo: true
    },
    {
      cdgoEstacion: '40105',
      cdgoAdmon: '0071',
      nmroPrioridad: 272,
      descEstacion: null,
      desgEstacion: 'LLERENA',
      cdgoUic: '40105',
      clave: '0071,40105,40105',
      desgEstacionPlano: 'LLERENA',
      activo: true
    },
    {
      cdgoEstacion: '21004',
      cdgoAdmon: '0071',
      nmroPrioridad: 273,
      descEstacion: null,
      desgEstacion: 'PONTEDEUME',
      cdgoUic: '21004',
      clave: '0071,21004,21004',
      desgEstacionPlano: 'PONTEDEUME',
      activo: true
    },
    {
      cdgoEstacion: '99114',
      cdgoAdmon: '0071',
      nmroPrioridad: 274,
      descEstacion: null,
      desgEstacion: 'ANDORRA-BUS',
      cdgoUic: null,
      clave: '0071,99114,null',
      desgEstacionPlano: 'ANDORRA-BUS',
      activo: true
    },
    {
      cdgoEstacion: '79303',
      cdgoAdmon: '0071',
      nmroPrioridad: 275,
      descEstacion: null,
      desgEstacion: 'FLAÇA',
      cdgoUic: '79303',
      clave: '0071,79303,79303',
      desgEstacionPlano: 'FLACA',
      activo: true
    },
    {
      cdgoEstacion: '80114',
      cdgoAdmon: '0071',
      nmroPrioridad: 276,
      descEstacion: null,
      desgEstacion: 'MARCILLA DE NAVARRA',
      cdgoUic: '80114',
      clave: '0071,80114,80114',
      desgEstacionPlano: 'MARCILLA DE NAVARRA',
      activo: true
    },
    {
      cdgoEstacion: '65308',
      cdgoAdmon: '0071',
      nmroPrioridad: 277,
      descEstacion: null,
      desgEstacion: 'ALCALA DE XIVERT',
      cdgoUic: '65308',
      clave: '0071,65308,65308',
      desgEstacionPlano: 'ALCALA DE XIVERT',
      activo: true
    },
    {
      cdgoEstacion: '55015',
      cdgoAdmon: '0071',
      nmroPrioridad: 278,
      descEstacion: null,
      desgEstacion: 'JIMENA DE LA FRONTERA',
      cdgoUic: '55015',
      clave: '0071,55015,55015',
      desgEstacionPlano: 'JIMENA DE LA FRONTERA',
      activo: true
    },
    {
      cdgoEstacion: '74217',
      cdgoAdmon: '0071',
      nmroPrioridad: 279,
      descEstacion: null,
      desgEstacion: 'CANFRANC',
      cdgoUic: '74217',
      clave: '0071,74217,74217',
      desgEstacionPlano: 'CANFRANC',
      activo: true
    },
    {
      cdgoEstacion: '94536',
      cdgoAdmon: '0094',
      nmroPrioridad: 280,
      descEstacion: null,
      desgEstacion: 'MANGUALDE',
      cdgoUic: '00536',
      clave: '0094,94536,00536',
      desgEstacionPlano: 'MANGUALDE',
      activo: true
    },
    {
      cdgoEstacion: '10207',
      cdgoAdmon: '0071',
      nmroPrioridad: 281,
      descEstacion: null,
      desgEstacion: 'LAS NAVAS DEL MARQUES',
      cdgoUic: '10207',
      clave: '0071,10207,10207',
      desgEstacionPlano: 'LAS NAVAS DEL MARQUES',
      activo: true
    },
    {
      cdgoEstacion: '94428',
      cdgoAdmon: '0094',
      nmroPrioridad: 283,
      descEstacion: null,
      desgEstacion: 'ENTRONCAMT',
      cdgoUic: '00428',
      clave: '0094,94428,00428',
      desgEstacionPlano: 'ENTRONCAMT',
      activo: true
    },
    {
      cdgoEstacion: '31304',
      cdgoAdmon: '0071',
      nmroPrioridad: 284,
      descEstacion: null,
      desgEstacion: 'O CARBALLIÑO',
      cdgoUic: '31304',
      clave: '0071,31304,31304',
      desgEstacionPlano: 'O CARBALLINO',
      activo: true
    },
    {
      cdgoEstacion: '65002',
      cdgoAdmon: '0071',
      nmroPrioridad: 285,
      descEstacion: null,
      desgEstacion: 'VALENCIA F.S.L-HOSPITAL LA FE',
      cdgoUic: '65002',
      clave: '0071,65002,65002',
      desgEstacionPlano: 'VALENCIA F.S.L-HOSPITAL LA FE',
      activo: true
    },
    {
      cdgoEstacion: '70403',
      cdgoAdmon: '0071',
      nmroPrioridad: 287,
      descEstacion: null,
      desgEstacion: 'ARCOS DE JALON',
      cdgoUic: '70403',
      clave: '0071,70403,70403',
      desgEstacionPlano: 'ARCOS DE JALON',
      activo: true
    },
    {
      cdgoEstacion: '60301',
      cdgoAdmon: '0071',
      nmroPrioridad: 288,
      descEstacion: null,
      desgEstacion: 'QUERO',
      cdgoUic: '60301',
      clave: '0071,60301,60301',
      desgEstacionPlano: 'QUERO',
      activo: true
    },
    {
      cdgoEstacion: '60907',
      cdgoAdmon: '0071',
      nmroPrioridad: 289,
      descEstacion: null,
      desgEstacion: 'NOVELDA-ASPE',
      cdgoUic: '60907',
      clave: '0071,60907,60907',
      desgEstacionPlano: 'NOVELDA-ASPE',
      activo: true
    },
    {
      cdgoEstacion: '51202',
      cdgoAdmon: '0071',
      nmroPrioridad: 290,
      descEstacion: null,
      desgEstacion: 'LAS CABEZAS DE SAN JUAN',
      cdgoUic: '51202',
      clave: '0071,51202,51202',
      desgEstacionPlano: 'LAS CABEZAS DE SAN JUAN',
      activo: true
    },
    {
      cdgoEstacion: '55003',
      cdgoAdmon: '0071',
      nmroPrioridad: 291,
      descEstacion: null,
      desgEstacion: 'ALMARGEN-CAÑETE LA REAL',
      cdgoUic: '55003',
      clave: '0071,55003,55003',
      desgEstacionPlano: 'ALMARGEN-CANETE LA REAL',
      activo: true
    },
    {
      cdgoEstacion: '43021',
      cdgoAdmon: '0071',
      nmroPrioridad: 292,
      descEstacion: null,
      desgEstacion: 'SAN JUAN DEL PUERTO',
      cdgoUic: '43021',
      clave: '0071,43021,43021',
      desgEstacionPlano: 'SAN JUAN DEL PUERTO',
      activo: true
    },
    {
      cdgoEstacion: '40005',
      cdgoAdmon: '0071',
      nmroPrioridad: 293,
      descEstacion: null,
      desgEstacion: 'VILLAFRANCA DE LOS BARROS',
      cdgoUic: '40005',
      clave: '0071,40005,40005',
      desgEstacionPlano: 'VILLAFRANCA DE LOS BARROS',
      activo: true
    },
    {
      cdgoEstacion: '10208',
      cdgoAdmon: '0071',
      nmroPrioridad: 294,
      descEstacion: null,
      desgEstacion: 'NAVALPERAL',
      cdgoUic: '10208',
      clave: '0071,10208,10208',
      desgEstacionPlano: 'NAVALPERAL',
      activo: true
    },
    {
      cdgoEstacion: '78302',
      cdgoAdmon: '0071',
      nmroPrioridad: 295,
      descEstacion: null,
      desgEstacion: 'BINEFAR',
      cdgoUic: '78302',
      clave: '0071,78302,78302',
      desgEstacionPlano: 'BINEFAR',
      activo: true
    },
    {
      cdgoEstacion: '67113',
      cdgoAdmon: '0071',
      nmroPrioridad: 296,
      descEstacion: null,
      desgEstacion: 'MONREAL DEL CAMPO',
      cdgoUic: '67113',
      clave: '0071,67113,67113',
      desgEstacionPlano: 'MONREAL DEL CAMPO',
      activo: true
    },
    {
      cdgoEstacion: '69008',
      cdgoAdmon: '0071',
      nmroPrioridad: 297,
      descEstacion: null,
      desgEstacion: 'ONTINYENT',
      cdgoUic: '69008',
      clave: '0071,69008,69008',
      desgEstacionPlano: 'ONTINYENT',
      activo: true
    },
    {
      cdgoEstacion: '20005',
      cdgoAdmon: '0071',
      nmroPrioridad: 298,
      descEstacion: null,
      desgEstacion: 'VEGUELLINA',
      cdgoUic: '20005',
      clave: '0071,20005,20005',
      desgEstacionPlano: 'VEGUELLINA',
      activo: true
    },
    {
      cdgoEstacion: '23000',
      cdgoAdmon: '0071',
      nmroPrioridad: 299,
      descEstacion: null,
      desgEstacion: 'REDONDELA-PICOTA',
      cdgoUic: '23000',
      clave: '0071,23000,23000',
      desgEstacionPlano: 'REDONDELA-PICOTA',
      activo: true
    },
    {
      cdgoEstacion: '67010',
      cdgoAdmon: '0071',
      nmroPrioridad: 300,
      descEstacion: null,
      desgEstacion: 'CARIÑENA',
      cdgoUic: '67010',
      clave: '0071,67010,67010',
      desgEstacionPlano: 'CARINENA',
      activo: true
    },
    {
      cdgoEstacion: '80115',
      cdgoAdmon: '0071',
      nmroPrioridad: 301,
      descEstacion: null,
      desgEstacion: 'VILLAFRANCA DE NAVARRA',
      cdgoUic: '80115',
      clave: '0071,80115,80115',
      desgEstacionPlano: 'VILLAFRANCA DE NAVARRA',
      activo: true
    },
    {
      cdgoEstacion: '37404',
      cdgoAdmon: '0071',
      nmroPrioridad: 302,
      descEstacion: null,
      desgEstacion: 'CAMPANARIO',
      cdgoUic: '37404',
      clave: '0071,37404,37404',
      desgEstacionPlano: 'CAMPANARIO',
      activo: true
    },
    {
      cdgoEstacion: '71300',
      cdgoAdmon: '0071',
      nmroPrioridad: 303,
      descEstacion: null,
      desgEstacion: 'MORA LA NOVA',
      cdgoUic: '71300',
      clave: '0071,71300,71300',
      desgEstacionPlano: 'MORA LA NOVA',
      activo: true
    },
    {
      cdgoEstacion: '65306',
      cdgoAdmon: '0071',
      nmroPrioridad: 304,
      descEstacion: null,
      desgEstacion: 'TORREBLANCA',
      cdgoUic: '65306',
      clave: '0071,65306,65306',
      desgEstacionPlano: 'TORREBLANCA',
      activo: true
    },
    {
      cdgoEstacion: '66011',
      cdgoAdmon: '0071',
      nmroPrioridad: 306,
      descEstacion: null,
      desgEstacion: 'HUETE',
      cdgoUic: '66011',
      clave: '0071,66011,66011',
      desgEstacionPlano: 'HUETE',
      activo: true
    },
    {
      cdgoEstacion: '65411',
      cdgoAdmon: '0071',
      nmroPrioridad: 307,
      descEstacion: null,
      desgEstacion: 'SALOU - PORT AVENTURA',
      cdgoUic: '65411',
      clave: '0071,65411,65411',
      desgEstacionPlano: 'SALOU - PORT AVENTURA',
      activo: true
    },
    {
      cdgoEstacion: '65206',
      cdgoAdmon: '0071',
      nmroPrioridad: 308,
      descEstacion: null,
      desgEstacion: 'NULES-LA VILAVELLA',
      cdgoUic: '65206',
      clave: '0071,65206,65206',
      desgEstacionPlano: 'NULES-LA VILAVELLA',
      activo: true
    },
    {
      cdgoEstacion: '33013',
      cdgoAdmon: '0071',
      nmroPrioridad: 309,
      descEstacion: null,
      desgEstacion: 'CIUDAD RODRIGO',
      cdgoUic: '33013',
      clave: '0071,33013,33013',
      desgEstacionPlano: 'CIUDAD RODRIGO',
      activo: true
    },
    {
      cdgoEstacion: '79333',
      cdgoAdmon: '0071',
      nmroPrioridad: 310,
      descEstacion: null,
      desgEstacion: 'FIGUERES.BUS',
      cdgoUic: '79333',
      clave: '0071,79333,79333',
      desgEstacionPlano: 'FIGUERES.BUS',
      activo: true
    },
    {
      cdgoEstacion: '79203',
      cdgoAdmon: '0071',
      nmroPrioridad: 311,
      descEstacion: null,
      desgEstacion: 'CALDES DE MALAVELLA',
      cdgoUic: '79203',
      clave: '0071,79203,79203',
      desgEstacionPlano: 'CALDES DE MALAVELLA',
      activo: true
    },
    {
      cdgoEstacion: '92124',
      cdgoAdmon: '0071',
      nmroPrioridad: 312,
      descEstacion: null,
      desgEstacion: 'MARBELLA-BUS',
      cdgoUic: null,
      clave: '0071,92124,null',
      desgEstacionPlano: 'MARBELLA-BUS',
      activo: true
    },
    {
      cdgoEstacion: '80109',
      cdgoAdmon: '0071',
      nmroPrioridad: 313,
      descEstacion: null,
      desgEstacion: 'OLITE/ERRIBERRI',
      cdgoUic: '80109',
      clave: '0071,80109,80109',
      desgEstacionPlano: 'OLITE/ERRIBERRI',
      activo: true
    },
    {
      cdgoEstacion: '55013',
      cdgoAdmon: '0071',
      nmroPrioridad: 314,
      descEstacion: null,
      desgEstacion: 'GAUCIN',
      cdgoUic: '55013',
      clave: '0071,55013,55013',
      desgEstacionPlano: 'GAUCIN',
      activo: true
    },
    {
      cdgoEstacion: '55010',
      cdgoAdmon: '0071',
      nmroPrioridad: 315,
      descEstacion: null,
      desgEstacion: 'BENAOJAN-MONTEJAQUE',
      cdgoUic: '55010',
      clave: '0071,55010,55010',
      desgEstacionPlano: 'BENAOJAN-MONTEJAQUE',
      activo: true
    },
    {
      cdgoEstacion: '35406',
      cdgoAdmon: '0071',
      nmroPrioridad: 316,
      descEstacion: null,
      desgEstacion: 'VALENCIA DE ALCANTARA',
      cdgoUic: '35406',
      clave: '0071,35406,35406',
      desgEstacionPlano: 'VALENCIA DE ALCANTARA',
      activo: true
    },
    {
      cdgoEstacion: '66006',
      cdgoAdmon: '0071',
      nmroPrioridad: 317,
      descEstacion: null,
      desgEstacion: 'TARANCON',
      cdgoUic: '66006',
      clave: '0071,66006,66006',
      desgEstacionPlano: 'TARANCON',
      activo: true
    },
    {
      cdgoEstacion: '11404',
      cdgoAdmon: '0071',
      nmroPrioridad: 318,
      descEstacion: null,
      desgEstacion: 'BEASAIN',
      cdgoUic: '11404',
      clave: '0071,11404,11404',
      desgEstacionPlano: 'BEASAIN',
      activo: true
    },
    {
      cdgoEstacion: '10610',
      cdgoAdmon: '0071',
      nmroPrioridad: 319,
      descEstacion: null,
      desgEstacion: 'VALLADOLID-UNIVERSIDAD',
      cdgoUic: '10610',
      clave: '0071,10610,10610',
      desgEstacionPlano: 'VALLADOLID-UNIVERSIDAD',
      activo: true
    },
    {
      cdgoEstacion: '12006',
      cdgoAdmon: '0071',
      nmroPrioridad: 320,
      descEstacion: null,
      desgEstacion: 'CERCEDILLA',
      cdgoUic: '12006',
      clave: '0071,12006,12006',
      desgEstacionPlano: 'CERCEDILLA',
      activo: true
    },
    {
      cdgoEstacion: '62100',
      cdgoAdmon: '0071',
      nmroPrioridad: 321,
      descEstacion: null,
      desgEstacion: 'ALBATERA-CATRAL',
      cdgoUic: '62100',
      clave: '0071,62100,62100',
      desgEstacionPlano: 'ALBATERA-CATRAL',
      activo: true
    },
    {
      cdgoEstacion: '56009',
      cdgoAdmon: '0071',
      nmroPrioridad: 322,
      descEstacion: null,
      desgEstacion: 'CABRA DEL SANTO CRISTO Y ALICUN',
      cdgoUic: '56009',
      clave: '0071,56009,56009',
      desgEstacionPlano: 'CABRA DEL SANTO CRISTO Y ALICUN',
      activo: true
    },
    {
      cdgoEstacion: '14107',
      cdgoAdmon: '0071',
      nmroPrioridad: 323,
      descEstacion: null,
      desgEstacion: 'OSORNO',
      cdgoUic: '14107',
      clave: '0071,14107,14107',
      desgEstacionPlano: 'OSORNO',
      activo: true
    },
    {
      cdgoEstacion: '35405',
      cdgoAdmon: '0071',
      nmroPrioridad: 324,
      descEstacion: null,
      desgEstacion: 'SAN VICENTE DE ALCANTARA',
      cdgoUic: '35405',
      clave: '0071,35405,35405',
      desgEstacionPlano: 'SAN VICENTE DE ALCANTARA',
      activo: true
    },
    {
      cdgoEstacion: '14210',
      cdgoAdmon: '0071',
      nmroPrioridad: 325,
      descEstacion: null,
      desgEstacion: 'LOS CORRALES DE BUELNA',
      cdgoUic: '14210',
      clave: '0071,14210,14210',
      desgEstacionPlano: 'LOS CORRALES DE BUELNA',
      activo: true
    },
    {
      cdgoEstacion: '84101',
      cdgoAdmon: '0071',
      nmroPrioridad: 326,
      descEstacion: null,
      desgEstacion: 'ALMAZAN',
      cdgoUic: '84101',
      clave: '0071,84101,84101',
      desgEstacionPlano: 'ALMAZAN',
      activo: true
    },
    {
      cdgoEstacion: '81205',
      cdgoAdmon: '0071',
      nmroPrioridad: 327,
      descEstacion: null,
      desgEstacion: 'CORTES DE NAVARRA',
      cdgoUic: '81205',
      clave: '0071,81205,81205',
      desgEstacionPlano: 'CORTES DE NAVARRA',
      activo: true
    },
    {
      cdgoEstacion: '94551',
      cdgoAdmon: '0094',
      nmroPrioridad: 328,
      descEstacion: null,
      desgEstacion: 'GUARDA',
      cdgoUic: '00551',
      clave: '0094,94551,00551',
      desgEstacionPlano: 'GUARDA',
      activo: true
    },
    {
      cdgoEstacion: '98305',
      cdgoAdmon: '0071',
      nmroPrioridad: 329,
      descEstacion: null,
      desgEstacion: 'MADRID-BARAJAS T4',
      cdgoUic: '98305',
      clave: '0071,98305,98305',
      desgEstacionPlano: 'MADRID-BARAJAS T4',
      activo: true
    },
    {
      cdgoEstacion: '97639',
      cdgoAdmon: '0071',
      nmroPrioridad: 330,
      descEstacion: null,
      desgEstacion: 'JAVEA-BUS',
      cdgoUic: null,
      clave: '0071,97639,null',
      desgEstacionPlano: 'JAVEA-BUS',
      activo: true
    },
    {
      cdgoEstacion: '74211',
      cdgoAdmon: '0071',
      nmroPrioridad: 331,
      descEstacion: null,
      desgEstacion: 'SABIÑANIGO',
      cdgoUic: '74211',
      clave: '0071,74211,74211',
      desgEstacionPlano: 'SABINANIGO',
      activo: true
    },
    {
      cdgoEstacion: '69006',
      cdgoAdmon: '0071',
      nmroPrioridad: 332,
      descEstacion: null,
      desgEstacion: 'ALBAIDA',
      cdgoUic: '69006',
      clave: '0071,69006,69006',
      desgEstacionPlano: 'ALBAIDA',
      activo: true
    },
    {
      cdgoEstacion: '80001',
      cdgoAdmon: '0071',
      nmroPrioridad: 333,
      descEstacion: null,
      desgEstacion: 'ALTSASU/ALSASUA-PUEBLO',
      cdgoUic: '80001',
      clave: '0071,80001,80001',
      desgEstacionPlano: 'ALTSASU/ALSASUA-PUEBLO',
      activo: true
    },
    {
      cdgoEstacion: '10204',
      cdgoAdmon: '0071',
      nmroPrioridad: 334,
      descEstacion: null,
      desgEstacion: 'ZARZALEJO',
      cdgoUic: '10204',
      clave: '0071,10204,10204',
      desgEstacionPlano: 'ZARZALEJO',
      activo: true
    },
    {
      cdgoEstacion: '11210',
      cdgoAdmon: '0071',
      nmroPrioridad: 335,
      descEstacion: null,
      desgEstacion: 'ALEGRIA DE ALAVA-DULANTZI',
      cdgoUic: '11210',
      clave: '0071,11210,11210',
      desgEstacionPlano: 'ALEGRIA DE ALAVA-DULANTZI',
      activo: true
    },
    {
      cdgoEstacion: '14111',
      cdgoAdmon: '0071',
      nmroPrioridad: 336,
      descEstacion: null,
      desgEstacion: 'HERRERA DE PISUERGA',
      cdgoUic: '14111',
      clave: '0071,14111,14111',
      desgEstacionPlano: 'HERRERA DE PISUERGA',
      activo: true
    },
    {
      cdgoEstacion: '40004',
      cdgoAdmon: '0071',
      nmroPrioridad: 337,
      descEstacion: null,
      desgEstacion: 'ALMENDRALEJO',
      cdgoUic: '40004',
      clave: '0071,40004,40004',
      desgEstacionPlano: 'ALMENDRALEJO',
      activo: true
    },
    {
      cdgoEstacion: '65405',
      cdgoAdmon: '0071',
      nmroPrioridad: 338,
      descEstacion: null,
      desgEstacion: "L'AMETLLA DE MAR",
      cdgoUic: '65405',
      clave: '0071,65405,65405',
      desgEstacionPlano: "L'AMETLLA DE MAR",
      activo: true
    },
    {
      cdgoEstacion: '71502',
      cdgoAdmon: '0071',
      nmroPrioridad: 339,
      descEstacion: null,
      desgEstacion: 'ALTAFULLA-TAMARIT',
      cdgoUic: '71502',
      clave: '0071,71502,71502',
      desgEstacionPlano: 'ALTAFULLA-TAMARIT',
      activo: true
    },
    {
      cdgoEstacion: '11212',
      cdgoAdmon: '0071',
      nmroPrioridad: 340,
      descEstacion: null,
      desgEstacion: 'SALVATIERRA/AGURAIN',
      cdgoUic: '11212',
      clave: '0071,11212,11212',
      desgEstacionPlano: 'SALVATIERRA/AGURAIN',
      activo: true
    },
    {
      cdgoEstacion: '10300',
      cdgoAdmon: '0071',
      nmroPrioridad: 341,
      descEstacion: null,
      desgEstacion: 'HERRADON-LA CAÑADA',
      cdgoUic: '10300',
      clave: '0071,10300,10300',
      desgEstacionPlano: 'HERRADON-LA CANADA',
      activo: true
    },
    {
      cdgoEstacion: '37302',
      cdgoAdmon: '0071',
      nmroPrioridad: 342,
      descEstacion: null,
      desgEstacion: 'BRAZATORTAS-VEREDAS',
      cdgoUic: '37302',
      clave: '0071,37302,37302',
      desgEstacionPlano: 'BRAZATORTAS-VEREDAS',
      activo: true
    },
    {
      cdgoEstacion: '20316',
      cdgoAdmon: '0071',
      nmroPrioridad: 343,
      descEstacion: null,
      desgEstacion: 'TEIXEIRO',
      cdgoUic: '20316',
      clave: '0071,20316,20316',
      desgEstacionPlano: 'TEIXEIRO',
      activo: true
    },
    {
      cdgoEstacion: '20317',
      cdgoAdmon: '0071',
      nmroPrioridad: 344,
      descEstacion: null,
      desgEstacion: 'CURTIS',
      cdgoUic: '20317',
      clave: '0071,20317,20317',
      desgEstacionPlano: 'CURTIS',
      activo: true
    },
    {
      cdgoEstacion: '21001',
      cdgoAdmon: '0071',
      nmroPrioridad: 345,
      descEstacion: null,
      desgEstacion: 'BETANZOS-CIDADE',
      cdgoUic: '21001',
      clave: '0071,21001,21001',
      desgEstacionPlano: 'BETANZOS-CIDADE',
      activo: true
    },
    {
      cdgoEstacion: '15106',
      cdgoAdmon: '0071',
      nmroPrioridad: 346,
      descEstacion: null,
      desgEstacion: 'LA ROBLA',
      cdgoUic: '15106',
      clave: '0071,15106,15106',
      desgEstacionPlano: 'LA ROBLA',
      activo: true
    },
    {
      cdgoEstacion: '01003',
      cdgoAdmon: '0071',
      nmroPrioridad: 347,
      descEstacion: null,
      desgEstacion: 'ARAHAL',
      cdgoUic: '01003',
      clave: '0071,01003,01003',
      desgEstacionPlano: 'ARAHAL',
      activo: true
    },
    {
      cdgoEstacion: '99131',
      cdgoAdmon: '0071',
      nmroPrioridad: 348,
      descEstacion: null,
      desgEstacion: 'PONFERRADA-BUS',
      cdgoUic: null,
      clave: '0071,99131,null',
      desgEstacionPlano: 'PONFERRADA-BUS',
      activo: true
    },
    {
      cdgoEstacion: '99141',
      cdgoAdmon: '0071',
      nmroPrioridad: 348,
      descEstacion: null,
      desgEstacion: 'TORO-BUS',
      cdgoUic: '99141',
      clave: '0071,99141,99141',
      desgEstacionPlano: 'TORO-BUS',
      activo: true
    },
    {
      cdgoEstacion: '11516',
      cdgoAdmon: '0071',
      nmroPrioridad: 349,
      descEstacion: null,
      desgEstacion: 'LEZO-ERRENTERIA',
      cdgoUic: '11516',
      clave: '0071,11516,11516',
      desgEstacionPlano: 'LEZO-ERRENTERIA',
      activo: true
    },
    {
      cdgoEstacion: '70801',
      cdgoAdmon: '0071',
      nmroPrioridad: 350,
      descEstacion: null,
      desgEstacion: 'UTEBO',
      cdgoUic: '70801',
      clave: '0071,70801,70801',
      desgEstacionPlano: 'UTEBO',
      activo: true
    },
    {
      cdgoEstacion: '71200',
      cdgoAdmon: '0071',
      nmroPrioridad: 351,
      descEstacion: null,
      desgEstacion: 'LA PUEBLA DE HIJAR',
      cdgoUic: '71200',
      clave: '0071,71200,71200',
      desgEstacionPlano: 'LA PUEBLA DE HIJAR',
      activo: true
    },
    {
      cdgoEstacion: '69002',
      cdgoAdmon: '0071',
      nmroPrioridad: 352,
      descEstacion: null,
      desgEstacion: 'BENIGANIM',
      cdgoUic: '69002',
      clave: '0071,69002,69002',
      desgEstacionPlano: 'BENIGANIM',
      activo: true
    },
    {
      cdgoEstacion: '66112',
      cdgoAdmon: '0071',
      nmroPrioridad: 353,
      descEstacion: null,
      desgEstacion: 'CAMPORROBLES',
      cdgoUic: '66112',
      clave: '0071,66112,66112',
      desgEstacionPlano: 'CAMPORROBLES',
      activo: true
    },
    {
      cdgoEstacion: '66002',
      cdgoAdmon: '0071',
      nmroPrioridad: 354,
      descEstacion: null,
      desgEstacion: 'OCAÑA',
      cdgoUic: '66002',
      clave: '0071,66002,66002',
      desgEstacionPlano: 'OCANA',
      activo: true
    },
    {
      cdgoEstacion: '65404',
      cdgoAdmon: '0071',
      nmroPrioridad: 355,
      descEstacion: null,
      desgEstacion: "L'AMPOLLA-EL PERELLO-DELTEBRE",
      cdgoUic: '65404',
      clave: '0071,65404,65404',
      desgEstacionPlano: "L'AMPOLLA-EL PERELLO-DELTEBRE",
      activo: true
    },
    {
      cdgoEstacion: '31415',
      cdgoAdmon: '0071',
      nmroPrioridad: 356,
      descEstacion: null,
      desgEstacion: 'ORDES',
      cdgoUic: '31415',
      clave: '0071,31415,31415',
      desgEstacionPlano: 'ORDES',
      activo: true
    },
    {
      cdgoEstacion: '37409',
      cdgoAdmon: '0071',
      nmroPrioridad: 357,
      descEstacion: null,
      desgEstacion: 'VALDETORRES',
      cdgoUic: '37409',
      clave: '0071,37409,37409',
      desgEstacionPlano: 'VALDETORRES',
      activo: true
    },
    {
      cdgoEstacion: '81102',
      cdgoAdmon: '0071',
      nmroPrioridad: 358,
      descEstacion: null,
      desgEstacion: 'AGONCILLO',
      cdgoUic: '81102',
      clave: '0071,81102,81102',
      desgEstacionPlano: 'AGONCILLO',
      activo: true
    },
    {
      cdgoEstacion: '84103',
      cdgoAdmon: '0071',
      nmroPrioridad: 359,
      descEstacion: null,
      desgEstacion: 'TARDELCUENDE',
      cdgoUic: '84103',
      clave: '0071,84103,84103',
      desgEstacionPlano: 'TARDELCUENDE',
      activo: true
    },
    {
      cdgoEstacion: '67021',
      cdgoAdmon: '0071',
      nmroPrioridad: 360,
      descEstacion: null,
      desgEstacion: 'CALAMOCHA NUEVA',
      cdgoUic: '67021',
      clave: '0071,67021,67021',
      desgEstacionPlano: 'CALAMOCHA NUEVA',
      activo: true
    },
    {
      cdgoEstacion: '67105',
      cdgoAdmon: '0071',
      nmroPrioridad: 361,
      descEstacion: null,
      desgEstacion: 'SANTA EULALIA DEL CAMPO',
      cdgoUic: '67105',
      clave: '0071,67105,67105',
      desgEstacionPlano: 'SANTA EULALIA DEL CAMPO',
      activo: true
    },
    {
      cdgoEstacion: '67107',
      cdgoAdmon: '0071',
      nmroPrioridad: 362,
      descEstacion: null,
      desgEstacion: 'CELLA',
      cdgoUic: '67107',
      clave: '0071,67107,67107',
      desgEstacionPlano: 'CELLA',
      activo: true
    },
    {
      cdgoEstacion: '67215',
      cdgoAdmon: '0071',
      nmroPrioridad: 363,
      descEstacion: null,
      desgEstacion: 'SEGORBE',
      cdgoUic: '67215',
      clave: '0071,67215,67215',
      desgEstacionPlano: 'SEGORBE',
      activo: true
    },
    {
      cdgoEstacion: '70500',
      cdgoAdmon: '0071',
      nmroPrioridad: 364,
      descEstacion: null,
      desgEstacion: 'ARIZA',
      cdgoUic: '70500',
      clave: '0071,70500,70500',
      desgEstacionPlano: 'ARIZA',
      activo: true
    },
    {
      cdgoEstacion: '71208',
      cdgoAdmon: '0071',
      nmroPrioridad: 365,
      descEstacion: null,
      desgEstacion: 'FAIO-LA POBLA DE MASSALUCA (FAYON)',
      cdgoUic: '71208',
      clave: '0071,71208,71208',
      desgEstacionPlano: 'FAIO-LA POBLA DE MASSALUCA (FAYON)',
      activo: true
    },
    {
      cdgoEstacion: '10205',
      cdgoAdmon: '0071',
      nmroPrioridad: 366,
      descEstacion: null,
      desgEstacion: 'ROBLEDO DE CHAVELA',
      cdgoUic: '10205',
      clave: '0071,10205,10205',
      desgEstacionPlano: 'ROBLEDO DE CHAVELA',
      activo: true
    },
    {
      cdgoEstacion: '20314',
      cdgoAdmon: '0071',
      nmroPrioridad: 367,
      descEstacion: null,
      desgEstacion: 'GUITIRIZ',
      cdgoUic: '20314',
      clave: '0071,20314,20314',
      desgEstacionPlano: 'GUITIRIZ',
      activo: true
    },
    {
      cdgoEstacion: '37604',
      cdgoAdmon: '0071',
      nmroPrioridad: 368,
      descEstacion: null,
      desgEstacion: 'GUADIANA',
      cdgoUic: '37604',
      clave: '0071,37604,37604',
      desgEstacionPlano: 'GUADIANA',
      activo: true
    },
    {
      cdgoEstacion: '31416',
      cdgoAdmon: '0071',
      nmroPrioridad: 369,
      descEstacion: null,
      desgEstacion: 'CERCEDA-MEIRAMA',
      cdgoUic: '31416',
      clave: '0071,31416,31416',
      desgEstacionPlano: 'CERCEDA-MEIRAMA',
      activo: true
    },
    {
      cdgoEstacion: '31411',
      cdgoAdmon: '0071',
      nmroPrioridad: 370,
      descEstacion: null,
      desgEstacion: 'UXES',
      cdgoUic: '31411',
      clave: '0071,31411,31411',
      desgEstacionPlano: 'UXES',
      activo: true
    },
    {
      cdgoEstacion: '75114',
      cdgoAdmon: '0071',
      nmroPrioridad: 371,
      descEstacion: null,
      desgEstacion: 'TREMP',
      cdgoUic: '75114',
      clave: '0071,75114,75114',
      desgEstacionPlano: 'TREMP',
      activo: true
    },
    {
      cdgoEstacion: '10206',
      cdgoAdmon: '0071',
      nmroPrioridad: 373,
      descEstacion: null,
      desgEstacion: 'SANTA MARIA DE LA ALAMEDA-PEGUERINOS',
      cdgoUic: '10206',
      clave: '0071,10206,10206',
      desgEstacionPlano: 'SANTA MARIA DE LA ALAMEDA-PEGUERINOS',
      activo: true
    },
    {
      cdgoEstacion: '43009',
      cdgoAdmon: '0071',
      nmroPrioridad: 374,
      descEstacion: null,
      desgEstacion: 'ESCACENA',
      cdgoUic: '43009',
      clave: '0071,43009,43009',
      desgEstacionPlano: 'ESCACENA',
      activo: true
    },
    {
      cdgoEstacion: '40008',
      cdgoAdmon: '0071',
      nmroPrioridad: 375,
      descEstacion: null,
      desgEstacion: 'ZAFRA FERIA',
      cdgoUic: '40008',
      clave: '0071,40008,40008',
      desgEstacionPlano: 'ZAFRA FERIA',
      activo: true
    },
    {
      cdgoEstacion: '37608',
      cdgoAdmon: '0071',
      nmroPrioridad: 376,
      descEstacion: null,
      desgEstacion: 'MONTIJO-EL MOLINO',
      cdgoUic: '37608',
      clave: '0071,37608,37608',
      desgEstacionPlano: 'MONTIJO-EL MOLINO',
      activo: true
    },
    {
      cdgoEstacion: '14200',
      cdgoAdmon: '0071',
      nmroPrioridad: 377,
      descEstacion: null,
      desgEstacion: 'MATAPORQUERA',
      cdgoUic: '14200',
      clave: '0071,14200,14200',
      desgEstacionPlano: 'MATAPORQUERA',
      activo: true
    },
    {
      cdgoEstacion: '12012',
      cdgoAdmon: '0071',
      nmroPrioridad: 378,
      descEstacion: null,
      desgEstacion: 'OTERO-HERREROS',
      cdgoUic: '12012',
      clave: '0071,12012,12012',
      desgEstacionPlano: 'OTERO-HERREROS',
      activo: true
    },
    {
      cdgoEstacion: '14112',
      cdgoAdmon: '0071',
      nmroPrioridad: 379,
      descEstacion: null,
      desgEstacion: 'ALAR DEL REY-SAN QUIRCE',
      cdgoUic: '14112',
      clave: '0071,14112,14112',
      desgEstacionPlano: 'ALAR DEL REY-SAN QUIRCE',
      activo: true
    },
    {
      cdgoEstacion: '22109',
      cdgoAdmon: '0071',
      nmroPrioridad: 380,
      descEstacion: null,
      desgEstacion: 'SALVATERRA DE MIÑO',
      cdgoUic: '22109',
      clave: '0071,22109,22109',
      desgEstacionPlano: 'SALVATERRA DE MINO',
      activo: true
    },
    {
      cdgoEstacion: '99116',
      cdgoAdmon: '0071',
      nmroPrioridad: 381,
      descEstacion: null,
      desgEstacion: 'ROQUETAS-BUS',
      cdgoUic: null,
      clave: '0071,99116,null',
      desgEstacionPlano: 'ROQUETAS-BUS',
      activo: true
    },
    {
      cdgoEstacion: '71503',
      cdgoAdmon: '0071',
      nmroPrioridad: 382,
      descEstacion: null,
      desgEstacion: 'TORREDEMBARRA',
      cdgoUic: '71503',
      clave: '0071,71503,71503',
      desgEstacionPlano: 'TORREDEMBARRA',
      activo: true
    },
    {
      cdgoEstacion: '70605',
      cdgoAdmon: '0071',
      nmroPrioridad: 383,
      descEstacion: null,
      desgEstacion: 'MORES',
      cdgoUic: '70605',
      clave: '0071,70605,70605',
      desgEstacionPlano: 'MORES',
      activo: true
    },
    {
      cdgoEstacion: '71210',
      cdgoAdmon: '0071',
      nmroPrioridad: 384,
      descEstacion: null,
      desgEstacion: 'FLIX',
      cdgoUic: '71210',
      clave: '0071,71210,71210',
      desgEstacionPlano: 'FLIX',
      activo: true
    },
    {
      cdgoEstacion: '66104',
      cdgoAdmon: '0071',
      nmroPrioridad: 385,
      descEstacion: null,
      desgEstacion: 'CARBONERAS DE GUADAZAON',
      cdgoUic: '66104',
      clave: '0071,66104,66104',
      desgEstacionPlano: 'CARBONERAS DE GUADAZAON',
      activo: true
    },
    {
      cdgoEstacion: '66208',
      cdgoAdmon: '0071',
      nmroPrioridad: 386,
      descEstacion: null,
      desgEstacion: 'CHESTE',
      cdgoUic: '66208',
      clave: '0071,66208,66208',
      desgEstacionPlano: 'CHESTE',
      activo: true
    },
    {
      cdgoEstacion: '94563',
      cdgoAdmon: '0094',
      nmroPrioridad: 387,
      descEstacion: null,
      desgEstacion: 'VILAR FORMOSO',
      cdgoUic: '00563',
      clave: '0094,94563,00563',
      desgEstacionPlano: 'VILAR FORMOSO',
      activo: true
    },
    {
      cdgoEstacion: '81210',
      cdgoAdmon: '0071',
      nmroPrioridad: 388,
      descEstacion: null,
      desgEstacion: 'ALAGON',
      cdgoUic: '81210',
      clave: '0071,81210,81210',
      desgEstacionPlano: 'ALAGON',
      activo: true
    },
    {
      cdgoEstacion: '78203',
      cdgoAdmon: '0071',
      nmroPrioridad: 389,
      descEstacion: null,
      desgEstacion: 'SARIÑENA',
      cdgoUic: '78203',
      clave: '0071,78203,78203',
      desgEstacionPlano: 'SARINENA',
      activo: true
    },
    {
      cdgoEstacion: '54509',
      cdgoAdmon: '0071',
      nmroPrioridad: 390,
      descEstacion: null,
      desgEstacion: 'TORREMOLINOS',
      cdgoUic: '54509',
      clave: '0071,54509,54509',
      desgEstacionPlano: 'TORREMOLINOS',
      activo: true
    },
    {
      cdgoEstacion: '56301',
      cdgoAdmon: '0071',
      nmroPrioridad: 391,
      descEstacion: null,
      desgEstacion: 'FIÑANA',
      cdgoUic: '56301',
      clave: '0071,56301,56301',
      desgEstacionPlano: 'FINANA',
      activo: true
    },
    {
      cdgoEstacion: '60207',
      cdgoAdmon: '0071',
      nmroPrioridad: 392,
      descEstacion: null,
      desgEstacion: 'EL ROMERAL',
      cdgoUic: '60207',
      clave: '0071,60207,60207',
      desgEstacionPlano: 'EL ROMERAL',
      activo: true
    },
    {
      cdgoEstacion: '64200',
      cdgoAdmon: '0071',
      nmroPrioridad: 393,
      descEstacion: null,
      desgEstacion: 'SILLA',
      cdgoUic: '64200',
      clave: '0071,64200,64200',
      desgEstacionPlano: 'SILLA',
      activo: true
    },
    {
      cdgoEstacion: '62109',
      cdgoAdmon: '0071',
      nmroPrioridad: 394,
      descEstacion: null,
      desgEstacion: 'SANT GABRIEL',
      cdgoUic: '62109',
      clave: '0071,62109,62109',
      desgEstacionPlano: 'SANT GABRIEL',
      activo: true
    },
    {
      cdgoEstacion: '71401',
      cdgoAdmon: '0071',
      nmroPrioridad: 395,
      descEstacion: null,
      desgEstacion: 'VILA-SECA',
      cdgoUic: '71401',
      clave: '0071,71401,71401',
      desgEstacionPlano: 'VILA-SECA',
      activo: true
    },
    {
      cdgoEstacion: '66202',
      cdgoAdmon: '0071',
      nmroPrioridad: 396,
      descEstacion: null,
      desgEstacion: 'REQUENA',
      cdgoUic: '66202',
      clave: '0071,66202,66202',
      desgEstacionPlano: 'REQUENA',
      activo: true
    },
    {
      cdgoEstacion: '70209',
      cdgoAdmon: '0071',
      nmroPrioridad: 397,
      descEstacion: null,
      desgEstacion: 'JADRAQUE',
      cdgoUic: '70209',
      clave: '0071,70209,70209',
      desgEstacionPlano: 'JADRAQUE',
      activo: true
    },
    {
      cdgoEstacion: '10104',
      cdgoAdmon: '0071',
      nmroPrioridad: 398,
      descEstacion: null,
      desgEstacion: 'GALAPAGAR-LA NAVATA',
      cdgoUic: '10104',
      clave: '0071,10104,10104',
      desgEstacionPlano: 'GALAPAGAR-LA NAVATA',
      activo: true
    },
    {
      cdgoEstacion: '20102',
      cdgoAdmon: '0071',
      nmroPrioridad: 399,
      descEstacion: null,
      desgEstacion: 'VEGA-MAGAZ',
      cdgoUic: '20102',
      clave: '0071,20102,20102',
      desgEstacionPlano: 'VEGA-MAGAZ',
      activo: true
    },
    {
      cdgoEstacion: '20204',
      cdgoAdmon: '0071',
      nmroPrioridad: 400,
      descEstacion: null,
      desgEstacion: 'TORAL DE LOS VADOS',
      cdgoUic: '20204',
      clave: '0071,20204,20204',
      desgEstacionPlano: 'TORAL DE LOS VADOS',
      activo: true
    },
    {
      cdgoEstacion: '22102',
      cdgoAdmon: '0071',
      nmroPrioridad: 401,
      descEstacion: null,
      desgEstacion: 'RIBADAVIA',
      cdgoUic: '22102',
      clave: '0071,22102,22102',
      desgEstacionPlano: 'RIBADAVIA',
      activo: true
    },
    {
      cdgoEstacion: '22401',
      cdgoAdmon: '0071',
      nmroPrioridad: 402,
      descEstacion: null,
      desgEstacion: 'TUI /TUY',
      cdgoUic: '22401',
      clave: '0071,22401,22401',
      desgEstacionPlano: 'TUI /TUY',
      activo: true
    },
    {
      cdgoEstacion: '15007',
      cdgoAdmon: '0071',
      nmroPrioridad: 403,
      descEstacion: null,
      desgEstacion: 'VILLADA',
      cdgoUic: '15007',
      clave: '0071,15007,15007',
      desgEstacionPlano: 'VILLADA',
      activo: true
    },
    {
      cdgoEstacion: '14104',
      cdgoAdmon: '0071',
      nmroPrioridad: 404,
      descEstacion: null,
      desgEstacion: 'FROMISTA',
      cdgoUic: '14104',
      clave: '0071,14104,14104',
      desgEstacionPlano: 'FROMISTA',
      activo: true
    },
    {
      cdgoEstacion: '12010',
      cdgoAdmon: '0071',
      nmroPrioridad: 405,
      descEstacion: null,
      desgEstacion: 'EL ESPINAR',
      cdgoUic: '12010',
      clave: '0071,12010,12010',
      desgEstacionPlano: 'EL ESPINAR',
      activo: true
    },
    {
      cdgoEstacion: '12011',
      cdgoAdmon: '0071',
      nmroPrioridad: 406,
      descEstacion: null,
      desgEstacion: 'LOS ANGELES DE SAN RAFAEL',
      cdgoUic: '12011',
      clave: '0071,12011,12011',
      desgEstacionPlano: 'LOS ANGELES DE SAN RAFAEL',
      activo: true
    },
    {
      cdgoEstacion: '35109',
      cdgoAdmon: '0071',
      nmroPrioridad: 407,
      descEstacion: null,
      desgEstacion: 'MONTEARAGON',
      cdgoUic: '35109',
      clave: '0071,35109,35109',
      desgEstacionPlano: 'MONTEARAGON',
      activo: true
    },
    {
      cdgoEstacion: '35207',
      cdgoAdmon: '0071',
      nmroPrioridad: 408,
      descEstacion: null,
      desgEstacion: 'CASATEJADA',
      cdgoUic: '35207',
      clave: '0071,35207,35207',
      desgEstacionPlano: 'CASATEJADA',
      activo: true
    },
    {
      cdgoEstacion: '42005',
      cdgoAdmon: '0071',
      nmroPrioridad: 409,
      descEstacion: null,
      desgEstacion: 'FREGENAL DE LA SIERRA',
      cdgoUic: '42005',
      clave: '0071,42005,42005',
      desgEstacionPlano: 'FREGENAL DE LA SIERRA',
      activo: true
    },
    {
      cdgoEstacion: '40108',
      cdgoAdmon: '0071',
      nmroPrioridad: 410,
      descEstacion: null,
      desgEstacion: 'GUADALCANAL',
      cdgoUic: '40108',
      clave: '0071,40108,40108',
      desgEstacionPlano: 'GUADALCANAL',
      activo: true
    },
    {
      cdgoEstacion: '40006',
      cdgoAdmon: '0071',
      nmroPrioridad: 411,
      descEstacion: null,
      desgEstacion: 'LOS SANTOS DE MAIMONA',
      cdgoUic: '40006',
      clave: '0071,40006,40006',
      desgEstacionPlano: 'LOS SANTOS DE MAIMONA',
      activo: true
    },
    {
      cdgoEstacion: '65005',
      cdgoAdmon: '0071',
      nmroPrioridad: 412,
      descEstacion: null,
      desgEstacion: 'ALBUIXECH',
      cdgoUic: '65005',
      clave: '0071,65005,65005',
      desgEstacionPlano: 'ALBUIXECH',
      activo: true
    },
    {
      cdgoEstacion: '60901',
      cdgoAdmon: '0071',
      nmroPrioridad: 413,
      descEstacion: null,
      desgEstacion: 'CAUDETE',
      cdgoUic: '60901',
      clave: '0071,60901,60901',
      desgEstacionPlano: 'CAUDETE',
      activo: true
    },
    {
      cdgoEstacion: '60904',
      cdgoAdmon: '0071',
      nmroPrioridad: 414,
      descEstacion: null,
      desgEstacion: 'SAX',
      cdgoUic: '60904',
      clave: '0071,60904,60904',
      desgEstacionPlano: 'SAX',
      activo: true
    },
    {
      cdgoEstacion: '60100',
      cdgoAdmon: '0071',
      nmroPrioridad: 415,
      descEstacion: null,
      desgEstacion: 'MADRID-VILLAVERDE BAJO',
      cdgoUic: '60100',
      clave: '0071,60100,60100',
      desgEstacionPlano: 'MADRID-VILLAVERDE BAJO',
      activo: true
    },
    {
      cdgoEstacion: '55005',
      cdgoAdmon: '0071',
      nmroPrioridad: 416,
      descEstacion: null,
      desgEstacion: 'SETENIL',
      cdgoUic: '55005',
      clave: '0071,55005,55005',
      desgEstacionPlano: 'SETENIL',
      activo: true
    },
    {
      cdgoEstacion: '55014',
      cdgoAdmon: '0071',
      nmroPrioridad: 417,
      descEstacion: null,
      desgEstacion: 'SAN PABLO',
      cdgoUic: '55014',
      clave: '0071,55014,55014',
      desgEstacionPlano: 'SAN PABLO',
      activo: true
    },
    {
      cdgoEstacion: '79202',
      cdgoAdmon: '0071',
      nmroPrioridad: 418,
      descEstacion: null,
      desgEstacion: 'SILS',
      cdgoUic: '79202',
      clave: '0071,79202,79202',
      desgEstacionPlano: 'SILS',
      activo: true
    },
    {
      cdgoEstacion: '81203',
      cdgoAdmon: '0071',
      nmroPrioridad: 419,
      descEstacion: null,
      desgEstacion: 'RIBAFORADA',
      cdgoUic: '81203',
      clave: '0071,81203,81203',
      desgEstacionPlano: 'RIBAFORADA',
      activo: true
    },
    {
      cdgoEstacion: '81106',
      cdgoAdmon: '0071',
      nmroPrioridad: 420,
      descEstacion: null,
      desgEstacion: 'FECULAS-NAVARRA',
      cdgoUic: '81106',
      clave: '0071,81106,81106',
      desgEstacionPlano: 'FECULAS-NAVARRA',
      activo: true
    },
    {
      cdgoEstacion: '81105',
      cdgoAdmon: '0071',
      nmroPrioridad: 421,
      descEstacion: null,
      desgEstacion: 'ALCANADRE',
      cdgoUic: '81105',
      clave: '0071,81105,81105',
      desgEstacionPlano: 'ALCANADRE',
      activo: true
    },
    {
      cdgoEstacion: '92157',
      cdgoAdmon: '0071',
      nmroPrioridad: 423,
      descEstacion: null,
      desgEstacion: 'ESTEPONA-BUS',
      cdgoUic: null,
      clave: '0071,92157,null',
      desgEstacionPlano: 'ESTEPONA-BUS',
      activo: true
    },
    {
      cdgoEstacion: '94543',
      cdgoAdmon: '0094',
      nmroPrioridad: 424,
      descEstacion: null,
      desgEstacion: 'CELORICO-BEIRA',
      cdgoUic: '00543',
      clave: '0094,94543,00543',
      desgEstacionPlano: 'CELORICO-BEIRA',
      activo: true
    },
    {
      cdgoEstacion: '67206',
      cdgoAdmon: '0071',
      nmroPrioridad: 425,
      descEstacion: null,
      desgEstacion: 'MORA DE RUBIELOS',
      cdgoUic: '67206',
      clave: '0071,67206,67206',
      desgEstacionPlano: 'MORA DE RUBIELOS',
      activo: true
    },
    {
      cdgoEstacion: '65007',
      cdgoAdmon: '0071',
      nmroPrioridad: 426,
      descEstacion: null,
      desgEstacion: 'EL PUIG',
      cdgoUic: '65007',
      clave: '0071,65007,65007',
      desgEstacionPlano: 'EL PUIG',
      activo: true
    },
    {
      cdgoEstacion: '10103',
      cdgoAdmon: '0071',
      nmroPrioridad: 427,
      descEstacion: null,
      desgEstacion: 'TORRELODONES',
      cdgoUic: '10103',
      clave: '0071,10103,10103',
      desgEstacionPlano: 'TORRELODONES',
      activo: true
    },
    {
      cdgoEstacion: '10202',
      cdgoAdmon: '0071',
      nmroPrioridad: 428,
      descEstacion: null,
      desgEstacion: 'LAS ZORRERAS-NAVALQUEJIGO',
      cdgoUic: '10202',
      clave: '0071,10202,10202',
      desgEstacionPlano: 'LAS ZORRERAS-NAVALQUEJIGO',
      activo: true
    },
    {
      cdgoEstacion: '10101',
      cdgoAdmon: '0071',
      nmroPrioridad: 429,
      descEstacion: null,
      desgEstacion: 'LAS MATAS',
      cdgoUic: '10101',
      clave: '0071,10101,10101',
      desgEstacionPlano: 'LAS MATAS',
      activo: true
    },
    {
      cdgoEstacion: '05790',
      cdgoAdmon: '0071',
      nmroPrioridad: 433,
      descEstacion: null,
      desgEstacion: 'SORRIBA',
      cdgoUic: null,
      clave: '0071,05790,null',
      desgEstacionPlano: 'SORRIBA',
      activo: true
    },
    {
      cdgoEstacion: '05799',
      cdgoAdmon: '0071',
      nmroPrioridad: 440,
      descEstacion: null,
      desgEstacion: 'CEREZAL DE LA GUZPEÑA',
      cdgoUic: null,
      clave: '0071,05799,null',
      desgEstacionPlano: 'CEREZAL DE LA GUZPENA',
      activo: true
    },
    {
      cdgoEstacion: '05803',
      cdgoAdmon: '0071',
      nmroPrioridad: 442,
      descEstacion: null,
      desgEstacion: 'LA ASUNCIÓN/UNIVERSIDAD',
      cdgoUic: null,
      clave: '0071,05803,null',
      desgEstacionPlano: 'LA ASUNCION/UNIVERSIDAD',
      activo: true
    },
    {
      cdgoEstacion: '06001',
      cdgoAdmon: '0071',
      nmroPrioridad: 459,
      descEstacion: null,
      desgEstacion: 'LIBRILLA (APD)',
      cdgoUic: '06001',
      clave: '0071,06001,06001',
      desgEstacionPlano: 'LIBRILLA (APD)',
      activo: true
    },
    {
      cdgoEstacion: '06008',
      cdgoAdmon: '0071',
      nmroPrioridad: 460,
      descEstacion: null,
      desgEstacion: 'ALCANTARILLA LOS ROMANOS',
      cdgoUic: '06008',
      clave: '0071,06008,06008',
      desgEstacionPlano: 'ALCANTARILLA LOS ROMANOS',
      activo: true
    },
    {
      cdgoEstacion: '06004',
      cdgoAdmon: '0071',
      nmroPrioridad: 461,
      descEstacion: null,
      desgEstacion: 'LA HOYA (APD)',
      cdgoUic: '06004',
      clave: '0071,06004,06004',
      desgEstacionPlano: 'LA HOYA (APD)',
      activo: true
    },
    {
      cdgoEstacion: '06005',
      cdgoAdmon: '0071',
      nmroPrioridad: 462,
      descEstacion: null,
      desgEstacion: 'LORCA-SAN DIEGO (APD)',
      cdgoUic: '06005',
      clave: '0071,06005,06005',
      desgEstacionPlano: 'LORCA-SAN DIEGO (APD)',
      activo: true
    },
    {
      cdgoEstacion: '11203',
      cdgoAdmon: '0071',
      nmroPrioridad: 463,
      descEstacion: null,
      desgEstacion: 'MANZANOS',
      cdgoUic: '11203',
      clave: '0071,11203,11203',
      desgEstacionPlano: 'MANZANOS',
      activo: true
    },
    {
      cdgoEstacion: '11204',
      cdgoAdmon: '0071',
      nmroPrioridad: 464,
      descEstacion: null,
      desgEstacion: 'LA PUEBLA DE ARGANZON',
      cdgoUic: '11204',
      clave: '0071,11204,11204',
      desgEstacionPlano: 'LA PUEBLA DE ARGANZON',
      activo: true
    },
    {
      cdgoEstacion: '11205',
      cdgoAdmon: '0071',
      nmroPrioridad: 465,
      descEstacion: null,
      desgEstacion: 'NANCLARES/LANGRAIZ',
      cdgoUic: '11205',
      clave: '0071,11205,11205',
      desgEstacionPlano: 'NANCLARES/LANGRAIZ',
      activo: true
    },
    {
      cdgoEstacion: '11111',
      cdgoAdmon: '0071',
      nmroPrioridad: 466,
      descEstacion: null,
      desgEstacion: 'PANCORBO',
      cdgoUic: '11111',
      clave: '0071,11111,11111',
      desgEstacionPlano: 'PANCORBO',
      activo: true
    },
    {
      cdgoEstacion: '11006',
      cdgoAdmon: '0071',
      nmroPrioridad: 467,
      descEstacion: null,
      desgEstacion: 'QUINTANA DEL PUENTE',
      cdgoUic: '11006',
      clave: '0071,11006,11006',
      desgEstacionPlano: 'QUINTANA DEL PUENTE',
      activo: true
    },
    {
      cdgoEstacion: '11009',
      cdgoAdmon: '0071',
      nmroPrioridad: 468,
      descEstacion: null,
      desgEstacion: 'VILLAQUIRAN',
      cdgoUic: '11009',
      clave: '0071,11009,11009',
      desgEstacionPlano: 'VILLAQUIRAN',
      activo: true
    },
    {
      cdgoEstacion: '11004',
      cdgoAdmon: '0071',
      nmroPrioridad: 469,
      descEstacion: null,
      desgEstacion: 'MAGAZ',
      cdgoUic: '11004',
      clave: '0071,11004,11004',
      desgEstacionPlano: 'MAGAZ',
      activo: true
    },
    {
      cdgoEstacion: '10603',
      cdgoAdmon: '0071',
      nmroPrioridad: 470,
      descEstacion: null,
      desgEstacion: 'CORCOS-AGUILAREJO',
      cdgoUic: '10603',
      clave: '0071,10603,10603',
      desgEstacionPlano: 'CORCOS-AGUILAREJO',
      activo: true
    },
    {
      cdgoEstacion: '10604',
      cdgoAdmon: '0071',
      nmroPrioridad: 471,
      descEstacion: null,
      desgEstacion: 'CUBILLAS DE SANTA MARTA',
      cdgoUic: '10604',
      clave: '0071,10604,10604',
      desgEstacionPlano: 'CUBILLAS DE SANTA MARTA',
      activo: true
    },
    {
      cdgoEstacion: '10605',
      cdgoAdmon: '0071',
      nmroPrioridad: 472,
      descEstacion: null,
      desgEstacion: 'DUEÑAS',
      cdgoUic: '10605',
      clave: '0071,10605,10605',
      desgEstacionPlano: 'DUENAS',
      activo: true
    },
    {
      cdgoEstacion: '11306',
      cdgoAdmon: '0071',
      nmroPrioridad: 473,
      descEstacion: null,
      desgEstacion: 'LEGAZPI',
      cdgoUic: '11306',
      clave: '0071,11306,11306',
      desgEstacionPlano: 'LEGAZPI',
      activo: true
    },
    {
      cdgoEstacion: '11303',
      cdgoAdmon: '0071',
      nmroPrioridad: 474,
      descEstacion: null,
      desgEstacion: 'ZEGAMA-OTZAURTE (APT)',
      cdgoUic: '11303',
      clave: '0071,11303,11303',
      desgEstacionPlano: 'ZEGAMA-OTZAURTE (APT)',
      activo: true
    },
    {
      cdgoEstacion: '11209',
      cdgoAdmon: '0071',
      nmroPrioridad: 475,
      descEstacion: null,
      desgEstacion: 'ESTIBALIZ-OREITIA',
      cdgoUic: '11209',
      clave: '0071,11209,11209',
      desgEstacionPlano: 'ESTIBALIZ-OREITIA',
      activo: true
    },
    {
      cdgoEstacion: '11213',
      cdgoAdmon: '0071',
      nmroPrioridad: 476,
      descEstacion: null,
      desgEstacion: 'ARAIA',
      cdgoUic: '11213',
      clave: '0071,11213,11213',
      desgEstacionPlano: 'ARAIA',
      activo: true
    },
    {
      cdgoEstacion: '11405',
      cdgoAdmon: '0071',
      nmroPrioridad: 478,
      descEstacion: null,
      desgEstacion: 'ORDIZIA/ORDICIA',
      cdgoUic: '11405',
      clave: '0071,11405,11405',
      desgEstacionPlano: 'ORDIZIA/ORDICIA',
      activo: true
    },
    {
      cdgoEstacion: '11512',
      cdgoAdmon: '0071',
      nmroPrioridad: 479,
      descEstacion: null,
      desgEstacion: 'GROS',
      cdgoUic: '11512',
      clave: '0071,11512,11512',
      desgEstacionPlano: 'GROS',
      activo: true
    },
    {
      cdgoEstacion: '11515',
      cdgoAdmon: '0071',
      nmroPrioridad: 480,
      descEstacion: null,
      desgEstacion: 'PASAIA',
      cdgoUic: '11515',
      clave: '0071,11515,11515',
      desgEstacionPlano: 'PASAIA',
      activo: true
    },
    {
      cdgoEstacion: '10503',
      cdgoAdmon: '0071',
      nmroPrioridad: 481,
      descEstacion: null,
      desgEstacion: 'VALDESTILLAS',
      cdgoUic: '10503',
      clave: '0071,10503,10503',
      desgEstacionPlano: 'VALDESTILLAS',
      activo: true
    },
    {
      cdgoEstacion: '10504',
      cdgoAdmon: '0071',
      nmroPrioridad: 482,
      descEstacion: null,
      desgEstacion: 'VIANA',
      cdgoUic: '10504',
      clave: '0071,10504,10504',
      desgEstacionPlano: 'VIANA',
      activo: true
    },
    {
      cdgoEstacion: '10602',
      cdgoAdmon: '0071',
      nmroPrioridad: 483,
      descEstacion: null,
      desgEstacion: 'CABEZON DEL PISUERGA',
      cdgoUic: '10602',
      clave: '0071,10602,10602',
      desgEstacionPlano: 'CABEZON DEL PISUERGA',
      activo: true
    },
    {
      cdgoEstacion: '10501',
      cdgoAdmon: '0071',
      nmroPrioridad: 484,
      descEstacion: null,
      desgEstacion: 'POZALDEZ',
      cdgoUic: '10501',
      clave: '0071,10501,10501',
      desgEstacionPlano: 'POZALDEZ',
      activo: true
    },
    {
      cdgoEstacion: '10502',
      cdgoAdmon: '0071',
      nmroPrioridad: 485,
      descEstacion: null,
      desgEstacion: 'MATAPOZUELOS',
      cdgoUic: '10502',
      clave: '0071,10502,10502',
      desgEstacionPlano: 'MATAPOZUELOS',
      activo: true
    },
    {
      cdgoEstacion: '10302',
      cdgoAdmon: '0071',
      nmroPrioridad: 486,
      descEstacion: null,
      desgEstacion: 'GUIMORCONDO',
      cdgoUic: '10302',
      clave: '0071,10302,10302',
      desgEstacionPlano: 'GUIMORCONDO',
      activo: true
    },
    {
      cdgoEstacion: '10209',
      cdgoAdmon: '0071',
      nmroPrioridad: 487,
      descEstacion: null,
      desgEstacion: 'EL PIMPOLLAR',
      cdgoUic: '10209',
      clave: '0071,10209,10209',
      desgEstacionPlano: 'EL PIMPOLLAR',
      activo: true
    },
    {
      cdgoEstacion: '10001',
      cdgoAdmon: '0071',
      nmroPrioridad: 488,
      descEstacion: null,
      desgEstacion: 'ARAVACA (APD-CGD)',
      cdgoUic: '10001',
      clave: '0071,10001,10001',
      desgEstacionPlano: 'ARAVACA (APD-CGD)',
      activo: true
    },
    {
      cdgoEstacion: '10002',
      cdgoAdmon: '0071',
      nmroPrioridad: 489,
      descEstacion: null,
      desgEstacion: 'POZUELO',
      cdgoUic: '10002',
      clave: '0071,10002,10002',
      desgEstacionPlano: 'POZUELO',
      activo: true
    },
    {
      cdgoEstacion: '10005',
      cdgoAdmon: '0071',
      nmroPrioridad: 490,
      descEstacion: null,
      desgEstacion: 'LAS ROZAS (APD)',
      cdgoUic: '10005',
      clave: '0071,10005,10005',
      desgEstacionPlano: 'LAS ROZAS (APD)',
      activo: true
    },
    {
      cdgoEstacion: '10100',
      cdgoAdmon: '0071',
      nmroPrioridad: 491,
      descEstacion: null,
      desgEstacion: 'PINAR DE LAS ROZAS',
      cdgoUic: '10100',
      clave: '0071,10100,10100',
      desgEstacionPlano: 'PINAR DE LAS ROZAS',
      activo: true
    },
    {
      cdgoEstacion: '10000',
      cdgoAdmon: '0071',
      nmroPrioridad: 492,
      descEstacion: null,
      desgEstacion: 'MADRID-PRÍNCIPE PIO',
      cdgoUic: '10000',
      clave: '0071,10000,10000',
      desgEstacionPlano: 'MADRID-PRINCIPE PIO',
      activo: true
    },
    {
      cdgoEstacion: '10201',
      cdgoAdmon: '0071',
      nmroPrioridad: 493,
      descEstacion: null,
      desgEstacion: 'SAN YAGO',
      cdgoUic: '10201',
      clave: '0071,10201,10201',
      desgEstacionPlano: 'SAN YAGO',
      activo: true
    },
    {
      cdgoEstacion: '72305',
      cdgoAdmon: '0071',
      nmroPrioridad: 495,
      descEstacion: null,
      desgEstacion: "L'HOSPITALET DE LLOBREGAT",
      cdgoUic: '72305',
      clave: '0071,72305,72305',
      desgEstacionPlano: "L'HOSPITALET DE LLOBREGAT",
      activo: true
    },
    {
      cdgoEstacion: '73001',
      cdgoAdmon: '0071',
      nmroPrioridad: 496,
      descEstacion: null,
      desgEstacion: 'PUIGVERD DE LLEIDA-ARTESA DE LLEIDA',
      cdgoUic: '73001',
      clave: '0071,73001,73001',
      desgEstacionPlano: 'PUIGVERD DE LLEIDA-ARTESA DE LLEIDA',
      activo: true
    },
    {
      cdgoEstacion: '73002',
      cdgoAdmon: '0071',
      nmroPrioridad: 497,
      descEstacion: null,
      desgEstacion: 'JUNEDA',
      cdgoUic: '73002',
      clave: '0071,73002,73002',
      desgEstacionPlano: 'JUNEDA',
      activo: true
    },
    {
      cdgoEstacion: '73003',
      cdgoAdmon: '0071',
      nmroPrioridad: 498,
      descEstacion: null,
      desgEstacion: 'LES BORGES BLANQUES',
      cdgoUic: '73003',
      clave: '0071,73003,73003',
      desgEstacionPlano: 'LES BORGES BLANQUES',
      activo: true
    },
    {
      cdgoEstacion: '73004',
      cdgoAdmon: '0071',
      nmroPrioridad: 499,
      descEstacion: null,
      desgEstacion: 'LA FLORESTA',
      cdgoUic: '73004',
      clave: '0071,73004,73004',
      desgEstacionPlano: 'LA FLORESTA',
      activo: true
    },
    {
      cdgoEstacion: '73005',
      cdgoAdmon: '0071',
      nmroPrioridad: 500,
      descEstacion: null,
      desgEstacion: 'VINAIXA',
      cdgoUic: '73005',
      clave: '0071,73005,73005',
      desgEstacionPlano: 'VINAIXA',
      activo: true
    },
    {
      cdgoEstacion: '73006',
      cdgoAdmon: '0071',
      nmroPrioridad: 501,
      descEstacion: null,
      desgEstacion: 'VIMBODI I POBLET',
      cdgoUic: '73006',
      clave: '0071,73006,73006',
      desgEstacionPlano: 'VIMBODI I POBLET',
      activo: true
    },
    {
      cdgoEstacion: '73007',
      cdgoAdmon: '0071',
      nmroPrioridad: 502,
      descEstacion: null,
      desgEstacion: "L'ESPLUGA DE FRANCOLI",
      cdgoUic: '73007',
      clave: '0071,73007,73007',
      desgEstacionPlano: "L'ESPLUGA DE FRANCOLI",
      activo: true
    },
    {
      cdgoEstacion: '73008',
      cdgoAdmon: '0071',
      nmroPrioridad: 503,
      descEstacion: null,
      desgEstacion: 'MONTBLANC',
      cdgoUic: '73008',
      clave: '0071,73008,73008',
      desgEstacionPlano: 'MONTBLANC',
      activo: true
    },
    {
      cdgoEstacion: '73009',
      cdgoAdmon: '0071',
      nmroPrioridad: 504,
      descEstacion: null,
      desgEstacion: 'VILAVERD',
      cdgoUic: '73009',
      clave: '0071,73009,73009',
      desgEstacionPlano: 'VILAVERD',
      activo: true
    },
    {
      cdgoEstacion: '73010',
      cdgoAdmon: '0071',
      nmroPrioridad: 505,
      descEstacion: null,
      desgEstacion: 'LA RIBA',
      cdgoUic: '73010',
      clave: '0071,73010,73010',
      desgEstacionPlano: 'LA RIBA',
      activo: true
    },
    {
      cdgoEstacion: '73100',
      cdgoAdmon: '0071',
      nmroPrioridad: 506,
      descEstacion: null,
      desgEstacion: 'LA PLANA-PICAMOIXONS',
      cdgoUic: '73100',
      clave: '0071,73100,73100',
      desgEstacionPlano: 'LA PLANA-PICAMOIXONS',
      activo: true
    },
    {
      cdgoEstacion: '73101',
      cdgoAdmon: '0071',
      nmroPrioridad: 507,
      descEstacion: null,
      desgEstacion: 'ALCOVER',
      cdgoUic: '73101',
      clave: '0071,73101,73101',
      desgEstacionPlano: 'ALCOVER',
      activo: true
    },
    {
      cdgoEstacion: '73102',
      cdgoAdmon: '0071',
      nmroPrioridad: 508,
      descEstacion: null,
      desgEstacion: 'LA SELVA DEL CAMP',
      cdgoUic: '73102',
      clave: '0071,73102,73102',
      desgEstacionPlano: 'LA SELVA DEL CAMP',
      activo: true
    },
    {
      cdgoEstacion: '74215',
      cdgoAdmon: '0071',
      nmroPrioridad: 509,
      descEstacion: null,
      desgEstacion: 'CASTIELLO',
      cdgoUic: '74215',
      clave: '0071,74215,74215',
      desgEstacionPlano: 'CASTIELLO',
      activo: true
    },
    {
      cdgoEstacion: '74216',
      cdgoAdmon: '0071',
      nmroPrioridad: 510,
      descEstacion: null,
      desgEstacion: 'VILLANUA-LETRANZ',
      cdgoUic: '74216',
      clave: '0071,74216,74216',
      desgEstacionPlano: 'VILLANUA-LETRANZ',
      activo: true
    },
    {
      cdgoEstacion: '74214',
      cdgoAdmon: '0071',
      nmroPrioridad: 511,
      descEstacion: null,
      desgEstacion: 'CASTIELLO-PUEBLO',
      cdgoUic: '74214',
      clave: '0071,74214,74214',
      desgEstacionPlano: 'CASTIELLO-PUEBLO',
      activo: true
    },
    {
      cdgoEstacion: '75102',
      cdgoAdmon: '0071',
      nmroPrioridad: 512,
      descEstacion: null,
      desgEstacion: 'VILANOVA DE LA BARCA',
      cdgoUic: '75102',
      clave: '0071,75102,75102',
      desgEstacionPlano: 'VILANOVA DE LA BARCA',
      activo: true
    },
    {
      cdgoEstacion: '75103',
      cdgoAdmon: '0071',
      nmroPrioridad: 513,
      descEstacion: null,
      desgEstacion: 'TERMENS',
      cdgoUic: '75103',
      clave: '0071,75103,75103',
      desgEstacionPlano: 'TERMENS',
      activo: true
    },
    {
      cdgoEstacion: '75101',
      cdgoAdmon: '0071',
      nmroPrioridad: 514,
      descEstacion: null,
      desgEstacion: 'ALCOLETGE',
      cdgoUic: '75101',
      clave: '0071,75101,75101',
      desgEstacionPlano: 'ALCOLETGE',
      activo: true
    },
    {
      cdgoEstacion: '74202',
      cdgoAdmon: '0071',
      nmroPrioridad: 515,
      descEstacion: null,
      desgEstacion: 'PLASENCIA DEL MONTE',
      cdgoUic: '74202',
      clave: '0071,74202,74202',
      desgEstacionPlano: 'PLASENCIA DEL MONTE',
      activo: true
    },
    {
      cdgoEstacion: '74204',
      cdgoAdmon: '0071',
      nmroPrioridad: 516,
      descEstacion: null,
      desgEstacion: 'AYERBE',
      cdgoUic: '74204',
      clave: '0071,74204,74204',
      desgEstacionPlano: 'AYERBE',
      activo: true
    },
    {
      cdgoEstacion: '74205',
      cdgoAdmon: '0071',
      nmroPrioridad: 517,
      descEstacion: null,
      desgEstacion: 'RIGLOS-CONCILIO',
      cdgoUic: '74205',
      clave: '0071,74205,74205',
      desgEstacionPlano: 'RIGLOS-CONCILIO',
      activo: true
    },
    {
      cdgoEstacion: '74206',
      cdgoAdmon: '0071',
      nmroPrioridad: 518,
      descEstacion: null,
      desgEstacion: 'RIGLOS',
      cdgoUic: '74206',
      clave: '0071,74206,74206',
      desgEstacionPlano: 'RIGLOS',
      activo: true
    },
    {
      cdgoEstacion: '74207',
      cdgoAdmon: '0071',
      nmroPrioridad: 519,
      descEstacion: null,
      desgEstacion: 'SANTA MARIA Y LA PEÑA',
      cdgoUic: '74207',
      clave: '0071,74207,74207',
      desgEstacionPlano: 'SANTA MARIA Y LA PENA',
      activo: true
    },
    {
      cdgoEstacion: '74208',
      cdgoAdmon: '0071',
      nmroPrioridad: 520,
      descEstacion: null,
      desgEstacion: 'ANZANIGO',
      cdgoUic: '74208',
      clave: '0071,74208,74208',
      desgEstacionPlano: 'ANZANIGO',
      activo: true
    },
    {
      cdgoEstacion: '74209',
      cdgoAdmon: '0071',
      nmroPrioridad: 521,
      descEstacion: null,
      desgEstacion: 'CALDEARENAS-AQUILUE',
      cdgoUic: '74209',
      clave: '0071,74209,74209',
      desgEstacionPlano: 'CALDEARENAS-AQUILUE',
      activo: true
    },
    {
      cdgoEstacion: '71701',
      cdgoAdmon: '0071',
      nmroPrioridad: 522,
      descEstacion: null,
      desgEstacion: 'SITGES',
      cdgoUic: '71701',
      clave: '0071,71701,71701',
      desgEstacionPlano: 'SITGES',
      activo: true
    },
    {
      cdgoEstacion: '71704',
      cdgoAdmon: '0071',
      nmroPrioridad: 523,
      descEstacion: null,
      desgEstacion: 'PLATJA DE CASTELLDEFELS',
      cdgoUic: '71704',
      clave: '0071,71704,71704',
      desgEstacionPlano: 'PLATJA DE CASTELLDEFELS',
      activo: true
    },
    {
      cdgoEstacion: '71705',
      cdgoAdmon: '0071',
      nmroPrioridad: 524,
      descEstacion: null,
      desgEstacion: 'CASTELLDEFELS',
      cdgoUic: '71705',
      clave: '0071,71705,71705',
      desgEstacionPlano: 'CASTELLDEFELS',
      activo: true
    },
    {
      cdgoEstacion: '71706',
      cdgoAdmon: '0071',
      nmroPrioridad: 525,
      descEstacion: null,
      desgEstacion: 'GAVA',
      cdgoUic: '71706',
      clave: '0071,71706,71706',
      desgEstacionPlano: 'GAVA',
      activo: true
    },
    {
      cdgoEstacion: '71602',
      cdgoAdmon: '0071',
      nmroPrioridad: 526,
      descEstacion: null,
      desgEstacion: 'SEGUR DE CALAFELL',
      cdgoUic: '71602',
      clave: '0071,71602,71602',
      desgEstacionPlano: 'SEGUR DE CALAFELL',
      activo: true
    },
    {
      cdgoEstacion: '71603',
      cdgoAdmon: '0071',
      nmroPrioridad: 527,
      descEstacion: null,
      desgEstacion: 'CUNIT',
      cdgoUic: '71603',
      clave: '0071,71603,71603',
      desgEstacionPlano: 'CUNIT',
      activo: true
    },
    {
      cdgoEstacion: '71604',
      cdgoAdmon: '0071',
      nmroPrioridad: 528,
      descEstacion: null,
      desgEstacion: 'CUBELLES',
      cdgoUic: '71604',
      clave: '0071,71604,71604',
      desgEstacionPlano: 'CUBELLES',
      activo: true
    },
    {
      cdgoEstacion: '72400',
      cdgoAdmon: '0071',
      nmroPrioridad: 529,
      descEstacion: null,
      desgEstacion: 'EL PRAT AEROPORT',
      cdgoUic: '72400',
      clave: '0071,72400,72400',
      desgEstacionPlano: 'EL PRAT AEROPORT',
      activo: true
    },
    {
      cdgoEstacion: '71707',
      cdgoAdmon: '0071',
      nmroPrioridad: 529,
      descEstacion: null,
      desgEstacion: 'EL PRAT DE LLOBREGAT',
      cdgoUic: '71707',
      clave: '0071,71707,71707',
      desgEstacionPlano: 'EL PRAT DE LLOBREGAT',
      activo: true
    },
    {
      cdgoEstacion: '71708',
      cdgoAdmon: '0071',
      nmroPrioridad: 530,
      descEstacion: null,
      desgEstacion: 'BELLVITGE',
      cdgoUic: '71708',
      clave: '0071,71708,71708',
      desgEstacionPlano: 'BELLVITGE',
      activo: true
    },
    {
      cdgoEstacion: '71709',
      cdgoAdmon: '0071',
      nmroPrioridad: 531,
      descEstacion: null,
      desgEstacion: 'VILADECANS',
      cdgoUic: '71709',
      clave: '0071,71709,71709',
      desgEstacionPlano: 'VILADECANS',
      activo: true
    },
    {
      cdgoEstacion: '72101',
      cdgoAdmon: '0071',
      nmroPrioridad: 532,
      descEstacion: null,
      desgEstacion: 'RODA DE MAR',
      cdgoUic: '72101',
      clave: '0071,72101,72101',
      desgEstacionPlano: 'RODA DE MAR',
      activo: true
    },
    {
      cdgoEstacion: '72100',
      cdgoAdmon: '0071',
      nmroPrioridad: 534,
      descEstacion: null,
      desgEstacion: 'RODA DE BARA',
      cdgoUic: '72100',
      clave: '0071,72100,72100',
      desgEstacionPlano: 'RODA DE BARA',
      activo: true
    },
    {
      cdgoEstacion: '71303',
      cdgoAdmon: '0071',
      nmroPrioridad: 535,
      descEstacion: null,
      desgEstacion: 'MARÇA-FALSET',
      cdgoUic: '71303',
      clave: '0071,71303,71303',
      desgEstacionPlano: 'MARCA-FALSET',
      activo: true
    },
    {
      cdgoEstacion: '71304',
      cdgoAdmon: '0071',
      nmroPrioridad: 536,
      descEstacion: null,
      desgEstacion: 'PRADELL DE LA TEIXETA',
      cdgoUic: '71304',
      clave: '0071,71304,71304',
      desgEstacionPlano: 'PRADELL DE LA TEIXETA',
      activo: true
    },
    {
      cdgoEstacion: '71305',
      cdgoAdmon: '0071',
      nmroPrioridad: 537,
      descEstacion: null,
      desgEstacion: "DUESAIGUES-L'ARGENTERA",
      cdgoUic: '71305',
      clave: '0071,71305,71305',
      desgEstacionPlano: "DUESAIGUES-L'ARGENTERA",
      activo: true
    },
    {
      cdgoEstacion: '71306',
      cdgoAdmon: '0071',
      nmroPrioridad: 538,
      descEstacion: null,
      desgEstacion: 'RIUDECANYES-BOTARELL',
      cdgoUic: '71306',
      clave: '0071,71306,71306',
      desgEstacionPlano: 'RIUDECANYES-BOTARELL',
      activo: true
    },
    {
      cdgoEstacion: '71301',
      cdgoAdmon: '0071',
      nmroPrioridad: 539,
      descEstacion: null,
      desgEstacion: 'ELS GUIAMETS',
      cdgoUic: '71301',
      clave: '0071,71301,71301',
      desgEstacionPlano: 'ELS GUIAMETS',
      activo: true
    },
    {
      cdgoEstacion: '71302',
      cdgoAdmon: '0071',
      nmroPrioridad: 540,
      descEstacion: null,
      desgEstacion: 'CAPÇANES',
      cdgoUic: '71302',
      clave: '0071,71302,71302',
      desgEstacionPlano: 'CAPCANES',
      activo: true
    },
    {
      cdgoEstacion: '71211',
      cdgoAdmon: '0071',
      nmroPrioridad: 541,
      descEstacion: null,
      desgEstacion: 'ASCÓ',
      cdgoUic: '71211',
      clave: '0071,71211,71211',
      desgEstacionPlano: 'ASCO',
      activo: true
    },
    {
      cdgoEstacion: '71601',
      cdgoAdmon: '0071',
      nmroPrioridad: 542,
      descEstacion: null,
      desgEstacion: 'CALAFELL',
      cdgoUic: '71601',
      clave: '0071,71601,71601',
      desgEstacionPlano: 'CALAFELL',
      activo: true
    },
    {
      cdgoEstacion: '71307',
      cdgoAdmon: '0071',
      nmroPrioridad: 543,
      descEstacion: null,
      desgEstacion: 'LES BORGES DEL CAMP',
      cdgoUic: '71307',
      clave: '0071,71307,71307',
      desgEstacionPlano: 'LES BORGES DEL CAMP',
      activo: true
    },
    {
      cdgoEstacion: '71103',
      cdgoAdmon: '0071',
      nmroPrioridad: 544,
      descEstacion: null,
      desgEstacion: 'FUENTES DE EBRO',
      cdgoUic: '71103',
      clave: '0071,71103,71103',
      desgEstacionPlano: 'FUENTES DE EBRO',
      activo: true
    },
    {
      cdgoEstacion: '71104',
      cdgoAdmon: '0071',
      nmroPrioridad: 545,
      descEstacion: null,
      desgEstacion: 'PINA DE EBRO',
      cdgoUic: '71104',
      clave: '0071,71104,71104',
      desgEstacionPlano: 'PINA DE EBRO',
      activo: true
    },
    {
      cdgoEstacion: '71105',
      cdgoAdmon: '0071',
      nmroPrioridad: 546,
      descEstacion: null,
      desgEstacion: 'QUINTO',
      cdgoUic: '71105',
      clave: '0071,71105,71105',
      desgEstacionPlano: 'QUINTO',
      activo: true
    },
    {
      cdgoEstacion: '71108',
      cdgoAdmon: '0071',
      nmroPrioridad: 547,
      descEstacion: null,
      desgEstacion: 'LA ZAIDA-SASTAGO',
      cdgoUic: '71108',
      clave: '0071,71108,71108',
      desgEstacionPlano: 'LA ZAIDA-SASTAGO',
      activo: true
    },
    {
      cdgoEstacion: '71201',
      cdgoAdmon: '0071',
      nmroPrioridad: 548,
      descEstacion: null,
      desgEstacion: 'SAMPER',
      cdgoUic: '71201',
      clave: '0071,71201,71201',
      desgEstacionPlano: 'SAMPER',
      activo: true
    },
    {
      cdgoEstacion: '71202',
      cdgoAdmon: '0071',
      nmroPrioridad: 549,
      descEstacion: null,
      desgEstacion: 'ESCATRON',
      cdgoUic: '71202',
      clave: '0071,71202,71202',
      desgEstacionPlano: 'ESCATRON',
      activo: true
    },
    {
      cdgoEstacion: '71109',
      cdgoAdmon: '0071',
      nmroPrioridad: 550,
      descEstacion: null,
      desgEstacion: 'AZAILA',
      cdgoUic: '71109',
      clave: '0071,71109,71109',
      desgEstacionPlano: 'AZAILA',
      activo: true
    },
    {
      cdgoEstacion: '71205',
      cdgoAdmon: '0071',
      nmroPrioridad: 551,
      descEstacion: null,
      desgEstacion: 'VAL DE PILAS',
      cdgoUic: '71205',
      clave: '0071,71205,71205',
      desgEstacionPlano: 'VAL DE PILAS',
      activo: true
    },
    {
      cdgoEstacion: '71206',
      cdgoAdmon: '0071',
      nmroPrioridad: 552,
      descEstacion: null,
      desgEstacion: 'FABARA',
      cdgoUic: '71206',
      clave: '0071,71206,71206',
      desgEstacionPlano: 'FABARA',
      activo: true
    },
    {
      cdgoEstacion: '71203',
      cdgoAdmon: '0071',
      nmroPrioridad: 553,
      descEstacion: null,
      desgEstacion: 'CHIPRANA',
      cdgoUic: '71203',
      clave: '0071,71203,71203',
      desgEstacionPlano: 'CHIPRANA',
      activo: true
    },
    {
      cdgoEstacion: '71207',
      cdgoAdmon: '0071',
      nmroPrioridad: 554,
      descEstacion: null,
      desgEstacion: 'NONASPE',
      cdgoUic: '71207',
      clave: '0071,71207,71207',
      desgEstacionPlano: 'NONASPE',
      activo: true
    },
    {
      cdgoEstacion: '71209',
      cdgoAdmon: '0071',
      nmroPrioridad: 555,
      descEstacion: null,
      desgEstacion: "RIBA-ROJA D'EBRE",
      cdgoUic: '71209',
      clave: '0071,71209,71209',
      desgEstacionPlano: "RIBA-ROJA D'EBRE",
      activo: true
    },
    {
      cdgoEstacion: '71102',
      cdgoAdmon: '0071',
      nmroPrioridad: 556,
      descEstacion: null,
      desgEstacion: 'EL BURGO DE EBRO',
      cdgoUic: '71102',
      clave: '0071,71102,71102',
      desgEstacionPlano: 'EL BURGO DE EBRO',
      activo: true
    },
    {
      cdgoEstacion: '70705',
      cdgoAdmon: '0071',
      nmroPrioridad: 557,
      descEstacion: null,
      desgEstacion: 'PLASENCIA DE JALON',
      cdgoUic: '70705',
      clave: '0071,70705,70705',
      desgEstacionPlano: 'PLASENCIA DE JALON',
      activo: true
    },
    {
      cdgoEstacion: '70706',
      cdgoAdmon: '0071',
      nmroPrioridad: 558,
      descEstacion: null,
      desgEstacion: 'GRISEN',
      cdgoUic: '70706',
      clave: '0071,70706,70706',
      desgEstacionPlano: 'GRISEN',
      activo: true
    },
    {
      cdgoEstacion: '70800',
      cdgoAdmon: '0071',
      nmroPrioridad: 559,
      descEstacion: null,
      desgEstacion: 'CASETAS',
      cdgoUic: '70800',
      clave: '0071,70800,70800',
      desgEstacionPlano: 'CASETAS',
      activo: true
    },
    {
      cdgoEstacion: '70701',
      cdgoAdmon: '0071',
      nmroPrioridad: 560,
      descEstacion: null,
      desgEstacion: 'CALATORAO',
      cdgoUic: '70701',
      clave: '0071,70701,70701',
      desgEstacionPlano: 'CALATORAO',
      activo: true
    },
    {
      cdgoEstacion: '70702',
      cdgoAdmon: '0071',
      nmroPrioridad: 561,
      descEstacion: null,
      desgEstacion: 'SALILLAS DE JALON',
      cdgoUic: '70702',
      clave: '0071,70702,70702',
      desgEstacionPlano: 'SALILLAS DE JALON',
      activo: true
    },
    {
      cdgoEstacion: '70703',
      cdgoAdmon: '0071',
      nmroPrioridad: 562,
      descEstacion: null,
      desgEstacion: 'EPILA',
      cdgoUic: '70703',
      clave: '0071,70703,70703',
      desgEstacionPlano: 'EPILA',
      activo: true
    },
    {
      cdgoEstacion: '70704',
      cdgoAdmon: '0071',
      nmroPrioridad: 563,
      descEstacion: null,
      desgEstacion: 'RUEDA DE JALON-LUMPIAQUE',
      cdgoUic: '70704',
      clave: '0071,70704,70704',
      desgEstacionPlano: 'RUEDA DE JALON-LUMPIAQUE',
      activo: true
    },
    {
      cdgoEstacion: '70607',
      cdgoAdmon: '0071',
      nmroPrioridad: 564,
      descEstacion: null,
      desgEstacion: 'MORATA DE JALON',
      cdgoUic: '70607',
      clave: '0071,70607,70607',
      desgEstacionPlano: 'MORATA DE JALON',
      activo: true
    },
    {
      cdgoEstacion: '70700',
      cdgoAdmon: '0071',
      nmroPrioridad: 565,
      descEstacion: null,
      desgEstacion: 'RICLA-LA ALMUNIA',
      cdgoUic: '70700',
      clave: '0071,70700,70700',
      desgEstacionPlano: 'RICLA-LA ALMUNIA',
      activo: true
    },
    {
      cdgoEstacion: '70606',
      cdgoAdmon: '0071',
      nmroPrioridad: 566,
      descEstacion: null,
      desgEstacion: 'PURROY',
      cdgoUic: '70606',
      clave: '0071,70606,70606',
      desgEstacionPlano: 'PURROY',
      activo: true
    },
    {
      cdgoEstacion: '70502',
      cdgoAdmon: '0071',
      nmroPrioridad: 567,
      descEstacion: null,
      desgEstacion: 'ALHAMA DE ARAGON',
      cdgoUic: '70502',
      clave: '0071,70502,70502',
      desgEstacionPlano: 'ALHAMA DE ARAGON',
      activo: true
    },
    {
      cdgoEstacion: '70503',
      cdgoAdmon: '0071',
      nmroPrioridad: 568,
      descEstacion: null,
      desgEstacion: 'BUBIERCA',
      cdgoUic: '70503',
      clave: '0071,70503,70503',
      desgEstacionPlano: 'BUBIERCA',
      activo: true
    },
    {
      cdgoEstacion: '70504',
      cdgoAdmon: '0071',
      nmroPrioridad: 569,
      descEstacion: null,
      desgEstacion: 'ATECA',
      cdgoUic: '70504',
      clave: '0071,70504,70504',
      desgEstacionPlano: 'ATECA',
      activo: true
    },
    {
      cdgoEstacion: '70505',
      cdgoAdmon: '0071',
      nmroPrioridad: 570,
      descEstacion: null,
      desgEstacion: 'TERRER',
      cdgoUic: '70505',
      clave: '0071,70505,70505',
      desgEstacionPlano: 'TERRER',
      activo: true
    },
    {
      cdgoEstacion: '70603',
      cdgoAdmon: '0071',
      nmroPrioridad: 571,
      descEstacion: null,
      desgEstacion: 'PARACUELLOS-SABIÑAN',
      cdgoUic: '70603',
      clave: '0071,70603,70603',
      desgEstacionPlano: 'PARACUELLOS-SABINAN',
      activo: true
    },
    {
      cdgoEstacion: '70604',
      cdgoAdmon: '0071',
      nmroPrioridad: 572,
      descEstacion: null,
      desgEstacion: 'SABIÑAN',
      cdgoUic: '70604',
      clave: '0071,70604,70604',
      desgEstacionPlano: 'SABINAN',
      activo: true
    },
    {
      cdgoEstacion: '70602',
      cdgoAdmon: '0071',
      nmroPrioridad: 573,
      descEstacion: null,
      desgEstacion: 'EMBID DE JALON',
      cdgoUic: '70602',
      clave: '0071,70602,70602',
      desgEstacionPlano: 'EMBID DE JALON',
      activo: true
    },
    {
      cdgoEstacion: '70400',
      cdgoAdmon: '0071',
      nmroPrioridad: 574,
      descEstacion: null,
      desgEstacion: 'TORRALBA',
      cdgoUic: '70400',
      clave: '0071,70400,70400',
      desgEstacionPlano: 'TORRALBA',
      activo: true
    },
    {
      cdgoEstacion: '70401',
      cdgoAdmon: '0071',
      nmroPrioridad: 575,
      descEstacion: null,
      desgEstacion: 'MEDINACELI',
      cdgoUic: '70401',
      clave: '0071,70401,70401',
      desgEstacionPlano: 'MEDINACELI',
      activo: true
    },
    {
      cdgoEstacion: '70404',
      cdgoAdmon: '0071',
      nmroPrioridad: 576,
      descEstacion: null,
      desgEstacion: 'SANTA MARIA DE HUERTA',
      cdgoUic: '70404',
      clave: '0071,70404,70404',
      desgEstacionPlano: 'SANTA MARIA DE HUERTA',
      activo: true
    },
    {
      cdgoEstacion: '70405',
      cdgoAdmon: '0071',
      nmroPrioridad: 577,
      descEstacion: null,
      desgEstacion: 'MONREAL DE ARIZA',
      cdgoUic: '70405',
      clave: '0071,70405,70405',
      desgEstacionPlano: 'MONREAL DE ARIZA',
      activo: true
    },
    {
      cdgoEstacion: '70501',
      cdgoAdmon: '0071',
      nmroPrioridad: 578,
      descEstacion: null,
      desgEstacion: 'CETINA',
      cdgoUic: '70501',
      clave: '0071,70501,70501',
      desgEstacionPlano: 'CETINA',
      activo: true
    },
    {
      cdgoEstacion: '70108',
      cdgoAdmon: '0071',
      nmroPrioridad: 579,
      descEstacion: null,
      desgEstacion: 'COSLADA (APD)',
      cdgoUic: '70108',
      clave: '0071,70108,70108',
      desgEstacionPlano: 'COSLADA (APD)',
      activo: true
    },
    {
      cdgoEstacion: '70109',
      cdgoAdmon: '0071',
      nmroPrioridad: 580,
      descEstacion: null,
      desgEstacion: 'SANTA EUGENIA',
      cdgoUic: '70109',
      clave: '0071,70109,70109',
      desgEstacionPlano: 'SANTA EUGENIA',
      activo: true
    },
    {
      cdgoEstacion: '70111',
      cdgoAdmon: '0071',
      nmroPrioridad: 581,
      descEstacion: null,
      desgEstacion: 'LA GARENA',
      cdgoUic: '70111',
      clave: '0071,70111,70111',
      desgEstacionPlano: 'LA GARENA',
      activo: true
    },
    {
      cdgoEstacion: '70105',
      cdgoAdmon: '0071',
      nmroPrioridad: 583,
      descEstacion: null,
      desgEstacion: 'AZUQUECA',
      cdgoUic: '70105',
      clave: '0071,70105,70105',
      desgEstacionPlano: 'AZUQUECA',
      activo: true
    },
    {
      cdgoEstacion: '70107',
      cdgoAdmon: '0071',
      nmroPrioridad: 584,
      descEstacion: null,
      desgEstacion: 'ALCALA HENARES-UNIVERSIDAD (APD)',
      cdgoUic: '70107',
      clave: '0071,70107,70107',
      desgEstacionPlano: 'ALCALA HENARES-UNIVERSIDAD (APD)',
      activo: true
    },
    {
      cdgoEstacion: '70104',
      cdgoAdmon: '0071',
      nmroPrioridad: 585,
      descEstacion: null,
      desgEstacion: 'MECO (APD-CGD)',
      cdgoUic: '70104',
      clave: '0071,70104,70104',
      desgEstacionPlano: 'MECO (APD-CGD)',
      activo: true
    },
    {
      cdgoEstacion: '70204',
      cdgoAdmon: '0071',
      nmroPrioridad: 586,
      descEstacion: null,
      desgEstacion: 'HUMANES DE MOHERNANDO',
      cdgoUic: '70204',
      clave: '0071,70204,70204',
      desgEstacionPlano: 'HUMANES DE MOHERNANDO',
      activo: true
    },
    {
      cdgoEstacion: '70207',
      cdgoAdmon: '0071',
      nmroPrioridad: 587,
      descEstacion: null,
      desgEstacion: 'ESPINOSA DE HENARES',
      cdgoUic: '70207',
      clave: '0071,70207,70207',
      desgEstacionPlano: 'ESPINOSA DE HENARES',
      activo: true
    },
    {
      cdgoEstacion: '70202',
      cdgoAdmon: '0071',
      nmroPrioridad: 588,
      descEstacion: null,
      desgEstacion: 'YUNQUERA DE HENARES',
      cdgoUic: '70202',
      clave: '0071,70202,70202',
      desgEstacionPlano: 'YUNQUERA DE HENARES',
      activo: true
    },
    {
      cdgoEstacion: '70210',
      cdgoAdmon: '0071',
      nmroPrioridad: 589,
      descEstacion: null,
      desgEstacion: 'MATILLAS',
      cdgoUic: '70210',
      clave: '0071,70210,70210',
      desgEstacionPlano: 'MATILLAS',
      activo: true
    },
    {
      cdgoEstacion: '70300',
      cdgoAdmon: '0071',
      nmroPrioridad: 590,
      descEstacion: null,
      desgEstacion: 'BAIDES',
      cdgoUic: '70300',
      clave: '0071,70300,70300',
      desgEstacionPlano: 'BAIDES',
      activo: true
    },
    {
      cdgoEstacion: '70208',
      cdgoAdmon: '0071',
      nmroPrioridad: 591,
      descEstacion: null,
      desgEstacion: 'CARRASCOSA DE HENARES',
      cdgoUic: '70208',
      clave: '0071,70208,70208',
      desgEstacionPlano: 'CARRASCOSA DE HENARES',
      activo: true
    },
    {
      cdgoEstacion: '66013',
      cdgoAdmon: '0071',
      nmroPrioridad: 592,
      descEstacion: null,
      desgEstacion: 'CASTILLEJO DEL ROMERAL',
      cdgoUic: '66013',
      clave: '0071,66013,66013',
      desgEstacionPlano: 'CASTILLEJO DEL ROMERAL',
      activo: true
    },
    {
      cdgoEstacion: '66014',
      cdgoAdmon: '0071',
      nmroPrioridad: 593,
      descEstacion: null,
      desgEstacion: 'CUEVAS DE VELASCO',
      cdgoUic: '66014',
      clave: '0071,66014,66014',
      desgEstacionPlano: 'CUEVAS DE VELASCO',
      activo: true
    },
    {
      cdgoEstacion: '66015',
      cdgoAdmon: '0071',
      nmroPrioridad: 594,
      descEstacion: null,
      desgEstacion: 'VILLAR DEL SAZ DE NAVALON',
      cdgoUic: '66015',
      clave: '0071,66015,66015',
      desgEstacionPlano: 'VILLAR DEL SAZ DE NAVALON',
      activo: true
    },
    {
      cdgoEstacion: '66016',
      cdgoAdmon: '0071',
      nmroPrioridad: 595,
      descEstacion: null,
      desgEstacion: 'CHILLARON',
      cdgoUic: '66016',
      clave: '0071,66016,66016',
      desgEstacionPlano: 'CHILLARON',
      activo: true
    },
    {
      cdgoEstacion: '66009',
      cdgoAdmon: '0071',
      nmroPrioridad: 596,
      descEstacion: null,
      desgEstacion: 'PAREDES DE MELO',
      cdgoUic: '66009',
      clave: '0071,66009,66009',
      desgEstacionPlano: 'PAREDES DE MELO',
      activo: true
    },
    {
      cdgoEstacion: '66010',
      cdgoAdmon: '0071',
      nmroPrioridad: 597,
      descEstacion: null,
      desgEstacion: 'VELLISCA',
      cdgoUic: '66010',
      clave: '0071,66010,66010',
      desgEstacionPlano: 'VELLISCA',
      activo: true
    },
    {
      cdgoEstacion: '66012',
      cdgoAdmon: '0071',
      nmroPrioridad: 598,
      descEstacion: null,
      desgEstacion: 'CARACENILLA',
      cdgoUic: '66012',
      clave: '0071,66012,66012',
      desgEstacionPlano: 'CARACENILLA',
      activo: true
    },
    {
      cdgoEstacion: '66004',
      cdgoAdmon: '0071',
      nmroPrioridad: 599,
      descEstacion: null,
      desgEstacion: 'VILLARRUBIA DE SANTIAGO',
      cdgoUic: '66004',
      clave: '0071,66004,66004',
      desgEstacionPlano: 'VILLARRUBIA DE SANTIAGO',
      activo: true
    },
    {
      cdgoEstacion: '66005',
      cdgoAdmon: '0071',
      nmroPrioridad: 600,
      descEstacion: null,
      desgEstacion: 'SANTA CRUZ DE LA ZARZA',
      cdgoUic: '66005',
      clave: '0071,66005,66005',
      desgEstacionPlano: 'SANTA CRUZ DE LA ZARZA',
      activo: true
    },
    {
      cdgoEstacion: '66008',
      cdgoAdmon: '0071',
      nmroPrioridad: 601,
      descEstacion: null,
      desgEstacion: 'HUELVES',
      cdgoUic: '66008',
      clave: '0071,66008,66008',
      desgEstacionPlano: 'HUELVES',
      activo: true
    },
    {
      cdgoEstacion: '66001',
      cdgoAdmon: '0071',
      nmroPrioridad: 603,
      descEstacion: null,
      desgEstacion: 'ONTIGOLA',
      cdgoUic: '66001',
      clave: '0071,66001,66001',
      desgEstacionPlano: 'ONTIGOLA',
      activo: true
    },
    {
      cdgoEstacion: '66003',
      cdgoAdmon: '0071',
      nmroPrioridad: 604,
      descEstacion: null,
      desgEstacion: 'NOBLEJAS',
      cdgoUic: '66003',
      clave: '0071,66003,66003',
      desgEstacionPlano: 'NOBLEJAS',
      activo: true
    },
    {
      cdgoEstacion: '66108',
      cdgoAdmon: '0071',
      nmroPrioridad: 605,
      descEstacion: null,
      desgEstacion: 'YEMEDA-CARDENETE',
      cdgoUic: '66108',
      clave: '0071,66108,66108',
      desgEstacionPlano: 'YEMEDA-CARDENETE',
      activo: true
    },
    {
      cdgoEstacion: '66109',
      cdgoAdmon: '0071',
      nmroPrioridad: 606,
      descEstacion: null,
      desgEstacion: 'VILLORA',
      cdgoUic: '66109',
      clave: '0071,66109,66109',
      desgEstacionPlano: 'VILLORA',
      activo: true
    },
    {
      cdgoEstacion: '66113',
      cdgoAdmon: '0071',
      nmroPrioridad: 607,
      descEstacion: null,
      desgEstacion: 'LAS CUEVAS',
      cdgoUic: '66113',
      clave: '0071,66113,66113',
      desgEstacionPlano: 'LAS CUEVAS',
      activo: true
    },
    {
      cdgoEstacion: '66103',
      cdgoAdmon: '0071',
      nmroPrioridad: 608,
      descEstacion: null,
      desgEstacion: 'CAÑADA DEL HOYO',
      cdgoUic: '66103',
      clave: '0071,66103,66103',
      desgEstacionPlano: 'CANADA DEL HOYO',
      activo: true
    },
    {
      cdgoEstacion: '66105',
      cdgoAdmon: '0071',
      nmroPrioridad: 609,
      descEstacion: null,
      desgEstacion: 'ARGUISUELAS',
      cdgoUic: '66105',
      clave: '0071,66105,66105',
      desgEstacionPlano: 'ARGUISUELAS',
      activo: true
    },
    {
      cdgoEstacion: '66204',
      cdgoAdmon: '0071',
      nmroPrioridad: 610,
      descEstacion: null,
      desgEstacion: 'SIETE AGUAS',
      cdgoUic: '66204',
      clave: '0071,66204,66204',
      desgEstacionPlano: 'SIETE AGUAS',
      activo: true
    },
    {
      cdgoEstacion: '66205',
      cdgoAdmon: '0071',
      nmroPrioridad: 611,
      descEstacion: null,
      desgEstacion: 'VENTA MINA-SIETE AGUAS',
      cdgoUic: '66205',
      clave: '0071,66205,66205',
      desgEstacionPlano: 'VENTA MINA-SIETE AGUAS',
      activo: true
    },
    {
      cdgoEstacion: '66206',
      cdgoAdmon: '0071',
      nmroPrioridad: 612,
      descEstacion: null,
      desgEstacion: 'BUÑOL',
      cdgoUic: '66206',
      clave: '0071,66206,66206',
      desgEstacionPlano: 'BUNOL',
      activo: true
    },
    {
      cdgoEstacion: '66207',
      cdgoAdmon: '0071',
      nmroPrioridad: 613,
      descEstacion: null,
      desgEstacion: 'CHIVA',
      cdgoUic: '66207',
      clave: '0071,66207,66207',
      desgEstacionPlano: 'CHIVA',
      activo: true
    },
    {
      cdgoEstacion: '66200',
      cdgoAdmon: '0071',
      nmroPrioridad: 614,
      descEstacion: null,
      desgEstacion: 'UTIEL',
      cdgoUic: '66200',
      clave: '0071,66200,66200',
      desgEstacionPlano: 'UTIEL',
      activo: true
    },
    {
      cdgoEstacion: '66201',
      cdgoAdmon: '0071',
      nmroPrioridad: 615,
      descEstacion: null,
      desgEstacion: 'SAN ANTONIO DE REQUENA',
      cdgoUic: '66201',
      clave: '0071,66201,66201',
      desgEstacionPlano: 'SAN ANTONIO DE REQUENA',
      activo: true
    },
    {
      cdgoEstacion: '66203',
      cdgoAdmon: '0071',
      nmroPrioridad: 616,
      descEstacion: null,
      desgEstacion: 'EL REBOLLAR',
      cdgoUic: '66203',
      clave: '0071,66203,66203',
      desgEstacionPlano: 'EL REBOLLAR',
      activo: true
    },
    {
      cdgoEstacion: '65401',
      cdgoAdmon: '0071',
      nmroPrioridad: 617,
      descEstacion: null,
      desgEstacion: 'CAMP-REDO',
      cdgoUic: '65401',
      clave: '0071,65401,65401',
      desgEstacionPlano: 'CAMP-REDO',
      activo: true
    },
    {
      cdgoEstacion: '65403',
      cdgoAdmon: '0071',
      nmroPrioridad: 619,
      descEstacion: null,
      desgEstacion: 'CAMARLES-DELTEBRE',
      cdgoUic: '65403',
      clave: '0071,65403,65403',
      desgEstacionPlano: 'CAMARLES-DELTEBRE',
      activo: true
    },
    {
      cdgoEstacion: '65209',
      cdgoAdmon: '0071',
      nmroPrioridad: 621,
      descEstacion: null,
      desgEstacion: 'ALMASSORA/ALMAZORA',
      cdgoUic: '65209',
      clave: '0071,65209,65209',
      desgEstacionPlano: 'ALMASSORA/ALMAZORA',
      activo: true
    },
    {
      cdgoEstacion: '65207',
      cdgoAdmon: '0071',
      nmroPrioridad: 622,
      descEstacion: null,
      desgEstacion: 'BURRIANA-ALQUERIAS NIÑO PERDIDO',
      cdgoUic: '65207',
      clave: '0071,65207,65207',
      desgEstacionPlano: 'BURRIANA-ALQUERIAS NINO PERDIDO',
      activo: true
    },
    {
      cdgoEstacion: '65202',
      cdgoAdmon: '0071',
      nmroPrioridad: 623,
      descEstacion: null,
      desgEstacion: 'ALMENARA',
      cdgoUic: '65202',
      clave: '0071,65202,65202',
      desgEstacionPlano: 'ALMENARA',
      activo: true
    },
    {
      cdgoEstacion: '65204',
      cdgoAdmon: '0071',
      nmroPrioridad: 625,
      descEstacion: null,
      desgEstacion: 'CHILCHES',
      cdgoUic: '65204',
      clave: '0071,65204,65204',
      desgEstacionPlano: 'CHILCHES',
      activo: true
    },
    {
      cdgoEstacion: '65008',
      cdgoAdmon: '0071',
      nmroPrioridad: 628,
      descEstacion: null,
      desgEstacion: 'PUÇOL',
      cdgoUic: '65008',
      clave: '0071,65008,65008',
      desgEstacionPlano: 'PUCOL',
      activo: true
    },
    {
      cdgoEstacion: '70005',
      cdgoAdmon: '0071',
      nmroPrioridad: 629,
      descEstacion: null,
      desgEstacion: 'VALLECAS',
      cdgoUic: '70005',
      clave: '0071,70005,70005',
      desgEstacionPlano: 'VALLECAS',
      activo: true
    },
    {
      cdgoEstacion: '70100',
      cdgoAdmon: '0071',
      nmroPrioridad: 630,
      descEstacion: null,
      desgEstacion: 'VICALVARO',
      cdgoUic: '70100',
      clave: '0071,70100,70100',
      desgEstacionPlano: 'VICALVARO',
      activo: true
    },
    {
      cdgoEstacion: '70101',
      cdgoAdmon: '0071',
      nmroPrioridad: 631,
      descEstacion: null,
      desgEstacion: 'SAN FERNANDO HENARES',
      cdgoUic: '70101',
      clave: '0071,70101,70101',
      desgEstacionPlano: 'SAN FERNANDO HENARES',
      activo: true
    },
    {
      cdgoEstacion: '70102',
      cdgoAdmon: '0071',
      nmroPrioridad: 632,
      descEstacion: null,
      desgEstacion: 'TORREJON DE ARDOZ',
      cdgoUic: '70102',
      clave: '0071,70102,70102',
      desgEstacionPlano: 'TORREJON DE ARDOZ',
      activo: true
    },
    {
      cdgoEstacion: '70002',
      cdgoAdmon: '0071',
      nmroPrioridad: 633,
      descEstacion: null,
      desgEstacion: 'ASAMBLEA DE MAD. ENTREVIAS',
      cdgoUic: '70002',
      clave: '0071,70002,70002',
      desgEstacionPlano: 'ASAMBLEA DE MAD. ENTREVIAS',
      activo: true
    },
    {
      cdgoEstacion: '70003',
      cdgoAdmon: '0071',
      nmroPrioridad: 634,
      descEstacion: null,
      desgEstacion: 'EL POZO',
      cdgoUic: '70003',
      clave: '0071,70003,70003',
      desgEstacionPlano: 'EL POZO',
      activo: true
    },
    {
      cdgoEstacion: '69105',
      cdgoAdmon: '0071',
      nmroPrioridad: 635,
      descEstacion: null,
      desgEstacion: 'TAVERNES DE LA VALLDIGNA',
      cdgoUic: '69105',
      clave: '0071,69105,69105',
      desgEstacionPlano: 'TAVERNES DE LA VALLDIGNA',
      activo: true
    },
    {
      cdgoEstacion: '69010',
      cdgoAdmon: '0071',
      nmroPrioridad: 636,
      descEstacion: null,
      desgEstacion: 'COCENTAINA',
      cdgoUic: '69010',
      clave: '0071,69010,69010',
      desgEstacionPlano: 'COCENTAINA',
      activo: true
    },
    {
      cdgoEstacion: '69103',
      cdgoAdmon: '0071',
      nmroPrioridad: 637,
      descEstacion: null,
      desgEstacion: 'SUECA',
      cdgoUic: '69103',
      clave: '0071,69103,69103',
      desgEstacionPlano: 'SUECA',
      activo: true
    },
    {
      cdgoEstacion: '69009',
      cdgoAdmon: '0071',
      nmroPrioridad: 638,
      descEstacion: null,
      desgEstacion: 'AGRES',
      cdgoUic: '69009',
      clave: '0071,69009,69009',
      desgEstacionPlano: 'AGRES',
      activo: true
    },
    {
      cdgoEstacion: '69007',
      cdgoAdmon: '0071',
      nmroPrioridad: 639,
      descEstacion: null,
      desgEstacion: 'AGULLENT',
      cdgoUic: '69007',
      clave: '0071,69007,69007',
      desgEstacionPlano: 'AGULLENT',
      activo: true
    },
    {
      cdgoEstacion: '69001',
      cdgoAdmon: '0071',
      nmroPrioridad: 643,
      descEstacion: null,
      desgEstacion: 'GENOVES',
      cdgoUic: '69001',
      clave: '0071,69001,69001',
      desgEstacionPlano: 'GENOVES',
      activo: true
    },
    {
      cdgoEstacion: '69004',
      cdgoAdmon: '0071',
      nmroPrioridad: 644,
      descEstacion: null,
      desgEstacion: 'MONTAVERNER',
      cdgoUic: '69004',
      clave: '0071,69004,69004',
      desgEstacionPlano: 'MONTAVERNER',
      activo: true
    },
    {
      cdgoEstacion: '69005',
      cdgoAdmon: '0071',
      nmroPrioridad: 645,
      descEstacion: null,
      desgEstacion: 'BUFALI',
      cdgoUic: '69005',
      clave: '0071,69005,69005',
      desgEstacionPlano: 'BUFALI',
      activo: true
    },
    {
      cdgoEstacion: '69003',
      cdgoAdmon: '0071',
      nmroPrioridad: 646,
      descEstacion: null,
      desgEstacion: 'LA POBLA DEL DUC',
      cdgoUic: '69003',
      clave: '0071,69003,69003',
      desgEstacionPlano: 'LA POBLA DEL DUC',
      activo: true
    },
    {
      cdgoEstacion: '67208',
      cdgoAdmon: '0071',
      nmroPrioridad: 647,
      descEstacion: null,
      desgEstacion: 'BARRACAS',
      cdgoUic: '67208',
      clave: '0071,67208,67208',
      desgEstacionPlano: 'BARRACAS',
      activo: true
    },
    {
      cdgoEstacion: '67014',
      cdgoAdmon: '0071',
      nmroPrioridad: 654,
      descEstacion: null,
      desgEstacion: 'VILLADOZ',
      cdgoUic: '67014',
      clave: '0071,67014,67014',
      desgEstacionPlano: 'VILLADOZ',
      activo: true
    },
    {
      cdgoEstacion: '67015',
      cdgoAdmon: '0071',
      nmroPrioridad: 655,
      descEstacion: null,
      desgEstacion: 'BADULES',
      cdgoUic: '67015',
      clave: '0071,67015,67015',
      desgEstacionPlano: 'BADULES',
      activo: true
    },
    {
      cdgoEstacion: '67016',
      cdgoAdmon: '0071',
      nmroPrioridad: 656,
      descEstacion: null,
      desgEstacion: 'VILLAHERMOSA',
      cdgoUic: '67016',
      clave: '0071,67016,67016',
      desgEstacionPlano: 'VILLAHERMOSA',
      activo: true
    },
    {
      cdgoEstacion: '67017',
      cdgoAdmon: '0071',
      nmroPrioridad: 657,
      descEstacion: null,
      desgEstacion: 'FERRERUELA',
      cdgoUic: '67017',
      clave: '0071,67017,67017',
      desgEstacionPlano: 'FERRERUELA',
      activo: true
    },
    {
      cdgoEstacion: '67011',
      cdgoAdmon: '0071',
      nmroPrioridad: 658,
      descEstacion: null,
      desgEstacion: 'ENCINACORBA',
      cdgoUic: '67011',
      clave: '0071,67011,67011',
      desgEstacionPlano: 'ENCINACORBA',
      activo: true
    },
    {
      cdgoEstacion: '67013',
      cdgoAdmon: '0071',
      nmroPrioridad: 659,
      descEstacion: null,
      desgEstacion: 'VILLARREAL DE HUERVA',
      cdgoUic: '67013',
      clave: '0071,67013,67013',
      desgEstacionPlano: 'VILLARREAL DE HUERVA',
      activo: true
    },
    {
      cdgoEstacion: '67009',
      cdgoAdmon: '0071',
      nmroPrioridad: 660,
      descEstacion: null,
      desgEstacion: 'LONGARES',
      cdgoUic: '67009',
      clave: '0071,67009,67009',
      desgEstacionPlano: 'LONGARES',
      activo: true
    },
    {
      cdgoEstacion: '67004',
      cdgoAdmon: '0071',
      nmroPrioridad: 661,
      descEstacion: null,
      desgEstacion: 'MARIA DE HUERVA',
      cdgoUic: '67004',
      clave: '0071,67004,67004',
      desgEstacionPlano: 'MARIA DE HUERVA',
      activo: true
    },
    {
      cdgoEstacion: '67007',
      cdgoAdmon: '0071',
      nmroPrioridad: 662,
      descEstacion: null,
      desgEstacion: 'ARAÑALES DE MUEL',
      cdgoUic: '67007',
      clave: '0071,67007,67007',
      desgEstacionPlano: 'ARANALES DE MUEL',
      activo: true
    },
    {
      cdgoEstacion: '66214',
      cdgoAdmon: '0071',
      nmroPrioridad: 663,
      descEstacion: null,
      desgEstacion: 'XIRIVELLA-ALQUERIES',
      cdgoUic: '66214',
      clave: '0071,66214,66214',
      desgEstacionPlano: 'XIRIVELLA-ALQUERIES',
      activo: true
    },
    {
      cdgoEstacion: '66210',
      cdgoAdmon: '0071',
      nmroPrioridad: 664,
      descEstacion: null,
      desgEstacion: 'CIRCUIT RICARDO TORMO',
      cdgoUic: '66210',
      clave: '0071,66210,66210',
      desgEstacionPlano: 'CIRCUIT RICARDO TORMO',
      activo: true
    },
    {
      cdgoEstacion: '66211',
      cdgoAdmon: '0071',
      nmroPrioridad: 665,
      descEstacion: null,
      desgEstacion: 'ALDAIA',
      cdgoUic: '66211',
      clave: '0071,66211,66211',
      desgEstacionPlano: 'ALDAIA',
      activo: true
    },
    {
      cdgoEstacion: '66209',
      cdgoAdmon: '0071',
      nmroPrioridad: 666,
      descEstacion: null,
      desgEstacion: 'LORIGUILLA-REVA',
      cdgoUic: '66209',
      clave: '0071,66209,66209',
      desgEstacionPlano: 'LORIGUILLA-REVA',
      activo: true
    },
    {
      cdgoEstacion: '67100',
      cdgoAdmon: '0071',
      nmroPrioridad: 667,
      descEstacion: null,
      desgEstacion: 'CAMINREAL-FUENTES CLARAS',
      cdgoUic: '67100',
      clave: '0071,67100,67100',
      desgEstacionPlano: 'CAMINREAL-FUENTES CLARAS',
      activo: true
    },
    {
      cdgoEstacion: '67101',
      cdgoAdmon: '0071',
      nmroPrioridad: 668,
      descEstacion: null,
      desgEstacion: 'TORRIJO DEL CAMPO',
      cdgoUic: '67101',
      clave: '0071,67101,67101',
      desgEstacionPlano: 'TORRIJO DEL CAMPO',
      activo: true
    },
    {
      cdgoEstacion: '67103',
      cdgoAdmon: '0071',
      nmroPrioridad: 669,
      descEstacion: null,
      desgEstacion: 'VILLAFRANCA DEL CAMPO',
      cdgoUic: '67103',
      clave: '0071,67103,67103',
      desgEstacionPlano: 'VILLAFRANCA DEL CAMPO',
      activo: true
    },
    {
      cdgoEstacion: '67018',
      cdgoAdmon: '0071',
      nmroPrioridad: 670,
      descEstacion: null,
      desgEstacion: 'CUENCABUENA',
      cdgoUic: '67018',
      clave: '0071,67018,67018',
      desgEstacionPlano: 'CUENCABUENA',
      activo: true
    },
    {
      cdgoEstacion: '67019',
      cdgoAdmon: '0071',
      nmroPrioridad: 671,
      descEstacion: null,
      desgEstacion: 'LECHAGO',
      cdgoUic: '67019',
      clave: '0071,67019,67019',
      desgEstacionPlano: 'LECHAGO',
      activo: true
    },
    {
      cdgoEstacion: '67020',
      cdgoAdmon: '0071',
      nmroPrioridad: 672,
      descEstacion: null,
      desgEstacion: 'NAVARRETE',
      cdgoUic: '67020',
      clave: '0071,67020,67020',
      desgEstacionPlano: 'NAVARRETE',
      activo: true
    },
    {
      cdgoEstacion: '67202',
      cdgoAdmon: '0071',
      nmroPrioridad: 673,
      descEstacion: null,
      desgEstacion: 'PUERTO ESCANDON',
      cdgoUic: '67202',
      clave: '0071,67202,67202',
      desgEstacionPlano: 'PUERTO ESCANDON',
      activo: true
    },
    {
      cdgoEstacion: '67203',
      cdgoAdmon: '0071',
      nmroPrioridad: 674,
      descEstacion: null,
      desgEstacion: 'PUEBLA DE VALVERDE',
      cdgoUic: '67203',
      clave: '0071,67203,67203',
      desgEstacionPlano: 'PUEBLA DE VALVERDE',
      activo: true
    },
    {
      cdgoEstacion: '67205',
      cdgoAdmon: '0071',
      nmroPrioridad: 675,
      descEstacion: null,
      desgEstacion: 'SARRION',
      cdgoUic: '67205',
      clave: '0071,67205,67205',
      desgEstacionPlano: 'SARRION',
      activo: true
    },
    {
      cdgoEstacion: '67207',
      cdgoAdmon: '0071',
      nmroPrioridad: 676,
      descEstacion: null,
      desgEstacion: 'RUBIELOS DE MORA',
      cdgoUic: '67207',
      clave: '0071,67207,67207',
      desgEstacionPlano: 'RUBIELOS DE MORA',
      activo: true
    },
    {
      cdgoEstacion: '94757',
      cdgoAdmon: '0094',
      nmroPrioridad: 677,
      descEstacion: null,
      desgEstacion: 'MARVAO',
      cdgoUic: '00757',
      clave: '0094,94757,00757',
      desgEstacionPlano: 'MARVAO',
      activo: true
    },
    {
      cdgoEstacion: '95100',
      cdgoAdmon: '0071',
      nmroPrioridad: 678,
      descEstacion: null,
      desgEstacion: 'MUSEO-FERROCAR.',
      cdgoUic: '95100',
      clave: '0071,95100,95100',
      desgEstacionPlano: 'MUSEO-FERROCAR.',
      activo: true
    },
    {
      cdgoEstacion: '97100',
      cdgoAdmon: '0071',
      nmroPrioridad: 679,
      descEstacion: null,
      desgEstacion: 'PITIS',
      cdgoUic: '97100',
      clave: '0071,97100,97100',
      desgEstacionPlano: 'PITIS',
      activo: true
    },
    {
      cdgoEstacion: '97103',
      cdgoAdmon: '0071',
      nmroPrioridad: 680,
      descEstacion: null,
      desgEstacion: 'EL TEJAR',
      cdgoUic: '97103',
      clave: '0071,97103,97103',
      desgEstacionPlano: 'EL TEJAR',
      activo: true
    },
    {
      cdgoEstacion: '97201',
      cdgoAdmon: '0071',
      nmroPrioridad: 681,
      descEstacion: null,
      desgEstacion: 'MADRID-RAMON Y CAJAL',
      cdgoUic: '97201',
      clave: '0071,97201,97201',
      desgEstacionPlano: 'MADRID-RAMON Y CAJAL',
      activo: true
    },
    {
      cdgoEstacion: '99001',
      cdgoAdmon: '0071',
      nmroPrioridad: 686,
      descEstacion: null,
      desgEstacion: 'SANTA POLA-BUS',
      cdgoUic: null,
      clave: '0071,99001,null',
      desgEstacionPlano: 'SANTA POLA-BUS',
      activo: true
    },
    {
      cdgoEstacion: '99002',
      cdgoAdmon: '0071',
      nmroPrioridad: 687,
      descEstacion: null,
      desgEstacion: 'LOS ARENALES-BUS',
      cdgoUic: null,
      clave: '0071,99002,null',
      desgEstacionPlano: 'LOS ARENALES-BUS',
      activo: true
    },
    {
      cdgoEstacion: '94456',
      cdgoAdmon: '0094',
      nmroPrioridad: 689,
      descEstacion: null,
      desgEstacion: 'PAMPILHOSA',
      cdgoUic: '00456',
      clave: '0094,94456,00456',
      desgEstacionPlano: 'PAMPILHOSA',
      activo: true
    },
    {
      cdgoEstacion: '94432',
      cdgoAdmon: '0094',
      nmroPrioridad: 690,
      descEstacion: null,
      desgEstacion: 'FATIMA',
      cdgoUic: '00432',
      clave: '0094,94432,00432',
      desgEstacionPlano: 'FATIMA',
      activo: true
    },
    {
      cdgoEstacion: '94546',
      cdgoAdmon: '0094',
      nmroPrioridad: 691,
      descEstacion: null,
      desgEstacion: 'VILA FRANCA DAS NAVES',
      cdgoUic: '00546',
      clave: '0094,94546,00546',
      desgEstacionPlano: 'VILA FRANCA DAS NAVES',
      activo: true
    },
    {
      cdgoEstacion: '94707',
      cdgoAdmon: '0094',
      nmroPrioridad: 692,
      descEstacion: null,
      desgEstacion: 'ABRANTES',
      cdgoUic: '00707',
      clave: '0094,94707,00707',
      desgEstacionPlano: 'ABRANTES',
      activo: true
    },
    {
      cdgoEstacion: '94524',
      cdgoAdmon: '0094',
      nmroPrioridad: 693,
      descEstacion: null,
      desgEstacion: 'STA.COMBA',
      cdgoUic: '00524',
      clave: '0094,94524,00524',
      desgEstacionPlano: 'STA.COMBA',
      activo: true
    },
    {
      cdgoEstacion: '94534',
      cdgoAdmon: '0094',
      nmroPrioridad: 694,
      descEstacion: null,
      desgEstacion: 'NELAS',
      cdgoUic: '00534',
      clave: '0094,94534,00534',
      desgEstacionPlano: 'NELAS',
      activo: true
    },
    {
      cdgoEstacion: '87034',
      cdgoAdmon: '0087',
      nmroPrioridad: 696,
      descEstacion: null,
      desgEstacion: 'LIMOGES',
      cdgoUic: '00034',
      clave: '0087,87034,00034',
      desgEstacionPlano: 'LIMOGES',
      activo: true
    },
    {
      cdgoEstacion: '87078',
      cdgoAdmon: '0087',
      nmroPrioridad: 697,
      descEstacion: null,
      desgEstacion: 'BEZIERS',
      cdgoUic: '00078',
      clave: '0087,87078,00078',
      desgEstacionPlano: 'BEZIERS',
      activo: true
    },
    {
      cdgoEstacion: '87081',
      cdgoAdmon: '0087',
      nmroPrioridad: 698,
      descEstacion: null,
      desgEstacion: 'POITIERS',
      cdgoUic: '00081',
      clave: '0087,87081,00081',
      desgEstacionPlano: 'POITIERS',
      activo: true
    },
    {
      cdgoEstacion: '87088',
      cdgoAdmon: '0087',
      nmroPrioridad: 699,
      descEstacion: null,
      desgEstacion: 'NARBONNE',
      cdgoUic: '00088',
      clave: '0087,87088,00088',
      desgEstacionPlano: 'NARBONNE',
      activo: true
    },
    {
      cdgoEstacion: '99171',
      cdgoAdmon: '0071',
      nmroPrioridad: 700,
      descEstacion: null,
      desgEstacion: 'SALAMANCA-BUS',
      cdgoUic: '99171',
      clave: '0071,99171,99171',
      desgEstacionPlano: 'SALAMANCA-BUS',
      activo: true
    },
    {
      cdgoEstacion: '87173',
      cdgoAdmon: '0087',
      nmroPrioridad: 701,
      descEstacion: null,
      desgEstacion: 'MONTPELLIER',
      cdgoUic: '00173',
      clave: '0087,87173,00173',
      desgEstacionPlano: 'MONTPELLIER',
      activo: true
    },
    {
      cdgoEstacion: '87374',
      cdgoAdmon: '0087',
      nmroPrioridad: 705,
      descEstacion: null,
      desgEstacion: 'PERPIGNAN',
      cdgoUic: '00374',
      clave: '0087,87374,00374',
      desgEstacionPlano: 'PERPIGNAN',
      activo: true
    },
    {
      cdgoEstacion: '87546',
      cdgoAdmon: '0087',
      nmroPrioridad: 706,
      descEstacion: null,
      desgEstacion: 'BLOIS(FR)',
      cdgoUic: '00546',
      clave: '0087,87546,00546',
      desgEstacionPlano: 'BLOIS(FR)',
      activo: true
    },
    {
      cdgoEstacion: '91696',
      cdgoAdmon: '0071',
      nmroPrioridad: 710,
      descEstacion: null,
      desgEstacion: 'GANDIA-BUS',
      cdgoUic: null,
      clave: '0071,91696,null',
      desgEstacionPlano: 'GANDIA-BUS',
      activo: true
    },
    {
      cdgoEstacion: '81011',
      cdgoAdmon: '0071',
      nmroPrioridad: 719,
      descEstacion: null,
      desgEstacion: 'HARO EL PARDO',
      cdgoUic: '81011',
      clave: '0071,81011,81011',
      desgEstacionPlano: 'HARO EL PARDO',
      activo: true
    },
    {
      cdgoEstacion: '81010',
      cdgoAdmon: '0071',
      nmroPrioridad: 720,
      descEstacion: null,
      desgEstacion: 'CENICERO SAN ISIDRO',
      cdgoUic: '81010',
      clave: '0071,81010,81010',
      desgEstacionPlano: 'CENICERO SAN ISIDRO',
      activo: true
    },
    {
      cdgoEstacion: '80102',
      cdgoAdmon: '0071',
      nmroPrioridad: 723,
      descEstacion: null,
      desgEstacion: 'CIZURMAYOR',
      cdgoUic: '80102',
      clave: '0071,80102,80102',
      desgEstacionPlano: 'CIZURMAYOR',
      activo: true
    },
    {
      cdgoEstacion: '79500',
      cdgoAdmon: '0071',
      nmroPrioridad: 729,
      descEstacion: null,
      desgEstacion: 'MATARO',
      cdgoUic: '79500',
      clave: '0071,79500,79500',
      desgEstacionPlano: 'MATARO',
      activo: true
    },
    {
      cdgoEstacion: '79600',
      cdgoAdmon: '0071',
      nmroPrioridad: 732,
      descEstacion: null,
      desgEstacion: 'ARENYS DE MAR',
      cdgoUic: '79600',
      clave: '0071,79600,79600',
      desgEstacionPlano: 'ARENYS DE MAR',
      activo: true
    },
    {
      cdgoEstacion: '79603',
      cdgoAdmon: '0071',
      nmroPrioridad: 735,
      descEstacion: null,
      desgEstacion: 'CALELLA',
      cdgoUic: '79603',
      clave: '0071,79603,79603',
      desgEstacionPlano: 'CALELLA',
      activo: true
    },
    {
      cdgoEstacion: '79606',
      cdgoAdmon: '0071',
      nmroPrioridad: 738,
      descEstacion: null,
      desgEstacion: 'BLANES',
      cdgoUic: '79606',
      clave: '0071,79606,79606',
      desgEstacionPlano: 'BLANES',
      activo: true
    },
    {
      cdgoEstacion: '80005',
      cdgoAdmon: '0071',
      nmroPrioridad: 741,
      descEstacion: null,
      desgEstacion: 'UHARTE-ARAKIL',
      cdgoUic: '80005',
      clave: '0071,80005,80005',
      desgEstacionPlano: 'UHARTE-ARAKIL',
      activo: true
    },
    {
      cdgoEstacion: '80003',
      cdgoAdmon: '0071',
      nmroPrioridad: 742,
      descEstacion: null,
      desgEstacion: 'ETXARRI-ARANATZ',
      cdgoUic: '80003',
      clave: '0071,80003,80003',
      desgEstacionPlano: 'ETXARRI-ARANATZ',
      activo: true
    },
    {
      cdgoEstacion: '85410',
      cdgoAdmon: '0085',
      nmroPrioridad: 743,
      descEstacion: null,
      desgEstacion: 'FRIBOURG',
      cdgoUic: '00410',
      clave: '0085,85410,00410',
      desgEstacionPlano: 'FRIBOURG',
      activo: true
    },
    {
      cdgoEstacion: '85444',
      cdgoAdmon: '0085',
      nmroPrioridad: 744,
      descEstacion: null,
      desgEstacion: 'GENEVE',
      cdgoUic: '00444',
      clave: '0085,85444,00444',
      desgEstacionPlano: 'GENEVE',
      activo: true
    },
    {
      cdgoEstacion: '85550',
      cdgoAdmon: '0085',
      nmroPrioridad: 745,
      descEstacion: null,
      desgEstacion: 'LAUSANNE',
      cdgoUic: '00550',
      clave: '0085,85550,00550',
      desgEstacionPlano: 'LAUSANNE',
      activo: true
    },
    {
      cdgoEstacion: '87004',
      cdgoAdmon: '0087',
      nmroPrioridad: 746,
      descEstacion: null,
      desgEstacion: 'ORLEANS-LES.AUBRAIS',
      cdgoUic: '00004',
      clave: '0087,87004,00004',
      desgEstacionPlano: 'ORLEANS-LES.AUBRAIS',
      activo: true
    },
    {
      cdgoEstacion: '85031',
      cdgoAdmon: '0085',
      nmroPrioridad: 747,
      descEstacion: null,
      desgEstacion: 'BERN',
      cdgoUic: '00031',
      clave: '0085,85031,00031',
      desgEstacionPlano: 'BERN',
      activo: true
    },
    {
      cdgoEstacion: '85200',
      cdgoAdmon: '0085',
      nmroPrioridad: 748,
      descEstacion: null,
      desgEstacion: 'ZURICH HB',
      cdgoUic: '00200',
      clave: '0085,85200,00200',
      desgEstacionPlano: 'ZURICH HB',
      activo: true
    },
    {
      cdgoEstacion: '84104',
      cdgoAdmon: '0071',
      nmroPrioridad: 749,
      descEstacion: null,
      desgEstacion: 'QUINTANA REDONDA',
      cdgoUic: '84104',
      clave: '0071,84104,84104',
      desgEstacionPlano: 'QUINTANA REDONDA',
      activo: true
    },
    {
      cdgoEstacion: '83008',
      cdgoAdmon: '0083',
      nmroPrioridad: 750,
      descEstacion: null,
      desgEstacion: 'NOVARA',
      cdgoUic: '00008',
      clave: '0083,83008,00008',
      desgEstacionPlano: 'NOVARA',
      activo: true
    },
    {
      cdgoEstacion: '83111',
      cdgoAdmon: '0083',
      nmroPrioridad: 751,
      descEstacion: null,
      desgEstacion: 'MILAN C.',
      cdgoUic: '00111',
      clave: '0083,83111,00111',
      desgEstacionPlano: 'MILAN C.',
      activo: true
    },
    {
      cdgoEstacion: '84100',
      cdgoAdmon: '0071',
      nmroPrioridad: 752,
      descEstacion: null,
      desgEstacion: 'COSCURITA',
      cdgoUic: '84100',
      clave: '0071,84100,84100',
      desgEstacionPlano: 'COSCURITA',
      activo: true
    },
    {
      cdgoEstacion: '83002',
      cdgoAdmon: '0083',
      nmroPrioridad: 753,
      descEstacion: null,
      desgEstacion: 'TURIN P.S.',
      cdgoUic: '00002',
      clave: '0083,83002,00002',
      desgEstacionPlano: 'TURIN P.S.',
      activo: true
    },
    {
      cdgoEstacion: '83005',
      cdgoAdmon: '0083',
      nmroPrioridad: 754,
      descEstacion: null,
      desgEstacion: 'BARDONECCHIA',
      cdgoUic: '00005',
      clave: '0083,83005,00005',
      desgEstacionPlano: 'BARDONECCHIA',
      activo: true
    },
    {
      cdgoEstacion: '81101',
      cdgoAdmon: '0071',
      nmroPrioridad: 755,
      descEstacion: null,
      desgEstacion: 'RECAJO',
      cdgoUic: '81101',
      clave: '0071,81101,81101',
      desgEstacionPlano: 'RECAJO',
      activo: true
    },
    {
      cdgoEstacion: '81103',
      cdgoAdmon: '0071',
      nmroPrioridad: 756,
      descEstacion: null,
      desgEstacion: 'ARRUBAL',
      cdgoUic: '81103',
      clave: '0071,81103,81103',
      desgEstacionPlano: 'ARRUBAL',
      activo: true
    },
    {
      cdgoEstacion: '81206',
      cdgoAdmon: '0071',
      nmroPrioridad: 757,
      descEstacion: null,
      desgEstacion: 'GALLUR',
      cdgoUic: '81206',
      clave: '0071,81206,81206',
      desgEstacionPlano: 'GALLUR',
      activo: true
    },
    {
      cdgoEstacion: '81207',
      cdgoAdmon: '0071',
      nmroPrioridad: 758,
      descEstacion: null,
      desgEstacion: 'LUCENI',
      cdgoUic: '81207',
      clave: '0071,81207,81207',
      desgEstacionPlano: 'LUCENI',
      activo: true
    },
    {
      cdgoEstacion: '81208',
      cdgoAdmon: '0071',
      nmroPrioridad: 759,
      descEstacion: null,
      desgEstacion: 'PEDROLA',
      cdgoUic: '81208',
      clave: '0071,81208,81208',
      desgEstacionPlano: 'PEDROLA',
      activo: true
    },
    {
      cdgoEstacion: '81209',
      cdgoAdmon: '0071',
      nmroPrioridad: 760,
      descEstacion: null,
      desgEstacion: 'CABAÑAS DE EBRO',
      cdgoUic: '81209',
      clave: '0071,81209,81209',
      desgEstacionPlano: 'CABANAS DE EBRO',
      activo: true
    },
    {
      cdgoEstacion: '76004',
      cdgoAdmon: '0071',
      nmroPrioridad: 761,
      descEstacion: null,
      desgEstacion: 'VALLS',
      cdgoUic: '76004',
      clave: '0071,76004,76004',
      desgEstacionPlano: 'VALLS',
      activo: true
    },
    {
      cdgoEstacion: '75104',
      cdgoAdmon: '0071',
      nmroPrioridad: 777,
      descEstacion: null,
      desgEstacion: 'VALLFOGONA DE BALAGUER',
      cdgoUic: '75104',
      clave: '0071,75104,75104',
      desgEstacionPlano: 'VALLFOGONA DE BALAGUER',
      activo: true
    },
    {
      cdgoEstacion: '75105',
      cdgoAdmon: '0071',
      nmroPrioridad: 778,
      descEstacion: null,
      desgEstacion: 'BALAGUER',
      cdgoUic: '75105',
      clave: '0071,75105,75105',
      desgEstacionPlano: 'BALAGUER',
      activo: true
    },
    {
      cdgoEstacion: '75106',
      cdgoAdmon: '0071',
      nmroPrioridad: 779,
      descEstacion: null,
      desgEstacion: 'GERB',
      cdgoUic: '75106',
      clave: '0071,75106,75106',
      desgEstacionPlano: 'GERB',
      activo: true
    },
    {
      cdgoEstacion: '75107',
      cdgoAdmon: '0071',
      nmroPrioridad: 780,
      descEstacion: null,
      desgEstacion: 'SANT LLORENÇ DE MONTGAI',
      cdgoUic: '75107',
      clave: '0071,75107,75107',
      desgEstacionPlano: 'SANT LLORENC DE MONTGAI',
      activo: true
    },
    {
      cdgoEstacion: '75108',
      cdgoAdmon: '0071',
      nmroPrioridad: 781,
      descEstacion: null,
      desgEstacion: 'VILANOVA DE LA SAL',
      cdgoUic: '75108',
      clave: '0071,75108,75108',
      desgEstacionPlano: 'VILANOVA DE LA SAL',
      activo: true
    },
    {
      cdgoEstacion: '75109',
      cdgoAdmon: '0071',
      nmroPrioridad: 782,
      descEstacion: null,
      desgEstacion: 'SANTA LIÑA',
      cdgoUic: '75109',
      clave: '0071,75109,75109',
      desgEstacionPlano: 'SANTA LINA',
      activo: true
    },
    {
      cdgoEstacion: '75110',
      cdgoAdmon: '0071',
      nmroPrioridad: 783,
      descEstacion: null,
      desgEstacion: 'AGER',
      cdgoUic: '75110',
      clave: '0071,75110,75110',
      desgEstacionPlano: 'AGER',
      activo: true
    },
    {
      cdgoEstacion: '75111',
      cdgoAdmon: '0071',
      nmroPrioridad: 784,
      descEstacion: null,
      desgEstacion: 'CELLERS-LLIMIANA',
      cdgoUic: '75111',
      clave: '0071,75111,75111',
      desgEstacionPlano: 'CELLERS-LLIMIANA',
      activo: true
    },
    {
      cdgoEstacion: '75116',
      cdgoAdmon: '0071',
      nmroPrioridad: 785,
      descEstacion: null,
      desgEstacion: 'LA POBLA DE SEGUR',
      cdgoUic: '75116',
      clave: '0071,75116,75116',
      desgEstacionPlano: 'LA POBLA DE SEGUR',
      activo: true
    },
    {
      cdgoEstacion: '76001',
      cdgoAdmon: '0071',
      nmroPrioridad: 786,
      descEstacion: null,
      desgEstacion: 'SALOMO',
      cdgoUic: '76001',
      clave: '0071,76001,76001',
      desgEstacionPlano: 'SALOMO',
      activo: true
    },
    {
      cdgoEstacion: '76002',
      cdgoAdmon: '0071',
      nmroPrioridad: 787,
      descEstacion: null,
      desgEstacion: 'VILABELLA',
      cdgoUic: '76002',
      clave: '0071,76002,76002',
      desgEstacionPlano: 'VILABELLA',
      activo: true
    },
    {
      cdgoEstacion: '76003',
      cdgoAdmon: '0071',
      nmroPrioridad: 788,
      descEstacion: null,
      desgEstacion: 'NULLES-BRAFIM',
      cdgoUic: '76003',
      clave: '0071,76003,76003',
      desgEstacionPlano: 'NULLES-BRAFIM',
      activo: true
    },
    {
      cdgoEstacion: '75112',
      cdgoAdmon: '0071',
      nmroPrioridad: 789,
      descEstacion: null,
      desgEstacion: 'GUARDIA DE TREMP',
      cdgoUic: '75112',
      clave: '0071,75112,75112',
      desgEstacionPlano: 'GUARDIA DE TREMP',
      activo: true
    },
    {
      cdgoEstacion: '75113',
      cdgoAdmon: '0071',
      nmroPrioridad: 790,
      descEstacion: null,
      desgEstacion: 'PALAU-PUIGCERCOS',
      cdgoUic: '75113',
      clave: '0071,75113,75113',
      desgEstacionPlano: 'PALAU-PUIGCERCOS',
      activo: true
    },
    {
      cdgoEstacion: '75115',
      cdgoAdmon: '0071',
      nmroPrioridad: 791,
      descEstacion: null,
      desgEstacion: 'SALAS DE PALLARS',
      cdgoUic: '75115',
      clave: '0071,75115,75115',
      desgEstacionPlano: 'SALAS DE PALLARS',
      activo: true
    },
    {
      cdgoEstacion: '78005',
      cdgoAdmon: '0071',
      nmroPrioridad: 804,
      descEstacion: null,
      desgEstacion: 'VILLANUEVA DE GALLEGO',
      cdgoUic: '78005',
      clave: '0071,78005,78005',
      desgEstacionPlano: 'VILLANUEVA DE GALLEGO',
      activo: true
    },
    {
      cdgoEstacion: '78100',
      cdgoAdmon: '0071',
      nmroPrioridad: 805,
      descEstacion: null,
      desgEstacion: 'ZUERA',
      cdgoUic: '78100',
      clave: '0071,78100,78100',
      desgEstacionPlano: 'ZUERA',
      activo: true
    },
    {
      cdgoEstacion: '78403',
      cdgoAdmon: '0071',
      nmroPrioridad: 807,
      descEstacion: null,
      desgEstacion: 'MOLLERUSSA',
      cdgoUic: '78403',
      clave: '0071,78403,78403',
      desgEstacionPlano: 'MOLLERUSSA',
      activo: true
    },
    {
      cdgoEstacion: '78404',
      cdgoAdmon: '0071',
      nmroPrioridad: 808,
      descEstacion: null,
      desgEstacion: 'GOLMES',
      cdgoUic: '78404',
      clave: '0071,78404,78404',
      desgEstacionPlano: 'GOLMES',
      activo: true
    },
    {
      cdgoEstacion: '78405',
      cdgoAdmon: '0071',
      nmroPrioridad: 809,
      descEstacion: null,
      desgEstacion: 'CASTELLNOU DE SEANA',
      cdgoUic: '78405',
      clave: '0071,78405,78405',
      desgEstacionPlano: 'CASTELLNOU DE SEANA',
      activo: true
    },
    {
      cdgoEstacion: '78406',
      cdgoAdmon: '0071',
      nmroPrioridad: 810,
      descEstacion: null,
      desgEstacion: 'BELLPUIG',
      cdgoUic: '78406',
      clave: '0071,78406,78406',
      desgEstacionPlano: 'BELLPUIG',
      activo: true
    },
    {
      cdgoEstacion: '78407',
      cdgoAdmon: '0071',
      nmroPrioridad: 811,
      descEstacion: null,
      desgEstacion: 'ANGLESOLA',
      cdgoUic: '78407',
      clave: '0071,78407,78407',
      desgEstacionPlano: 'ANGLESOLA',
      activo: true
    },
    {
      cdgoEstacion: '78408',
      cdgoAdmon: '0071',
      nmroPrioridad: 812,
      descEstacion: null,
      desgEstacion: 'TARREGA',
      cdgoUic: '78408',
      clave: '0071,78408,78408',
      desgEstacionPlano: 'TARREGA',
      activo: true
    },
    {
      cdgoEstacion: '78500',
      cdgoAdmon: '0071',
      nmroPrioridad: 813,
      descEstacion: null,
      desgEstacion: 'CERVERA',
      cdgoUic: '78500',
      clave: '0071,78500,78500',
      desgEstacionPlano: 'CERVERA',
      activo: true
    },
    {
      cdgoEstacion: '78501',
      cdgoAdmon: '0071',
      nmroPrioridad: 814,
      descEstacion: null,
      desgEstacion: 'SANT GUIM DE FREIXENET',
      cdgoUic: '78501',
      clave: '0071,78501,78501',
      desgEstacionPlano: 'SANT GUIM DE FREIXENET',
      activo: true
    },
    {
      cdgoEstacion: '78402',
      cdgoAdmon: '0071',
      nmroPrioridad: 815,
      descEstacion: null,
      desgEstacion: "BELL-LLOC D'URGELL",
      cdgoUic: '78402',
      clave: '0071,78402,78402',
      desgEstacionPlano: "BELL-LLOC D'URGELL",
      activo: true
    },
    {
      cdgoEstacion: '78201',
      cdgoAdmon: '0071',
      nmroPrioridad: 816,
      descEstacion: null,
      desgEstacion: 'GRAÑEN',
      cdgoUic: '78201',
      clave: '0071,78201,78201',
      desgEstacionPlano: 'GRANEN',
      activo: true
    },
    {
      cdgoEstacion: '78202',
      cdgoAdmon: '0071',
      nmroPrioridad: 817,
      descEstacion: null,
      desgEstacion: 'MARCEN-POLEÑINO',
      cdgoUic: '78202',
      clave: '0071,78202,78202',
      desgEstacionPlano: 'MARCEN-POLENINO',
      activo: true
    },
    {
      cdgoEstacion: '78300',
      cdgoAdmon: '0071',
      nmroPrioridad: 818,
      descEstacion: null,
      desgEstacion: 'SELGUA',
      cdgoUic: '78300',
      clave: '0071,78300,78300',
      desgEstacionPlano: 'SELGUA',
      activo: true
    },
    {
      cdgoEstacion: '78502',
      cdgoAdmon: '0071',
      nmroPrioridad: 819,
      descEstacion: null,
      desgEstacion: 'SANT MARTI SESGUEIOLES',
      cdgoUic: '78502',
      clave: '0071,78502,78502',
      desgEstacionPlano: 'SANT MARTI SESGUEIOLES',
      activo: true
    },
    {
      cdgoEstacion: '78503',
      cdgoAdmon: '0071',
      nmroPrioridad: 820,
      descEstacion: null,
      desgEstacion: 'CALAF',
      cdgoUic: '78503',
      clave: '0071,78503,78503',
      desgEstacionPlano: 'CALAF',
      activo: true
    },
    {
      cdgoEstacion: '78504',
      cdgoAdmon: '0071',
      nmroPrioridad: 821,
      descEstacion: null,
      desgEstacion: 'SEGUERS-SANT PERE SALLAVINERA',
      cdgoUic: '78504',
      clave: '0071,78504,78504',
      desgEstacionPlano: 'SEGUERS-SANT PERE SALLAVINERA',
      activo: true
    },
    {
      cdgoEstacion: '78505',
      cdgoAdmon: '0071',
      nmroPrioridad: 822,
      descEstacion: null,
      desgEstacion: 'AGUILAR DE SEGARRA',
      cdgoUic: '78505',
      clave: '0071,78505,78505',
      desgEstacionPlano: 'AGUILAR DE SEGARRA',
      activo: true
    },
    {
      cdgoEstacion: '78506',
      cdgoAdmon: '0071',
      nmroPrioridad: 823,
      descEstacion: null,
      desgEstacion: 'RAJADELL',
      cdgoUic: '78506',
      clave: '0071,78506,78506',
      desgEstacionPlano: 'RAJADELL',
      activo: true
    },
    {
      cdgoEstacion: '78600',
      cdgoAdmon: '0071',
      nmroPrioridad: 824,
      descEstacion: null,
      desgEstacion: 'MANRESA',
      cdgoUic: '78600',
      clave: '0071,78600,78600',
      desgEstacionPlano: 'MANRESA',
      activo: true
    },
    {
      cdgoEstacion: '78604',
      cdgoAdmon: '0071',
      nmroPrioridad: 825,
      descEstacion: null,
      desgEstacion: 'SANT VICENÇ DE CASTELLET',
      cdgoUic: '78604',
      clave: '0071,78604,78604',
      desgEstacionPlano: 'SANT VICENC DE CASTELLET',
      activo: true
    },
    {
      cdgoEstacion: '78605',
      cdgoAdmon: '0071',
      nmroPrioridad: 826,
      descEstacion: null,
      desgEstacion: 'CASTELLBELL I EL VILAR-MONISTROL MONTS',
      cdgoUic: '78605',
      clave: '0071,78605,78605',
      desgEstacionPlano: 'CASTELLBELL I EL VILAR-MONISTROL MONTS',
      activo: true
    },
    {
      cdgoEstacion: '78606',
      cdgoAdmon: '0071',
      nmroPrioridad: 827,
      descEstacion: null,
      desgEstacion: 'VACARISSES',
      cdgoUic: '78606',
      clave: '0071,78606,78606',
      desgEstacionPlano: 'VACARISSES',
      activo: true
    },
    {
      cdgoEstacion: '78607',
      cdgoAdmon: '0071',
      nmroPrioridad: 828,
      descEstacion: null,
      desgEstacion: 'VACARISSES-TORREBLANCA',
      cdgoUic: '78607',
      clave: '0071,78607,78607',
      desgEstacionPlano: 'VACARISSES-TORREBLANCA',
      activo: true
    },
    {
      cdgoEstacion: '78609',
      cdgoAdmon: '0071',
      nmroPrioridad: 829,
      descEstacion: null,
      desgEstacion: 'VILADECAVALLS',
      cdgoUic: '78609',
      clave: '0071,78609,78609',
      desgEstacionPlano: 'VILADECAVALLS',
      activo: true
    },
    {
      cdgoEstacion: '78610',
      cdgoAdmon: '0071',
      nmroPrioridad: 830,
      descEstacion: null,
      desgEstacion: 'SANT MIQUEL DE GONTERES',
      cdgoUic: '78610',
      clave: '0071,78610,78610',
      desgEstacionPlano: 'SANT MIQUEL DE GONTERES',
      activo: true
    },
    {
      cdgoEstacion: '78700',
      cdgoAdmon: '0071',
      nmroPrioridad: 831,
      descEstacion: null,
      desgEstacion: 'TERRASSA',
      cdgoUic: '78700',
      clave: '0071,78700,78700',
      desgEstacionPlano: 'TERRASSA',
      activo: true
    },
    {
      cdgoEstacion: '78703',
      cdgoAdmon: '0071',
      nmroPrioridad: 832,
      descEstacion: null,
      desgEstacion: 'SABADELL SUD',
      cdgoUic: '78703',
      clave: '0071,78703,78703',
      desgEstacionPlano: 'SABADELL SUD',
      activo: true
    },
    {
      cdgoEstacion: '78704',
      cdgoAdmon: '0071',
      nmroPrioridad: 833,
      descEstacion: null,
      desgEstacion: 'SABADELL CENTRE',
      cdgoUic: '78704',
      clave: '0071,78704,78704',
      desgEstacionPlano: 'SABADELL CENTRE',
      activo: true
    },
    {
      cdgoEstacion: '78705',
      cdgoAdmon: '0071',
      nmroPrioridad: 834,
      descEstacion: null,
      desgEstacion: 'BARBERA DEL VALLES',
      cdgoUic: '78705',
      clave: '0071,78705,78705',
      desgEstacionPlano: 'BARBERA DEL VALLES',
      activo: true
    },
    {
      cdgoEstacion: '78706',
      cdgoAdmon: '0071',
      nmroPrioridad: 835,
      descEstacion: null,
      desgEstacion: 'CERDANYOLA DEL VALLES',
      cdgoUic: '78706',
      clave: '0071,78706,78706',
      desgEstacionPlano: 'CERDANYOLA DEL VALLES',
      activo: true
    },
    {
      cdgoEstacion: '78707',
      cdgoAdmon: '0071',
      nmroPrioridad: 836,
      descEstacion: null,
      desgEstacion: 'MONTCADA I REIXAC-SANTA MARIA',
      cdgoUic: '78707',
      clave: '0071,78707,78707',
      desgEstacionPlano: 'MONTCADA I REIXAC-SANTA MARIA',
      activo: true
    },
    {
      cdgoEstacion: '78708',
      cdgoAdmon: '0071',
      nmroPrioridad: 837,
      descEstacion: null,
      desgEstacion: 'MONTCADA I REIXAC-MANRESA',
      cdgoUic: '78708',
      clave: '0071,78708,78708',
      desgEstacionPlano: 'MONTCADA I REIXAC-MANRESA',
      activo: true
    },
    {
      cdgoEstacion: '78709',
      cdgoAdmon: '0071',
      nmroPrioridad: 838,
      descEstacion: null,
      desgEstacion: 'SABADELL NORD',
      cdgoUic: '78709',
      clave: '0071,78709,78709',
      desgEstacionPlano: 'SABADELL NORD',
      activo: true
    },
    {
      cdgoEstacion: '78710',
      cdgoAdmon: '0071',
      nmroPrioridad: 839,
      descEstacion: null,
      desgEstacion: 'TERRASA EST',
      cdgoUic: '78710',
      clave: '0071,78710,78710',
      desgEstacionPlano: 'TERRASA EST',
      activo: true
    },
    {
      cdgoEstacion: '78800',
      cdgoAdmon: '0071',
      nmroPrioridad: 840,
      descEstacion: null,
      desgEstacion: 'MONTCADA BIFURCACIO',
      cdgoUic: '78800',
      clave: '0071,78800,78800',
      desgEstacionPlano: 'MONTCADA BIFURCACIO',
      activo: true
    },
    {
      cdgoEstacion: '78801',
      cdgoAdmon: '0071',
      nmroPrioridad: 841,
      descEstacion: null,
      desgEstacion: 'BARCELONA-TORRE DEL BARO',
      cdgoUic: '78801',
      clave: '0071,78801,78801',
      desgEstacionPlano: 'BARCELONA-TORRE DEL BARO',
      activo: true
    },
    {
      cdgoEstacion: '78802',
      cdgoAdmon: '0071',
      nmroPrioridad: 842,
      descEstacion: null,
      desgEstacion: 'SANT ANDREU ARENAL',
      cdgoUic: '78802',
      clave: '0071,78802,78802',
      desgEstacionPlano: 'SANT ANDREU ARENAL',
      activo: true
    },
    {
      cdgoEstacion: '79104',
      cdgoAdmon: '0071',
      nmroPrioridad: 843,
      descEstacion: null,
      desgEstacion: 'SANT CELONI',
      cdgoUic: '79104',
      clave: '0071,79104,79104',
      desgEstacionPlano: 'SANT CELONI',
      activo: true
    },
    {
      cdgoEstacion: '79105',
      cdgoAdmon: '0071',
      nmroPrioridad: 844,
      descEstacion: null,
      desgEstacion: 'GUALBA',
      cdgoUic: '79105',
      clave: '0071,79105,79105',
      desgEstacionPlano: 'GUALBA',
      activo: true
    },
    {
      cdgoEstacion: '79100',
      cdgoAdmon: '0071',
      nmroPrioridad: 845,
      descEstacion: null,
      desgEstacion: 'GRANOLLERS CENTRE',
      cdgoUic: '79100',
      clave: '0071,79100,79100',
      desgEstacionPlano: 'GRANOLLERS CENTRE',
      activo: true
    },
    {
      cdgoEstacion: '78804',
      cdgoAdmon: '0071',
      nmroPrioridad: 846,
      descEstacion: null,
      desgEstacion: 'BARCELONA-ARC DE TRIOMF',
      cdgoUic: '78804',
      clave: '0071,78804,78804',
      desgEstacionPlano: 'BARCELONA-ARC DE TRIOMF',
      activo: true
    },
    {
      cdgoEstacion: '78805',
      cdgoAdmon: '0071',
      nmroPrioridad: 847,
      descEstacion: null,
      desgEstacion: 'BARCELONA-PLAÇA DE CATALUNYA',
      cdgoUic: '78805',
      clave: '0071,78805,78805',
      desgEstacionPlano: 'BARCELONA-PLACA DE CATALUNYA',
      activo: true
    },
    {
      cdgoEstacion: '78806',
      cdgoAdmon: '0071',
      nmroPrioridad: 848,
      descEstacion: null,
      desgEstacion: 'BARCELONA LA SAGRERA MERIDIANA',
      cdgoUic: '78806',
      clave: '0071,78806,78806',
      desgEstacionPlano: 'BARCELONA LA SAGRERA MERIDIANA',
      activo: true
    },
    {
      cdgoEstacion: '79311',
      cdgoAdmon: '0071',
      nmroPrioridad: 857,
      descEstacion: null,
      desgEstacion: 'VILAJUIGA',
      cdgoUic: '79311',
      clave: '0071,79311,79311',
      desgEstacionPlano: 'VILAJUIGA',
      activo: true
    },
    {
      cdgoEstacion: '79312',
      cdgoAdmon: '0071',
      nmroPrioridad: 858,
      descEstacion: null,
      desgEstacion: 'LLANÇÀ',
      cdgoUic: '79312',
      clave: '0071,79312,79312',
      desgEstacionPlano: 'LLANCA',
      activo: true
    },
    {
      cdgoEstacion: '79314',
      cdgoAdmon: '0071',
      nmroPrioridad: 859,
      descEstacion: null,
      desgEstacion: 'COLERA',
      cdgoUic: '79314',
      clave: '0071,79314,79314',
      desgEstacionPlano: 'COLERA',
      activo: true
    },
    {
      cdgoEstacion: '79315',
      cdgoAdmon: '0071',
      nmroPrioridad: 860,
      descEstacion: null,
      desgEstacion: 'PORTBOU',
      cdgoUic: '79315',
      clave: '0071,79315,79315',
      desgEstacionPlano: 'PORTBOU',
      activo: true
    },
    {
      cdgoEstacion: '79316',
      cdgoAdmon: '0087',
      nmroPrioridad: 862,
      descEstacion: null,
      desgEstacion: 'CERBERE',
      cdgoUic: '00290',
      clave: '0087,79316,00290',
      desgEstacionPlano: 'CERBERE',
      activo: true
    },
    {
      cdgoEstacion: '79305',
      cdgoAdmon: '0071',
      nmroPrioridad: 863,
      descEstacion: null,
      desgEstacion: 'CAMALLERA',
      cdgoUic: '79305',
      clave: '0071,79305,79305',
      desgEstacionPlano: 'CAMALLERA',
      activo: true
    },
    {
      cdgoEstacion: '79306',
      cdgoAdmon: '0071',
      nmroPrioridad: 864,
      descEstacion: null,
      desgEstacion: 'SANT MIQUEL DE FLUVIA',
      cdgoUic: '79306',
      clave: '0071,79306,79306',
      desgEstacionPlano: 'SANT MIQUEL DE FLUVIA',
      activo: true
    },
    {
      cdgoEstacion: '79308',
      cdgoAdmon: '0071',
      nmroPrioridad: 865,
      descEstacion: null,
      desgEstacion: 'VILAMALLA',
      cdgoUic: '79308',
      clave: '0071,79308,79308',
      desgEstacionPlano: 'VILAMALLA',
      activo: true
    },
    {
      cdgoEstacion: '79301',
      cdgoAdmon: '0071',
      nmroPrioridad: 866,
      descEstacion: null,
      desgEstacion: 'CELRA',
      cdgoUic: '79301',
      clave: '0071,79301,79301',
      desgEstacionPlano: 'CELRA',
      activo: true
    },
    {
      cdgoEstacion: '79302',
      cdgoAdmon: '0071',
      nmroPrioridad: 867,
      descEstacion: null,
      desgEstacion: 'BORDILS-JUIA',
      cdgoUic: '79302',
      clave: '0071,79302,79302',
      desgEstacionPlano: 'BORDILS-JUIA',
      activo: true
    },
    {
      cdgoEstacion: '79304',
      cdgoAdmon: '0071',
      nmroPrioridad: 868,
      descEstacion: null,
      desgEstacion: 'SANT JORDI DESVALLS',
      cdgoUic: '79304',
      clave: '0071,79304,79304',
      desgEstacionPlano: 'SANT JORDI DESVALLS',
      activo: true
    },
    {
      cdgoEstacion: '79205',
      cdgoAdmon: '0071',
      nmroPrioridad: 869,
      descEstacion: null,
      desgEstacion: 'FORNELLS DE LA SELVA',
      cdgoUic: '79205',
      clave: '0071,79205,79205',
      desgEstacionPlano: 'FORNELLS DE LA SELVA',
      activo: true
    },
    {
      cdgoEstacion: '79204',
      cdgoAdmon: '0071',
      nmroPrioridad: 870,
      descEstacion: null,
      desgEstacion: 'RIUDELLOTS',
      cdgoUic: '79204',
      clave: '0071,79204,79204',
      desgEstacionPlano: 'RIUDELLOTS',
      activo: true
    },
    {
      cdgoEstacion: '79106',
      cdgoAdmon: '0071',
      nmroPrioridad: 871,
      descEstacion: null,
      desgEstacion: 'RIELLS I VIABREA-BREDA',
      cdgoUic: '79106',
      clave: '0071,79106,79106',
      desgEstacionPlano: 'RIELLS I VIABREA-BREDA',
      activo: true
    },
    {
      cdgoEstacion: '79107',
      cdgoAdmon: '0071',
      nmroPrioridad: 872,
      descEstacion: null,
      desgEstacion: 'HOSTALRIC',
      cdgoUic: '79107',
      clave: '0071,79107,79107',
      desgEstacionPlano: 'HOSTALRIC',
      activo: true
    },
    {
      cdgoEstacion: '79200',
      cdgoAdmon: '0071',
      nmroPrioridad: 873,
      descEstacion: null,
      desgEstacion: 'MAÇANET-MASSANES',
      cdgoUic: '79200',
      clave: '0071,79200,79200',
      desgEstacionPlano: 'MACANET-MASSANES',
      activo: true
    },
    {
      cdgoEstacion: '51414',
      cdgoAdmon: '0071',
      nmroPrioridad: 874,
      descEstacion: null,
      desgEstacion: 'SAN SEVERIANO',
      cdgoUic: '51414',
      clave: '0071,51414,51414',
      desgEstacionPlano: 'SAN SEVERIANO',
      activo: true
    },
    {
      cdgoEstacion: '51415',
      cdgoAdmon: '0071',
      nmroPrioridad: 875,
      descEstacion: null,
      desgEstacion: 'LAS ALETAS',
      cdgoUic: '51415',
      clave: '0071,51415,51415',
      desgEstacionPlano: 'LAS ALETAS',
      activo: true
    },
    {
      cdgoEstacion: '51417',
      cdgoAdmon: '0071',
      nmroPrioridad: 876,
      descEstacion: null,
      desgEstacion: 'VALDELAGRANA',
      cdgoUic: '51417',
      clave: '0071,51417,51417',
      desgEstacionPlano: 'VALDELAGRANA',
      activo: true
    },
    {
      cdgoEstacion: '54105',
      cdgoAdmon: '0071',
      nmroPrioridad: 877,
      descEstacion: null,
      desgEstacion: 'MONTILLA',
      cdgoUic: '54105',
      clave: '0071,54105,54105',
      desgEstacionPlano: 'MONTILLA',
      activo: true
    },
    {
      cdgoEstacion: '51404',
      cdgoAdmon: '0071',
      nmroPrioridad: 878,
      descEstacion: null,
      desgEstacion: 'SEGUNDA AGUADA',
      cdgoUic: '51404',
      clave: '0071,51404,51404',
      desgEstacionPlano: 'SEGUNDA AGUADA',
      activo: true
    },
    {
      cdgoEstacion: '51402',
      cdgoAdmon: '0071',
      nmroPrioridad: 879,
      descEstacion: null,
      desgEstacion: 'SAN FERNANDO DE CADIZ',
      cdgoUic: '51402',
      clave: '0071,51402,51402',
      desgEstacionPlano: 'SAN FERNANDO DE CADIZ',
      activo: true
    },
    {
      cdgoEstacion: '54106',
      cdgoAdmon: '0071',
      nmroPrioridad: 880,
      descEstacion: null,
      desgEstacion: 'AGUILAR DE LA FRONTERA',
      cdgoUic: '54106',
      clave: '0071,54106,54106',
      desgEstacionPlano: 'AGUILAR DE LA FRONTERA',
      activo: true
    },
    {
      cdgoEstacion: '54201',
      cdgoAdmon: '0071',
      nmroPrioridad: 881,
      descEstacion: null,
      desgEstacion: 'PUENTE GENIL',
      cdgoUic: '54201',
      clave: '0071,54201,54201',
      desgEstacionPlano: 'PUENTE GENIL',
      activo: true
    },
    {
      cdgoEstacion: '54202',
      cdgoAdmon: '0071',
      nmroPrioridad: 882,
      descEstacion: null,
      desgEstacion: 'CASARICHE',
      cdgoUic: '54202',
      clave: '0071,54202,54202',
      desgEstacionPlano: 'CASARICHE',
      activo: true
    },
    {
      cdgoEstacion: '54300',
      cdgoAdmon: '0071',
      nmroPrioridad: 883,
      descEstacion: null,
      desgEstacion: 'LA RODA DE ANDALUCIA',
      cdgoUic: '54300',
      clave: '0071,54300,54300',
      desgEstacionPlano: 'LA RODA DE ANDALUCIA',
      activo: true
    },
    {
      cdgoEstacion: '54301',
      cdgoAdmon: '0071',
      nmroPrioridad: 884,
      descEstacion: null,
      desgEstacion: 'FUENTE DE PIEDRA',
      cdgoUic: '54301',
      clave: '0071,54301,54301',
      desgEstacionPlano: 'FUENTE DE PIEDRA',
      activo: true
    },
    {
      cdgoEstacion: '54404',
      cdgoAdmon: '0071',
      nmroPrioridad: 885,
      descEstacion: null,
      desgEstacion: 'LAS MELLIZAS',
      cdgoUic: '54404',
      clave: '0071,54404,54404',
      desgEstacionPlano: 'LAS MELLIZAS',
      activo: true
    },
    {
      cdgoEstacion: '54405',
      cdgoAdmon: '0071',
      nmroPrioridad: 886,
      descEstacion: null,
      desgEstacion: 'ALORA',
      cdgoUic: '54405',
      clave: '0071,54405,54405',
      desgEstacionPlano: 'ALORA',
      activo: true
    },
    {
      cdgoEstacion: '54406',
      cdgoAdmon: '0071',
      nmroPrioridad: 887,
      descEstacion: null,
      desgEstacion: 'PIZARRA',
      cdgoUic: '54406',
      clave: '0071,54406,54406',
      desgEstacionPlano: 'PIZARRA',
      activo: true
    },
    {
      cdgoEstacion: '54408',
      cdgoAdmon: '0071',
      nmroPrioridad: 888,
      descEstacion: null,
      desgEstacion: 'CARTAMA',
      cdgoUic: '54408',
      clave: '0071,54408,54408',
      desgEstacionPlano: 'CARTAMA',
      activo: true
    },
    {
      cdgoEstacion: '54511',
      cdgoAdmon: '0071',
      nmroPrioridad: 889,
      descEstacion: null,
      desgEstacion: 'BENALMADENA/ARROYO DE LA MIEL',
      cdgoUic: '54511',
      clave: '0071,54511,54511',
      desgEstacionPlano: 'BENALMADENA/ARROYO DE LA MIEL',
      activo: true
    },
    {
      cdgoEstacion: '54516',
      cdgoAdmon: '0071',
      nmroPrioridad: 890,
      descEstacion: null,
      desgEstacion: 'FUENGIROLA',
      cdgoUic: '54516',
      clave: '0071,54516,54516',
      desgEstacionPlano: 'FUENGIROLA',
      activo: true
    },
    {
      cdgoEstacion: '54505',
      cdgoAdmon: '0071',
      nmroPrioridad: 891,
      descEstacion: null,
      desgEstacion: 'MÁLAGA AEROPUERTO',
      cdgoUic: '54505',
      clave: '0071,54505,54505',
      desgEstacionPlano: 'MALAGA AEROPUERTO',
      activo: true
    },
    {
      cdgoEstacion: '56005',
      cdgoAdmon: '0071',
      nmroPrioridad: 892,
      descEstacion: null,
      desgEstacion: 'LOS PROPIOS-CAZORLA',
      cdgoUic: '56005',
      clave: '0071,56005,56005',
      desgEstacionPlano: 'LOS PROPIOS-CAZORLA',
      activo: true
    },
    {
      cdgoEstacion: '56007',
      cdgoAdmon: '0071',
      nmroPrioridad: 893,
      descEstacion: null,
      desgEstacion: 'LARVA',
      cdgoUic: '56007',
      clave: '0071,56007,56007',
      desgEstacionPlano: 'LARVA',
      activo: true
    },
    {
      cdgoEstacion: '56011',
      cdgoAdmon: '0071',
      nmroPrioridad: 895,
      descEstacion: null,
      desgEstacion: 'ALAMEDILLA-GUADAHORTUNA',
      cdgoUic: '56011',
      clave: '0071,56011,56011',
      desgEstacionPlano: 'ALAMEDILLA-GUADAHORTUNA',
      activo: true
    },
    {
      cdgoEstacion: '56100',
      cdgoAdmon: '0071',
      nmroPrioridad: 896,
      descEstacion: null,
      desgEstacion: 'MOREDA',
      cdgoUic: '56100',
      clave: '0071,56100,56100',
      desgEstacionPlano: 'MOREDA',
      activo: true
    },
    {
      cdgoEstacion: '56008',
      cdgoAdmon: '0071',
      nmroPrioridad: 897,
      descEstacion: null,
      desgEstacion: 'HUESA',
      cdgoUic: '56008',
      clave: '0071,56008,56008',
      desgEstacionPlano: 'HUESA',
      activo: true
    },
    {
      cdgoEstacion: '57003',
      cdgoAdmon: '0071',
      nmroPrioridad: 898,
      descEstacion: null,
      desgEstacion: 'IZNALLOZ',
      cdgoUic: '57003',
      clave: '0071,57003,57003',
      desgEstacionPlano: 'IZNALLOZ',
      activo: true
    },
    {
      cdgoEstacion: '56308',
      cdgoAdmon: '0071',
      nmroPrioridad: 899,
      descEstacion: null,
      desgEstacion: 'GADOR',
      cdgoUic: '56308',
      clave: '0071,56308,56308',
      desgEstacionPlano: 'GADOR',
      activo: true
    },
    {
      cdgoEstacion: '56103',
      cdgoAdmon: '0071',
      nmroPrioridad: 900,
      descEstacion: null,
      desgEstacion: 'BENALUA DE GUADIX',
      cdgoUic: '56103',
      clave: '0071,56103,56103',
      desgEstacionPlano: 'BENALUA DE GUADIX',
      activo: true
    },
    {
      cdgoEstacion: '56305',
      cdgoAdmon: '0071',
      nmroPrioridad: 901,
      descEstacion: null,
      desgEstacion: 'GERGAL',
      cdgoUic: '56305',
      clave: '0071,56305,56305',
      desgEstacionPlano: 'GERGAL',
      activo: true
    },
    {
      cdgoEstacion: '55019',
      cdgoAdmon: '0071',
      nmroPrioridad: 902,
      descEstacion: null,
      desgEstacion: 'LOS BARRIOS',
      cdgoUic: '55019',
      clave: '0071,55019,55019',
      desgEstacionPlano: 'LOS BARRIOS',
      activo: true
    },
    {
      cdgoEstacion: '55017',
      cdgoAdmon: '0071',
      nmroPrioridad: 903,
      descEstacion: null,
      desgEstacion: 'ALMORAIMA',
      cdgoUic: '55017',
      clave: '0071,55017,55017',
      desgEstacionPlano: 'ALMORAIMA',
      activo: true
    },
    {
      cdgoEstacion: '55012',
      cdgoAdmon: '0071',
      nmroPrioridad: 904,
      descEstacion: null,
      desgEstacion: 'CORTES DE LA FRONTERA',
      cdgoUic: '55012',
      clave: '0071,55012,55012',
      desgEstacionPlano: 'CORTES DE LA FRONTERA',
      activo: true
    },
    {
      cdgoEstacion: '55008',
      cdgoAdmon: '0071',
      nmroPrioridad: 905,
      descEstacion: null,
      desgEstacion: 'ARRIATE',
      cdgoUic: '55008',
      clave: '0071,55008,55008',
      desgEstacionPlano: 'ARRIATE',
      activo: true
    },
    {
      cdgoEstacion: '55011',
      cdgoAdmon: '0071',
      nmroPrioridad: 906,
      descEstacion: null,
      desgEstacion: 'JIMERA DE LIBAR',
      cdgoUic: '55011',
      clave: '0071,55011,55011',
      desgEstacionPlano: 'JIMERA DE LIBAR',
      activo: true
    },
    {
      cdgoEstacion: '55002',
      cdgoAdmon: '0071',
      nmroPrioridad: 907,
      descEstacion: null,
      desgEstacion: 'TEBA',
      cdgoUic: '55002',
      clave: '0071,55002,55002',
      desgEstacionPlano: 'TEBA',
      activo: true
    },
    {
      cdgoEstacion: '60503',
      cdgoAdmon: '0071',
      nmroPrioridad: 908,
      descEstacion: null,
      desgEstacion: 'MINAYA',
      cdgoUic: '60503',
      clave: '0071,60503,60503',
      desgEstacionPlano: 'MINAYA',
      activo: true
    },
    {
      cdgoEstacion: '60202',
      cdgoAdmon: '0071',
      nmroPrioridad: 909,
      descEstacion: null,
      desgEstacion: 'CASTILLEJO-AÑOVER',
      cdgoUic: '60202',
      clave: '0071,60202,60202',
      desgEstacionPlano: 'CASTILLEJO-ANOVER',
      activo: true
    },
    {
      cdgoEstacion: '60206',
      cdgoAdmon: '0071',
      nmroPrioridad: 910,
      descEstacion: null,
      desgEstacion: 'TEMBLEQUE',
      cdgoUic: '60206',
      clave: '0071,60206,60206',
      desgEstacionPlano: 'TEMBLEQUE',
      activo: true
    },
    {
      cdgoEstacion: '61100',
      cdgoAdmon: '0071',
      nmroPrioridad: 913,
      descEstacion: null,
      desgEstacion: 'ALCANTARILLA',
      cdgoUic: '61100',
      clave: '0071,61100,61100',
      desgEstacionPlano: 'ALCANTARILLA',
      activo: true
    },
    {
      cdgoEstacion: '60507',
      cdgoAdmon: '0071',
      nmroPrioridad: 915,
      descEstacion: null,
      desgEstacion: 'LA GINETA',
      cdgoUic: '60507',
      clave: '0071,60507,60507',
      desgEstacionPlano: 'LA GINETA',
      activo: true
    },
    {
      cdgoEstacion: '62001',
      cdgoAdmon: '0071',
      nmroPrioridad: 916,
      descEstacion: null,
      desgEstacion: 'BENIEL',
      cdgoUic: '62001',
      clave: '0071,62001,62001',
      desgEstacionPlano: 'BENIEL',
      activo: true
    },
    {
      cdgoEstacion: '62104',
      cdgoAdmon: '0071',
      nmroPrioridad: 917,
      descEstacion: null,
      desgEstacion: 'TORRELLANO',
      cdgoUic: '62104',
      clave: '0071,62104,62104',
      desgEstacionPlano: 'TORRELLANO',
      activo: true
    },
    {
      cdgoEstacion: '62101',
      cdgoAdmon: '0071',
      nmroPrioridad: 918,
      descEstacion: null,
      desgEstacion: 'CREVILLENT',
      cdgoUic: '62101',
      clave: '0071,62101,62101',
      desgEstacionPlano: 'CREVILLENT',
      activo: true
    },
    {
      cdgoEstacion: '64003',
      cdgoAdmon: '0071',
      nmroPrioridad: 920,
      descEstacion: null,
      desgEstacion: 'MOIXENT/MOGENTE',
      cdgoUic: '64003',
      clave: '0071,64003,64003',
      desgEstacionPlano: 'MOIXENT/MOGENTE',
      activo: true
    },
    {
      cdgoEstacion: '64101',
      cdgoAdmon: '0071',
      nmroPrioridad: 921,
      descEstacion: null,
      desgEstacion: 'MANUEL-L.ENOVA',
      cdgoUic: '64101',
      clave: '0071,64101,64101',
      desgEstacionPlano: 'MANUEL-L.ENOVA',
      activo: true
    },
    {
      cdgoEstacion: '64102',
      cdgoAdmon: '0071',
      nmroPrioridad: 922,
      descEstacion: null,
      desgEstacion: 'LA POBLA LLARGA',
      cdgoUic: '64102',
      clave: '0071,64102,64102',
      desgEstacionPlano: 'LA POBLA LLARGA',
      activo: true
    },
    {
      cdgoEstacion: '64103',
      cdgoAdmon: '0071',
      nmroPrioridad: 923,
      descEstacion: null,
      desgEstacion: 'CARCAIXENT',
      cdgoUic: '64103',
      clave: '0071,64103,64103',
      desgEstacionPlano: 'CARCAIXENT',
      activo: true
    },
    {
      cdgoEstacion: '64105',
      cdgoAdmon: '0071',
      nmroPrioridad: 924,
      descEstacion: null,
      desgEstacion: 'ALGEMESI',
      cdgoUic: '64105',
      clave: '0071,64105,64105',
      desgEstacionPlano: 'ALGEMESI',
      activo: true
    },
    {
      cdgoEstacion: '64107',
      cdgoAdmon: '0071',
      nmroPrioridad: 925,
      descEstacion: null,
      desgEstacion: 'BENIFAIO-ALMUSSAFES',
      cdgoUic: '64107',
      clave: '0071,64107,64107',
      desgEstacionPlano: 'BENIFAIO-ALMUSSAFES',
      activo: true
    },
    {
      cdgoEstacion: '64201',
      cdgoAdmon: '0071',
      nmroPrioridad: 926,
      descEstacion: null,
      desgEstacion: 'CATARROJA',
      cdgoUic: '64201',
      clave: '0071,64201,64201',
      desgEstacionPlano: 'CATARROJA',
      activo: true
    },
    {
      cdgoEstacion: '64202',
      cdgoAdmon: '0071',
      nmroPrioridad: 927,
      descEstacion: null,
      desgEstacion: 'MASSANASSA',
      cdgoUic: '64202',
      clave: '0071,64202,64202',
      desgEstacionPlano: 'MASSANASSA',
      activo: true
    },
    {
      cdgoEstacion: '64203',
      cdgoAdmon: '0071',
      nmroPrioridad: 928,
      descEstacion: null,
      desgEstacion: 'ALFAFAR-BENETUSSER',
      cdgoUic: '64203',
      clave: '0071,64203,64203',
      desgEstacionPlano: 'ALFAFAR-BENETUSSER',
      activo: true
    },
    {
      cdgoEstacion: '50701',
      cdgoAdmon: '0071',
      nmroPrioridad: 931,
      descEstacion: null,
      desgEstacion: 'CANTILLANA',
      cdgoUic: '50701',
      clave: '0071,50701,50701',
      desgEstacionPlano: 'CANTILLANA',
      activo: true
    },
    {
      cdgoEstacion: '50702',
      cdgoAdmon: '0071',
      nmroPrioridad: 932,
      descEstacion: null,
      desgEstacion: 'BRENES',
      cdgoUic: '50702',
      clave: '0071,50702,50702',
      desgEstacionPlano: 'BRENES',
      activo: true
    },
    {
      cdgoEstacion: '50703',
      cdgoAdmon: '0071',
      nmroPrioridad: 933,
      descEstacion: null,
      desgEstacion: 'LA RINCONADA',
      cdgoUic: '50703',
      clave: '0071,50703,50703',
      desgEstacionPlano: 'LA RINCONADA',
      activo: true
    },
    {
      cdgoEstacion: '51111',
      cdgoAdmon: '0071',
      nmroPrioridad: 934,
      descEstacion: null,
      desgEstacion: 'BELLAVISTA',
      cdgoUic: '51111',
      clave: '0071,51111,51111',
      desgEstacionPlano: 'BELLAVISTA',
      activo: true
    },
    {
      cdgoEstacion: '50417',
      cdgoAdmon: '0071',
      nmroPrioridad: 935,
      descEstacion: null,
      desgEstacion: 'CAMPUS UNIVERSITARIO RABANALES / CORDOBA',
      cdgoUic: '50417',
      clave: '0071,50417,50417',
      desgEstacionPlano: 'CAMPUS UNIVERSITARIO RABANALES / CORDOBA',
      activo: true
    },
    {
      cdgoEstacion: '50700',
      cdgoAdmon: '0071',
      nmroPrioridad: 936,
      descEstacion: null,
      desgEstacion: 'LOS ROSALES',
      cdgoUic: '50700',
      clave: '0071,50700,50700',
      desgEstacionPlano: 'LOS ROSALES',
      activo: true
    },
    {
      cdgoEstacion: '43012',
      cdgoAdmon: '0071',
      nmroPrioridad: 938,
      descEstacion: null,
      desgEstacion: 'VILLARRASA',
      cdgoUic: '43012',
      clave: '0071,43012,43012',
      desgEstacionPlano: 'VILLARRASA',
      activo: true
    },
    {
      cdgoEstacion: '43015',
      cdgoAdmon: '0071',
      nmroPrioridad: 939,
      descEstacion: null,
      desgEstacion: 'NIEBLA',
      cdgoUic: '43015',
      clave: '0071,43015,43015',
      desgEstacionPlano: 'NIEBLA',
      activo: true
    },
    {
      cdgoEstacion: '37611',
      cdgoAdmon: '0071',
      nmroPrioridad: 940,
      descEstacion: null,
      desgEstacion: 'GARROVILLA-LAS VEGAS',
      cdgoUic: '37611',
      clave: '0071,37611,37611',
      desgEstacionPlano: 'GARROVILLA-LAS VEGAS',
      activo: true
    },
    {
      cdgoEstacion: '40002',
      cdgoAdmon: '0071',
      nmroPrioridad: 941,
      descEstacion: null,
      desgEstacion: 'CALAMONTE',
      cdgoUic: '40002',
      clave: '0071,40002,40002',
      desgEstacionPlano: 'CALAMONTE',
      activo: true
    },
    {
      cdgoEstacion: '40115',
      cdgoAdmon: '0071',
      nmroPrioridad: 942,
      descEstacion: null,
      desgEstacion: 'PEDROSO',
      cdgoUic: '40115',
      clave: '0071,40115,40115',
      desgEstacionPlano: 'PEDROSO',
      activo: true
    },
    {
      cdgoEstacion: '40119',
      cdgoAdmon: '0071',
      nmroPrioridad: 943,
      descEstacion: null,
      desgEstacion: 'VILLANUEVA DEL RIO-MINAS',
      cdgoUic: '40119',
      clave: '0071,40119,40119',
      desgEstacionPlano: 'VILLANUEVA DEL RIO-MINAS',
      activo: true
    },
    {
      cdgoEstacion: '40121',
      cdgoAdmon: '0071',
      nmroPrioridad: 944,
      descEstacion: null,
      desgEstacion: 'ALCOLEA DEL RIO',
      cdgoUic: '40121',
      clave: '0071,40121,40121',
      desgEstacionPlano: 'ALCOLEA DEL RIO',
      activo: true
    },
    {
      cdgoEstacion: '40122',
      cdgoAdmon: '0071',
      nmroPrioridad: 945,
      descEstacion: null,
      desgEstacion: 'TOCINA',
      cdgoUic: '40122',
      clave: '0071,40122,40122',
      desgEstacionPlano: 'TOCINA',
      activo: true
    },
    {
      cdgoEstacion: '40107',
      cdgoAdmon: '0071',
      nmroPrioridad: 946,
      descEstacion: null,
      desgEstacion: 'FUENTE DEL ARCO',
      cdgoUic: '40107',
      clave: '0071,40107,40107',
      desgEstacionPlano: 'FUENTE DEL ARCO',
      activo: true
    },
    {
      cdgoEstacion: '40113',
      cdgoAdmon: '0071',
      nmroPrioridad: 947,
      descEstacion: null,
      desgEstacion: 'CAZALLA-CONSTANTINA',
      cdgoUic: '40113',
      clave: '0071,40113,40113',
      desgEstacionPlano: 'CAZALLA-CONSTANTINA',
      activo: true
    },
    {
      cdgoEstacion: '42013',
      cdgoAdmon: '0071',
      nmroPrioridad: 948,
      descEstacion: null,
      desgEstacion: 'EL TAMUJOSO',
      cdgoUic: '42013',
      clave: '0071,42013,42013',
      desgEstacionPlano: 'EL TAMUJOSO',
      activo: true
    },
    {
      cdgoEstacion: '42015',
      cdgoAdmon: '0071',
      nmroPrioridad: 949,
      descEstacion: null,
      desgEstacion: 'CALAÑAS',
      cdgoUic: '42015',
      clave: '0071,42015,42015',
      desgEstacionPlano: 'CALANAS',
      activo: true
    },
    {
      cdgoEstacion: '42016',
      cdgoAdmon: '0071',
      nmroPrioridad: 950,
      descEstacion: null,
      desgEstacion: 'LOS MILANOS',
      cdgoUic: '42016',
      clave: '0071,42016,42016',
      desgEstacionPlano: 'LOS MILANOS',
      activo: true
    },
    {
      cdgoEstacion: '42018',
      cdgoAdmon: '0071',
      nmroPrioridad: 951,
      descEstacion: null,
      desgEstacion: 'EL COBUJON',
      cdgoUic: '42018',
      clave: '0071,42018,42018',
      desgEstacionPlano: 'EL COBUJON',
      activo: true
    },
    {
      cdgoEstacion: '42019',
      cdgoAdmon: '0071',
      nmroPrioridad: 952,
      descEstacion: null,
      desgEstacion: 'BELMONTE',
      cdgoUic: '42019',
      clave: '0071,42019,42019',
      desgEstacionPlano: 'BELMONTE',
      activo: true
    },
    {
      cdgoEstacion: '42020',
      cdgoAdmon: '0071',
      nmroPrioridad: 953,
      descEstacion: null,
      desgEstacion: 'GIBRALEON',
      cdgoUic: '42020',
      clave: '0071,42020,42020',
      desgEstacionPlano: 'GIBRALEON',
      activo: true
    },
    {
      cdgoEstacion: '43005',
      cdgoAdmon: '0071',
      nmroPrioridad: 954,
      descEstacion: null,
      desgEstacion: 'BENACAZON',
      cdgoUic: '43005',
      clave: '0071,43005,43005',
      desgEstacionPlano: 'BENACAZON',
      activo: true
    },
    {
      cdgoEstacion: '43008',
      cdgoAdmon: '0071',
      nmroPrioridad: 955,
      descEstacion: null,
      desgEstacion: 'CARRION DE LOS CESPEDES',
      cdgoUic: '43008',
      clave: '0071,43008,43008',
      desgEstacionPlano: 'CARRION DE LOS CESPEDES',
      activo: true
    },
    {
      cdgoEstacion: '42008',
      cdgoAdmon: '0071',
      nmroPrioridad: 956,
      descEstacion: null,
      desgEstacion: 'JABUGO-GALAROZA',
      cdgoUic: '42008',
      clave: '0071,42008,42008',
      desgEstacionPlano: 'JABUGO-GALAROZA',
      activo: true
    },
    {
      cdgoEstacion: '42009',
      cdgoAdmon: '0071',
      nmroPrioridad: 957,
      descEstacion: null,
      desgEstacion: 'ALMONASTER-CORTEGANA',
      cdgoUic: '42009',
      clave: '0071,42009,42009',
      desgEstacionPlano: 'ALMONASTER-CORTEGANA',
      activo: true
    },
    {
      cdgoEstacion: '42010',
      cdgoAdmon: '0071',
      nmroPrioridad: 958,
      descEstacion: null,
      desgEstacion: 'GIL MARQUEZ',
      cdgoUic: '42010',
      clave: '0071,42010,42010',
      desgEstacionPlano: 'GIL MARQUEZ',
      activo: true
    },
    {
      cdgoEstacion: '42012',
      cdgoAdmon: '0071',
      nmroPrioridad: 959,
      descEstacion: null,
      desgEstacion: 'VALDELAMUSA',
      cdgoUic: '42012',
      clave: '0071,42012,42012',
      desgEstacionPlano: 'VALDELAMUSA',
      activo: true
    },
    {
      cdgoEstacion: '42002',
      cdgoAdmon: '0071',
      nmroPrioridad: 960,
      descEstacion: null,
      desgEstacion: 'MEDINA DE LAS TORRES',
      cdgoUic: '42002',
      clave: '0071,42002,42002',
      desgEstacionPlano: 'MEDINA DE LAS TORRES',
      activo: true
    },
    {
      cdgoEstacion: '42003',
      cdgoAdmon: '0071',
      nmroPrioridad: 961,
      descEstacion: null,
      desgEstacion: 'VALENCIA DEL VENTOSO',
      cdgoUic: '42003',
      clave: '0071,42003,42003',
      desgEstacionPlano: 'VALENCIA DEL VENTOSO',
      activo: true
    },
    {
      cdgoEstacion: '42006',
      cdgoAdmon: '0071',
      nmroPrioridad: 962,
      descEstacion: null,
      desgEstacion: 'CUMBRES MAYORES',
      cdgoUic: '42006',
      clave: '0071,42006,42006',
      desgEstacionPlano: 'CUMBRES MAYORES',
      activo: true
    },
    {
      cdgoEstacion: '37400',
      cdgoAdmon: '0071',
      nmroPrioridad: 963,
      descEstacion: null,
      desgEstacion: 'ALMORCHON',
      cdgoUic: '37400',
      clave: '0071,37400,37400',
      desgEstacionPlano: 'ALMORCHON',
      activo: true
    },
    {
      cdgoEstacion: '37600',
      cdgoAdmon: '0071',
      nmroPrioridad: 964,
      descEstacion: null,
      desgEstacion: 'ALJUCEN',
      cdgoUic: '37600',
      clave: '0071,37600,37600',
      desgEstacionPlano: 'ALJUCEN',
      activo: true
    },
    {
      cdgoEstacion: '37601',
      cdgoAdmon: '0071',
      nmroPrioridad: 965,
      descEstacion: null,
      desgEstacion: 'LA GARROVILLA',
      cdgoUic: '37601',
      clave: '0071,37601,37601',
      desgEstacionPlano: 'LA GARROVILLA',
      activo: true
    },
    {
      cdgoEstacion: '35402',
      cdgoAdmon: '0071',
      nmroPrioridad: 968,
      descEstacion: null,
      desgEstacion: 'ARROYO-MALPARTIDA',
      cdgoUic: '35402',
      clave: '0071,35402,35402',
      desgEstacionPlano: 'ARROYO-MALPARTIDA',
      activo: true
    },
    {
      cdgoEstacion: '35302',
      cdgoAdmon: '0071',
      nmroPrioridad: 969,
      descEstacion: null,
      desgEstacion: 'CASAS DE MILLAN',
      cdgoUic: '35302',
      clave: '0071,35302,35302',
      desgEstacionPlano: 'CASAS DE MILLAN',
      activo: true
    },
    {
      cdgoEstacion: '32004',
      cdgoAdmon: '0071',
      nmroPrioridad: 970,
      descEstacion: null,
      desgEstacion: 'CANTALAPIEDRA',
      cdgoUic: '32004',
      clave: '0071,32004,32004',
      desgEstacionPlano: 'CANTALAPIEDRA',
      activo: true
    },
    {
      cdgoEstacion: '32006',
      cdgoAdmon: '0071',
      nmroPrioridad: 971,
      descEstacion: null,
      desgEstacion: 'EL PEDROSO DE LA ARMUÑA',
      cdgoUic: '32006',
      clave: '0071,32006,32006',
      desgEstacionPlano: 'EL PEDROSO DE LA ARMUNA',
      activo: true
    },
    {
      cdgoEstacion: '32007',
      cdgoAdmon: '0071',
      nmroPrioridad: 972,
      descEstacion: null,
      desgEstacion: 'PITIEGUA',
      cdgoUic: '32007',
      clave: '0071,32007,32007',
      desgEstacionPlano: 'PITIEGUA',
      activo: true
    },
    {
      cdgoEstacion: '32008',
      cdgoAdmon: '0071',
      nmroPrioridad: 973,
      descEstacion: null,
      desgEstacion: 'GOMECELLO',
      cdgoUic: '32008',
      clave: '0071,32008,32008',
      desgEstacionPlano: 'GOMECELLO',
      activo: true
    },
    {
      cdgoEstacion: '32009',
      cdgoAdmon: '0071',
      nmroPrioridad: 974,
      descEstacion: null,
      desgEstacion: 'MORISCOS',
      cdgoUic: '32009',
      clave: '0071,32009,32009',
      desgEstacionPlano: 'MORISCOS',
      activo: true
    },
    {
      cdgoEstacion: '33008',
      cdgoAdmon: '0071',
      nmroPrioridad: 975,
      descEstacion: null,
      desgEstacion: 'FUENTE DE SAN ESTEBAN-BOADILLA',
      cdgoUic: '33008',
      clave: '0071,33008,33008',
      desgEstacionPlano: 'FUENTE DE SAN ESTEBAN-BOADILLA',
      activo: true
    },
    {
      cdgoEstacion: '33016',
      cdgoAdmon: '0071',
      nmroPrioridad: 976,
      descEstacion: null,
      desgEstacion: 'FUENTES DE OÑORO',
      cdgoUic: '33016',
      clave: '0071,33016,33016',
      desgEstacionPlano: 'FUENTES DE ONORO',
      activo: true
    },
    {
      cdgoEstacion: '32002',
      cdgoAdmon: '0071',
      nmroPrioridad: 977,
      descEstacion: null,
      desgEstacion: 'EL CARPIO',
      cdgoUic: '32002',
      clave: '0071,32002,32002',
      desgEstacionPlano: 'EL CARPIO',
      activo: true
    },
    {
      cdgoEstacion: '32003',
      cdgoAdmon: '0071',
      nmroPrioridad: 978,
      descEstacion: null,
      desgEstacion: 'FRESNO EL VIEJO',
      cdgoUic: '32003',
      clave: '0071,32003,32003',
      desgEstacionPlano: 'FRESNO EL VIEJO',
      activo: true
    },
    {
      cdgoEstacion: '32001',
      cdgoAdmon: '0071',
      nmroPrioridad: 979,
      descEstacion: null,
      desgEstacion: 'CAMPILLO',
      cdgoUic: '32001',
      clave: '0071,32001,32001',
      desgEstacionPlano: 'CAMPILLO',
      activo: true
    },
    {
      cdgoEstacion: '34002',
      cdgoAdmon: '0071',
      nmroPrioridad: 980,
      descEstacion: null,
      desgEstacion: 'CARDEÑOSA DE AVILA',
      cdgoUic: '34002',
      clave: '0071,34002,34002',
      desgEstacionPlano: 'CARDENOSA DE AVILA',
      activo: true
    },
    {
      cdgoEstacion: '34005',
      cdgoAdmon: '0071',
      nmroPrioridad: 981,
      descEstacion: null,
      desgEstacion: 'SAN PEDRO DEL ARROYO',
      cdgoUic: '34005',
      clave: '0071,34005,34005',
      desgEstacionPlano: 'SAN PEDRO DEL ARROYO',
      activo: true
    },
    {
      cdgoEstacion: '34007',
      cdgoAdmon: '0071',
      nmroPrioridad: 982,
      descEstacion: null,
      desgEstacion: 'CRESPOS',
      cdgoUic: '34007',
      clave: '0071,34007,34007',
      desgEstacionPlano: 'CRESPOS',
      activo: true
    },
    {
      cdgoEstacion: '34008',
      cdgoAdmon: '0071',
      nmroPrioridad: 983,
      descEstacion: null,
      desgEstacion: 'NARROS DEL CASTILLO',
      cdgoUic: '34008',
      clave: '0071,34008,34008',
      desgEstacionPlano: 'NARROS DEL CASTILLO',
      activo: true
    },
    {
      cdgoEstacion: '34012',
      cdgoAdmon: '0071',
      nmroPrioridad: 984,
      descEstacion: null,
      desgEstacion: 'BABILAFUENTE',
      cdgoUic: '34012',
      clave: '0071,34012,34012',
      desgEstacionPlano: 'BABILAFUENTE',
      activo: true
    },
    {
      cdgoEstacion: '34013',
      cdgoAdmon: '0071',
      nmroPrioridad: 985,
      descEstacion: null,
      desgEstacion: 'SAN MORALES',
      cdgoUic: '34013',
      clave: '0071,34013,34013',
      desgEstacionPlano: 'SAN MORALES',
      activo: true
    },
    {
      cdgoEstacion: '34011',
      cdgoAdmon: '0071',
      nmroPrioridad: 986,
      descEstacion: null,
      desgEstacion: 'VILLAR DE GALLIMAZO',
      cdgoUic: '34011',
      clave: '0071,34011,34011',
      desgEstacionPlano: 'VILLAR DE GALLIMAZO',
      activo: true
    },
    {
      cdgoEstacion: '34014',
      cdgoAdmon: '0071',
      nmroPrioridad: 987,
      descEstacion: null,
      desgEstacion: 'ALDEALENGUA',
      cdgoUic: '34014',
      clave: '0071,34014,34014',
      desgEstacionPlano: 'ALDEALENGUA',
      activo: true
    },
    {
      cdgoEstacion: '35107',
      cdgoAdmon: '0071',
      nmroPrioridad: 988,
      descEstacion: null,
      desgEstacion: 'ERUSTES',
      cdgoUic: '35107',
      clave: '0071,35107,35107',
      desgEstacionPlano: 'ERUSTES',
      activo: true
    },
    {
      cdgoEstacion: '14229',
      cdgoAdmon: '0071',
      nmroPrioridad: 989,
      descEstacion: null,
      desgEstacion: 'ZURITA',
      cdgoUic: '14229',
      clave: '0071,14229,14229',
      desgEstacionPlano: 'ZURITA',
      activo: true
    },
    {
      cdgoEstacion: '14230',
      cdgoAdmon: '0071',
      nmroPrioridad: 990,
      descEstacion: null,
      desgEstacion: 'VALDECILLA',
      cdgoUic: '14230',
      clave: '0071,14230,14230',
      desgEstacionPlano: 'VALDECILLA',
      activo: true
    },
    {
      cdgoEstacion: '14231',
      cdgoAdmon: '0071',
      nmroPrioridad: 991,
      descEstacion: null,
      desgEstacion: 'NUEVA MONTAÑA',
      cdgoUic: '14231',
      clave: '0071,14231,14231',
      desgEstacionPlano: 'NUEVA MONTANA',
      activo: true
    },
    {
      cdgoEstacion: '14232',
      cdgoAdmon: '0071',
      nmroPrioridad: 992,
      descEstacion: null,
      desgEstacion: 'LOMBERA',
      cdgoUic: '14232',
      clave: '0071,14232,14232',
      desgEstacionPlano: 'LOMBERA',
      activo: true
    },
    {
      cdgoEstacion: '14233',
      cdgoAdmon: '0071',
      nmroPrioridad: 993,
      descEstacion: null,
      desgEstacion: 'RIO EBRO',
      cdgoUic: '14233',
      clave: '0071,14233,14233',
      desgEstacionPlano: 'RIO EBRO',
      activo: true
    },
    {
      cdgoEstacion: '14234',
      cdgoAdmon: '0071',
      nmroPrioridad: 994,
      descEstacion: null,
      desgEstacion: 'PUJAYO',
      cdgoUic: '14234',
      clave: '0071,14234,14234',
      desgEstacionPlano: 'PUJAYO',
      activo: true
    },
    {
      cdgoEstacion: '14235',
      cdgoAdmon: '0071',
      nmroPrioridad: 995,
      descEstacion: null,
      desgEstacion: 'ARENAS DE IGUÑA',
      cdgoUic: '14235',
      clave: '0071,14235,14235',
      desgEstacionPlano: 'ARENAS DE IGUNA',
      activo: true
    },
    {
      cdgoEstacion: '14236',
      cdgoAdmon: '0071',
      nmroPrioridad: 996,
      descEstacion: null,
      desgEstacion: 'VIOÑO',
      cdgoUic: '14236',
      clave: '0071,14236,14236',
      desgEstacionPlano: 'VIONO',
      activo: true
    },
    {
      cdgoEstacion: '14214',
      cdgoAdmon: '0071',
      nmroPrioridad: 997,
      descEstacion: null,
      desgEstacion: 'SIERRAPANDO',
      cdgoUic: '14214',
      clave: '0071,14214,14214',
      desgEstacionPlano: 'SIERRAPANDO',
      activo: true
    },
    {
      cdgoEstacion: '14216',
      cdgoAdmon: '0071',
      nmroPrioridad: 998,
      descEstacion: null,
      desgEstacion: 'RENEDO',
      cdgoUic: '14216',
      clave: '0071,14216,14216',
      desgEstacionPlano: 'RENEDO',
      activo: true
    },
    {
      cdgoEstacion: '14217',
      cdgoAdmon: '0071',
      nmroPrioridad: 999,
      descEstacion: null,
      desgEstacion: 'PARBAYON',
      cdgoUic: '14217',
      clave: '0071,14217,14217',
      desgEstacionPlano: 'PARBAYON',
      activo: true
    },
    {
      cdgoEstacion: '14218',
      cdgoAdmon: '0071',
      nmroPrioridad: 1000,
      descEstacion: null,
      desgEstacion: 'GUARNIZO',
      cdgoUic: '14218',
      clave: '0071,14218,14218',
      desgEstacionPlano: 'GUARNIZO',
      activo: true
    },
    {
      cdgoEstacion: '14219',
      cdgoAdmon: '0071',
      nmroPrioridad: 1001,
      descEstacion: null,
      desgEstacion: 'BOO',
      cdgoUic: '14219',
      clave: '0071,14219,14219',
      desgEstacionPlano: 'BOO',
      activo: true
    },
    {
      cdgoEstacion: '14220',
      cdgoAdmon: '0071',
      nmroPrioridad: 1002,
      descEstacion: null,
      desgEstacion: 'MALIAÑO',
      cdgoUic: '14220',
      clave: '0071,14220,14220',
      desgEstacionPlano: 'MALIANO',
      activo: true
    },
    {
      cdgoEstacion: '14221',
      cdgoAdmon: '0071',
      nmroPrioridad: 1003,
      descEstacion: null,
      desgEstacion: 'MURIEDAS',
      cdgoUic: '14221',
      clave: '0071,14221,14221',
      desgEstacionPlano: 'MURIEDAS',
      activo: true
    },
    {
      cdgoEstacion: '14206',
      cdgoAdmon: '0071',
      nmroPrioridad: 1004,
      descEstacion: null,
      desgEstacion: 'BARCENA',
      cdgoUic: '14206',
      clave: '0071,14206,14206',
      desgEstacionPlano: 'BARCENA',
      activo: true
    },
    {
      cdgoEstacion: '14207',
      cdgoAdmon: '0071',
      nmroPrioridad: 1005,
      descEstacion: null,
      desgEstacion: 'MOLLEDO-PORTOLIN',
      cdgoUic: '14207',
      clave: '0071,14207,14207',
      desgEstacionPlano: 'MOLLEDO-PORTOLIN',
      activo: true
    },
    {
      cdgoEstacion: '14208',
      cdgoAdmon: '0071',
      nmroPrioridad: 1006,
      descEstacion: null,
      desgEstacion: 'SANTA CRUZ DE IGUÑA',
      cdgoUic: '14208',
      clave: '0071,14208,14208',
      desgEstacionPlano: 'SANTA CRUZ DE IGUNA',
      activo: true
    },
    {
      cdgoEstacion: '14209',
      cdgoAdmon: '0071',
      nmroPrioridad: 1007,
      descEstacion: null,
      desgEstacion: 'LAS FRAGUAS',
      cdgoUic: '14209',
      clave: '0071,14209,14209',
      desgEstacionPlano: 'LAS FRAGUAS',
      activo: true
    },
    {
      cdgoEstacion: '14212',
      cdgoAdmon: '0071',
      nmroPrioridad: 1008,
      descEstacion: null,
      desgEstacion: 'VIERNOLES',
      cdgoUic: '14212',
      clave: '0071,14212,14212',
      desgEstacionPlano: 'VIERNOLES',
      activo: true
    },
    {
      cdgoEstacion: '14211',
      cdgoAdmon: '0071',
      nmroPrioridad: 1009,
      descEstacion: null,
      desgEstacion: 'LAS CALDAS DE BESAYA',
      cdgoUic: '14211',
      clave: '0071,14211,14211',
      desgEstacionPlano: 'LAS CALDAS DE BESAYA',
      activo: true
    },
    {
      cdgoEstacion: '14116',
      cdgoAdmon: '0071',
      nmroPrioridad: 1010,
      descEstacion: null,
      desgEstacion: 'EL CARRION (APD)',
      cdgoUic: '14116',
      clave: '0071,14116,14116',
      desgEstacionPlano: 'EL CARRION (APD)',
      activo: true
    },
    {
      cdgoEstacion: '14117',
      cdgoAdmon: '0071',
      nmroPrioridad: 1011,
      descEstacion: null,
      desgEstacion: 'EL CARRION',
      cdgoUic: '14117',
      clave: '0071,14117,14117',
      desgEstacionPlano: 'EL CARRION',
      activo: true
    },
    {
      cdgoEstacion: '14115',
      cdgoAdmon: '0071',
      nmroPrioridad: 1012,
      descEstacion: null,
      desgEstacion: 'QUINTANILLA DE LAS TORRES',
      cdgoUic: '14115',
      clave: '0071,14115,14115',
      desgEstacionPlano: 'QUINTANILLA DE LAS TORRES',
      activo: true
    },
    {
      cdgoEstacion: '14203',
      cdgoAdmon: '0071',
      nmroPrioridad: 1013,
      descEstacion: null,
      desgEstacion: 'LANTUENO-SANTIURDE',
      cdgoUic: '14203',
      clave: '0071,14203,14203',
      desgEstacionPlano: 'LANTUENO-SANTIURDE',
      activo: true
    },
    {
      cdgoEstacion: '14204',
      cdgoAdmon: '0071',
      nmroPrioridad: 1014,
      descEstacion: null,
      desgEstacion: 'PESQUERA',
      cdgoUic: '14204',
      clave: '0071,14204,14204',
      desgEstacionPlano: 'PESQUERA',
      activo: true
    },
    {
      cdgoEstacion: '12001',
      cdgoAdmon: '0071',
      nmroPrioridad: 1015,
      descEstacion: null,
      desgEstacion: 'LOS NEGRALES',
      cdgoUic: '12001',
      clave: '0071,12001,12001',
      desgEstacionPlano: 'LOS NEGRALES',
      activo: true
    },
    {
      cdgoEstacion: '12002',
      cdgoAdmon: '0071',
      nmroPrioridad: 1016,
      descEstacion: null,
      desgEstacion: 'ALPEDRETE-MATAESPESA',
      cdgoUic: '12002',
      clave: '0071,12002,12002',
      desgEstacionPlano: 'ALPEDRETE-MATAESPESA',
      activo: true
    },
    {
      cdgoEstacion: '12004',
      cdgoAdmon: '0071',
      nmroPrioridad: 1017,
      descEstacion: null,
      desgEstacion: 'COLLADO MEDIANO',
      cdgoUic: '12004',
      clave: '0071,12004,12004',
      desgEstacionPlano: 'COLLADO MEDIANO',
      activo: true
    },
    {
      cdgoEstacion: '12005',
      cdgoAdmon: '0071',
      nmroPrioridad: 1018,
      descEstacion: null,
      desgEstacion: 'LOS MOLINOS-GUADARRAMA',
      cdgoUic: '12005',
      clave: '0071,12005,12005',
      desgEstacionPlano: 'LOS MOLINOS-GUADARRAMA',
      activo: true
    },
    {
      cdgoEstacion: '12007',
      cdgoAdmon: '0071',
      nmroPrioridad: 1019,
      descEstacion: null,
      desgEstacion: 'TABLADA',
      cdgoUic: '12007',
      clave: '0071,12007,12007',
      desgEstacionPlano: 'TABLADA',
      activo: true
    },
    {
      cdgoEstacion: '12014',
      cdgoAdmon: '0071',
      nmroPrioridad: 1020,
      descEstacion: null,
      desgEstacion: 'NAVAS DE RIOFRIO- LA LOSA',
      cdgoUic: '12014',
      clave: '0071,12014,12014',
      desgEstacionPlano: 'NAVAS DE RIOFRIO- LA LOSA',
      activo: true
    },
    {
      cdgoEstacion: '12013',
      cdgoAdmon: '0071',
      nmroPrioridad: 1021,
      descEstacion: null,
      desgEstacion: 'ORTIGOSA DEL MONTE',
      cdgoUic: '12013',
      clave: '0071,12013,12013',
      desgEstacionPlano: 'ORTIGOSA DEL MONTE',
      activo: true
    },
    {
      cdgoEstacion: '12008',
      cdgoAdmon: '0071',
      nmroPrioridad: 1022,
      descEstacion: null,
      desgEstacion: 'GUDILLOS',
      cdgoUic: '12008',
      clave: '0071,12008,12008',
      desgEstacionPlano: 'GUDILLOS',
      activo: true
    },
    {
      cdgoEstacion: '12009',
      cdgoAdmon: '0071',
      nmroPrioridad: 1023,
      descEstacion: null,
      desgEstacion: 'SAN RAFAEL',
      cdgoUic: '12009',
      clave: '0071,12009,12009',
      desgEstacionPlano: 'SAN RAFAEL',
      activo: true
    },
    {
      cdgoEstacion: '14101',
      cdgoAdmon: '0071',
      nmroPrioridad: 1024,
      descEstacion: null,
      desgEstacion: 'MONZON DE CAMPOS',
      cdgoUic: '14101',
      clave: '0071,14101,14101',
      desgEstacionPlano: 'MONZON DE CAMPOS',
      activo: true
    },
    {
      cdgoEstacion: '14102',
      cdgoAdmon: '0071',
      nmroPrioridad: 1025,
      descEstacion: null,
      desgEstacion: 'AMUSCO',
      cdgoUic: '14102',
      clave: '0071,14102,14102',
      desgEstacionPlano: 'AMUSCO',
      activo: true
    },
    {
      cdgoEstacion: '14108',
      cdgoAdmon: '0071',
      nmroPrioridad: 1029,
      descEstacion: null,
      desgEstacion: 'ESPINOSA DE VILLAGONZALO (APD)',
      cdgoUic: '14108',
      clave: '0071,14108,14108',
      desgEstacionPlano: 'ESPINOSA DE VILLAGONZALO (APD)',
      activo: true
    },
    {
      cdgoEstacion: '14113',
      cdgoAdmon: '0071',
      nmroPrioridad: 1030,
      descEstacion: null,
      desgEstacion: 'MAVE',
      cdgoUic: '14113',
      clave: '0071,14113,14113',
      desgEstacionPlano: 'MAVE',
      activo: true
    },
    {
      cdgoEstacion: '14103',
      cdgoAdmon: '0071',
      nmroPrioridad: 1032,
      descEstacion: null,
      desgEstacion: 'PIÑA',
      cdgoUic: '14103',
      clave: '0071,14103,14103',
      desgEstacionPlano: 'PINA',
      activo: true
    },
    {
      cdgoEstacion: '15300',
      cdgoAdmon: '0071',
      nmroPrioridad: 1033,
      descEstacion: null,
      desgEstacion: 'LUGO DE LLANERA',
      cdgoUic: '15300',
      clave: '0071,15300,15300',
      desgEstacionPlano: 'LUGO DE LLANERA',
      activo: true
    },
    {
      cdgoEstacion: '15301',
      cdgoAdmon: '0071',
      nmroPrioridad: 1034,
      descEstacion: null,
      desgEstacion: 'VILLABONA DE ASTURIAS',
      cdgoUic: '15301',
      clave: '0071,15301,15301',
      desgEstacionPlano: 'VILLABONA DE ASTURIAS',
      activo: true
    },
    {
      cdgoEstacion: '15302',
      cdgoAdmon: '0071',
      nmroPrioridad: 1035,
      descEstacion: null,
      desgEstacion: 'SERIN',
      cdgoUic: '15302',
      clave: '0071,15302,15302',
      desgEstacionPlano: 'SERIN',
      activo: true
    },
    {
      cdgoEstacion: '15303',
      cdgoAdmon: '0071',
      nmroPrioridad: 1036,
      descEstacion: null,
      desgEstacion: 'MONTEANA',
      cdgoUic: '15303',
      clave: '0071,15303,15303',
      desgEstacionPlano: 'MONTEANA',
      activo: true
    },
    {
      cdgoEstacion: '15305',
      cdgoAdmon: '0071',
      nmroPrioridad: 1037,
      descEstacion: null,
      desgEstacion: 'VILLABONA TABLADIELLO',
      cdgoUic: '15305',
      clave: '0071,15305,15305',
      desgEstacionPlano: 'VILLABONA TABLADIELLO',
      activo: true
    },
    {
      cdgoEstacion: '15400',
      cdgoAdmon: '0071',
      nmroPrioridad: 1038,
      descEstacion: null,
      desgEstacion: 'VERIÑA',
      cdgoUic: '15400',
      clave: '0071,15400,15400',
      desgEstacionPlano: 'VERINA',
      activo: true
    },
    {
      cdgoEstacion: '15401',
      cdgoAdmon: '0071',
      nmroPrioridad: 1039,
      descEstacion: null,
      desgEstacion: 'CALZADA DE ASTURIAS',
      cdgoUic: '15401',
      clave: '0071,15401,15401',
      desgEstacionPlano: 'CALZADA DE ASTURIAS',
      activo: true
    },
    {
      cdgoEstacion: '15207',
      cdgoAdmon: '0071',
      nmroPrioridad: 1041,
      descEstacion: null,
      desgEstacion: 'OLLONIEGO',
      cdgoUic: '15207',
      clave: '0071,15207,15207',
      desgEstacionPlano: 'OLLONIEGO',
      activo: true
    },
    {
      cdgoEstacion: '15208',
      cdgoAdmon: '0071',
      nmroPrioridad: 1042,
      descEstacion: null,
      desgEstacion: 'SOTO DE REY',
      cdgoUic: '15208',
      clave: '0071,15208,15208',
      desgEstacionPlano: 'SOTO DE REY',
      activo: true
    },
    {
      cdgoEstacion: '15209',
      cdgoAdmon: '0071',
      nmroPrioridad: 1043,
      descEstacion: null,
      desgEstacion: 'LAS SEGADAS',
      cdgoUic: '15209',
      clave: '0071,15209,15209',
      desgEstacionPlano: 'LAS SEGADAS',
      activo: true
    },
    {
      cdgoEstacion: '15210',
      cdgoAdmon: '0071',
      nmroPrioridad: 1044,
      descEstacion: null,
      desgEstacion: 'EL CALEYO',
      cdgoUic: '15210',
      clave: '0071,15210,15210',
      desgEstacionPlano: 'EL CALEYO',
      activo: true
    },
    {
      cdgoEstacion: '15217',
      cdgoAdmon: '0071',
      nmroPrioridad: 1045,
      descEstacion: null,
      desgEstacion: 'LA CORREDORIA',
      cdgoUic: '15217',
      clave: '0071,15217,15217',
      desgEstacionPlano: 'LA CORREDORIA',
      activo: true
    },
    {
      cdgoEstacion: '15218',
      cdgoAdmon: '0071',
      nmroPrioridad: 1046,
      descEstacion: null,
      desgEstacion: 'OVIEDO-LLAMAQUIQUE',
      cdgoUic: '15218',
      clave: '0071,15218,15218',
      desgEstacionPlano: 'OVIEDO-LLAMAQUIQUE',
      activo: true
    },
    {
      cdgoEstacion: '15212',
      cdgoAdmon: '0071',
      nmroPrioridad: 1047,
      descEstacion: null,
      desgEstacion: 'LUGONES',
      cdgoUic: '15212',
      clave: '0071,15212,15212',
      desgEstacionPlano: 'LUGONES',
      activo: true
    },
    {
      cdgoEstacion: '17200',
      cdgoAdmon: '0071',
      nmroPrioridad: 1048,
      descEstacion: null,
      desgEstacion: 'ARANDA DE DUERO-MONTECILLO',
      cdgoUic: '17200',
      clave: '0071,17200,17200',
      desgEstacionPlano: 'ARANDA DE DUERO-MONTECILLO',
      activo: true
    },
    {
      cdgoEstacion: '18001',
      cdgoAdmon: '0071',
      nmroPrioridad: 1050,
      descEstacion: null,
      desgEstacion: 'MADRID-RECOLETOS',
      cdgoUic: '18001',
      clave: '0071,18001,18001',
      desgEstacionPlano: 'MADRID-RECOLETOS',
      activo: true
    },
    {
      cdgoEstacion: '18003',
      cdgoAdmon: '0071',
      nmroPrioridad: 1051,
      descEstacion: null,
      desgEstacion: 'M.ALVARO-P',
      cdgoUic: '18003',
      clave: '0071,18003,18003',
      desgEstacionPlano: 'M.ALVARO-P',
      activo: true
    },
    {
      cdgoEstacion: '18004',
      cdgoAdmon: '0071',
      nmroPrioridad: 1052,
      descEstacion: null,
      desgEstacion: 'DELICIAS',
      cdgoUic: null,
      clave: '0071,18004,null',
      desgEstacionPlano: 'DELICIAS',
      activo: true
    },
    {
      cdgoEstacion: '18005',
      cdgoAdmon: '0071',
      nmroPrioridad: 1053,
      descEstacion: null,
      desgEstacion: 'PIRAMIDES',
      cdgoUic: '18005',
      clave: '0071,18005,18005',
      desgEstacionPlano: 'PIRAMIDES',
      activo: true
    },
    {
      cdgoEstacion: '20002',
      cdgoAdmon: '0071',
      nmroPrioridad: 1055,
      descEstacion: null,
      desgEstacion: 'QUINTANA-RANEROS',
      cdgoUic: '20002',
      clave: '0071,20002,20002',
      desgEstacionPlano: 'QUINTANA-RANEROS',
      activo: true
    },
    {
      cdgoEstacion: '20006',
      cdgoAdmon: '0071',
      nmroPrioridad: 1056,
      descEstacion: null,
      desgEstacion: 'BARRIENTOS',
      cdgoUic: '20006',
      clave: '0071,20006,20006',
      desgEstacionPlano: 'BARRIENTOS',
      activo: true
    },
    {
      cdgoEstacion: '20007',
      cdgoAdmon: '0071',
      nmroPrioridad: 1057,
      descEstacion: null,
      desgEstacion: 'NISTAL',
      cdgoUic: '20007',
      clave: '0071,20007,20007',
      desgEstacionPlano: 'NISTAL',
      activo: true
    },
    {
      cdgoEstacion: '20003',
      cdgoAdmon: '0071',
      nmroPrioridad: 1058,
      descEstacion: null,
      desgEstacion: 'VILLADANGOS',
      cdgoUic: '20003',
      clave: '0071,20003,20003',
      desgEstacionPlano: 'VILLADANGOS',
      activo: true
    },
    {
      cdgoEstacion: '15109',
      cdgoAdmon: '0071',
      nmroPrioridad: 1059,
      descEstacion: null,
      desgEstacion: 'SANTA LUCIA',
      cdgoUic: '15109',
      clave: '0071,15109,15109',
      desgEstacionPlano: 'SANTA LUCIA',
      activo: true
    },
    {
      cdgoEstacion: '15110',
      cdgoAdmon: '0071',
      nmroPrioridad: 1060,
      descEstacion: null,
      desgEstacion: 'CIÑERA',
      cdgoUic: '15110',
      clave: '0071,15110,15110',
      desgEstacionPlano: 'CINERA',
      activo: true
    },
    {
      cdgoEstacion: '15112',
      cdgoAdmon: '0071',
      nmroPrioridad: 1061,
      descEstacion: null,
      desgEstacion: 'VILLAMANIN',
      cdgoUic: '15112',
      clave: '0071,15112,15112',
      desgEstacionPlano: 'VILLAMANIN',
      activo: true
    },
    {
      cdgoEstacion: '15113',
      cdgoAdmon: '0071',
      nmroPrioridad: 1062,
      descEstacion: null,
      desgEstacion: 'BUSDONGO',
      cdgoUic: '15113',
      clave: '0071,15113,15113',
      desgEstacionPlano: 'BUSDONGO',
      activo: true
    },
    {
      cdgoEstacion: '15116',
      cdgoAdmon: '0071',
      nmroPrioridad: 1063,
      descEstacion: null,
      desgEstacion: 'LINARES-CONGOSTINAS',
      cdgoUic: '15116',
      clave: '0071,15116,15116',
      desgEstacionPlano: 'LINARES-CONGOSTINAS',
      activo: true
    },
    {
      cdgoEstacion: '15118',
      cdgoAdmon: '0071',
      nmroPrioridad: 1064,
      descEstacion: null,
      desgEstacion: 'PUENTE DE LOS FIERROS',
      cdgoUic: '15118',
      clave: '0071,15118,15118',
      desgEstacionPlano: 'PUENTE DE LOS FIERROS',
      activo: true
    },
    {
      cdgoEstacion: '15119',
      cdgoAdmon: '0071',
      nmroPrioridad: 1065,
      descEstacion: null,
      desgEstacion: 'LA FRECHA',
      cdgoUic: '15119',
      clave: '0071,15119,15119',
      desgEstacionPlano: 'LA FRECHA',
      activo: true
    },
    {
      cdgoEstacion: '15120',
      cdgoAdmon: '0071',
      nmroPrioridad: 1066,
      descEstacion: null,
      desgEstacion: 'CAMPOMANES',
      cdgoUic: '15120',
      clave: '0071,15120,15120',
      desgEstacionPlano: 'CAMPOMANES',
      activo: true
    },
    {
      cdgoEstacion: '15205',
      cdgoAdmon: '0071',
      nmroPrioridad: 1067,
      descEstacion: null,
      desgEstacion: 'ABLAÑA',
      cdgoUic: '15205',
      clave: '0071,15205,15205',
      desgEstacionPlano: 'ABLANA',
      activo: true
    },
    {
      cdgoEstacion: '15206',
      cdgoAdmon: '0071',
      nmroPrioridad: 1068,
      descEstacion: null,
      desgEstacion: 'LA PEREDA-RIOSA',
      cdgoUic: '15206',
      clave: '0071,15206,15206',
      desgEstacionPlano: 'LA PEREDA-RIOSA',
      activo: true
    },
    {
      cdgoEstacion: '15202',
      cdgoAdmon: '0071',
      nmroPrioridad: 1069,
      descEstacion: null,
      desgEstacion: 'SANTULLANO',
      cdgoUic: '15202',
      clave: '0071,15202,15202',
      desgEstacionPlano: 'SANTULLANO',
      activo: true
    },
    {
      cdgoEstacion: '15123',
      cdgoAdmon: '0071',
      nmroPrioridad: 1070,
      descEstacion: null,
      desgEstacion: 'VILLALLANA',
      cdgoUic: '15123',
      clave: '0071,15123,15123',
      desgEstacionPlano: 'VILLALLANA',
      activo: true
    },
    {
      cdgoEstacion: '15200',
      cdgoAdmon: '0071',
      nmroPrioridad: 1071,
      descEstacion: null,
      desgEstacion: 'UJO',
      cdgoUic: '15200',
      clave: '0071,15200,15200',
      desgEstacionPlano: 'UJO',
      activo: true
    },
    {
      cdgoEstacion: '15121',
      cdgoAdmon: '0071',
      nmroPrioridad: 1072,
      descEstacion: null,
      desgEstacion: 'LA COBERTORIA',
      cdgoUic: '15121',
      clave: '0071,15121,15121',
      desgEstacionPlano: 'LA COBERTORIA',
      activo: true
    },
    {
      cdgoEstacion: '15012',
      cdgoAdmon: '0071',
      nmroPrioridad: 1073,
      descEstacion: null,
      desgEstacion: 'EL BURGO RANERO',
      cdgoUic: '15012',
      clave: '0071,15012,15012',
      desgEstacionPlano: 'EL BURGO RANERO',
      activo: true
    },
    {
      cdgoEstacion: '15014',
      cdgoAdmon: '0071',
      nmroPrioridad: 1074,
      descEstacion: null,
      desgEstacion: 'SANTAS MARTAS',
      cdgoUic: '15014',
      clave: '0071,15014,15014',
      desgEstacionPlano: 'SANTAS MARTAS',
      activo: true
    },
    {
      cdgoEstacion: '15015',
      cdgoAdmon: '0071',
      nmroPrioridad: 1075,
      descEstacion: null,
      desgEstacion: 'PALANQUINOS',
      cdgoUic: '15015',
      clave: '0071,15015,15015',
      desgEstacionPlano: 'PALANQUINOS',
      activo: true
    },
    {
      cdgoEstacion: '15104',
      cdgoAdmon: '0071',
      nmroPrioridad: 1077,
      descEstacion: null,
      desgEstacion: 'CUADROS',
      cdgoUic: '15104',
      clave: '0071,15104,15104',
      desgEstacionPlano: 'CUADROS',
      activo: true
    },
    {
      cdgoEstacion: '15108',
      cdgoAdmon: '0071',
      nmroPrioridad: 1078,
      descEstacion: null,
      desgEstacion: 'LA POLA DE GORDON',
      cdgoUic: '15108',
      clave: '0071,15108,15108',
      desgEstacionPlano: 'LA POLA DE GORDON',
      activo: true
    },
    {
      cdgoEstacion: '14237',
      cdgoAdmon: '0071',
      nmroPrioridad: 1079,
      descEstacion: null,
      desgEstacion: 'MURIEDAS-BAHIA',
      cdgoUic: '14237',
      clave: '0071,14237,14237',
      desgEstacionPlano: 'MURIEDAS-BAHIA',
      activo: true
    },
    {
      cdgoEstacion: '15001',
      cdgoAdmon: '0071',
      nmroPrioridad: 1080,
      descEstacion: null,
      desgEstacion: 'GRIJOTA',
      cdgoUic: '15001',
      clave: '0071,15001,15001',
      desgEstacionPlano: 'GRIJOTA',
      activo: true
    },
    {
      cdgoEstacion: '15003',
      cdgoAdmon: '0071',
      nmroPrioridad: 1081,
      descEstacion: null,
      desgEstacion: 'BECERRIL',
      cdgoUic: '15003',
      clave: '0071,15003,15003',
      desgEstacionPlano: 'BECERRIL',
      activo: true
    },
    {
      cdgoEstacion: '15004',
      cdgoAdmon: '0071',
      nmroPrioridad: 1082,
      descEstacion: null,
      desgEstacion: 'PAREDES DE NAVA',
      cdgoUic: '15004',
      clave: '0071,15004,15004',
      desgEstacionPlano: 'PAREDES DE NAVA',
      activo: true
    },
    {
      cdgoEstacion: '15008',
      cdgoAdmon: '0071',
      nmroPrioridad: 1083,
      descEstacion: null,
      desgEstacion: 'GRAJAL',
      cdgoUic: '15008',
      clave: '0071,15008,15008',
      desgEstacionPlano: 'GRAJAL',
      activo: true
    },
    {
      cdgoEstacion: '15006',
      cdgoAdmon: '0071',
      nmroPrioridad: 1084,
      descEstacion: null,
      desgEstacion: 'CISNEROS',
      cdgoUic: '15006',
      clave: '0071,15006,15006',
      desgEstacionPlano: 'CISNEROS',
      activo: true
    },
    {
      cdgoEstacion: '31203',
      cdgoAdmon: '0071',
      nmroPrioridad: 1085,
      descEstacion: null,
      desgEstacion: 'LUBIAN',
      cdgoUic: '31203',
      clave: '0071,31203,31203',
      desgEstacionPlano: 'LUBIAN',
      activo: true
    },
    {
      cdgoEstacion: '31204',
      cdgoAdmon: '0071',
      nmroPrioridad: 1086,
      descEstacion: null,
      desgEstacion: 'A MEZQUITA-VILAVELLA',
      cdgoUic: '31204',
      clave: '0071,31204,31204',
      desgEstacionPlano: 'A MEZQUITA-VILAVELLA',
      activo: true
    },
    {
      cdgoEstacion: '31206',
      cdgoAdmon: '0071',
      nmroPrioridad: 1087,
      descEstacion: null,
      desgEstacion: 'VILARIÑO DE CONSO.-A CAPELA',
      cdgoUic: '31206',
      clave: '0071,31206,31206',
      desgEstacionPlano: 'VILARINO DE CONSO.-A CAPELA',
      activo: true
    },
    {
      cdgoEstacion: '31109',
      cdgoAdmon: '0071',
      nmroPrioridad: 1088,
      descEstacion: null,
      desgEstacion: 'CABAÑAS DE ALISTE',
      cdgoUic: '31109',
      clave: '0071,31109,31109',
      desgEstacionPlano: 'CABANAS DE ALISTE',
      activo: true
    },
    {
      cdgoEstacion: '31112',
      cdgoAdmon: '0071',
      nmroPrioridad: 1089,
      descEstacion: null,
      desgEstacion: 'LINAREJOS-PEDROSO',
      cdgoUic: '31112',
      clave: '0071,31112,31112',
      desgEstacionPlano: 'LINAREJOS-PEDROSO',
      activo: true
    },
    {
      cdgoEstacion: '31201',
      cdgoAdmon: '0071',
      nmroPrioridad: 1090,
      descEstacion: null,
      desgEstacion: 'PEDRALBA',
      cdgoUic: '31201',
      clave: '0071,31201,31201',
      desgEstacionPlano: 'PEDRALBA',
      activo: true
    },
    {
      cdgoEstacion: '31104',
      cdgoAdmon: '0071',
      nmroPrioridad: 1091,
      descEstacion: null,
      desgEstacion: 'CARBAJALES DE ALBA',
      cdgoUic: '31104',
      clave: '0071,31104,31104',
      desgEstacionPlano: 'CARBAJALES DE ALBA',
      activo: true
    },
    {
      cdgoEstacion: '31106',
      cdgoAdmon: '0071',
      nmroPrioridad: 1092,
      descEstacion: null,
      desgEstacion: 'FERRERUELA DE TABARA',
      cdgoUic: '31106',
      clave: '0071,31106,31106',
      desgEstacionPlano: 'FERRERUELA DE TABARA',
      activo: true
    },
    {
      cdgoEstacion: '31107',
      cdgoAdmon: '0071',
      nmroPrioridad: 1093,
      descEstacion: null,
      desgEstacion: 'ABEJERA',
      cdgoUic: '31107',
      clave: '0071,31107,31107',
      desgEstacionPlano: 'ABEJERA',
      activo: true
    },
    {
      cdgoEstacion: '31108',
      cdgoAdmon: '0071',
      nmroPrioridad: 1094,
      descEstacion: null,
      desgEstacion: 'SARRACIN DE ALISTE',
      cdgoUic: '31108',
      clave: '0071,31108,31108',
      desgEstacionPlano: 'SARRACIN DE ALISTE',
      activo: true
    },
    {
      cdgoEstacion: '31002',
      cdgoAdmon: '0071',
      nmroPrioridad: 1095,
      descEstacion: null,
      desgEstacion: 'NAVA DEL REY',
      cdgoUic: '31002',
      clave: '0071,31002,31002',
      desgEstacionPlano: 'NAVA DEL REY',
      activo: true
    },
    {
      cdgoEstacion: '31006',
      cdgoAdmon: '0071',
      nmroPrioridad: 1096,
      descEstacion: null,
      desgEstacion: 'TORO',
      cdgoUic: '31006',
      clave: '0071,31006,31006',
      desgEstacionPlano: 'TORO',
      activo: true
    },
    {
      cdgoEstacion: '31207',
      cdgoAdmon: '0071',
      nmroPrioridad: 1097,
      descEstacion: null,
      desgEstacion: 'CASTRELO DO VAL-VERIN-CAMPOBECERROS',
      cdgoUic: '31207',
      clave: '0071,31207,31207',
      desgEstacionPlano: 'CASTRELO DO VAL-VERIN-CAMPOBECERROS',
      activo: true
    },
    {
      cdgoEstacion: '31208',
      cdgoAdmon: '0071',
      nmroPrioridad: 1098,
      descEstacion: null,
      desgEstacion: 'LAZA-CERDEDELO',
      cdgoUic: '31208',
      clave: '0071,31208,31208',
      desgEstacionPlano: 'LAZA-CERDEDELO',
      activo: true
    },
    {
      cdgoEstacion: '31209',
      cdgoAdmon: '0071',
      nmroPrioridad: 1099,
      descEstacion: null,
      desgEstacion: 'A ALBERGUERIA-PRADO',
      cdgoUic: '31209',
      clave: '0071,31209,31209',
      desgEstacionPlano: 'A ALBERGUERIA-PRADO',
      activo: true
    },
    {
      cdgoEstacion: '31210',
      cdgoAdmon: '0071',
      nmroPrioridad: 1100,
      descEstacion: null,
      desgEstacion: 'VILAR DE BARRIO/VILLAR DE BARRIO',
      cdgoUic: '31210',
      clave: '0071,31210,31210',
      desgEstacionPlano: 'VILAR DE BARRIO/VILLAR DE BARRIO',
      activo: true
    },
    {
      cdgoEstacion: '31211',
      cdgoAdmon: '0071',
      nmroPrioridad: 1101,
      descEstacion: null,
      desgEstacion: 'BAÑOS DE MOLGAS',
      cdgoUic: '31211',
      clave: '0071,31211,31211',
      desgEstacionPlano: 'BANOS DE MOLGAS',
      activo: true
    },
    {
      cdgoEstacion: '31212',
      cdgoAdmon: '0071',
      nmroPrioridad: 1102,
      descEstacion: null,
      desgEstacion: 'PONTEAMBIA',
      cdgoUic: '31212',
      clave: '0071,31212,31212',
      desgEstacionPlano: 'PONTEAMBIA',
      activo: true
    },
    {
      cdgoEstacion: '31213',
      cdgoAdmon: '0071',
      nmroPrioridad: 1103,
      descEstacion: null,
      desgEstacion: 'PADERNE-CANTOÑA',
      cdgoUic: '31213',
      clave: '0071,31213,31213',
      desgEstacionPlano: 'PADERNE-CANTONA',
      activo: true
    },
    {
      cdgoEstacion: '31214',
      cdgoAdmon: '0071',
      nmroPrioridad: 1104,
      descEstacion: null,
      desgEstacion: 'TABOADELA',
      cdgoUic: '31214',
      clave: '0071,31214,31214',
      desgEstacionPlano: 'TABOADELA',
      activo: true
    },
    {
      cdgoEstacion: '31308',
      cdgoAdmon: '0071',
      nmroPrioridad: 1105,
      descEstacion: null,
      desgEstacion: 'LALIN',
      cdgoUic: '31308',
      clave: '0071,31308,31308',
      desgEstacionPlano: 'LALIN',
      activo: true
    },
    {
      cdgoEstacion: '31309',
      cdgoAdmon: '0071',
      nmroPrioridad: 1106,
      descEstacion: null,
      desgEstacion: 'PONTE TABOADA',
      cdgoUic: '31309',
      clave: '0071,31309,31309',
      desgEstacionPlano: 'PONTE TABOADA',
      activo: true
    },
    {
      cdgoEstacion: '31311',
      cdgoAdmon: '0071',
      nmroPrioridad: 1107,
      descEstacion: null,
      desgEstacion: 'A BANDEIRA',
      cdgoUic: '31311',
      clave: '0071,31311,31311',
      desgEstacionPlano: 'A BANDEIRA',
      activo: true
    },
    {
      cdgoEstacion: '31312',
      cdgoAdmon: '0071',
      nmroPrioridad: 1108,
      descEstacion: null,
      desgEstacion: 'VEDRA-RIBADULLA',
      cdgoUic: '31312',
      clave: '0071,31312,31312',
      desgEstacionPlano: 'VEDRA-RIBADULLA',
      activo: true
    },
    {
      cdgoEstacion: '31215',
      cdgoAdmon: '0071',
      nmroPrioridad: 1109,
      descEstacion: null,
      desgEstacion: 'OURENSE-SAN FRANCISCO',
      cdgoUic: '31215',
      clave: '0071,31215,31215',
      desgEstacionPlano: 'OURENSE-SAN FRANCISCO',
      activo: true
    },
    {
      cdgoEstacion: '31303',
      cdgoAdmon: '0071',
      nmroPrioridad: 1110,
      descEstacion: null,
      desgEstacion: 'A FRIELA-MASIDE',
      cdgoUic: '31303',
      clave: '0071,31303,31303',
      desgEstacionPlano: 'A FRIELA-MASIDE',
      activo: true
    },
    {
      cdgoEstacion: '31306',
      cdgoAdmon: '0071',
      nmroPrioridad: 1111,
      descEstacion: null,
      desgEstacion: 'O IRIXO',
      cdgoUic: '31306',
      clave: '0071,31306,31306',
      desgEstacionPlano: 'O IRIXO',
      activo: true
    },
    {
      cdgoEstacion: '23021',
      cdgoAdmon: '0071',
      nmroPrioridad: 1112,
      descEstacion: null,
      desgEstacion: 'PADRON-BARBANZA',
      cdgoUic: '23021',
      clave: '0071,23021,23021',
      desgEstacionPlano: 'PADRON-BARBANZA',
      activo: true
    },
    {
      cdgoEstacion: '23013',
      cdgoAdmon: '0071',
      nmroPrioridad: 1113,
      descEstacion: null,
      desgEstacion: 'OSEBE',
      cdgoUic: '23013',
      clave: '0071,23013,23013',
      desgEstacionPlano: 'OSEBE',
      activo: true
    },
    {
      cdgoEstacion: '23018',
      cdgoAdmon: '0071',
      nmroPrioridad: 1114,
      descEstacion: null,
      desgEstacion: 'PONTEVEDRA-UNIVERSIDAD',
      cdgoUic: '23018',
      clave: '0071,23018,23018',
      desgEstacionPlano: 'PONTEVEDRA-UNIVERSIDAD',
      activo: true
    },
    {
      cdgoEstacion: '23012',
      cdgoAdmon: '0071',
      nmroPrioridad: 1115,
      descEstacion: null,
      desgEstacion: 'A ESCRAVITUDE/LA ESCLAVITUD',
      cdgoUic: '23012',
      clave: '0071,23012,23012',
      desgEstacionPlano: 'A ESCRAVITUDE/LA ESCLAVITUD',
      activo: true
    },
    {
      cdgoEstacion: '23005',
      cdgoAdmon: '0071',
      nmroPrioridad: 1116,
      descEstacion: null,
      desgEstacion: 'PORTELA',
      cdgoUic: '23005',
      clave: '0071,23005,23005',
      desgEstacionPlano: 'PORTELA',
      activo: true
    },
    {
      cdgoEstacion: '23001',
      cdgoAdmon: '0071',
      nmroPrioridad: 1117,
      descEstacion: null,
      desgEstacion: 'CESANTES',
      cdgoUic: '23001',
      clave: '0071,23001,23001',
      desgEstacionPlano: 'CESANTES',
      activo: true
    },
    {
      cdgoEstacion: '22103',
      cdgoAdmon: '0071',
      nmroPrioridad: 1118,
      descEstacion: null,
      desgEstacion: 'FILGUEIRA',
      cdgoUic: '22103',
      clave: '0071,22103,22103',
      desgEstacionPlano: 'FILGUEIRA',
      activo: true
    },
    {
      cdgoEstacion: '22104',
      cdgoAdmon: '0071',
      nmroPrioridad: 1119,
      descEstacion: null,
      desgEstacion: 'FRIEIRA',
      cdgoUic: '22104',
      clave: '0071,22104,22104',
      desgEstacionPlano: 'FRIEIRA',
      activo: true
    },
    {
      cdgoEstacion: '22105',
      cdgoAdmon: '0071',
      nmroPrioridad: 1120,
      descEstacion: null,
      desgEstacion: 'POUSA-CRECENTE',
      cdgoUic: '22105',
      clave: '0071,22105,22105',
      desgEstacionPlano: 'POUSA-CRECENTE',
      activo: true
    },
    {
      cdgoEstacion: '22106',
      cdgoAdmon: '0071',
      nmroPrioridad: 1121,
      descEstacion: null,
      desgEstacion: 'ARBO',
      cdgoUic: '22106',
      clave: '0071,22106,22106',
      desgEstacionPlano: 'ARBO',
      activo: true
    },
    {
      cdgoEstacion: '22107',
      cdgoAdmon: '0071',
      nmroPrioridad: 1122,
      descEstacion: null,
      desgEstacion: 'SELA',
      cdgoUic: '22107',
      clave: '0071,22107,22107',
      desgEstacionPlano: 'SELA',
      activo: true
    },
    {
      cdgoEstacion: '22108',
      cdgoAdmon: '0071',
      nmroPrioridad: 1123,
      descEstacion: null,
      desgEstacion: 'AS NEVES',
      cdgoUic: '22108',
      clave: '0071,22108,22108',
      desgEstacionPlano: 'AS NEVES',
      activo: true
    },
    {
      cdgoEstacion: '22110',
      cdgoAdmon: '0071',
      nmroPrioridad: 1124,
      descEstacion: null,
      desgEstacion: 'CALDELAS',
      cdgoUic: '22110',
      clave: '0071,22110,22110',
      desgEstacionPlano: 'CALDELAS',
      activo: true
    },
    {
      cdgoEstacion: '22202',
      cdgoAdmon: '0071',
      nmroPrioridad: 1125,
      descEstacion: null,
      desgEstacion: 'LOUREDO-VALOS',
      cdgoUic: '22202',
      clave: '0071,22202,22202',
      desgEstacionPlano: 'LOUREDO-VALOS',
      activo: true
    },
    {
      cdgoEstacion: '22302',
      cdgoAdmon: '0071',
      nmroPrioridad: 1126,
      descEstacion: null,
      desgEstacion: 'CHAPELA',
      cdgoUic: '22302',
      clave: '0071,22302,22302',
      desgEstacionPlano: 'CHAPELA',
      activo: true
    },
    {
      cdgoEstacion: '20403',
      cdgoAdmon: '0071',
      nmroPrioridad: 1127,
      descEstacion: null,
      desgEstacion: 'CAMBRE',
      cdgoUic: '20403',
      clave: '0071,20403,20403',
      desgEstacionPlano: 'CAMBRE',
      activo: true
    },
    {
      cdgoEstacion: '20404',
      cdgoAdmon: '0071',
      nmroPrioridad: 1128,
      descEstacion: null,
      desgEstacion: 'O BURGO SANTIAGO',
      cdgoUic: '20404',
      clave: '0071,20404,20404',
      desgEstacionPlano: 'O BURGO SANTIAGO',
      activo: true
    },
    {
      cdgoEstacion: '20402',
      cdgoAdmon: '0071',
      nmroPrioridad: 1129,
      descEstacion: null,
      desgEstacion: 'CECEBRE',
      cdgoUic: '20402',
      clave: '0071,20402,20402',
      desgEstacionPlano: 'CECEBRE',
      activo: true
    },
    {
      cdgoEstacion: '21002',
      cdgoAdmon: '0071',
      nmroPrioridad: 1130,
      descEstacion: null,
      desgEstacion: 'MIÑO',
      cdgoUic: '21002',
      clave: '0071,21002,21002',
      desgEstacionPlano: 'MINO',
      activo: true
    },
    {
      cdgoEstacion: '21003',
      cdgoAdmon: '0071',
      nmroPrioridad: 1131,
      descEstacion: null,
      desgEstacion: 'PERBES',
      cdgoUic: '21003',
      clave: '0071,21003,21003',
      desgEstacionPlano: 'PERBES',
      activo: true
    },
    {
      cdgoEstacion: '20410',
      cdgoAdmon: '0071',
      nmroPrioridad: 1132,
      descEstacion: null,
      desgEstacion: 'ELVIÑA-UNIVERSIDADE',
      cdgoUic: '20410',
      clave: '0071,20410,20410',
      desgEstacionPlano: 'ELVINA-UNIVERSIDADE',
      activo: true
    },
    {
      cdgoEstacion: '20319',
      cdgoAdmon: '0071',
      nmroPrioridad: 1133,
      descEstacion: null,
      desgEstacion: 'CESURAS',
      cdgoUic: '20319',
      clave: '0071,20319,20319',
      desgEstacionPlano: 'CESURAS',
      activo: true
    },
    {
      cdgoEstacion: '20320',
      cdgoAdmon: '0071',
      nmroPrioridad: 1134,
      descEstacion: null,
      desgEstacion: 'OZA DOS RIOS',
      cdgoUic: '20320',
      clave: '0071,20320,20320',
      desgEstacionPlano: 'OZA DOS RIOS',
      activo: true
    },
    {
      cdgoEstacion: '20318',
      cdgoAdmon: '0071',
      nmroPrioridad: 1135,
      descEstacion: null,
      desgEstacion: 'PIÑOI',
      cdgoUic: '20318',
      clave: '0071,20318,20318',
      desgEstacionPlano: 'PINOI',
      activo: true
    },
    {
      cdgoEstacion: '20312',
      cdgoAdmon: '0071',
      nmroPrioridad: 1136,
      descEstacion: null,
      desgEstacion: 'BAAMONDE',
      cdgoUic: '20312',
      clave: '0071,20312,20312',
      desgEstacionPlano: 'BAAMONDE',
      activo: true
    },
    {
      cdgoEstacion: '20313',
      cdgoAdmon: '0071',
      nmroPrioridad: 1137,
      descEstacion: null,
      desgEstacion: 'PARGA',
      cdgoUic: '20313',
      clave: '0071,20313,20313',
      desgEstacionPlano: 'PARGA',
      activo: true
    },
    {
      cdgoEstacion: '21008',
      cdgoAdmon: '0071',
      nmroPrioridad: 1138,
      descEstacion: null,
      desgEstacion: 'PERLIO',
      cdgoUic: '21008',
      clave: '0071,21008,21008',
      desgEstacionPlano: 'PERLIO',
      activo: true
    },
    {
      cdgoEstacion: '21009',
      cdgoAdmon: '0071',
      nmroPrioridad: 1139,
      descEstacion: null,
      desgEstacion: 'NEDA',
      cdgoUic: '21009',
      clave: '0071,21009,21009',
      desgEstacionPlano: 'NEDA',
      activo: true
    },
    {
      cdgoEstacion: '22001',
      cdgoAdmon: '0071',
      nmroPrioridad: 1140,
      descEstacion: null,
      desgEstacion: 'CANABAL',
      cdgoUic: '22001',
      clave: '0071,22001,22001',
      desgEstacionPlano: 'CANABAL',
      activo: true
    },
    {
      cdgoEstacion: '21006',
      cdgoAdmon: '0071',
      nmroPrioridad: 1141,
      descEstacion: null,
      desgEstacion: 'FRANZA',
      cdgoUic: '21006',
      clave: '0071,21006,21006',
      desgEstacionPlano: 'FRANZA',
      activo: true
    },
    {
      cdgoEstacion: '21007',
      cdgoAdmon: '0071',
      nmroPrioridad: 1142,
      descEstacion: null,
      desgEstacion: 'BARALLOBRE',
      cdgoUic: '21007',
      clave: '0071,21007,21007',
      desgEstacionPlano: 'BARALLOBRE',
      activo: true
    },
    {
      cdgoEstacion: '21005',
      cdgoAdmon: '0071',
      nmroPrioridad: 1143,
      descEstacion: null,
      desgEstacion: 'CABANAS-AREAL',
      cdgoUic: '21005',
      clave: '0071,21005,21005',
      desgEstacionPlano: 'CABANAS-AREAL',
      activo: true
    },
    {
      cdgoEstacion: '22002',
      cdgoAdmon: '0071',
      nmroPrioridad: 1144,
      descEstacion: null,
      desgEstacion: 'AREAS',
      cdgoUic: '22002',
      clave: '0071,22002,22002',
      desgEstacionPlano: 'AREAS',
      activo: true
    },
    {
      cdgoEstacion: '22003',
      cdgoAdmon: '0071',
      nmroPrioridad: 1145,
      descEstacion: null,
      desgEstacion: 'SAN ESTEVO DO SIL',
      cdgoUic: '22003',
      clave: '0071,22003,22003',
      desgEstacionPlano: 'SAN ESTEVO DO SIL',
      activo: true
    },
    {
      cdgoEstacion: '22004',
      cdgoAdmon: '0071',
      nmroPrioridad: 1146,
      descEstacion: null,
      desgEstacion: 'SAN PEDRO DO SIL',
      cdgoUic: '22004',
      clave: '0071,22004,22004',
      desgEstacionPlano: 'SAN PEDRO DO SIL',
      activo: true
    },
    {
      cdgoEstacion: '22005',
      cdgoAdmon: '0071',
      nmroPrioridad: 1147,
      descEstacion: null,
      desgEstacion: 'OS PEARES',
      cdgoUic: '22005',
      clave: '0071,22005,22005',
      desgEstacionPlano: 'OS PEARES',
      activo: true
    },
    {
      cdgoEstacion: '22006',
      cdgoAdmon: '0071',
      nmroPrioridad: 1148,
      descEstacion: null,
      desgEstacion: 'BARRA DE MIÑO',
      cdgoUic: '22006',
      clave: '0071,22006,22006',
      desgEstacionPlano: 'BARRA DE MINO',
      activo: true
    },
    {
      cdgoEstacion: '22101',
      cdgoAdmon: '0071',
      nmroPrioridad: 1149,
      descEstacion: null,
      desgEstacion: 'BARBANTES',
      cdgoUic: '22101',
      clave: '0071,22101,22101',
      desgEstacionPlano: 'BARBANTES',
      activo: true
    },
    {
      cdgoEstacion: '20207',
      cdgoAdmon: '0071',
      nmroPrioridad: 1150,
      descEstacion: null,
      desgEstacion: 'COVAS',
      cdgoUic: '20207',
      clave: '0071,20207,20207',
      desgEstacionPlano: 'COVAS',
      activo: true
    },
    {
      cdgoEstacion: '20208',
      cdgoAdmon: '0071',
      nmroPrioridad: 1151,
      descEstacion: null,
      desgEstacion: 'QUEREÑO',
      cdgoUic: '20208',
      clave: '0071,20208,20208',
      desgEstacionPlano: 'QUERENO',
      activo: true
    },
    {
      cdgoEstacion: '20209',
      cdgoAdmon: '0071',
      nmroPrioridad: 1152,
      descEstacion: null,
      desgEstacion: 'PUMARES',
      cdgoUic: '20209',
      clave: '0071,20209,20209',
      desgEstacionPlano: 'PUMARES',
      activo: true
    },
    {
      cdgoEstacion: '20210',
      cdgoAdmon: '0071',
      nmroPrioridad: 1153,
      descEstacion: null,
      desgEstacion: 'SOBRADELO',
      cdgoUic: '20210',
      clave: '0071,20210,20210',
      desgEstacionPlano: 'SOBRADELO',
      activo: true
    },
    {
      cdgoEstacion: '20212',
      cdgoAdmon: '0071',
      nmroPrioridad: 1154,
      descEstacion: null,
      desgEstacion: 'VILAMARTIN DE VALDEORRAS',
      cdgoUic: '20212',
      clave: '0071,20212,20212',
      desgEstacionPlano: 'VILAMARTIN DE VALDEORRAS',
      activo: true
    },
    {
      cdgoEstacion: '20214',
      cdgoAdmon: '0071',
      nmroPrioridad: 1155,
      descEstacion: null,
      desgEstacion: 'MONTEFURADO',
      cdgoUic: '20214',
      clave: '0071,20214,20214',
      desgEstacionPlano: 'MONTEFURADO',
      activo: true
    },
    {
      cdgoEstacion: '20310',
      cdgoAdmon: '0071',
      nmroPrioridad: 1156,
      descEstacion: null,
      desgEstacion: 'RABADE',
      cdgoUic: '20310',
      clave: '0071,20310,20310',
      desgEstacionPlano: 'RABADE',
      activo: true
    },
    {
      cdgoEstacion: '20311',
      cdgoAdmon: '0071',
      nmroPrioridad: 1157,
      descEstacion: null,
      desgEstacion: 'BEGONTE',
      cdgoUic: '20311',
      clave: '0071,20311,20311',
      desgEstacionPlano: 'BEGONTE',
      activo: true
    },
    {
      cdgoEstacion: '20306',
      cdgoAdmon: '0071',
      nmroPrioridad: 1158,
      descEstacion: null,
      desgEstacion: 'PEDRELO-CELTIGOS',
      cdgoUic: '20306',
      clave: '0071,20306,20306',
      desgEstacionPlano: 'PEDRELO-CELTIGOS',
      activo: true
    },
    {
      cdgoEstacion: '20218',
      cdgoAdmon: '0071',
      nmroPrioridad: 1159,
      descEstacion: null,
      desgEstacion: 'A POBRA DO BROLLON',
      cdgoUic: '20218',
      clave: '0071,20218,20218',
      desgEstacionPlano: 'A POBRA DO BROLLON',
      activo: true
    },
    {
      cdgoEstacion: '20113',
      cdgoAdmon: '0071',
      nmroPrioridad: 1160,
      descEstacion: null,
      desgEstacion: 'SAN MIGUEL DE LAS DUEÑAS',
      cdgoUic: '20113',
      clave: '0071,20113,20113',
      desgEstacionPlano: 'SAN MIGUEL DE LAS DUENAS',
      activo: true
    },
    {
      cdgoEstacion: '20112',
      cdgoAdmon: '0071',
      nmroPrioridad: 1161,
      descEstacion: null,
      desgEstacion: 'VILLAVERDE DE LOS CESTOS',
      cdgoUic: '20112',
      clave: '0071,20112,20112',
      desgEstacionPlano: 'VILLAVERDE DE LOS CESTOS',
      activo: true
    },
    {
      cdgoEstacion: '20202',
      cdgoAdmon: '0071',
      nmroPrioridad: 1162,
      descEstacion: null,
      desgEstacion: 'POSADA DEL BIERZO',
      cdgoUic: '20202',
      clave: '0071,20202,20202',
      desgEstacionPlano: 'POSADA DEL BIERZO',
      activo: true
    },
    {
      cdgoEstacion: '20203',
      cdgoAdmon: '0071',
      nmroPrioridad: 1163,
      descEstacion: null,
      desgEstacion: 'VILLADEPALOS',
      cdgoUic: '20203',
      clave: '0071,20203,20203',
      desgEstacionPlano: 'VILLADEPALOS',
      activo: true
    },
    {
      cdgoEstacion: '20206',
      cdgoAdmon: '0071',
      nmroPrioridad: 1164,
      descEstacion: null,
      desgEstacion: 'LA BAROSA',
      cdgoUic: '20206',
      clave: '0071,20206,20206',
      desgEstacionPlano: 'LA BAROSA',
      activo: true
    },
    {
      cdgoEstacion: '20103',
      cdgoAdmon: '0071',
      nmroPrioridad: 1165,
      descEstacion: null,
      desgEstacion: 'PORQUEROS',
      cdgoUic: '20103',
      clave: '0071,20103,20103',
      desgEstacionPlano: 'PORQUEROS',
      activo: true
    },
    {
      cdgoEstacion: '20104',
      cdgoAdmon: '0071',
      nmroPrioridad: 1166,
      descEstacion: null,
      desgEstacion: 'BRAÑUELAS',
      cdgoUic: '20104',
      clave: '0071,20104,20104',
      desgEstacionPlano: 'BRANUELAS',
      activo: true
    },
    {
      cdgoEstacion: '20106',
      cdgoAdmon: '0071',
      nmroPrioridad: 1167,
      descEstacion: null,
      desgEstacion: 'LA GRANJA DE SAN VICENTE',
      cdgoUic: '20106',
      clave: '0071,20106,20106',
      desgEstacionPlano: 'LA GRANJA DE SAN VICENTE',
      activo: true
    },
    {
      cdgoEstacion: '20109',
      cdgoAdmon: '0071',
      nmroPrioridad: 1168,
      descEstacion: null,
      desgEstacion: 'TORRE DEL BIERZO',
      cdgoUic: '20109',
      clave: '0071,20109,20109',
      desgEstacionPlano: 'TORRE DEL BIERZO',
      activo: true
    },
    {
      cdgoEstacion: '20008',
      cdgoAdmon: '0071',
      nmroPrioridad: 1169,
      descEstacion: null,
      desgEstacion: 'VILLAVANTE',
      cdgoUic: '20008',
      clave: '0071,20008,20008',
      desgEstacionPlano: 'VILLAVANTE',
      activo: true
    },
    {
      cdgoEstacion: '20101',
      cdgoAdmon: '0071',
      nmroPrioridad: 1170,
      descEstacion: null,
      desgEstacion: 'OTERO DE ESCARPIZO',
      cdgoUic: '20101',
      clave: '0071,20101,20101',
      desgEstacionPlano: 'OTERO DE ESCARPIZO',
      activo: true
    },
    {
      cdgoEstacion: '05451',
      cdgoAdmon: '0071',
      nmroPrioridad: 1280,
      descEstacion: null,
      desgEstacion: 'BILBAO LA CONCORDIA',
      cdgoUic: null,
      clave: '0071,05451,null',
      desgEstacionPlano: 'BILBAO LA CONCORDIA',
      activo: true
    },
    {
      cdgoEstacion: '05455',
      cdgoAdmon: '0071',
      nmroPrioridad: 1281,
      descEstacion: null,
      desgEstacion: 'BASURTO HOSPITAL',
      cdgoUic: null,
      clave: '0071,05455,null',
      desgEstacionPlano: 'BASURTO HOSPITAL',
      activo: true
    },
    {
      cdgoEstacion: '05457',
      cdgoAdmon: '0071',
      nmroPrioridad: 1282,
      descEstacion: null,
      desgEstacion: 'FEVE-ZORROZA ZORROZGOITI',
      cdgoUic: null,
      clave: '0071,05457,null',
      desgEstacionPlano: 'FEVE-ZORROZA ZORROZGOITI',
      activo: true
    },
    {
      cdgoEstacion: '05461',
      cdgoAdmon: '0071',
      nmroPrioridad: 1285,
      descEstacion: null,
      desgEstacion: 'IRAUREGUI',
      cdgoUic: null,
      clave: '0071,05461,null',
      desgEstacionPlano: 'IRAUREGUI',
      activo: true
    },
    {
      cdgoEstacion: '05463',
      cdgoAdmon: '0071',
      nmroPrioridad: 1286,
      descEstacion: null,
      desgEstacion: 'ZARAMILLO',
      cdgoUic: null,
      clave: '0071,05463,null',
      desgEstacionPlano: 'ZARAMILLO',
      activo: true
    },
    {
      cdgoEstacion: '05467',
      cdgoAdmon: '0071',
      nmroPrioridad: 1288,
      descEstacion: null,
      desgEstacion: 'SODUPE',
      cdgoUic: null,
      clave: '0071,05467,null',
      desgEstacionPlano: 'SODUPE',
      activo: true
    },
    {
      cdgoEstacion: '05473',
      cdgoAdmon: '0071',
      nmroPrioridad: 1292,
      descEstacion: null,
      desgEstacion: 'ARANGUREN',
      cdgoUic: null,
      clave: '0071,05473,null',
      desgEstacionPlano: 'ARANGUREN',
      activo: true
    },
    {
      cdgoEstacion: '05484',
      cdgoAdmon: '0071',
      nmroPrioridad: 1292,
      descEstacion: null,
      desgEstacion: 'MIMETIZ',
      cdgoUic: '05484',
      clave: '0071,05484,05484',
      desgEstacionPlano: 'MIMETIZ',
      activo: true
    },
    {
      cdgoEstacion: '05474',
      cdgoAdmon: '0071',
      nmroPrioridad: 1293,
      descEstacion: null,
      desgEstacion: 'ARANGUREN/APDRO.',
      cdgoUic: null,
      clave: '0071,05474,null',
      desgEstacionPlano: 'ARANGUREN/APDRO.',
      activo: true
    },
    {
      cdgoEstacion: '05475',
      cdgoAdmon: '0071',
      nmroPrioridad: 1294,
      descEstacion: null,
      desgEstacion: 'ZALLA',
      cdgoUic: null,
      clave: '0071,05475,null',
      desgEstacionPlano: 'ZALLA',
      activo: true
    },
    {
      cdgoEstacion: '05110',
      cdgoAdmon: '0071',
      nmroPrioridad: 1304,
      descEstacion: null,
      desgEstacion: 'SANTANDER-FEVE',
      cdgoUic: null,
      clave: '0071,05110,null',
      desgEstacionPlano: 'SANTANDER-FEVE',
      activo: true
    },
    {
      cdgoEstacion: '05193',
      cdgoAdmon: '0071',
      nmroPrioridad: 1347,
      descEstacion: null,
      desgEstacion: 'RIBADEO',
      cdgoUic: null,
      clave: '0071,05193,null',
      desgEstacionPlano: 'RIBADEO',
      activo: true
    },
    {
      cdgoEstacion: 'MEDIN',
      cdgoAdmon: '0071',
      nmroPrioridad: 1367,
      descEstacion: null,
      desgEstacion: 'MEDINA DEL CAMPO (TODAS)',
      cdgoUic: null,
      clave: '0071,MEDIN,null',
      desgEstacionPlano: 'MEDINA DEL CAMPO (TODAS)',
      activo: true
    },
    {
      cdgoEstacion: '99003',
      cdgoAdmon: '0071',
      nmroPrioridad: 1372,
      descEstacion: null,
      desgEstacion: 'ALTET-BUS',
      cdgoUic: null,
      clave: '0071,99003,null',
      desgEstacionPlano: 'ALTET-BUS',
      activo: true
    },
    {
      cdgoEstacion: '99004',
      cdgoAdmon: '0071',
      nmroPrioridad: 1373,
      descEstacion: null,
      desgEstacion: 'GRAN ALACANT-BUS',
      cdgoUic: null,
      clave: '0071,99004,null',
      desgEstacionPlano: 'GRAN ALACANT-BUS',
      activo: true
    },
    {
      cdgoEstacion: '99100',
      cdgoAdmon: '0071',
      nmroPrioridad: 1374,
      descEstacion: null,
      desgEstacion: 'BARBASTRO-BUS',
      cdgoUic: null,
      clave: '0071,99100,null',
      desgEstacionPlano: 'BARBASTRO-BUS',
      activo: true
    },
    {
      cdgoEstacion: '99101',
      cdgoAdmon: '0071',
      nmroPrioridad: 1375,
      descEstacion: null,
      desgEstacion: 'GRAUS-BUS',
      cdgoUic: null,
      clave: '0071,99101,null',
      desgEstacionPlano: 'GRAUS-BUS',
      activo: true
    },
    {
      cdgoEstacion: '99102',
      cdgoAdmon: '0071',
      nmroPrioridad: 1376,
      descEstacion: null,
      desgEstacion: 'BENASQUE-BUS',
      cdgoUic: null,
      clave: '0071,99102,null',
      desgEstacionPlano: 'BENASQUE-BUS',
      activo: true
    },
    {
      cdgoEstacion: '99103',
      cdgoAdmon: '0071',
      nmroPrioridad: 1377,
      descEstacion: null,
      desgEstacion: 'JACA-BUS',
      cdgoUic: null,
      clave: '0071,99103,null',
      desgEstacionPlano: 'JACA-BUS',
      activo: true
    },
    {
      cdgoEstacion: '99104',
      cdgoAdmon: '0071',
      nmroPrioridad: 1378,
      descEstacion: null,
      desgEstacion: 'CANDANCHU-BUS',
      cdgoUic: null,
      clave: '0071,99104,null',
      desgEstacionPlano: 'CANDANCHU-BUS',
      activo: true
    },
    {
      cdgoEstacion: '99105',
      cdgoAdmon: '0071',
      nmroPrioridad: 1379,
      descEstacion: null,
      desgEstacion: 'ASTUN-BUS',
      cdgoUic: null,
      clave: '0071,99105,null',
      desgEstacionPlano: 'ASTUN-BUS',
      activo: true
    },
    {
      cdgoEstacion: '99106',
      cdgoAdmon: '0071',
      nmroPrioridad: 1380,
      descEstacion: null,
      desgEstacion: 'BIESCAS-BUS',
      cdgoUic: null,
      clave: '0071,99106,null',
      desgEstacionPlano: 'BIESCAS-BUS',
      activo: true
    },
    {
      cdgoEstacion: '99107',
      cdgoAdmon: '0071',
      nmroPrioridad: 1381,
      descEstacion: null,
      desgEstacion: 'PANTICOSA-BUS',
      cdgoUic: null,
      clave: '0071,99107,null',
      desgEstacionPlano: 'PANTICOSA-BUS',
      activo: true
    },
    {
      cdgoEstacion: '99108',
      cdgoAdmon: '0071',
      nmroPrioridad: 1382,
      descEstacion: null,
      desgEstacion: 'FORMIGAL-BUS',
      cdgoUic: null,
      clave: '0071,99108,null',
      desgEstacionPlano: 'FORMIGAL-BUS',
      activo: true
    },
    {
      cdgoEstacion: '99109',
      cdgoAdmon: '0071',
      nmroPrioridad: 1383,
      descEstacion: null,
      desgEstacion: 'SANXENXO-BUS',
      cdgoUic: null,
      clave: '0071,99109,null',
      desgEstacionPlano: 'SANXENXO-BUS',
      activo: true
    },
    {
      cdgoEstacion: '99110',
      cdgoAdmon: '0071',
      nmroPrioridad: 1384,
      descEstacion: null,
      desgEstacion: 'OGROVE-BUS',
      cdgoUic: null,
      clave: '0071,99110,null',
      desgEstacionPlano: 'OGROVE-BUS',
      activo: true
    },
    {
      cdgoEstacion: '99111',
      cdgoAdmon: '0071',
      nmroPrioridad: 1385,
      descEstacion: null,
      desgEstacion: 'CANGAS-BUS',
      cdgoUic: null,
      clave: '0071,99111,null',
      desgEstacionPlano: 'CANGAS-BUS',
      activo: true
    },
    {
      cdgoEstacion: '99112',
      cdgoAdmon: '0071',
      nmroPrioridad: 1386,
      descEstacion: null,
      desgEstacion: 'BUEU-BUS',
      cdgoUic: null,
      clave: '0071,99112,null',
      desgEstacionPlano: 'BUEU-BUS',
      activo: true
    },
    {
      cdgoEstacion: '99113',
      cdgoAdmon: '0071',
      nmroPrioridad: 1387,
      descEstacion: null,
      desgEstacion: 'SABIÑANIGO-BUS',
      cdgoUic: '99113',
      clave: '0071,99113,99113',
      desgEstacionPlano: 'SABINANIGO-BUS',
      activo: true
    },
    {
      cdgoEstacion: '99124',
      cdgoAdmon: '0071',
      nmroPrioridad: 1390,
      descEstacion: null,
      desgEstacion: 'TANGER MED-BARCO',
      cdgoUic: null,
      clave: '0071,99124,null',
      desgEstacionPlano: 'TANGER MED-BARCO',
      activo: true
    },
    {
      cdgoEstacion: '99125',
      cdgoAdmon: '0071',
      nmroPrioridad: 1391,
      descEstacion: null,
      desgEstacion: 'TANGER VILLE-BARCO',
      cdgoUic: null,
      clave: '0071,99125,null',
      desgEstacionPlano: 'TANGER VILLE-BARCO',
      activo: true
    },
    {
      cdgoEstacion: '99126',
      cdgoAdmon: '0071',
      nmroPrioridad: 1392,
      descEstacion: null,
      desgEstacion: 'CEUTA-BARCO',
      cdgoUic: null,
      clave: '0071,99126,null',
      desgEstacionPlano: 'CEUTA-BARCO',
      activo: true
    },
    {
      cdgoEstacion: '99118',
      cdgoAdmon: '0071',
      nmroPrioridad: 1396,
      descEstacion: null,
      desgEstacion: 'MUROS-BUS',
      cdgoUic: null,
      clave: '0071,99118,null',
      desgEstacionPlano: 'MUROS-BUS',
      activo: true
    },
    {
      cdgoEstacion: '99119',
      cdgoAdmon: '0071',
      nmroPrioridad: 1397,
      descEstacion: null,
      desgEstacion: 'FISTERRA-BUS',
      cdgoUic: null,
      clave: '0071,99119,null',
      desgEstacionPlano: 'FISTERRA-BUS',
      activo: true
    },
    {
      cdgoEstacion: '99115',
      cdgoAdmon: '0071',
      nmroPrioridad: 1400,
      descEstacion: null,
      desgEstacion: 'AGUADULCE-BUS',
      cdgoUic: null,
      clave: '0071,99115,null',
      desgEstacionPlano: 'AGUADULCE-BUS',
      activo: true
    },
    {
      cdgoEstacion: '05483',
      cdgoAdmon: '0071',
      nmroPrioridad: 1402,
      descEstacion: null,
      desgEstacion: 'BALMASEDA',
      cdgoUic: null,
      clave: '0071,05483,null',
      desgEstacionPlano: 'BALMASEDA',
      activo: true
    },
    {
      cdgoEstacion: '05487',
      cdgoAdmon: '0071',
      nmroPrioridad: 1404,
      descEstacion: null,
      desgEstacion: 'TRASLAVIÑA',
      cdgoUic: null,
      clave: '0071,05487,null',
      desgEstacionPlano: 'TRASLAVINA',
      activo: true
    },
    {
      cdgoEstacion: '05489',
      cdgoAdmon: '0071',
      nmroPrioridad: 1405,
      descEstacion: null,
      desgEstacion: 'ARZENTALES',
      cdgoUic: null,
      clave: '0071,05489,null',
      desgEstacionPlano: 'ARZENTALES',
      activo: true
    },
    {
      cdgoEstacion: '05493',
      cdgoAdmon: '0071',
      nmroPrioridad: 1406,
      descEstacion: null,
      desgEstacion: 'VILLAVERDE DE TRUCIOS',
      cdgoUic: null,
      clave: '0071,05493,null',
      desgEstacionPlano: 'VILLAVERDE DE TRUCIOS',
      activo: true
    },
    {
      cdgoEstacion: '05497',
      cdgoAdmon: '0071',
      nmroPrioridad: 1407,
      descEstacion: null,
      desgEstacion: 'KARRANZA',
      cdgoUic: null,
      clave: '0071,05497,null',
      desgEstacionPlano: 'KARRANZA',
      activo: true
    },
    {
      cdgoEstacion: '05504',
      cdgoAdmon: '0071',
      nmroPrioridad: 1408,
      descEstacion: null,
      desgEstacion: 'PARQUE PRINCIPADO',
      cdgoUic: null,
      clave: '0071,05504,null',
      desgEstacionPlano: 'PARQUE PRINCIPADO',
      activo: true
    },
    {
      cdgoEstacion: '05505',
      cdgoAdmon: '0071',
      nmroPrioridad: 1409,
      descEstacion: null,
      desgEstacion: 'COLLOTO',
      cdgoUic: null,
      clave: '0071,05505,null',
      desgEstacionPlano: 'COLLOTO',
      activo: true
    },
    {
      cdgoEstacion: '05507',
      cdgoAdmon: '0071',
      nmroPrioridad: 1410,
      descEstacion: null,
      desgEstacion: 'MERES',
      cdgoUic: null,
      clave: '0071,05507,null',
      desgEstacionPlano: 'MERES',
      activo: true
    },
    {
      cdgoEstacion: '05508',
      cdgoAdmon: '0071',
      nmroPrioridad: 1411,
      descEstacion: null,
      desgEstacion: 'FONCIELLO',
      cdgoUic: null,
      clave: '0071,05508,null',
      desgEstacionPlano: 'FONCIELLO',
      activo: true
    },
    {
      cdgoEstacion: '05509',
      cdgoAdmon: '0071',
      nmroPrioridad: 1412,
      descEstacion: null,
      desgEstacion: 'EL BERRÓN',
      cdgoUic: null,
      clave: '0071,05509,null',
      desgEstacionPlano: 'EL BERRON',
      activo: true
    },
    {
      cdgoEstacion: '05511',
      cdgoAdmon: '0071',
      nmroPrioridad: 1413,
      descEstacion: null,
      desgEstacion: 'LA CARRERA DE SIERO',
      cdgoUic: null,
      clave: '0071,05511,null',
      desgEstacionPlano: 'LA CARRERA DE SIERO',
      activo: true
    },
    {
      cdgoEstacion: '05513',
      cdgoAdmon: '0071',
      nmroPrioridad: 1414,
      descEstacion: null,
      desgEstacion: 'POLA DE SIERO',
      cdgoUic: null,
      clave: '0071,05513,null',
      desgEstacionPlano: 'POLA DE SIERO',
      activo: true
    },
    {
      cdgoEstacion: '05515',
      cdgoAdmon: '0071',
      nmroPrioridad: 1415,
      descEstacion: null,
      desgEstacion: 'LOS CORROS',
      cdgoUic: null,
      clave: '0071,05515,null',
      desgEstacionPlano: 'LOS CORROS',
      activo: true
    },
    {
      cdgoEstacion: '05517',
      cdgoAdmon: '0071',
      nmroPrioridad: 1416,
      descEstacion: null,
      desgEstacion: 'LIERES',
      cdgoUic: null,
      clave: '0071,05517,null',
      desgEstacionPlano: 'LIERES',
      activo: true
    },
    {
      cdgoEstacion: '05521',
      cdgoAdmon: '0071',
      nmroPrioridad: 1417,
      descEstacion: null,
      desgEstacion: 'EL REMEDIO',
      cdgoUic: null,
      clave: '0071,05521,null',
      desgEstacionPlano: 'EL REMEDIO',
      activo: true
    },
    {
      cdgoEstacion: '05522',
      cdgoAdmon: '0071',
      nmroPrioridad: 1418,
      descEstacion: null,
      desgEstacion: 'LLAMES',
      cdgoUic: null,
      clave: '0071,05522,null',
      desgEstacionPlano: 'LLAMES',
      activo: true
    },
    {
      cdgoEstacion: '05523',
      cdgoAdmon: '0071',
      nmroPrioridad: 1419,
      descEstacion: null,
      desgEstacion: 'NAVA',
      cdgoUic: null,
      clave: '0071,05523,null',
      desgEstacionPlano: 'NAVA',
      activo: true
    },
    {
      cdgoEstacion: '05529',
      cdgoAdmon: '0071',
      nmroPrioridad: 1422,
      descEstacion: null,
      desgEstacion: 'CARANCOS',
      cdgoUic: null,
      clave: '0071,05529,null',
      desgEstacionPlano: 'CARANCOS',
      activo: true
    },
    {
      cdgoEstacion: '05533',
      cdgoAdmon: '0071',
      nmroPrioridad: 1424,
      descEstacion: null,
      desgEstacion: 'INFIESTO',
      cdgoUic: null,
      clave: '0071,05533,null',
      desgEstacionPlano: 'INFIESTO',
      activo: true
    },
    {
      cdgoEstacion: '05534',
      cdgoAdmon: '0071',
      nmroPrioridad: 1425,
      descEstacion: null,
      desgEstacion: 'INFIESTO/APDRO.',
      cdgoUic: null,
      clave: '0071,05534,null',
      desgEstacionPlano: 'INFIESTO/APDRO.',
      activo: true
    },
    {
      cdgoEstacion: '05535',
      cdgoAdmon: '0071',
      nmroPrioridad: 1426,
      descEstacion: null,
      desgEstacion: 'VILLAMAYOR',
      cdgoUic: null,
      clave: '0071,05535,null',
      desgEstacionPlano: 'VILLAMAYOR',
      activo: true
    },
    {
      cdgoEstacion: '05537',
      cdgoAdmon: '0071',
      nmroPrioridad: 1427,
      descEstacion: null,
      desgEstacion: 'SEBARES',
      cdgoUic: null,
      clave: '0071,05537,null',
      desgEstacionPlano: 'SEBARES',
      activo: true
    },
    {
      cdgoEstacion: '05539',
      cdgoAdmon: '0071',
      nmroPrioridad: 1428,
      descEstacion: null,
      desgEstacion: 'SOTO DE DUEÑAS',
      cdgoUic: null,
      clave: '0071,05539,null',
      desgEstacionPlano: 'SOTO DE DUENAS',
      activo: true
    },
    {
      cdgoEstacion: '05541',
      cdgoAdmon: '0071',
      nmroPrioridad: 1429,
      descEstacion: null,
      desgEstacion: 'OZANES',
      cdgoUic: null,
      clave: '0071,05541,null',
      desgEstacionPlano: 'OZANES',
      activo: true
    },
    {
      cdgoEstacion: '05542',
      cdgoAdmon: '0071',
      nmroPrioridad: 1430,
      descEstacion: null,
      desgEstacion: 'POLICLÍNICO',
      cdgoUic: null,
      clave: '0071,05542,null',
      desgEstacionPlano: 'POLICLINICO',
      activo: true
    },
    {
      cdgoEstacion: '05543',
      cdgoAdmon: '0071',
      nmroPrioridad: 1431,
      descEstacion: null,
      desgEstacion: 'ARRIONDAS',
      cdgoUic: null,
      clave: '0071,05543,null',
      desgEstacionPlano: 'ARRIONDAS',
      activo: true
    },
    {
      cdgoEstacion: '05545',
      cdgoAdmon: '0071',
      nmroPrioridad: 1432,
      descEstacion: null,
      desgEstacion: 'FUENTES',
      cdgoUic: null,
      clave: '0071,05545,null',
      desgEstacionPlano: 'FUENTES',
      activo: true
    },
    {
      cdgoEstacion: '05547',
      cdgoAdmon: '0071',
      nmroPrioridad: 1433,
      descEstacion: null,
      desgEstacion: 'TORAÑO',
      cdgoUic: null,
      clave: '0071,05547,null',
      desgEstacionPlano: 'TORANO',
      activo: true
    },
    {
      cdgoEstacion: '05549',
      cdgoAdmon: '0071',
      nmroPrioridad: 1434,
      descEstacion: null,
      desgEstacion: 'CUEVAS',
      cdgoUic: null,
      clave: '0071,05549,null',
      desgEstacionPlano: 'CUEVAS',
      activo: true
    },
    {
      cdgoEstacion: '05551',
      cdgoAdmon: '0071',
      nmroPrioridad: 1435,
      descEstacion: null,
      desgEstacion: 'LLOVIO',
      cdgoUic: null,
      clave: '0071,05551,null',
      desgEstacionPlano: 'LLOVIO',
      activo: true
    },
    {
      cdgoEstacion: '05553',
      cdgoAdmon: '0071',
      nmroPrioridad: 1437,
      descEstacion: null,
      desgEstacion: 'RIBADESELLA',
      cdgoUic: null,
      clave: '0071,05553,null',
      desgEstacionPlano: 'RIBADESELLA',
      activo: true
    },
    {
      cdgoEstacion: '05555',
      cdgoAdmon: '0071',
      nmroPrioridad: 1438,
      descEstacion: null,
      desgEstacion: 'CAMANGO',
      cdgoUic: null,
      clave: '0071,05555,null',
      desgEstacionPlano: 'CAMANGO',
      activo: true
    },
    {
      cdgoEstacion: '05557',
      cdgoAdmon: '0071',
      nmroPrioridad: 1439,
      descEstacion: null,
      desgEstacion: 'FEVE-BELMONTE DE PRIA',
      cdgoUic: null,
      clave: '0071,05557,null',
      desgEstacionPlano: 'FEVE-BELMONTE DE PRIA',
      activo: true
    },
    {
      cdgoEstacion: '05559',
      cdgoAdmon: '0071',
      nmroPrioridad: 1440,
      descEstacion: null,
      desgEstacion: 'NUEVA',
      cdgoUic: null,
      clave: '0071,05559,null',
      desgEstacionPlano: 'NUEVA',
      activo: true
    },
    {
      cdgoEstacion: '05561',
      cdgoAdmon: '0071',
      nmroPrioridad: 1441,
      descEstacion: null,
      desgEstacion: 'VILLAHORMES',
      cdgoUic: null,
      clave: '0071,05561,null',
      desgEstacionPlano: 'VILLAHORMES',
      activo: true
    },
    {
      cdgoEstacion: '05563',
      cdgoAdmon: '0071',
      nmroPrioridad: 1442,
      descEstacion: null,
      desgEstacion: 'POSADA',
      cdgoUic: null,
      clave: '0071,05563,null',
      desgEstacionPlano: 'POSADA',
      activo: true
    },
    {
      cdgoEstacion: '05565',
      cdgoAdmon: '0071',
      nmroPrioridad: 1443,
      descEstacion: null,
      desgEstacion: 'BALMORI',
      cdgoUic: null,
      clave: '0071,05565,null',
      desgEstacionPlano: 'BALMORI',
      activo: true
    },
    {
      cdgoEstacion: '05567',
      cdgoAdmon: '0071',
      nmroPrioridad: 1444,
      descEstacion: null,
      desgEstacion: 'CELORIO',
      cdgoUic: null,
      clave: '0071,05567,null',
      desgEstacionPlano: 'CELORIO',
      activo: true
    },
    {
      cdgoEstacion: '05569',
      cdgoAdmon: '0071',
      nmroPrioridad: 1445,
      descEstacion: null,
      desgEstacion: 'POÓ',
      cdgoUic: null,
      clave: '0071,05569,null',
      desgEstacionPlano: 'POO',
      activo: true
    },
    {
      cdgoEstacion: '05571',
      cdgoAdmon: '0071',
      nmroPrioridad: 1446,
      descEstacion: null,
      desgEstacion: 'LLANES',
      cdgoUic: null,
      clave: '0071,05571,null',
      desgEstacionPlano: 'LLANES',
      activo: true
    },
    {
      cdgoEstacion: '05573',
      cdgoAdmon: '0071',
      nmroPrioridad: 1447,
      descEstacion: null,
      desgEstacion: 'SAN ROQUE DEL ACEBAL',
      cdgoUic: null,
      clave: '0071,05573,null',
      desgEstacionPlano: 'SAN ROQUE DEL ACEBAL',
      activo: true
    },
    {
      cdgoEstacion: '05575',
      cdgoAdmon: '0071',
      nmroPrioridad: 1448,
      descEstacion: null,
      desgEstacion: 'VIDIAGO',
      cdgoUic: null,
      clave: '0071,05575,null',
      desgEstacionPlano: 'VIDIAGO',
      activo: true
    },
    {
      cdgoEstacion: '05577',
      cdgoAdmon: '0071',
      nmroPrioridad: 1449,
      descEstacion: null,
      desgEstacion: 'PENDUELES',
      cdgoUic: null,
      clave: '0071,05577,null',
      desgEstacionPlano: 'PENDUELES',
      activo: true
    },
    {
      cdgoEstacion: '05579',
      cdgoAdmon: '0071',
      nmroPrioridad: 1450,
      descEstacion: null,
      desgEstacion: 'COLOMBRES',
      cdgoUic: null,
      clave: '0071,05579,null',
      desgEstacionPlano: 'COLOMBRES',
      activo: true
    },
    {
      cdgoEstacion: '05602',
      cdgoAdmon: '0071',
      nmroPrioridad: 1451,
      descEstacion: null,
      desgEstacion: 'FEVE-VALDECILLA LA MARGA',
      cdgoUic: null,
      clave: '0071,05602,null',
      desgEstacionPlano: 'FEVE-VALDECILLA LA MARGA',
      activo: true
    },
    {
      cdgoEstacion: '05621',
      cdgoAdmon: '0071',
      nmroPrioridad: 1463,
      descEstacion: null,
      desgEstacion: 'FEVE-TORRELAVEGA/CENTRO',
      cdgoUic: null,
      clave: '0071,05621,null',
      desgEstacionPlano: 'FEVE-TORRELAVEGA/CENTRO',
      activo: true
    },
    {
      cdgoEstacion: '05623',
      cdgoAdmon: '0071',
      nmroPrioridad: 1465,
      descEstacion: null,
      desgEstacion: 'PUENTE SAN MIGUEL',
      cdgoUic: null,
      clave: '0071,05623,null',
      desgEstacionPlano: 'PUENTE SAN MIGUEL',
      activo: true
    },
    {
      cdgoEstacion: '05627',
      cdgoAdmon: '0071',
      nmroPrioridad: 1467,
      descEstacion: null,
      desgEstacion: 'SAN PEDRO DE RUDAGÜERA',
      cdgoUic: null,
      clave: '0071,05627,null',
      desgEstacionPlano: 'SAN PEDRO DE RUDAGUERA',
      activo: true
    },
    {
      cdgoEstacion: '05637',
      cdgoAdmon: '0071',
      nmroPrioridad: 1472,
      descEstacion: null,
      desgEstacion: 'CABEZÓN DE LA SAL',
      cdgoUic: null,
      clave: '0071,05637,null',
      desgEstacionPlano: 'CABEZON DE LA SAL',
      activo: true
    },
    {
      cdgoEstacion: '05641',
      cdgoAdmon: '0071',
      nmroPrioridad: 1473,
      descEstacion: null,
      desgEstacion: 'TRECEÑO',
      cdgoUic: null,
      clave: '0071,05641,null',
      desgEstacionPlano: 'TRECENO',
      activo: true
    },
    {
      cdgoEstacion: '05643',
      cdgoAdmon: '0071',
      nmroPrioridad: 1474,
      descEstacion: null,
      desgEstacion: 'ROIZ',
      cdgoUic: null,
      clave: '0071,05643,null',
      desgEstacionPlano: 'ROIZ',
      activo: true
    },
    {
      cdgoEstacion: '05644',
      cdgoAdmon: '0071',
      nmroPrioridad: 1475,
      descEstacion: null,
      desgEstacion: 'EL BARCENAL',
      cdgoUic: null,
      clave: '0071,05644,null',
      desgEstacionPlano: 'EL BARCENAL',
      activo: true
    },
    {
      cdgoEstacion: '05645',
      cdgoAdmon: '0071',
      nmroPrioridad: 1476,
      descEstacion: null,
      desgEstacion: 'SAN VICENTE DE LA BARQUERA',
      cdgoUic: null,
      clave: '0071,05645,null',
      desgEstacionPlano: 'SAN VICENTE DE LA BARQUERA',
      activo: true
    },
    {
      cdgoEstacion: '05647',
      cdgoAdmon: '0071',
      nmroPrioridad: 1477,
      descEstacion: null,
      desgEstacion: 'PESÚES',
      cdgoUic: null,
      clave: '0071,05647,null',
      desgEstacionPlano: 'PESUES',
      activo: true
    },
    {
      cdgoEstacion: '05649',
      cdgoAdmon: '0071',
      nmroPrioridad: 1478,
      descEstacion: null,
      desgEstacion: 'UNQUERA',
      cdgoUic: null,
      clave: '0071,05649,null',
      desgEstacionPlano: 'UNQUERA',
      activo: true
    },
    {
      cdgoEstacion: '05651',
      cdgoAdmon: '0071',
      nmroPrioridad: 1479,
      descEstacion: null,
      desgEstacion: 'FEVE-NUEVA MONTAÑA',
      cdgoUic: null,
      clave: '0071,05651,null',
      desgEstacionPlano: 'FEVE-NUEVA MONTANA',
      activo: true
    },
    {
      cdgoEstacion: '05652',
      cdgoAdmon: '0071',
      nmroPrioridad: 1480,
      descEstacion: null,
      desgEstacion: 'VALLE REAL',
      cdgoUic: null,
      clave: '0071,05652,null',
      desgEstacionPlano: 'VALLE REAL',
      activo: true
    },
    {
      cdgoEstacion: '05655',
      cdgoAdmon: '0071',
      nmroPrioridad: 1481,
      descEstacion: null,
      desgEstacion: 'FEVE-MALIAÑO LA VIDRIERA',
      cdgoUic: null,
      clave: '0071,05655,null',
      desgEstacionPlano: 'FEVE-MALIANO LA VIDRIERA',
      activo: true
    },
    {
      cdgoEstacion: '05657',
      cdgoAdmon: '0071',
      nmroPrioridad: 1482,
      descEstacion: null,
      desgEstacion: 'ASTILLERO',
      cdgoUic: null,
      clave: '0071,05657,null',
      desgEstacionPlano: 'ASTILLERO',
      activo: true
    },
    {
      cdgoEstacion: '05661',
      cdgoAdmon: '0071',
      nmroPrioridad: 1485,
      descEstacion: null,
      desgEstacion: 'HERAS',
      cdgoUic: null,
      clave: '0071,05661,null',
      desgEstacionPlano: 'HERAS',
      activo: true
    },
    {
      cdgoEstacion: '05663',
      cdgoAdmon: '0071',
      nmroPrioridad: 1486,
      descEstacion: null,
      desgEstacion: 'OREJO',
      cdgoUic: null,
      clave: '0071,05663,null',
      desgEstacionPlano: 'OREJO',
      activo: true
    },
    {
      cdgoEstacion: '05672',
      cdgoAdmon: '0071',
      nmroPrioridad: 1491,
      descEstacion: null,
      desgEstacion: 'PUENTE AGÜERO',
      cdgoUic: null,
      clave: '0071,05672,null',
      desgEstacionPlano: 'PUENTE AGUERO',
      activo: true
    },
    {
      cdgoEstacion: '05673',
      cdgoAdmon: '0071',
      nmroPrioridad: 1492,
      descEstacion: null,
      desgEstacion: 'VILLAVERDE DE PONTONES',
      cdgoUic: null,
      clave: '0071,05673,null',
      desgEstacionPlano: 'VILLAVERDE DE PONTONES',
      activo: true
    },
    {
      cdgoEstacion: '05675',
      cdgoAdmon: '0071',
      nmroPrioridad: 1493,
      descEstacion: null,
      desgEstacion: 'HOZ DE ANERO',
      cdgoUic: null,
      clave: '0071,05675,null',
      desgEstacionPlano: 'HOZ DE ANERO',
      activo: true
    },
    {
      cdgoEstacion: '05677',
      cdgoAdmon: '0071',
      nmroPrioridad: 1494,
      descEstacion: null,
      desgEstacion: 'BERANGA',
      cdgoUic: null,
      clave: '0071,05677,null',
      desgEstacionPlano: 'BERANGA',
      activo: true
    },
    {
      cdgoEstacion: '05679',
      cdgoAdmon: '0071',
      nmroPrioridad: 1495,
      descEstacion: null,
      desgEstacion: 'GAMA',
      cdgoUic: null,
      clave: '0071,05679,null',
      desgEstacionPlano: 'GAMA',
      activo: true
    },
    {
      cdgoEstacion: '05681',
      cdgoAdmon: '0071',
      nmroPrioridad: 1496,
      descEstacion: null,
      desgEstacion: 'CICERO',
      cdgoUic: null,
      clave: '0071,05681,null',
      desgEstacionPlano: 'CICERO',
      activo: true
    },
    {
      cdgoEstacion: '05683',
      cdgoAdmon: '0071',
      nmroPrioridad: 1497,
      descEstacion: null,
      desgEstacion: 'TRETO',
      cdgoUic: null,
      clave: '0071,05683,null',
      desgEstacionPlano: 'TRETO',
      activo: true
    },
    {
      cdgoEstacion: '05685',
      cdgoAdmon: '0071',
      nmroPrioridad: 1498,
      descEstacion: null,
      desgEstacion: 'LIMPIAS',
      cdgoUic: null,
      clave: '0071,05685,null',
      desgEstacionPlano: 'LIMPIAS',
      activo: true
    },
    {
      cdgoEstacion: '05687',
      cdgoAdmon: '0071',
      nmroPrioridad: 1499,
      descEstacion: null,
      desgEstacion: 'MARRÓN',
      cdgoUic: null,
      clave: '0071,05687,null',
      desgEstacionPlano: 'MARRON',
      activo: true
    },
    {
      cdgoEstacion: '05689',
      cdgoAdmon: '0071',
      nmroPrioridad: 1500,
      descEstacion: null,
      desgEstacion: 'UDALLA',
      cdgoUic: null,
      clave: '0071,05689,null',
      desgEstacionPlano: 'UDALLA',
      activo: true
    },
    {
      cdgoEstacion: '05691',
      cdgoAdmon: '0071',
      nmroPrioridad: 1501,
      descEstacion: null,
      desgEstacion: 'GIBAJA',
      cdgoUic: null,
      clave: '0071,05691,null',
      desgEstacionPlano: 'GIBAJA',
      activo: true
    },
    {
      cdgoEstacion: '05721',
      cdgoAdmon: '0071',
      nmroPrioridad: 1502,
      descEstacion: null,
      desgEstacion: 'ARLA-BERRÓN',
      cdgoUic: null,
      clave: '0071,05721,null',
      desgEstacionPlano: 'ARLA-BERRON',
      activo: true
    },
    {
      cdgoEstacion: '05722',
      cdgoAdmon: '0071',
      nmroPrioridad: 1503,
      descEstacion: null,
      desgEstacion: 'UNGO NAVA',
      cdgoUic: null,
      clave: '0071,05722,null',
      desgEstacionPlano: 'UNGO NAVA',
      activo: true
    },
    {
      cdgoEstacion: '05724',
      cdgoAdmon: '0071',
      nmroPrioridad: 1504,
      descEstacion: null,
      desgEstacion: 'MERCADILLO-VILLASANA',
      cdgoUic: null,
      clave: '0071,05724,null',
      desgEstacionPlano: 'MERCADILLO-VILLASANA',
      activo: true
    },
    {
      cdgoEstacion: '05727',
      cdgoAdmon: '0071',
      nmroPrioridad: 1505,
      descEstacion: null,
      desgEstacion: 'CADAGUA',
      cdgoUic: null,
      clave: '0071,05727,null',
      desgEstacionPlano: 'CADAGUA',
      activo: true
    },
    {
      cdgoEstacion: '05730',
      cdgoAdmon: '0071',
      nmroPrioridad: 1506,
      descEstacion: null,
      desgEstacion: 'BERCEDO MONTIJA',
      cdgoUic: null,
      clave: '0071,05730,null',
      desgEstacionPlano: 'BERCEDO MONTIJA',
      activo: true
    },
    {
      cdgoEstacion: '05731',
      cdgoAdmon: '0071',
      nmroPrioridad: 1507,
      descEstacion: null,
      desgEstacion: 'QUINTANA DE LOS PRADOS',
      cdgoUic: null,
      clave: '0071,05731,null',
      desgEstacionPlano: 'QUINTANA DE LOS PRADOS',
      activo: true
    },
    {
      cdgoEstacion: '05732',
      cdgoAdmon: '0071',
      nmroPrioridad: 1508,
      descEstacion: null,
      desgEstacion: 'ESPINOSA DE LOS MONTEROS',
      cdgoUic: null,
      clave: '0071,05732,null',
      desgEstacionPlano: 'ESPINOSA DE LOS MONTEROS',
      activo: true
    },
    {
      cdgoEstacion: '05733',
      cdgoAdmon: '0071',
      nmroPrioridad: 1509,
      descEstacion: null,
      desgEstacion: 'REDONDO',
      cdgoUic: null,
      clave: '0071,05733,null',
      desgEstacionPlano: 'REDONDO',
      activo: true
    },
    {
      cdgoEstacion: '05734',
      cdgoAdmon: '0071',
      nmroPrioridad: 1510,
      descEstacion: null,
      desgEstacion: 'SOTOSCUEVA',
      cdgoUic: null,
      clave: '0071,05734,null',
      desgEstacionPlano: 'SOTOSCUEVA',
      activo: true
    },
    {
      cdgoEstacion: '05735',
      cdgoAdmon: '0071',
      nmroPrioridad: 1511,
      descEstacion: null,
      desgEstacion: 'PEDROSA',
      cdgoUic: null,
      clave: '0071,05735,null',
      desgEstacionPlano: 'PEDROSA',
      activo: true
    },
    {
      cdgoEstacion: '05736',
      cdgoAdmon: '0071',
      nmroPrioridad: 1512,
      descEstacion: null,
      desgEstacion: 'DOSANTE CIDAD',
      cdgoUic: null,
      clave: '0071,05736,null',
      desgEstacionPlano: 'DOSANTE CIDAD',
      activo: true
    },
    {
      cdgoEstacion: '05737',
      cdgoAdmon: '0071',
      nmroPrioridad: 1513,
      descEstacion: null,
      desgEstacion: 'ROBREDO AHEDO',
      cdgoUic: null,
      clave: '0071,05737,null',
      desgEstacionPlano: 'ROBREDO AHEDO',
      activo: true
    },
    {
      cdgoEstacion: '05738',
      cdgoAdmon: '0071',
      nmroPrioridad: 1514,
      descEstacion: null,
      desgEstacion: 'SONCILLO',
      cdgoUic: null,
      clave: '0071,05738,null',
      desgEstacionPlano: 'SONCILLO',
      activo: true
    },
    {
      cdgoEstacion: '05739',
      cdgoAdmon: '0071',
      nmroPrioridad: 1515,
      descEstacion: null,
      desgEstacion: 'CABAÑAS DE VIRTUS',
      cdgoUic: null,
      clave: '0071,05739,null',
      desgEstacionPlano: 'CABANAS DE VIRTUS',
      activo: true
    },
    {
      cdgoEstacion: '05740',
      cdgoAdmon: '0071',
      nmroPrioridad: 1516,
      descEstacion: null,
      desgEstacion: 'ARIJA',
      cdgoUic: null,
      clave: '0071,05740,null',
      desgEstacionPlano: 'ARIJA',
      activo: true
    },
    {
      cdgoEstacion: '05741',
      cdgoAdmon: '0071',
      nmroPrioridad: 1517,
      descEstacion: null,
      desgEstacion: 'LLANO',
      cdgoUic: null,
      clave: '0071,05741,null',
      desgEstacionPlano: 'LLANO',
      activo: true
    },
    {
      cdgoEstacion: '05742',
      cdgoAdmon: '0071',
      nmroPrioridad: 1518,
      descEstacion: null,
      desgEstacion: 'FEVE-LAS ROZAS DE VALDEARROYO',
      cdgoUic: null,
      clave: '0071,05742,null',
      desgEstacionPlano: 'FEVE-LAS ROZAS DE VALDEARROYO',
      activo: true
    },
    {
      cdgoEstacion: '05743',
      cdgoAdmon: '0071',
      nmroPrioridad: 1519,
      descEstacion: null,
      desgEstacion: 'MONTES CLAROS',
      cdgoUic: null,
      clave: '0071,05743,null',
      desgEstacionPlano: 'MONTES CLAROS',
      activo: true
    },
    {
      cdgoEstacion: '05744',
      cdgoAdmon: '0071',
      nmroPrioridad: 1520,
      descEstacion: null,
      desgEstacion: 'LOS CARABEOS',
      cdgoUic: null,
      clave: '0071,05744,null',
      desgEstacionPlano: 'LOS CARABEOS',
      activo: true
    },
    {
      cdgoEstacion: '05747',
      cdgoAdmon: '0071',
      nmroPrioridad: 1521,
      descEstacion: null,
      desgEstacion: 'CILLAMAYOR',
      cdgoUic: null,
      clave: '0071,05747,null',
      desgEstacionPlano: 'CILLAMAYOR',
      activo: true
    },
    {
      cdgoEstacion: '05748',
      cdgoAdmon: '0071',
      nmroPrioridad: 1522,
      descEstacion: null,
      desgEstacion: 'SALINAS DE PISUERGA',
      cdgoUic: null,
      clave: '0071,05748,null',
      desgEstacionPlano: 'SALINAS DE PISUERGA',
      activo: true
    },
    {
      cdgoEstacion: '05749',
      cdgoAdmon: '0071',
      nmroPrioridad: 1523,
      descEstacion: null,
      desgEstacion: 'VADO CERVERA',
      cdgoUic: null,
      clave: '0071,05749,null',
      desgEstacionPlano: 'VADO CERVERA',
      activo: true
    },
    {
      cdgoEstacion: '05750',
      cdgoAdmon: '0071',
      nmroPrioridad: 1524,
      descEstacion: null,
      desgEstacion: 'CASTREJÓN DE LA PEÑA',
      cdgoUic: null,
      clave: '0071,05750,null',
      desgEstacionPlano: 'CASTREJON DE LA PENA',
      activo: true
    },
    {
      cdgoEstacion: '05751',
      cdgoAdmon: '0071',
      nmroPrioridad: 1525,
      descEstacion: null,
      desgEstacion: 'VILLAVERDE TARILONTE',
      cdgoUic: null,
      clave: '0071,05751,null',
      desgEstacionPlano: 'VILLAVERDE TARILONTE',
      activo: true
    },
    {
      cdgoEstacion: '05752',
      cdgoAdmon: '0071',
      nmroPrioridad: 1526,
      descEstacion: null,
      desgEstacion: 'SANTIBAÑEZ DE LA PEÑA',
      cdgoUic: null,
      clave: '0071,05752,null',
      desgEstacionPlano: 'SANTIBANEZ DE LA PENA',
      activo: true
    },
    {
      cdgoEstacion: '05753',
      cdgoAdmon: '0071',
      nmroPrioridad: 1527,
      descEstacion: null,
      desgEstacion: 'GUARDO/APDRO.',
      cdgoUic: null,
      clave: '0071,05753,null',
      desgEstacionPlano: 'GUARDO/APDRO.',
      activo: true
    },
    {
      cdgoEstacion: '05754',
      cdgoAdmon: '0071',
      nmroPrioridad: 1528,
      descEstacion: null,
      desgEstacion: 'GUARDO',
      cdgoUic: null,
      clave: '0071,05754,null',
      desgEstacionPlano: 'GUARDO',
      activo: true
    },
    {
      cdgoEstacion: '05755',
      cdgoAdmon: '0071',
      nmroPrioridad: 1529,
      descEstacion: null,
      desgEstacion: 'LA LLAMA DE LA GUZPEÑA',
      cdgoUic: null,
      clave: '0071,05755,null',
      desgEstacionPlano: 'LA LLAMA DE LA GUZPENA',
      activo: true
    },
    {
      cdgoEstacion: '05756',
      cdgoAdmon: '0071',
      nmroPrioridad: 1530,
      descEstacion: null,
      desgEstacion: 'LA ESPINA',
      cdgoUic: null,
      clave: '0071,05756,null',
      desgEstacionPlano: 'LA ESPINA',
      activo: true
    },
    {
      cdgoEstacion: '05757',
      cdgoAdmon: '0071',
      nmroPrioridad: 1531,
      descEstacion: null,
      desgEstacion: 'VALCUENDE',
      cdgoUic: null,
      clave: '0071,05757,null',
      desgEstacionPlano: 'VALCUENDE',
      activo: true
    },
    {
      cdgoEstacion: '05758',
      cdgoAdmon: '0071',
      nmroPrioridad: 1532,
      descEstacion: null,
      desgEstacion: 'PUENTE ALMUHEY',
      cdgoUic: null,
      clave: '0071,05758,null',
      desgEstacionPlano: 'PUENTE ALMUHEY',
      activo: true
    },
    {
      cdgoEstacion: '05759',
      cdgoAdmon: '0071',
      nmroPrioridad: 1533,
      descEstacion: null,
      desgEstacion: 'PRADO DE LA GUZPEÑA',
      cdgoUic: null,
      clave: '0071,05759,null',
      desgEstacionPlano: 'PRADO DE LA GUZPENA',
      activo: true
    },
    {
      cdgoEstacion: '05760',
      cdgoAdmon: '0071',
      nmroPrioridad: 1534,
      descEstacion: null,
      desgEstacion: 'VALLE DE LAS CASAS',
      cdgoUic: null,
      clave: '0071,05760,null',
      desgEstacionPlano: 'VALLE DE LAS CASAS',
      activo: true
    },
    {
      cdgoEstacion: '05761',
      cdgoAdmon: '0071',
      nmroPrioridad: 1535,
      descEstacion: null,
      desgEstacion: 'CISTIERNA',
      cdgoUic: null,
      clave: '0071,05761,null',
      desgEstacionPlano: 'CISTIERNA',
      activo: true
    },
    {
      cdgoEstacion: '05763',
      cdgoAdmon: '0071',
      nmroPrioridad: 1537,
      descEstacion: null,
      desgEstacion: 'LA ERCINA',
      cdgoUic: null,
      clave: '0071,05763,null',
      desgEstacionPlano: 'LA ERCINA',
      activo: true
    },
    {
      cdgoEstacion: '05766',
      cdgoAdmon: '0071',
      nmroPrioridad: 1540,
      descEstacion: null,
      desgEstacion: 'BOÑAR',
      cdgoUic: null,
      clave: '0071,05766,null',
      desgEstacionPlano: 'BONAR',
      activo: true
    },
    {
      cdgoEstacion: '05767',
      cdgoAdmon: '0071',
      nmroPrioridad: 1541,
      descEstacion: null,
      desgEstacion: 'LA VECILLA',
      cdgoUic: null,
      clave: '0071,05767,null',
      desgEstacionPlano: 'LA VECILLA',
      activo: true
    },
    {
      cdgoEstacion: '05770',
      cdgoAdmon: '0071',
      nmroPrioridad: 1544,
      descEstacion: null,
      desgEstacion: 'MATALLANA',
      cdgoUic: null,
      clave: '0071,05770,null',
      desgEstacionPlano: 'MATALLANA',
      activo: true
    },
    {
      cdgoEstacion: '05776',
      cdgoAdmon: '0071',
      nmroPrioridad: 1550,
      descEstacion: null,
      desgEstacion: 'SAN FELIZ',
      cdgoUic: null,
      clave: '0071,05776,null',
      desgEstacionPlano: 'SAN FELIZ',
      activo: true
    },
    {
      cdgoEstacion: '05778',
      cdgoAdmon: '0071',
      nmroPrioridad: 1552,
      descEstacion: null,
      desgEstacion: 'FEVE-LEÓN',
      cdgoUic: null,
      clave: '0071,05778,null',
      desgEstacionPlano: 'FEVE-LEON',
      activo: true
    },
    {
      cdgoEstacion: "03410",
      cdgoAdmon: "0071",
      nmroPrioridad: 9999,
      descEstacion: null as string | null,
      desgEstacion: "ELCHE AV/ELX AV",
      cdgoUic: "03410",
      clav: "0071,03410,03410",
      desgEstacionPlano: "ELCHE AV/ELX AV",
      activo: true
    },
  ];
}
