import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  booleanAttribute,
  forwardRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Slider, SliderModule } from 'primeng/slider';
import { EColorSlider, EEstadoSlider, EModeloSlider } from './models/enums';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NumberToTimePipe } from '@destinux/destinux-pipes';
import { TooltipModule } from 'primeng/tooltip';
import { ControlValue } from '../../../../../libs/destinux-components/src/utiles/ControlValue';
import { EnumsLib } from '../../utiles/enums';

@Component({
  selector: 'destinux-slider',
  standalone: true,
  imports: [
    CommonModule,
    SliderModule,
    FormsModule,
    NumberToTimePipe,
    TooltipModule,
  ],
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SliderComponent),
    },
  ],
})
export class SliderComponent
  extends ControlValue
  implements OnInit, AfterViewInit
{
  @ViewChild(Slider) _slider: Slider | undefined;

  //#region inputs
  @Input() set min(val){
    this.#min = val;
    if(this.value && this.value.length>0){
    this.value[0] = val;
    }
  }
  get min(){
    return this.#min;
  }; // NOTA: para formato tiempo, debe ponerse la cantidad de MINUTOS
  @Input() set max(val){
    this.#max = val;
    if(this.value && this.value.length>1){
    this.value[1] = val;
    }
  }
  get max(){
    return this.#max;
  };
  @Input() color: EColorSlider;
  @Input() estado: EEstadoSlider;
  @Input() modelo: EModeloSlider;
  @Input() step: number = 1; // NOTA: para formato tiempo, debe ponerse la cantidad de MINUTOS (ej: 30, para ir de media en media hora)
  esRango: boolean = false;
  @Input({ transform: booleanAttribute }) esFormatoTiempo: boolean = false;
  @Input() datosBase: number[];
  @Input() tooltipStart: string | undefined;
  @Input() tooltipEnd: string | undefined;
  //#endregion

  //#region output
  @Output() minChange: EventEmitter<number> = new EventEmitter<number>();
  //#endregion

  //#region declaración variables, constantes y funciones
  #min: number = 0;
  #max: number = 0;
  valoresSeleccionados: { minValue: number; maxValue: number };
  valoresModelo = EModeloSlider;
  coloresSlider = EColorSlider;
  errores: string[];
  clases: any[];
  stringClases: string;
  readonly valoresPorDefecto: any = {
    icono: null,
    imagen: null,
    color: EColorSlider.primario,
    modelo: EModeloSlider.continuo,
    estado: EEstadoSlider.activo,
    esRango: false,
  };

  public static enums = EnumsLib;
  //#endregion

  constructor() {
    super();
    this.clases = [];
    this.stringClases = '';
    this.color = this.valoresPorDefecto.color;
    this.modelo = this.valoresPorDefecto.modelo;
    this.estado = this.valoresPorDefecto.estado;
    this.errores = [];
    this.datosBase = [0, 0];
    this.valoresSeleccionados = { minValue: this.min, maxValue: this.max };
  }

  ngOnInit(): void {
    this.clasesBase();
    this.clasesEstilo();
    this.montarClases();
    this.value = this.datosBase;

    if (this.errores.length > 0) console.error(this.errores);

  }

  async ngAfterViewInit() {
    if (this.tooltipStart) {
      const sliderStart = this._slider?.sliderHandleStart?.nativeElement;
      sliderStart.classList.add('tooltip');
      sliderStart.innerHTML = `<span class="tooltiptext">${this.tooltipStart}</span>`;
    }

    if (this.tooltipEnd) {
      const sliderEnd = this._slider?.sliderHandleEnd?.nativeElement;
      sliderEnd.classList.add('tooltip');
      sliderEnd.innerHTML = `<span class="tooltiptext">${this.tooltipEnd}</span>`;
    }
  }

  clasesBase() {
    this.clases = [
      ...[
        'g-width-fill',
        'g-height-xp',
        'c-slider',
        'g-padding-vertical-xp',
        'g-font-family-secundaria',
      ],
    ];
  }

  montarClases(): void {
    this.stringClases = this.clases.join(' ').toLowerCase();
  }

  clasesEstilo(): void {
    let clases = [];
    clases.push(
      `c-slider--${EModeloSlider[this.modelo]}-${EColorSlider[this.color]}`
    );
    if (this.estado == EEstadoSlider.desactivado) clases.push('p-disabled');
    this.anyadirClases(clases);
  }

  anyadirClases(clases: string[]): void {
    clases.forEach((element) => {
      this.clases.push(element);
    });
  }
}
