import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EPosicionNotificacion, ETipoNotificacion } from './models/enums';
import { IconoSvgComponent } from '../icono-svg/icono-svg.component';
import { EModeloIcono } from '../icono-svg/models/enums';
import { EnumsLib } from '../../utiles/enums';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'destinux-notificacion',
  standalone: true,
  templateUrl: './notificacion.component.html',
  styleUrls: ['./notificacion.component.scss'],
  imports: [CommonModule, IconoSvgComponent, ToastModule],
  providers: [MessageService]
})
export class NotificacionComponent {
  //#region inputs
  @Input({ required: true }) tipo!: ETipoNotificacion;
  @Input() titulo: string = 'Titulo';
  @Input() mensaje: string = 'Mensaje';
  @Input() posicion: EPosicionNotificacion = EPosicionNotificacion.centro;
  @Input({ required: true }) set mostrarNotificacion(value: boolean) {
    if (value) {
      setTimeout(() => {
        this.show();
      this._mostrarToast = false;
      this.mostrarNotificacionChange.emit(false);
      }, 100);
      
    }
    else{
    this._mostrarToast = value;
    this.mostrarNotificacionChange.emit(value);
    }
  }
  get mostrarNotificacion() {
    return this._mostrarToast;
  }
  //#endregion

  //#region output
  @Output() mostrarNotificacionChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  //#endregion

  //#region variables
  private _mostrarToast: boolean = false;

  public enums = EnumsLib;
  //#endregion

  constructor(private messageService: MessageService) {}

  //#region metodos
  show() {
    this.messageService.add({
      severity: this.tipo,
      summary: this.titulo,
      detail: this.mensaje,
    });
  }
  //#endregion

  //#region html
  getColorICono() {
    return this.enums.EModeloIcono[
      this.tipo as unknown as keyof typeof EModeloIcono
    ];
  }

  hndCerrarNotificacion(): void {
    this.mostrarNotificacion = false;
  }

  getClassNotificacion() {
    if (!this.mostrarNotificacion) {
      return 'mostrar__cerrar ' + this.posicion;
    } else {
      return 'mostrar__abrir ' + this.posicion;
    }
  }
  //#endregion
}
