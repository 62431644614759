import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, Inject, HostListener } from '@angular/core';
import { IconoSvgComponent } from "../icono-svg/icono-svg.component";
import { Icon } from '../../iconos/iconos';
import { EnumsLib } from '../../utiles/enums';

@Component({
    selector: 'destinux-scroll-to-top',
    standalone: true,
    templateUrl: './scroll-to-top.component.html',
    styleUrls: ['./scroll-to-top.component.scss'],
    imports: [CommonModule, IconoSvgComponent]
})
export class ScrollToTopComponent {
  
  //#region hostlistener
  @HostListener('window:scroll', []) onWindowScroll() {
    if (window.scrollY || document.documentElement.scrollTop || document.body.scrollTop > 100) {
      this.windowScrolled = true;
    } else if (
      (this.windowScrolled && window.scrollY) ||
      document.documentElement.scrollTop ||
      document.body.scrollTop < 10
    ) {
      this.windowScrolled = false;
    }
  }
  //#endregion
  
  //#region variables
  windowScrolled!: boolean;
  tamanyoMovil!: boolean;
  public Icon = Icon;

  public enums = EnumsLib;
  //#endregion

  constructor(@Inject(DOCUMENT) private document: Document) {}

  

  scrollToTop() {
    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - currentScroll / 8);
      }
    })();
  }
}

