<div class="{{stringClases}}">
    <ng-container *ngIf="modelo == valoresModelo.continuo || modelo == valoresModelo.discreto">
        <p-slider [(ngModel)]="value" [step]="step" (ngModelChange)="EscribirValor()"></p-slider>
        <div *ngIf="modelo == valoresModelo.discreto" class="g-margin-top-10 g-font-family-secundaria">{{value}}</div>
    </ng-container>
    <ng-container *ngIf="modelo == valoresModelo.rango">
        <p-slider [(ngModel)]="value" [range]="true" [step]="step" [min]="min" [max]="max" (ngModelChange)="EscribirValor()"></p-slider>
        <div class="g-margin-top-10 g-flex g-flex--justify-content-space-between g-font-family-secundaria">
            <ng-container *ngIf="esFormatoTiempo">
                <div>{{ value[0] | numberToTime }}</div>
                <div>{{ value[1] | numberToTime }}</div>
            </ng-container>
            <ng-container *ngIf="!esFormatoTiempo">
                <div>{{value[0]}}</div> 
                <div>{{value[1]}}</div>
            </ng-container>
        </div>
    </ng-container>
</div>
