export const peticionRenaCar = {
    "Recogida": {
        "Nombre": "The View Fish & Chips",
        "NombreCompleto": "The View Fish & Chips",
        "Ciudad": "Madrid",
        "Pais": "ESP",
        "CodigoPais": "ESP",
        "Latitud": "40.46317",
        "Longitud": "-3.74983",
    },
    "Devolucion": {
        "Nombre": "Madrid, Comunidad de Madrid, España",
        "NombreCompleto": "Madrid, Comunidad de Madrid, España",
        "Ciudad": "Madrid",
        "Pais": "ESP",
        "CodigoPais": "ESP",
        "Latitud": "40.41955",
        "Longitud": "-3.69196",
    },
    "FechaRecogida": "2024-07-25",
    "FechaDevolucion": "2024-07-30",
    "RecogidaDevolucionDistintos": true,
    "HoraRecogida": "09:00",
    "HoraDevolucion": "12:00",
    "ViajerosSeleccionados": [
        {
            "id_cliente": 43921,
            "ramaTraducida": "Arribas Blasco, Mario | Dpto Informatico | Turisgestion, S.L. | Grupo Gheisa",
            "ramaEstilizada": "<span class=\"viajero\">Arribas Blasco, Mario</span><span class=\"departamento\">Dpto Informatico</span><span class=\"empresa\">Turisgestion, S.L.</span><span class=\"departamento\">Grupo Gheisa</span>",
            "Nombre": "Mario",
            "Apellidos": "Arribas Blasco",
            "NumIdentificacion": "52659401G",
            "TlfEmpresa": "34-666666666",
            "CorreoEmpresa": "coscado@gmail.com",
            "rama": [
                43921,
                37402,
                234,
                29485
            ],
            "guid": "14bd00c2-ed7f-4dfe-b234-4e47558db652",
            "seleccionada": false,
            "SoloAgenda": false,
            "ParteEmpresa": [
                234,
                29485
            ],
            "ParteExtra": [
                37402
            ]
        }
    ],
    "Pais": {
        "id": 724,
        "alpha2": "es",
        "alpha3": "esp",
        "name": "España",
        "lat": "40.463667",
        "long": "-3.74922",
        "prefix": 34
    }
}