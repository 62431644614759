export enum EIcono{
    Error= 'error',
    Success = 'success',
    Info = 'info',
    Warning = 'warning',
    Question = 'question'
}

export enum ETitulos{
    Error = 'admin.comun.error',
    Atencion = 'admin.comun.atencion',
    SinResultado = 'admin.comun.sin-resultados-titulo',
    ErrorPeticion = 'admin.comun.error-peticion',
    ErrorRecurso = 'admin.comun.error-recurso',
    ErrorServidor = 'admin.comun.error-servidor-no-disponible',
    ErrorPermisos = 'admin.albaranes.error-permisos'
}

export enum EPosicion{
    Arriba = 'top',
    ArribaInicio = 'top-start',
    ArribaFinal = 'top-end',
    ArribaIzquierda = 'top-left',
    ArribaDerecha = 'top-right',
    Centro = 'center',
    CentroInicio = 'center-start',
    CentroFinal = 'center-end',
    CentroIzquierda = 'center-left',
    CentroDerecha = 'center-right',
    Abajo = 'bottom',
    AbajoInicio = 'bottom-start',
    AbajoFinal = 'bottom-end',
    AbajoIzquierda = 'bottom-left',
    AbajoDerecha = 'bottom-right'
}