import { Directive, Input, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[destinux-plus99]',
  standalone: true,
})
export class Plus99Directive implements OnInit {
  @Input('destinux-plus99') valor!: number ;

  constructor(private el: ElementRef) { }

  ngOnInit() {
    if (this.valor >= 100) {
      this.el.nativeElement.textContent = '+99';
    } else {
      this.el.nativeElement.textContent = this.valor;
    }
  }
}





