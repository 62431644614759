export class Funciones {
  static descargarBlob(body: any, arg1: string) {
    throw new Error('Method not implemented.');
  }
  static ValidarValorEnum<T extends Record<number, string>>(valor: string, enumObject: T): boolean {
    return (Object.keys(enumObject) as Array<string>).includes(valor);
  }

  static dividirEnteroDecimal(numero: number) {
    const partesNumero = numero.toFixed(2).split('.');
    return { parteEntera: partesNumero[0], parteDecimal: partesNumero[1] };
  }
}


