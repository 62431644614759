import { Directive, Input, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[destinuxColorPorSigno]',
  standalone: true,
})
export class ColorPorSignoDirective {
  @Input('destinuxColorPorSigno') numero!: number;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnChanges() {
    this.setEstilo();
  }

  private setEstilo() {
    let estilo = '';
    switch (Math.sign(this.numero)) {
      case -1:
        estilo = 'g-color-boton-error';
        break;
      case 0:
        estilo = 'g-color-boton-info';
        break;
      case 1:
        estilo = 'g-color-boton-exito-400';
        break;
    }
    this.renderer.addClass(this.el.nativeElement, estilo);
  }
}
