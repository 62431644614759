/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { ServiceHttpService } from '../../service-http.service';
import { BehaviorSubject, Observable, firstValueFrom, map, of } from 'rxjs';
import { LoginService } from '../../sesion/login/login.service';
import { environment } from '@env/environment';
import { EnvironmentEnum } from '@env/environments.enum';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ServicioGeneralService {

  //#region variables
  #_loading = new BehaviorSubject<boolean>(false);
  loading$ = this.#_loading.asObservable();

  #_NavegadorIE = new BehaviorSubject<boolean>(false);
  NavegadorIE$ = this.#_NavegadorIE.asObservable();


  #_lenguaje = new BehaviorSubject<string>(localStorage.getItem('lang') || this.translateService.currentLang); 
  lenguaje$ = this.#_lenguaje.asObservable();;
  //#endregion

  //#region constructor
  constructor(private _httpService: ServiceHttpService, private _loginService: LoginService, private translateService: TranslateService ) {}
  //#endregion

  //#region metodos getter ande setter
  setLoading(valor: boolean): void {
    this.#_loading.next(valor);
  }

  setNavegadorIE(valor: boolean): void {
    this.#_NavegadorIE.next(valor);
  }

  getNavegadorIE(): boolean {
    return this.#_NavegadorIE.value;
  }

  getLenguaje(): string {
    return this.#_lenguaje.value;
  }

  async setLenguaje(lenguaje: string): Promise<void> {
    const estaLogueado = this._loginService.getLoggedInValue();
    const lenguajeActual = localStorage.getItem('lang');
    if (lenguajeActual != lenguaje) {
      localStorage.setItem('lang', lenguaje);
      if (estaLogueado) await firstValueFrom(this.seleccionarLenguajeServidor(lenguaje));  
      console.log('LS lang: ', localStorage.getItem('lang'));
        this.#_lenguaje.next(lenguaje);
    }
  }
  //#endregion
  
  //#region metodos httpService
  seleccionarLenguajeServidor(lenguaje:string) : Observable<any> {
    console.log('hace cambio lang en servidor a: ', lenguaje);
    if (environment.entorno != EnvironmentEnum.DEMO) {
      return this._httpService.getApi(`SeleccionarLenguaje?id=${lenguaje}`);
    } else {
      return of(true);
    }
  }

  getCountries(): Observable<any>{
    const lenguaje = this.getLenguaje()?.split('-')[1].toLowerCase();
    return this._httpService.get(environment.baseArchivoPruebas + `paises/${this.getLenguaje()}/countries.json`).pipe(
      map((data: any) => {
        return data.sort((a: any) => a.alpha2 === lenguaje ? -1 : 1);
      })
    )
  }
  //#endregion
}
