import { ControlValueAccessor } from "@angular/forms";

/* eslint-disable @typescript-eslint/no-explicit-any */
export abstract class ControlValue implements ControlValueAccessor{
  value: any;
  isDisabled: boolean = false;
  onChange = (_: any) => {};
  onTouch = () => {};

  constructor() {
  }

  writeValue(value: any): void {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  EscribirValor(): void{
    this.onTouch();
    this.onChange(this.value);
  }
}

