import { Component, Input, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValue } from '../../utiles/ControlValue';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IconoSvgComponent } from '../icono-svg/icono-svg.component';
import { Icon } from '../../iconos/iconos';
import { EnumsLib } from '../../utiles/enums';

@Component({
  selector: 'destinux-numbers',
  standalone: true,
  imports: [CommonModule, IconoSvgComponent],
  templateUrl: './numbers.component.html',
  styleUrls: ['./numbers.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumbersComponent),
      multi: true,
    },
  ],
})
export class NumbersComponent extends ControlValue{
  //#region inputs
  /** indica el numero máximo que se le puede asignar */
  @Input() maximo: number = 0;
  /** indica el numero mínimo que se puede asignar */
  @Input() minimo: number = 0;
  //#endregion

  //#region variables
  public Icon = Icon;
  public enums = EnumsLib;
  //#endregion

  //#region constructor
  constructor(){
    super();
    this.value = this.minimo;
  }
  //#endregion

  //#region metodos usados en html
  mas(): void {
    const suma = this.value + 1;
    if (suma <= this.maximo) {
      this.value++;
    }
    this.EscribirValor();
  }

  menos(): void {
    const resta = this.value - 1;
    if (resta >= this.minimo) {
      this.value--;
    }
    this.EscribirValor();
  }
  //#endregion
}
