import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconoSvgComponent } from '../icono-svg/icono-svg.component';
import { Icon } from '../../iconos/iconos';
import { EnumsLib } from '../../utiles/enums';

@Component({
  selector: 'destinux-estrellas',
  standalone: true,
  imports: [CommonModule, IconoSvgComponent
  ],
  templateUrl: './estrellas.component.html',
  styleUrls: ['./estrellas.component.scss'],
})
export class EstrellasComponent implements OnInit {
  //#region variables
  categorias: number[] | undefined = [];
  estrella: any = {};
  public enums = EnumsLib;
  //#endregion

  //#region input
  @Input() set categoria(valor: string | undefined | null) {
    this.categorias = valor?.split(',').map((numeroString) => parseInt(numeroString, 10));
  }
  //#endregion

  ngOnInit() {
    this.estrella = {
      activa: Icon.EstrellaActiva,
      enfocada: Icon.EstrellaEnfocada,
      seleccionada: Icon.EstrellaSeleccionada,
    };
  }
  
}
