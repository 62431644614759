import { AfterViewInit, Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[destinuxTruncarTexto]',
  standalone: true,
})
export class TruncarTextoDirective implements AfterViewInit{
  @Input('destinuxTruncarTexto') maxLength!: number;

  private textoOriginal!: string;
  
  constructor(private el: ElementRef) {}
    
  ngAfterViewInit() {
    if(this.maxLength != 0) {    
      this.truncarTexto();
    }
  }

  private truncarTexto() {  
    const elementoHTML = this.el.nativeElement;
    this.textoOriginal = elementoHTML.innerText || elementoHTML.textContent;  
    if(this.textoOriginal.length > this.maxLength) {      
      const textoTruncado = this.textoOriginal.substring(0, this.maxLength) + '...';
      elementoHTML.innerText = textoTruncado;
    }
  }
}
