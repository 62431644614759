import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { obtenerVariablesCSS } from '../../utiles/variablesScss';
import { environment } from '@destinux-components/environment';


@Component({
  selector: 'destinux-spinner',
  standalone: true,
  imports: [CommonModule, TranslateModule, NgxSpinnerModule],
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit{
//#region inputs
  //INFO: background-color siempre en formato RGBA.
  @Input() backgrColorRGBA: string = obtenerVariablesCSS("--backgroundLista-base-claro");
  @Input() spinnerColor: string = obtenerVariablesCSS("--backgroundLista-boton-destaque-activo");
  @Input() texto: string = "spinner-global.texto";
  /*INFO: para que el componente spinner se adapte al tamaño de
  su contenedor es necesario que el contenedor tenga la propiedad position: relative */
  @Input() esPantallaCompleta: boolean = false;
  //INFO: path al archivo .gif en iconografia
  @Input() imagenServidorGIF!: string;
  @Input() esVisible: boolean = false;
  @Input() estilosSpinner: string = '';
//#endregion
//#region variables
  public nombre: string = '';
  public imagenSpinner!: string;
  #imagenServidorDestinux: string = "nuevoestiloweb/spinners/loadingSelfbooking.gif"
//#endregion
//#region constructor
  constructor(private _spinner: NgxSpinnerService) {
    /*INFO: al ser necesario conectarse al servicio, se genera un nombre aleatorio del tipo "ahn3e_1703242468419" 
    para diferenciar por nombre a todos los spinners de la web y que no se muestren todos a la vez. */
    this.nombre = `${Math.random().toString(36).slice(2, 7)}_${new Date().getTime()}`;
  }
//#endregion
//#region hook-cycles
  ngOnInit() {
    this.imagenServidorGIF = this.imagenServidorGIF ?? this.#imagenServidorDestinux;
    this.imagenSpinner = '<img src="' + environment.recursos + this.imagenServidorGIF + '" class="' + this.estilosSpinner + '" style="display: block; margin: 0 auto;" width="50%" />';
  }

  ngOnChanges(changes: SimpleChanges): void {
    const esVisible = changes['esVisible'];
    if(esVisible) {
      esVisible.currentValue ? this._spinner.show(this.nombre) : this._spinner.hide(this.nombre);
    }
  }
//#endregion
}
