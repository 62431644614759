export enum EResultadoDeLaOperacion {
  Confirmada = 0,
  Revisar = 1,
  NoRealizada = 2,
  Desconocido = 3,
}

export enum ETipoProducto {
  Hotel = 1,
  Aereo = 2,
  RentAcar = 3,
  Barco = 4,
  Tren = 5,
  Transfer = 9
}

export enum EPuntoViaje {
  'Origen' = 1,
  'Destino' = 2,
}

export enum ETipoViaje {
  'Ida' = 1,
  'Vuelta' = 2,
}

export enum EProductoReglas{
  Hotel = 'H',
  Aereo = 'V',
  RentAcar = 'R',
  Barco = 'B',
  Tren = 'T'
}

export enum ETipoUbicacion{
  Origen = 'origen',
  Destino = 'destino'
}

export interface IItemEnum {  // se copia desde barcos.ts
  Clave: number;
  Valor: string;
}

export enum ETipoTrayecto{
  IdayVuelta = 'IdayVuelta',
  SoloIda = 'SoloIda'
}