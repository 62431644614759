export * from './boton/boton.component';
export * from './icono-svg/icono-svg.component';
export * from './switch/switch.component';
export * from './precio/precio.component';
export * from './estrellas/estrellas.component';
export * from './tarjeta-empleado/tarjeta-empleado.component';
export * from './departamento/departamento.component';
export * from './selectorMultiplePrime/selector-multiple-prime.component';
export * from './inputs/inputs.component';
export * from './switchPrime/switch-prime.component';
export * from './checkboxPrime/checkbox-prime.component';
export * from './radioButtonPrime/radio-button-prime.component';
export * from './calendar/calendar.component';
export * from './slider/slider.component';
export * from './numbers/numbers.component';
export * from './selectorBandera/selector-bandera.component';
export * from './seleccionEstrellas/seleccion-estrellas.component';
export * from './dropdown/dropdown.component';
export * from './seleccionModal/seleccion-modal.component';
export * from './scroll-to-top/scroll-to-top.component';
export * from './switch-doble-texto/switch-doble-texto.component';
export * from './notificacion/notificacion.component';
export * from './barra-novedades-web/barra-novedades-web.component';
export * from './desplegable-acordeon/desplegable-acordeon.component';
export * from './numero-formateado/numero-formateado.component';
export * from './spinner/spinner.component';

export * from './seleccionModal/modelo/lista';
export * from './tarjeta-empleado/modelo/empleado';
export * from './informacion/informacion.component';

export * from './modal/modal.component';

