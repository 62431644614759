import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'destinux-switch-doble-texto',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './switch-doble-texto.component.html',
  styleUrls: ['./switch-doble-texto.component.scss'],
})
export class SwitchDobleTextoComponent {
  //#region inputs
  @Input() esPrimeraOpcion: boolean = false;
  @Input() primerTexto: string = "";
  @Input() segundoTexto: string = "";
  //#endregion

  //#region outputs
  @Output() onChange = new EventEmitter<void>(); 
  @Output() estado: EventEmitter<boolean> = new EventEmitter<boolean>();
  //#endregion

  //#region meetodos  
  private change(){
    this.esPrimeraOpcion = !this.esPrimeraOpcion;
  }
  //#endregion

  //#region metodos html
  hndCambiarSeleccion(): void {
    this.change();
    this.onChange.emit();
    this.estado.emit(this.esPrimeraOpcion);
  }
  //#endregion
}
