<div class="g-flex g-flex--align-items-center g-flex--gap-xxp g-font--boton-xp 
    g-font--boton-md@md g-color-parrafo g-padding-vertical-2 g-padding-horizontal-2 g-background-color-icono-desactivado 
    g-border-radius-xp g-height-md g-height-gd@md g-cursor-pointer" (click)="hndCambiarSeleccion()">
  <div [ngClass]="!esPrimeraOpcion ? 'vista-seleccionada' : 'g-padding-left-xp no-seleccionada'">
    {{ primerTexto }}
  </div> <!--TODO: traducciones-->
  <div [ngClass]=" esPrimeraOpcion ? 'vista-seleccionada' : 'g-padding-right-xp no-seleccionada'">
    {{ segundoTexto }}
  </div> <!--TODO: traducciones-->
</div>
