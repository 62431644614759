
<p-dropdown [options]="paises" [(ngModel)]="value" optionLabel="valor" [showClear]="false" (ngModelChange)="hndCambiarIdioma()">
    <ng-template pTemplate="selectedItem">
        <div class="flex align-items-center gap-2" *ngIf="value">
            <img [src]="value.valor" class="icono-bandera--seleccionada"/>
        </div>
    </ng-template>
    <ng-template let-obj pTemplate="item">
        <div class="flex align-items-center" *ngIf="obj !== value">
            <img [src]="obj.valor" style="width: 2rem; height: 2rem;"/>
        </div>
    </ng-template>
</p-dropdown>