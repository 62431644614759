/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { ServiceHttpService } from '../../service-http.service';
import { BehaviorSubject, Observable, lastValueFrom, tap, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { EnvironmentEnum } from '@env/environments.enum';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class LoginService {

  //#region declaración de variables
  #userLoggedIn = new BehaviorSubject(false);
  public userServerLang: string | null = null;
  //#endregion

  //#region constructor
  constructor(
    private _httpService: ServiceHttpService,
    private router: Router,
    private _authService: AuthService
  ) { }
  //#endregion

  //#region metodos getter and setter
  getLoggedIn(): Observable<boolean> {
    return this.#userLoggedIn.asObservable();
  }

  getLoggedInValue(): boolean {
    return this.#userLoggedIn.getValue();
  }

  setLoggedIn(val: boolean): void {
    this.#userLoggedIn.next(val);
  }

  getUserServerLang(): string | null {
    return this.userServerLang;
  }

  setUserServerLang(value: string): void {
    this.userServerLang = value;
  }
  //#endregion

  //#region metodos httpService
  postLogin(log_user: any, token: string | null = null, provider: string | null = null): Observable<any> {
    const credencialesDemo = {
      dni: '12345678Z',
      password: '123456789',
    };
    if (environment.entorno == EnvironmentEnum.DEMO) {
      if (JSON.stringify(log_user) != JSON.stringify(credencialesDemo)) {
        return throwError(() =>
          new HttpErrorResponse({
            error: 'CredencialesKo',
            status: 401,
            statusText: 'Error',
          })
        );
      } else {
        return this._httpService.get(
          '',
          environment.baseJsonPruebas + 'login/respuesta_login.json',
          { observe: 'response' }
        );
      }
    } else {
      const httpOptions = {
        'Content-Type': 'application/x-www-form-urlencoded',
      };

      let peticion = `username=${log_user.dni}&password=${encodeURIComponent(log_user.password)}&grant_type=password`;
      if (token !== null) {
        peticion = `Token=${token}&Provider=${provider}&grant_type=password`;
      }
      return this._httpService.post<any>(
        `/api/login`, peticion, null,
        { headers: httpOptions, observe: 'response' }
      )
    }
  }

  getInfoUser(): Observable<any> {
    return this._httpService.getApi(
      'DatosPerfil',
      environment.baseJsonPruebas + 'respuestas_API/login/res_DatosPerfil.json'
    );
  }

  public getRespuestaMensajesMantenimiento(): Observable<any> {
    return this._httpService.get(`${environment.recursos}noticias/mantenimiento.json`).pipe(
      tap((respuesta) => {
        localStorage.setItem('mensajesMantenimiento', JSON.stringify(respuesta));
      })
    );
  }

  public getVersionApis(): Observable<any> {
    if(environment.entorno == EnvironmentEnum.DEMO) return this._httpService.get(environment.baseJsonPruebas + 'version_APIs/version_mantenimiento_API.json');
    return this._httpService.get('version/Select'); // NOTA: not call SERVICEHTTPSERVICE here
  }

  getCorreoProvider(): Promise<any> {
    return lastValueFrom(this._httpService.get(environment.baseUrl + "/.auth/me"));
  }

  logoutAzure(): Promise<any> {
    return lastValueFrom(this._httpService.get(environment.baseUrl + "/.auth/logout"));
  }
  //#endregion

  //#region metodos
  isUserAuthenticated(): boolean {
    if (!localStorage.getItem('user_token')) {
      return false;
    }
    return true;
  }

  getLogOut(datosCaducados: boolean): void { // Puede ser con sesión activa o caducada, programáticamente
    this.gestionarAccionesLogout(datosCaducados);
  }

  GetLogOutPorUsuario() { // Siempre con sesión activa, iniciado por acción del usuario
    this.cerrarSesionApi();
  }

  cerrarSesionApi(): void {
    if (environment.entorno == EnvironmentEnum.DEMO) {
      this.gestionarAccionesLogout(false);
      return;
    }

    this._httpService.getApi('LogOut')
      .subscribe({
        next: (response) => {
          this.gestionarAccionesLogout(false);
        }, error: (error) => {
          console.error('Error en LogOut: ', error.Message || 'Error');
          this.router.navigateByUrl(`/login`, { state: { sesionCaducada: false } });
        }
      });
  }

  gestionarAccionesLogout(sesionCaducada: boolean): void {
    this._authService.setUltimaAccion(null);
    localStorage.clear();
    this.setLoggedIn(false);
    this.router.navigateByUrl(`/login${sesionCaducada ? '/r' : ''}`, { state: { sesionCaducada: sesionCaducada } })
    .then((navigated)=>{
      if(navigated) window.location.reload();
    });
  }
  //#endregion
}
