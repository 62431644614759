<ngx-spinner
  [name]="nombre"
  [bdColor]="backgrColorRGBA"
  [color]="spinnerColor"
  [template]="imagenSpinner"
  [fullScreen]="esPantallaCompleta"
  [zIndex]="esPantallaCompleta ? 99999 : 1109">
  <p class="g-font--subtitulo-md g-margin-top-160 g-width-144 g-width-fill@md g-text-align-center" [style.color]="spinnerColor">
    {{ texto | translate }}
  </p>
</ngx-spinner>
