import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { LoginService } from '../services/sesion/login/login.service';
import { AuthService } from '@services/sesion/auth/auth.service';

export const AuthGuard: CanActivateFn = (route, state) => {  // NOTA: código equivalente a CanActivateGuard
  const _authService: AuthService =inject(AuthService);
  const _loginService: LoginService = inject(LoginService);
  // console.log('url.route authGuard: ', route.url);
  const url = `/${route.url.join('/')}`
  // console.log('url authGuard: ', url);
  if (_authService.isAuthenticated(url)) {
    return true;
  }
  _loginService.getLogOut(_authService.getToken() ? true : false);
    return false;
};
