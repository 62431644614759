import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { provideRouter } from '@angular/router';
import { routes } from './app/app.routes';
import { LOCALE_ID, APP_INITIALIZER, importProvidersFrom, isDevMode } from '@angular/core';
import localeEs from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt-PT';
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
registerLocaleData(localeEs);
registerLocaleData(localePt);
registerLocaleData(localeFr);
registerLocaleData(localeDe);
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { provideServiceWorker } from '@angular/service-worker';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { HttpLoaderFactory, appInitializerFactory } from './app/core/utils/initialTranslate';
import { AuthInterceptorService } from './app/core/interceptors/auth-interceptor/auth-interceptor.service';
import { ControlErroresService } from './app/core/interceptors/controlErrrores/control-errores.service';
import { registerLocaleData } from '@angular/common';
import { ServicioGeneralService } from './app/core/services/general/servicio-general/servicio-general.service';
import { ErroresService } from './app/core/services/control-errores/errores/errores.service';
import { AlertasSwalService } from './app/core/utils/alertas/swal/alertas-swal.service';
import { environment } from '@destinux/environment';
import { EnvironmentEnum } from '@env/environments.enum';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(routes),
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptorService,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: ControlErroresService,
        multi: true,
        deps:[AlertasSwalService, ErroresService]
    },
    {
        provide: LOCALE_ID,
        deps: [ServicioGeneralService],
        useFactory: (servicioGeneral:any) => {
        return servicioGeneral.getLenguaje();
        }
    },
    {
        provide: APP_INITIALIZER,
        useFactory: appInitializerFactory,
        deps: [TranslateService],
        multi: true
    },
    provideAnimations(),
    importProvidersFrom([BrowserModule, BrowserAnimationsModule, HttpClientModule,
      TranslateModule.forRoot({
          loader: {
              provide: TranslateLoader,
              useFactory: HttpLoaderFactory,
              deps: [HttpClient]
          }
      }),
      SweetAlert2Module.forRoot()      
    ]),
  provideServiceWorker('ngsw-worker.js', {
      enabled: environment.entorno != EnvironmentEnum.LOCAL && !document.baseURI.includes('localhost'), // NOTA: evita habilitar SW en modo desarrollo apuntando a test (evita loop con webpack)
      registrationStrategy: 'registerWhenStable:30000'
  })
  ],
})
.then(()=>{
    if(document.baseURI.includes('localhost')) return; // NOTA: evita registro SW en modo desarrollo en modo desarrollo (evita loop con webpack)
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('ngsw-worker.js');
    }
})
.catch((err)=> console.error(err));
