import { Component, EventEmitter, Output, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import {
  FormsModule, NG_VALUE_ACCESSOR
} from '@angular/forms';
import { IItem } from '../../utiles/Item';
import { ControlValue } from '../../utiles/ControlValue';
import { environment } from "@destinux-components/environment";
import { Icon } from '../../iconos/iconos';

@Component({
  selector: 'destinux-selector-bandera',
  standalone: true,
  templateUrl: './selector-bandera.component.html',
  styleUrls: ['./selector-bandera.component.scss'],
  imports: [CommonModule, DropdownModule, FormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectorBanderaComponent),
      multi: true,
    },
  ],
})
export class SelectorBanderaComponent extends ControlValue{

  //#region outputs
  @Output() onChangeIdioma: EventEmitter<string> = new EventEmitter();
  //#endregion

  //#region variables
  public paises: IItem[] = [];
  readonly urlRecursos = environment.recursos;
  //#endregion

  ngOnInit() {
    this.inicializadorPaises();
    this.asignarIdiomaLS();
  }
  //#region metodos
  inicializadorPaises() {
    this.paises = [
      {
        valor: Icon.BanderaEs,
        clave: 'es-ES',
      },
      {
        valor: Icon.BanderaFr,
        clave: 'fr-FR',
      },
      {
        valor: Icon.BanderaPt,
        clave: 'pt-PT',
      },
      {
        valor: Icon.BanderaUk,
        clave: 'en-GB',
      },
      {
        valor: Icon.BanderaDe,
        clave: 'de-DE',
      }];
  }

  private asignarIdiomaLS(): void {
    const idioma = localStorage.getItem('lang');
    this.value = this.paises.find(pais => pais.clave === idioma);
    this.EscribirValor();
    this.onChangeIdioma.emit(this.value.clave);
   }
  //#endregion

  //#region handlers
  hndCambiarIdioma(): void {
    this.EscribirValor();
    this.onChangeIdioma.emit(this.value.clave);
  }
  //#endregion
}
