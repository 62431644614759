import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RatingModule } from 'primeng/rating';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValue } from '../../utiles/ControlValue';
import { svgEstandarComponent } from '../../svg/svgEstandar/svgEstandar.component';
import { ETexto } from '../../enums/texto';
import { svg, texto } from '../../svg/svgEstandar/modelo/svg';
import { Icon } from "../../iconos/iconos";

@Component({
  selector: 'destinux-seleccion-estrellas',
  standalone: true,
  imports: [CommonModule, RatingModule, FormsModule, svgEstandarComponent],
  templateUrl: './seleccion-estrellas.component.html',
  styleUrls: ['./seleccion-estrellas.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SeleccionEstrellasComponent),
      multi: true,
    },
  ],
})
export class SeleccionEstrellasComponent
  extends ControlValue
  implements OnInit
{
  //#region inputs
  /** cantidad de estrellas a mostrar */
  @Input() cantidad: number = 5;
  /**  */
  @Input() color: string = ETexto.default;
  @Input() colorClick: string = this.color;
  @Input() disabled: boolean = false;
  @Input() colorFondo: string = ETexto.transparente;
  @Input() tamanyo: string = "34";
  //#endregion

  //#region declaración de variables
  public estrellas: estrella[] = [];
  public icono: string = "";
  public class: string = 'click';
  //#endregion

  constructor(){
    super();
    this.value = [];
    this.icono = Icon.EstrellaEstandar;
  }

  //#region OnInit
  ngOnInit() {
    this.inicializarSvg(this.cantidad);

    if (this.disabled) {
      this.class = '';
    }
  }
  //#endregion

  private inicializarSvg(cantidad: number) {
    for (let i = 0; i < cantidad; i++) {
      this.estrellas.push({
        texto: {
          texto: (i + 1).toString(),
          fill: this.color,
          x: '38%',
          y: '70%',
        },
        svg: [
          {
            path: this.icono + '#1',
            stroke: this.color,
            fill: this.colorFondo,
            strokeWidth: 3,
          },
        ],
        seleccionado: false,
        numero: i + 1,
      });
    }
  }

  override writeValue(value: any): void {
    this.value = [];
    const cantidad = this.estrellas.length;
    this.estrellas = [];
    this.inicializarSvg(cantidad);
    if (value && value.length > 0) {
      if (this.disabled) {
        this.value = value;
        this.value.forEach((y: number) => {
          const x = y - 1;
          this.estrellas[x].svg[0].fill = this.colorClick;
          this.estrellas[x].svg[0].stroke = this.colorClick;
          this.estrellas[x].texto.fill = ETexto.blanco;
        });
      } else {
        value.forEach((y: number) => {
          const x = y - 1;
          this.cambiarColor(this.estrellas[x]);
        });
      }
    }
  }

  //#region metodos usados en el html
  cambiarColor(estrella: estrella) {
    if (!this.disabled) {
      estrella.seleccionado = estrella.seleccionado ? false : true;
      if (estrella.seleccionado) {
        this.value.push(estrella.numero);
        estrella.svg[0].fill = this.colorClick;
        estrella.svg[0].stroke = this.colorClick;
        estrella.texto.fill = ETexto.blanco;
      } else {
        const index = this.value.indexOf(estrella.numero);
        this.value.splice(index, 1);
        estrella.svg[0].fill = ETexto.transparente;
        estrella.svg[0].stroke = this.color;
        estrella.texto.fill = this.color;
      }

      this.EscribirValor();
    }
  }
  //#endregion
}

export interface estrella {
  texto: texto;
  svg: svg[];
  seleccionado: boolean;
  numero: number;
}
