import { IRespuestaDiponibilidadRentACar } from "src/app/core/models/autoreserva/rentACar/rentacar";

export const repuestaDisponibilidadRentacar: IRespuestaDiponibilidadRentACar = {
	"Companias": [
		{
			"UrlLogo": "https://webapp.destinux.com/recursos_online/destinux_test/rentacar/logo_sixt.svg",
			"Codigo": 3,
			"Nombre": "SIXT",
			"TerminosYCondiciones": null
		}
	],
	"CodigosDescuento": [
		{
			"Id": "9728826",
			"Compania": 3,
			"Conceptos": [
				{
					"Id": "ADS",
					"Descripcion": "Cargo por conductor adicional",
					"DescripcionAmpliada": null,
					"Notas": null
				},
				{
					"Id": "CDW",
					"Descripcion": "Exención parcial por colisión ",
					"DescripcionAmpliada": null,
					"Notas": "Franquicia 0."
				},
				{
					"Id": "OWC",
					"Descripcion": "Cargo de trayecto de solo ida",
					"DescripcionAmpliada": null,
					"Notas": null
				},
				{
					"Id": "PARWS",
					"Descripcion": "Cargos por entregas en ofic. de aeropuertos, estaciones y puertos",
					"DescripcionAmpliada": null,
					"Notas": null
				},
				{
					"Id": "TP",
					"Descripcion": "Proteccion contra robo",
					"DescripcionAmpliada": null,
					"Notas": "Franquicia 0."
				}
			]
		}
	],
	"Ofertas": [
		{
			"CumpleReglas": true,
			"Id": "8308295e-96cc-44e8-b529-d52ca79883af",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=EC&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPE",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 382.98,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.17
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": true,
			"Id": "21b3ecc9-d273-45e8-8486-ffdb88247178",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=EC&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 382.98,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.17
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": true,
			"Id": "31661c26-5576-44db-b830-d857ee9cb196",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=EC&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 382.98,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.17
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": true,
			"Id": "6e61da75-9324-4001-a8cc-fdee018227f7",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=EC&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 382.98,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.17
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": true,
			"Id": "c0de8735-d498-44af-802d-cf77af2a020d",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=EC&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 382.98,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.17
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": true,
			"Id": "07910a82-ba7d-48fc-89ef-c91328b39724",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ED&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 404.95,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.17
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": true,
			"Id": "877ed356-cdde-4c93-8be6-a305bbe07398",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ED&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 404.95,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.17
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": true,
			"Id": "69a7fb76-67dd-4ce1-bb57-68724e132b5e",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ED&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 404.95,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.17
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": true,
			"Id": "ca4eb175-6903-4e32-8312-845492d35bf4",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ED&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 404.95,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.17
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": true,
			"Id": "8b25b73f-0c0c-46a0-92ee-f1e54df6dbae",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ED&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 404.95,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.17
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": true,
			"Id": "179ade9b-95c8-42b3-aaca-2228f337f550",
			"MarcaModelo": "ford ka",
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=EB&item=0&stamp=VEHICLE_0__0__1553248071944&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": 8,
				"Maletas": 20,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 404.95,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.17
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": true,
			"Id": "c8ae1f86-0661-4172-81ba-404f161adc1b",
			"MarcaModelo": "ford ka",
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=EB&item=0&stamp=VEHICLE_0__0__1553248071944&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": 8,
				"Maletas": 20,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 404.95,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.17
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": true,
			"Id": "5a5e6786-79f7-4085-9232-d7be63486be2",
			"MarcaModelo": "ford ka",
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=EB&item=0&stamp=VEHICLE_0__0__1553248071944&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": 8,
				"Maletas": 20,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 404.95,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.17
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": true,
			"Id": "22cc370c-34b2-49fe-a094-e44884ac2e04",
			"MarcaModelo": "ford ka",
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=EB&item=0&stamp=VEHICLE_0__0__1553248071944&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": 8,
				"Maletas": 20,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 404.95,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.17
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": true,
			"Id": "e6baeed1-b8c5-4fdc-b3fb-575006e85932",
			"MarcaModelo": "ford ka",
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=EB&item=0&stamp=VEHICLE_0__0__1553248071944&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": 8,
				"Maletas": 20,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 404.95,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.17
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "b90d437f-1555-4110-b1a0-50a5d12cd3bc",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=EC&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 421.93,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "7871393c-b9fe-4a5a-ae49-3e07cd2de4de",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=EC&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 421.93,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "9c793533-f10f-4faa-94af-ef03f6ef9ad1",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=EC&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 421.93,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "ac2b21a9-0857-4c66-9686-6ee6342ba10e",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=EC&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 421.93,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "2cb6f58d-0963-4286-9524-3af54b43bb58",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=EC&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 421.93,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "f83307bf-8480-4c6e-8c84-2d0f1ad12f6b",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ED&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 446.98,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "f5f30238-cbb8-496a-b010-b668b8af2f97",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ED&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 446.98,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "e9e3ba3a-4934-4206-88c2-7a92ce45978d",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ED&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 446.98,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "944f7a51-921f-4d3b-be15-57fe81625a40",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ED&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 446.98,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "bb0ed402-de2f-4712-a89a-beb6c5264459",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ED&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 446.98,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "ac4d6ca7-85a9-436b-b3ce-913993e9d556",
			"MarcaModelo": "ford ka",
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=EB&item=0&stamp=VEHICLE_0__0__1553248071944&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": 8,
				"Maletas": 20,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 446.98,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "4f19a4bd-81f8-4af6-b57c-db242c589173",
			"MarcaModelo": "ford ka",
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=EB&item=0&stamp=VEHICLE_0__0__1553248071944&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": 8,
				"Maletas": 20,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 446.98,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "36579c43-4fe4-4693-9c30-72cfbd8fea4c",
			"MarcaModelo": "ford ka",
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=EB&item=0&stamp=VEHICLE_0__0__1553248071944&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": 8,
				"Maletas": 20,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 446.98,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "58483f25-1e30-43f3-a67f-9fe7d8419c93",
			"MarcaModelo": "ford ka",
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=EB&item=0&stamp=VEHICLE_0__0__1553248071944&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": 8,
				"Maletas": 20,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 446.98,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "7333f7f5-681b-4ec9-8860-ae4c095f6a19",
			"MarcaModelo": "ford ka",
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=EB&item=0&stamp=VEHICLE_0__0__1553248071944&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": 8,
				"Maletas": 20,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 446.98,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "396954b3-d707-4532-bf48-e63fa7a6f076",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ED&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 509.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.17
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "4460c4bc-93d6-4372-aac3-b8c18334d5fa",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ED&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 509.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.17
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "5ad75f2e-b1ca-41b9-8858-8b09abb2cf55",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ED&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 509.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.17
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "401f3ba4-ef98-4ffe-8464-5e576c9803b6",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ED&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 509.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.17
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "d2861001-1268-4d38-be9e-1ceef77d825a",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ED&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 509.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.17
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "e2e351ca-191f-468f-80ac-7c36c8594331",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ED&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 562.96,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "a9f08fea-0da8-425b-a9cb-0b158e8fdc10",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ED&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 562.96,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "224b224d-25cb-4d3e-abe8-9ad759db207b",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ED&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 562.96,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "b3d789bc-02b4-4252-bdaa-46c1cbbaec40",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ED&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 562.96,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "b0c3fc36-4da8-49aa-8f2b-4dffbddb7d76",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ED&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Economico",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 562.96,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": true,
			"Id": "e67c01b3-f6ed-4a56-af73-859fb45abb81",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 410.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": true,
			"Id": "3c72471a-beea-4e5a-a930-c9b036861fd6",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 410.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": true,
			"Id": "f2b9c301-cbd3-4937-b434-db621299feb1",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 410.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": true,
			"Id": "6da10bc6-dc0f-4b80-b6cb-b6808917596a",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 410.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": true,
			"Id": "c01d4084-af22-4840-8ea6-d75753ff39dc",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 410.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": true,
			"Id": "1a3f9b42-825a-4073-8459-9ee91aec22da",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 410.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": true,
			"Id": "a2af8a66-5bfc-405e-a8d7-c4dbfd58a852",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 410.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": true,
			"Id": "e5b98f82-cea4-4b40-ba1f-3c442e36460c",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 410.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": true,
			"Id": "f8930e81-4670-4f2f-be1b-3f8c0a820ec3",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 410.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": true,
			"Id": "6ad49a43-6eac-477e-9b77-864cc415a380",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 410.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "43df942b-9de3-48de-9b83-68e6ea1f0419",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=EC&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 431.97,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "987ee77d-27c5-482b-a587-8b8e18e8e47f",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=EC&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 431.97,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "33aa675f-9fba-42c8-8a31-d6951ec72d16",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=EC&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 431.97,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "254e2681-5af7-4f94-b9a6-64a72b3da8ca",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=EC&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 431.97,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "2e03e161-4a75-4ceb-9b6b-f3cc31f40350",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=EC&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 431.97,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "358f630d-5aec-461b-81c3-738e0d6a4030",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CC&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 442.97,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "393dac9a-b852-4af8-a212-38d0ca9449bd",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CC&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 442.97,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "ef0a9377-c982-4057-b33e-f6e3137b3280",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CC&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 442.97,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "648ff95d-676f-4bf8-a129-87a44e2a2e42",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CC&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 442.97,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "4895f64e-88bf-48c6-a223-eac409cd4a74",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CC&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 442.97,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "75c7ea08-b9d2-4c18-96f3-de1f11215aa8",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 453.94,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "27df0c74-0e56-4d41-b5b1-676092279b4a",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 453.94,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "be9caf95-9a69-469e-93ab-800623b07441",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 453.94,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "f104c774-02d3-4d71-9fbb-d8afba546773",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 453.94,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "f2dcb7ad-5ad5-4e0b-98a0-939ba23aaeae",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 453.94,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "5ed5d48b-b360-4878-8b25-667ecaa0e740",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 453.94,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "3fbb8359-9afd-4ce8-b8a2-af094d775773",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 453.94,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "5b5b957a-1469-4815-a0a3-5004cdf3d1f0",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 453.94,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "45c6bd4f-0ea9-4d6d-87cc-8e2d94d32ee1",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 453.94,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "7ccb6e2b-b79f-4c65-92fe-fe537b886ea6",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 453.94,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "63683c81-6f84-4088-995a-d1907fde7fc7",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CL&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 455.94,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "e3aba678-455b-4bf4-ae62-4c2b0ae5a8eb",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CL&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 455.94,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "31ea2209-a4e4-44dd-b597-58b975643059",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CL&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 455.94,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "614c2289-a578-4484-b7db-8f9364b8961e",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CL&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 455.94,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "6c1dcb19-9d00-4497-bb89-4a05fbccce27",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CL&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 455.94,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "94364e89-e259-445c-a3c5-0bf465ae345b",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ID&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 475.96,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "c6452dcf-27cb-405c-9741-9522a23f300c",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ID&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 475.96,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "7de7ca1c-0477-413c-bedc-09d3fcb0ffba",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ID&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 475.96,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "31eda60c-ffba-4fd4-b8d7-09b05e00def5",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ID&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 475.96,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "6816e411-2d32-424b-8976-bfa6b585e8b6",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ID&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 475.96,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "7c86c322-20f7-42ab-97d0-7729bf0533aa",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CC&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 487.98,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "6ad453f2-29f3-478d-b203-371876e6c774",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CC&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 487.98,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "4429bd89-4ad9-4f07-837b-a1ed644b4c09",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CC&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 487.98,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "bfc2f0d0-ab17-4553-8bac-29ef9e881ed6",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CC&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 487.98,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "e6185513-353d-4e44-8840-7357ea89dc38",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CC&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 487.98,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "b6a0b45f-7b09-428c-bbb5-e9b3f5e8f172",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CW&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 490.97,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "b4523a56-f964-444c-a7bd-cabcf1c0689e",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CW&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 490.97,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "f3b50320-53a2-4e07-bf5c-626a3675a6bb",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CW&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 490.97,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "b49382e2-c4bb-44bf-a3ad-c559e3190058",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CW&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 490.97,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "51f82503-faa4-4216-aa82-cfc99c04133b",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CW&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 490.97,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "7ce10d30-da6c-4845-9ec4-7cc32b7514a6",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CL&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 502.96,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "b6123225-c057-4ea3-a3c4-42c2631f43ca",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CL&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 502.96,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "b554672a-8aee-41da-b0f5-39e874ca82cc",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CL&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 502.96,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "a9a3dd00-f2d0-4617-81ec-a1d822750bbb",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CL&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 502.96,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "c6d372b2-35f8-4bbf-871d-0f2e1342a5fe",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CL&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 502.96,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "3297e450-8629-4cfe-9631-18ae728fe0af",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CL&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 525.95,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "227fd2b8-e989-437e-b9e4-63639daeb938",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CL&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 525.95,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "2f5d51d6-6950-459b-baec-19e96d06860b",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CL&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 525.95,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "6e9476d3-09d9-4de9-bd85-1e51c8680696",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CL&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 525.95,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "a92bdb2e-260f-4eae-a815-1937e5e72642",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CL&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 525.95,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "312c0f82-d9f5-4435-b632-778f3431d7f3",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 533.99,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "77222ae1-fe76-46b0-8202-7a60d2b3dc6e",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 533.99,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "810e9a13-966e-4242-a73c-41254b763156",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 533.99,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "8d7d2650-f63c-43b4-9fcc-095366e9e7f8",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 533.99,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "8339b4dd-af44-4f21-8d1f-5c9352e09467",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 533.99,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.19
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "99bc4e7f-371a-4065-b501-e2d0e6671e3a",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CW&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 541.93,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "747cf509-a452-450c-ba3b-36419b4eedd2",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CW&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 541.93,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "06eda017-bc98-47c6-9b12-4d0423997998",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CW&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 541.93,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "ba8f2a68-b258-42e2-be1c-75a3a527fe4a",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CW&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 541.93,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "28c571ee-116a-4e8d-aac6-c75717ba74c7",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CW&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 541.93,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "f8fc1470-e87b-459d-928d-ccc04dbe1c30",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CL&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 579.97,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "f6d539d3-4376-44ac-82a0-e9cb2b05083a",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CL&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 579.97,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "71fc5ee3-5dbd-4b40-b6a0-8c103156d1ec",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CL&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 579.97,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "e635eaef-55f0-4a94-b275-1445c3649360",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CL&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 579.97,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "bd506c3a-0323-46a3-9444-8caea75ce739",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CL&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 579.97,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "da4763c5-20bc-49ba-8f49-f873287962bf",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 588.95,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "bafd75a8-f08e-4f5f-b575-6bf46e0c4f4d",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 588.95,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "2fd832cf-ca0e-4559-9358-48d6bbac1325",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 588.95,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "3ab41833-452a-4322-9d76-68238e08750d",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 588.95,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "495af87e-8a06-4413-bbb8-f91d02ddf59d",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=CD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Compact",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 588.95,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "eb13d065-2057-41ca-8c45-3d4304f721d3",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 466.97,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.2
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "b798d2df-7d37-4d8a-90fc-fdc8981a9f9f",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 466.97,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.2
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "7b874b77-ba3f-4020-8b02-46ca9bbbbbb4",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 466.97,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.2
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "ba1ca6f7-9c44-4f92-aca8-5b382e559ef6",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 466.97,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.2
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "c384e93b-9ae4-41aa-83fb-9726dbf6ecf5",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 466.97,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.2
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "df6fc9fa-9fb2-474d-bb54-ff126787afe9",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ID&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 468.96,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.2
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "0bf36b34-21b7-41bc-b5e2-d1026d52d716",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ID&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 468.96,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.2
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "92688fd9-fb97-496a-bb26-36e4ac80b559",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ID&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 468.96,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.2
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "c3e1b629-c54d-4f0f-b5cc-6022e135509a",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ID&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 468.96,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.2
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "8420cb37-b65c-4424-831a-98614c963e44",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ID&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 468.96,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.2
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "1535d0f0-aefc-4b23-9b78-95700218a8e5",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 514.98,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "1a4958a0-6254-443d-b510-42ee3980e032",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 514.98,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "431bebd6-1f8a-4749-bff5-986c95d7f8ab",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 514.98,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "3c093027-0ad1-451e-9bff-b7293f1302ff",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 514.98,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "e1e1ac45-04f7-4a40-8db3-3151f5412061",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 514.98,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "3bad92cf-aaba-4a58-8b8f-35477a6b4908",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ID&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 516.99,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "5b3225fb-777b-4df4-9e92-8129b794ae7f",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ID&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 516.99,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "7c9a95a7-3bb3-46ab-a4b9-6c39da3863d1",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ID&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 516.99,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "20992fdf-5086-438e-8e8c-a55a96176ec4",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ID&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 516.99,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "5ef8fe94-d836-4296-a76a-406492d1b119",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ID&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 516.99,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "791580f8-d37a-4122-aba3-983f7e304a71",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ID&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 540.97,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.2
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "9a1ae31f-bb68-40d3-8f56-f8600e835b92",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ID&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 540.97,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.2
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "42df2e7b-b12a-4ded-ae90-781f8348198e",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ID&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 540.97,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.2
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "e04a6c05-1ee2-48ec-8aaf-3fc02d2a3720",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ID&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 540.97,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.2
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "2e6ffc0b-1ff4-47dd-a233-61af2c069a17",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ID&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 540.97,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.2
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "7db78022-7e5a-42d8-b711-8f6c52504d02",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IV&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 541.97,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.2
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "80b3c0a7-5efc-4e1c-9a56-d011ed1df6e4",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IV&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 541.97,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.2
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "a38bf79f-fa9f-4d6b-bc69-6ab413282950",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IV&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 541.97,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.2
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "a496dc96-5e77-4c0e-841f-87adebc89170",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IV&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 541.97,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.2
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "2b30aa8e-8c6c-4c63-bfd0-aa8a69d2321a",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IV&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 541.97,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.2
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "77455565-2cc2-4a81-838c-4505db4e06e8",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 571.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.2
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "0bd2d43c-d039-4ef8-8432-4a8a5b8ab6db",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 571.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.2
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "eaa9d3e0-27cf-47c6-a46a-838f1a292e3f",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 571.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.2
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "a5fca3c9-6056-4d6e-a788-951cfaa18c4b",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 571.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.2
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "2e51241b-b8bb-4783-a955-4b56a93e1263",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 571.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.2
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "16c76ce3-4dec-4199-8689-f446c262dcaa",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ID&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 596.92,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "89089f3d-8c0d-4898-a1dc-32edf88abbad",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ID&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 596.92,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "28483b21-f14d-409d-a010-94750ae2b326",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ID&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 596.92,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "66a6bb98-77e6-4c8e-ac82-f219b4df73d9",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ID&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 596.92,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "b7de777e-faa5-45a6-96a4-32e9635366cd",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=ID&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 596.92,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "8c23cc73-f4ee-41bf-8cb2-7eab97ad5cc8",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IV&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 597.99,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "7564ba6c-e1d6-4680-a49d-eab681a1428c",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IV&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 597.99,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "0e554675-0fa1-463d-a7a8-39da927d5299",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IV&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 597.99,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "2afe5566-3915-4850-9507-87d15c426ad4",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IV&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 597.99,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "0119a628-9eb7-4d04-81e8-0f4a75ed5368",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IV&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 597.99,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "1986f170-5097-464e-a881-3ad2543352da",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 630.97,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "603aaea3-74dd-44b5-81b7-b77f156a6278",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 630.97,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "ba52f5a8-8410-48d4-8a78-2e789bf5c862",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 630.97,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "872e9bb7-4cdf-4f36-bddb-13825c0b5a28",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 630.97,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "2c43625f-e555-4512-8f5f-3c0c0c5fd7c7",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 630.97,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "bf3023d6-3496-4bb7-b696-f3d9699184ab",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IV&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 656.94,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.2
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "a375dbd6-dfe5-422f-99ef-40371bb7c393",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IV&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 656.94,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.2
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "e7db835a-c75b-4a93-83df-cd1799118b01",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IV&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 656.94,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.2
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "211ed3fb-7ab0-4f55-8e68-c11e94446dfb",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IV&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 656.94,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.2
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "665becc4-afda-4b35-8215-60f4fed61263",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IV&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 656.94,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.2
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "3c321f07-07f7-4c21-9757-3422c4f312ed",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IV&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 724.99,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "4a6dd6f9-443c-4cb8-a80a-466071784b2e",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IV&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 724.99,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "4ae3747a-61fc-4863-a992-20907c15199b",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IV&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 724.99,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "709f70ec-6146-4271-87d2-f43bae2a6a9b",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IV&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 724.99,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "926e930e-cf3e-4bcd-9d62-d6c9d6f5a3c4",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IV&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Intermediate",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 724.99,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "1a571ed2-cdad-4986-bfaf-759cdd683aeb",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Standard",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 568.97,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.23
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "37af286f-eed7-403b-953b-3ddd6dd17a68",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Standard",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 568.97,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.23
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "2aa18018-9951-4172-9f63-97315669793e",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Standard",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 568.97,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.23
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "10fe1323-63a6-4362-a83b-ee38e0a129b1",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Standard",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 568.97,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.23
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "7bacaa32-1f2e-4036-95b8-f7648065b861",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Standard",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 568.97,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.23
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "46392313-dc60-4798-803c-1a78a66624d8",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Standard",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 627.93,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "e329745b-f100-40ca-ab98-42a12a1fd772",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Standard",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 627.93,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "3ffd09ea-4ca2-44c4-99e8-0d9ee4b31712",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Standard",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 627.93,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "ab864f21-a8c2-45ff-a4e9-2e29ec0f87e7",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Standard",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 627.93,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "8da46b07-f1fd-4c10-86a9-2344d775a2fa",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Standard",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 627.93,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "138d6603-90f9-43db-9a9c-bda10b1e8423",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Standard",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 668.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.23
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "a5303bea-a42a-4491-bf71-5552e9a39b62",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Standard",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 668.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.23
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "d5fd8fbb-5a71-4a48-ae40-5d25d67b07a8",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Standard",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 668.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.23
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "1efd62f9-458c-449a-95ff-f45e29863c95",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Standard",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 668.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.23
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "88c13348-08a0-4bd0-9cfc-22f4e39685d5",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Standard",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 668.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.23
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "5e9b3fa9-4a48-45b5-9186-ccd15225da0c",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Standard",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 723.95,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.23
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "a8b4de34-ba41-4b77-a74d-8cea918d71ac",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Standard",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 723.95,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.23
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "97d822ee-288c-4d53-8373-63abf0751543",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Standard",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 723.95,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.23
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "5b84d041-e1e8-49ce-8eec-8497ce2972cd",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Standard",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 723.95,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.23
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "15fb4ffd-efde-494c-a266-75d36ebb2c5e",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Standard",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 723.95,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.23
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "ad4e0830-5a9e-4d1e-aaf2-99a3ae2ede73",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Standard",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 737.92,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "104e9461-4eb9-4a00-887b-6ef60df3df7f",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Standard",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 737.92,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "ab1cdc98-af9f-4ba9-bfd8-f6819f3a3de1",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Standard",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 737.92,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "0b75a8d0-9c7a-47c3-9723-81d58b9c00a1",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Standard",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 737.92,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "eb3ea9dd-f244-4eb9-ac36-321f05ef4393",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Standard",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 737.92,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "dc11ee55-6416-459c-9382-3b4f5c514125",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Standard",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 797.94,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "79449088-89f3-4d78-8706-7a737d333ae5",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Standard",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 797.94,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "c7227959-2d72-4362-a148-83400cc611f7",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Standard",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 797.94,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "1b8cd4dd-9fa2-4796-afa5-0e526d21cb42",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Standard",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 797.94,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "bc2e34a1-0d6c-4011-a76d-f5f6b33d24c4",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Standard",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 797.94,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "009c29ca-0e67-4eb1-91dd-5eed58feb39c",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IV&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 602.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.23
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "0e09a58a-f4f9-47d1-ba7a-46e3dbc86fbc",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IV&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 602.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.23
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "cd61700b-8607-4bfa-8431-1f00aac52aff",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IV&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 602.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.23
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "a264da93-20a5-4108-953e-aefcfab2e38b",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IV&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 602.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.23
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "c24754d4-9c76-4093-a7bc-a425d87b9bb3",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=IV&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 602.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.23
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "b9f8ab8c-71fa-4c82-bf25-0bdae4fe378b",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 633.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.26
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "4a4082b7-a655-454e-8f9c-e1ae906d08cf",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 633.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.26
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "1d05616d-03f8-43fe-beca-5f88989d567c",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 633.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.26
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "20d8c166-e97c-4e12-826e-0bb91903ccb7",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 633.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.26
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "499fb7fd-0bd1-41f3-8f73-8174f5eca8d0",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 633.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.26
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "81805f34-ef81-4777-af2f-9eddd76bc22e",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 659.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.23
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "01c5d79a-4600-496f-bbe9-1904482d8f83",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 659.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.23
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "b6051bdd-3c1d-4900-92a9-e456a0bbfec5",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 659.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.23
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "ef5817e0-f4de-4341-87fa-83af9fc39310",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 659.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.23
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "8782088c-3047-4bbe-b7f3-fb7dbc1f79c5",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 659.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.23
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "de00c1cf-643d-4792-bd56-1d3d9eed9c60",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 664.98,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "f30e6893-443c-4144-9040-3265965f9977",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 664.98,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "3a54be6b-dd2c-425e-8889-a372fdbff1b6",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 664.98,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "24791a7a-7bc8-4a9a-b41d-ded4f192fc4b",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 664.98,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "e1bede1e-d5ca-48ea-95b7-d772ecc562bb",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": null,
				"AA": true
			},
			"Precio": 664.98,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "4c6ca673-e350-4ad3-b7e0-71adc23bb4f2",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 698.92,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "3d56c766-99c0-4b1c-baa6-28dd37537deb",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 698.92,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "33b5c18a-f5a4-4df2-8393-1a66091383cd",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 698.92,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "c2c802ab-8893-4577-a140-7804466a318d",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 698.92,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "6732d7bf-366b-4c34-9d14-24748f9bd7b3",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 698.92,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "1977a7c2-cd46-4840-a4f6-52a75fee24ad",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 727.98,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "4eee703e-dd62-42b5-a79f-2d9f65cee78e",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 727.98,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "e42f6f31-98b2-4474-8b3b-3ffeea17f555",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 727.98,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "abbdbc7d-691d-436a-8419-a39d4b47a0e8",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 727.98,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "37df6be0-761e-4e10-b1f0-203893e07703",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 727.98,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "18f95d1f-00a4-47ed-abb4-dcf7f59be8d9",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 749.94,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.26
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "ccfe5411-7101-45e7-a8ab-77cb33be2d22",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 749.94,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.26
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "4f90d7e7-8174-4e07-a652-99db4b03bde2",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 749.94,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.26
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "b9f5c1d9-c294-4c6b-b18b-51ea3cdc976c",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 749.94,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.26
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "3a08f69c-718b-4421-a70d-4a8c5baaa34a",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 749.94,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.26
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "1f198ebc-d743-4c66-b324-c59b579cd93d",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 771.95,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.23
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "5dd07bb4-87c7-43cf-8dbe-5ad4a07422ff",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 771.95,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.23
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "6506b551-bd24-4c37-8a54-301533aee842",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 771.95,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.23
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "ff130e45-34d1-4ca7-be39-f3b968434cf3",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 771.95,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.23
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "81ebc548-1603-4a08-8954-d6420405313b",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 771.95,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.23
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "219245ba-5af4-40b5-94b9-2c7a3eeb04f3",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 771.95,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.23
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "91817db2-2a9b-45ca-a6cf-a361402b7cbc",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 771.95,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.23
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "12c12531-a9c8-4db3-8654-533a725b6747",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 771.95,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.23
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "5c8ce16c-f8b3-487c-8225-1617912cf5f6",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 771.95,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.23
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "59ba7e16-ccda-4429-a154-d1e0aa46e4b0",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=SF&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 771.95,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.23
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "35a21931-940c-4620-a50a-016cce78f78c",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 806.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.26
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "d11251d6-fdac-43fa-a304-a996cbef0651",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 806.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.26
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "5feeddeb-1bf8-4ffc-8466-1895258bbfac",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 806.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.26
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "76ad58eb-740e-4c58-9a0e-e1706e50fd42",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 806.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.26
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "304e56ed-5f60-4351-9e54-14afa550a977",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 806.93,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.26
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "7e551b66-8dc4-45fb-b4da-152385e458d6",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 826.99,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "db161e22-9020-4dcf-866f-eeb8c6eba630",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 826.99,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "98c5013c-ff68-40a7-a7ef-db50c9f7b665",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 826.99,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "5e81fc3c-f26e-435f-abfe-ddf0def5423d",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 826.99,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "d481f99e-5ab3-4d74-90cd-b395e7ed6446",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Manual",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 826.99,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "639f97ec-a9d6-4c43-996b-f9014e32cac1",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 850.94,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.26
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "1a75867a-4e4c-48cc-9d2f-63d985a8da45",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 850.94,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.26
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "db596421-99fb-4ee3-8d8e-29c4c642a8dc",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 850.94,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.26
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "0e7f0829-eb8e-404b-978e-2c17b57bf26f",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 850.94,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.26
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "ff250266-da46-401d-a657-a39a02aba822",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 850.94,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.26
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "6ddac6ca-0d69-4843-8e5b-717c594aa0b4",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 851,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "c984b6b6-fa62-494b-8f3a-2ad0d838937c",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 851,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "8f04fbbf-78b5-472d-b4c0-5e9e1ad752cc",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 851,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "150d157d-9493-454c-9006-7a5d523a7d0c",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 851,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "dace3257-9eb6-4e5b-84b5-c3e8b3bafb6c",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 851,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "5c0ca42d-b799-449a-b1ba-066dc3ae7a3d",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 851,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "f3b5984a-16bd-48e7-974f-ce54948c5b82",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 851,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "049b419c-fdc2-4a91-9508-bbd671819036",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 851,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "d7e9c61f-4e17-4381-a115-a937faedf4c1",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 851,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "bf247ee0-2a96-460e-9c8b-746f51ac9a2f",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 851,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "5408a0ae-35f5-4b0b-bf86-7340abfdae4e",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 889.97,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "02404d9f-0702-4961-9787-ae5ac25ecf8d",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 889.97,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "9b335839-dce2-4432-b974-646b5ff6efa4",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 889.97,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "150d9f63-95cb-4874-8e29-5d526e935bde",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 889.97,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "ea1ecf34-ea9d-452e-8343-e324a3e1e3f0",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "FullSize",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": null,
				"AA": true
			},
			"Precio": 889.97,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "8a9899b6-560e-4e5d-af3c-39be64da4d27",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Lujo",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 832.92,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.25
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "003ecf98-d486-48eb-84f6-4ecddac7ef90",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Lujo",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 832.92,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.25
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "715c9087-c83f-4534-ac90-6193158c51af",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Lujo",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 832.92,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.25
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "0c42ac76-76d7-404f-96d7-28cc2fc29d41",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 1,
			"InfoCoche": {
				"Categoria": "Lujo",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 832.92,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.25
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "a2888f5d-78f1-4b87-b8eb-1056dd5e2b9f",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Lujo",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 832.92,
			"DistanciaIncluida": {
				"Limitacion": {
					"Unidades": 2100,
					"Metrica": "K",
					"PrecioUnidadAdicional": 0.25
				},
				"Ilimitado": false
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "2b5bffdf-df15-41e8-9e18-4941dbc06937",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 2,
			"InfoCoche": {
				"Categoria": "Lujo",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 918.99,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "fb84b40d-047b-4558-8b13-420a20f5239c",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 5,
			"InfoCoche": {
				"Categoria": "Lujo",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 918.99,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "2ec4b195-aea2-4169-8ba4-8432c96fe2a8",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 4,
			"InfoCoche": {
				"Categoria": "Lujo",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 918.99,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		},
		{
			"CumpleReglas": false,
			"Id": "0d2529fe-ceac-49d1-b47e-2ed72df19ee6",
			"MarcaModelo": null,
			"UrlFoto": "https://post-development.multimedia.testing.amadeus.com/mdc/retrieveCarItem?ctg=VEHICLE&prov=SX&cnt=ES&vehcat=FD&item=0&stamp=VEHICLE_0_0_1169827704039&file=6.JPEG",
			"Compania": 3,
			"OficinaRecogida": 1,
			"OficinaDevolucion": 3,
			"InfoCoche": {
				"Categoria": "Lujo",
				"NumPuertas": null,
				"Plazas": null,
				"Maletas": null,
				"Transmision": "Automatica",
				"Combustible": "Electrico",
				"AA": true
			},
			"Precio": 918.99,
			"DistanciaIncluida": {
				"Limitacion": null,
				"Ilimitado": true
			},
			"CodigoDescuento": "9728826",
			"SolicitudAutorizacionAprobada": false
		}
	],
	"Oficinas": [
		{
			"Horarios": [
				{
					"Apertura": "07:00:00",
					"Cierre": "21:00:00",
					"Diadelasemana": 1
				},
				{
					"Apertura": "07:00:00",
					"Cierre": "21:00:00",
					"Diadelasemana": 2
				},
				{
					"Apertura": "07:00:00",
					"Cierre": "21:00:00",
					"Diadelasemana": 3
				},
				{
					"Apertura": "07:00:00",
					"Cierre": "21:00:00",
					"Diadelasemana": 4
				},
				{
					"Apertura": "07:00:00",
					"Cierre": "21:00:00",
					"Diadelasemana": 5
				},
				{
					"Apertura": "07:00:00",
					"Cierre": "21:00:00",
					"Diadelasemana": 6
				},
				{
					"Apertura": "07:00:00",
					"Cierre": "21:00:00",
					"Diadelasemana": 0
				}
			],
			"Coordenadas": {
				"Latitud": 40.42452,
				"Longitud": -3.71212
			},
			"Direccion": "CALLE DE LA PRINCESA 1, MADRID",
			"Aeropuerto": {CodigoIata: "",
				Terminal: ""},
			"Id": 1
		},
		{
			"Horarios": [
				{
					"Apertura": "07:00:00",
					"Cierre": "22:00:00",
					"Diadelasemana": 1
				},
				{
					"Apertura": "07:00:00",
					"Cierre": "22:00:00",
					"Diadelasemana": 2
				},
				{
					"Apertura": "07:00:00",
					"Cierre": "22:00:00",
					"Diadelasemana": 3
				},
				{
					"Apertura": "07:00:00",
					"Cierre": "22:00:00",
					"Diadelasemana": 4
				},
				{
					"Apertura": "07:00:00",
					"Cierre": "22:00:00",
					"Diadelasemana": 5
				},
				{
					"Apertura": "07:00:00",
					"Cierre": "22:00:00",
					"Diadelasemana": 6
				},
				{
					"Apertura": "07:00:00",
					"Cierre": "22:00:00",
					"Diadelasemana": 0
				}
			],
			"Coordenadas": {
				"Latitud": 40.40693,
				"Longitud": -3.69613
			},
			"Direccion": "C/ RONDA DE ATOCHA 10, MADRID",
			"Aeropuerto": null,
			"Id": 2
		},
		{
			"Horarios": [
				{
					"Apertura": "07:00:00",
					"Cierre": "21:00:00",
					"Diadelasemana": 1
				},
				{
					"Apertura": "07:00:00",
					"Cierre": "21:00:00",
					"Diadelasemana": 2
				},
				{
					"Apertura": "07:00:00",
					"Cierre": "21:00:00",
					"Diadelasemana": 3
				},
				{
					"Apertura": "07:00:00",
					"Cierre": "21:00:00",
					"Diadelasemana": 4
				},
				{
					"Apertura": "07:00:00",
					"Cierre": "21:00:00",
					"Diadelasemana": 5
				},
				{
					"Apertura": "07:00:00",
					"Cierre": "21:00:00",
					"Diadelasemana": 6
				},
				{
					"Apertura": "07:00:00",
					"Cierre": "21:00:00",
					"Diadelasemana": 0
				}
			],
			"Coordenadas": {
				"Latitud": 40.44312,
				"Longitud": -3.69098
			},
			"Direccion": "PASEO DE LA CASTELLANA 90, MADRID",
			"Aeropuerto": null,
			"Id": 3
		},
		{
			"Horarios": [
				{
					"Apertura": "07:00:00",
					"Cierre": "21:00:00",
					"Diadelasemana": 1
				},
				{
					"Apertura": "07:00:00",
					"Cierre": "21:00:00",
					"Diadelasemana": 2
				},
				{
					"Apertura": "07:00:00",
					"Cierre": "21:00:00",
					"Diadelasemana": 3
				},
				{
					"Apertura": "07:00:00",
					"Cierre": "21:00:00",
					"Diadelasemana": 4
				},
				{
					"Apertura": "07:00:00",
					"Cierre": "21:00:00",
					"Diadelasemana": 5
				},
				{
					"Apertura": "07:00:00",
					"Cierre": "21:00:00",
					"Diadelasemana": 6
				},
				{
					"Apertura": "07:00:00",
					"Cierre": "21:00:00",
					"Diadelasemana": 0
				}
			],
			"Coordenadas": {
				"Latitud": 40.43339,
				"Longitud": -3.68619
			},
			"Direccion": "CALLE SERRANO 98, MADRID",
			"Aeropuerto": null,
			"Id": 4
		},
		{
			"Horarios": [
				{
					"Apertura": "07:30:00",
					"Cierre": "00:00:00",
					"Diadelasemana": 1
				},
				{
					"Apertura": "07:30:00",
					"Cierre": "23:59:00",
					"Diadelasemana": 2
				},
				{
					"Apertura": "07:30:00",
					"Cierre": "00:00:00",
					"Diadelasemana": 3
				},
				{
					"Apertura": "07:30:00",
					"Cierre": "00:00:00",
					"Diadelasemana": 4
				},
				{
					"Apertura": "07:30:00",
					"Cierre": "00:00:00",
					"Diadelasemana": 5
				},
				{
					"Apertura": "07:30:00",
					"Cierre": "00:00:00",
					"Diadelasemana": 6
				},
				{
					"Apertura": "07:30:00",
					"Cierre": "00:00:00",
					"Diadelasemana": 0
				}
			],
			"Coordenadas": {
				"Latitud": 40.4077,
				"Longitud": -3.69284
			},
			"Direccion": "PLAZA EMPERADOR CARLOS V, S/N, MADRID",
			"Aeropuerto": null,
			"Id": 5
		}
	]
}