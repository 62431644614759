import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Funciones } from '../funciones-comunes';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'destinux-precio',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './precio.component.html',
  styleUrls: ['./precio.component.scss'],
})
export class PrecioComponent implements OnChanges {
  @Input() precio: number | undefined = 0;
  @Input() decimales: boolean = true;
  precioPartido!: { parteEntera: string; parteDecimal: string };
  localInglaterra = false;

  constructor(private _translateService: TranslateService) {}

  ngOnChanges() {
    if(this.precio) {
      this.precioPartido = Funciones.dividirEnteroDecimal(this.precio);
    } else {
      this.precioPartido = { parteEntera: '0', parteDecimal: '00' }
    }    
    this.comprobarLocalizacionYLengua();
  }

  comprobarLocalizacionYLengua() {
    const locale = this._translateService.currentLang;
    if(locale == 'en-GB' || locale == 'en-IE') {
      this.localInglaterra = true;
    } 
  }
}


