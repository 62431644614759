import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BotonComponent } from "../boton/boton.component";
import { IconoSvgComponent } from "../icono-svg/icono-svg.component";
import { Icon } from '../../iconos/iconos';
import { EnumsLib } from '../../utiles/enums';

@Component({
    selector: 'destinux-barra-novedades-web',
    standalone: true,
    templateUrl: './barra-novedades-web.component.html',
    styleUrls: ['./barra-novedades-web.component.scss'],
    imports: [CommonModule, TranslateModule, BotonComponent, IconoSvgComponent]
})
export class BarraNovedadesWebComponent {

  //#region inputs
  @Input() titulo: string = 'te damos la bienvenida a la nueva version de ';
  @Input() tituloNegrita: string = 'destinux web'; //TODO: deberá estar sin inicializar para que no se muestre si no es necesario
  @Input() textoBoton: string = 'conoce las novedades';
  //#endregion

  //#region output
  @Output() onClickOcultar: EventEmitter<any> = new EventEmitter();
  @Output() onVerNovedad: EventEmitter<any> = new EventEmitter();
  //#endregion

  //#region variables
  public Icon = Icon;
  public enums = EnumsLib;
  //#endregion

  hndOcultar() {
    this.onClickOcultar.emit();
  }

  hndVerNovedad() {
    this.onVerNovedad.emit();
  }    
}
