<button #boton [ngClass]="getClaseBoton()" (click)="hndClick()" [type]="submitType ? 'submit' : 'button'" 
class="g-flex g-flex--align-items-center g-flex--justify-content-center g-flex--gap-xxp" [style]="getAlturaAnchura()">
  <!-- ICONO IZQ. -->
  <ng-container *ngIf="iconoStart">
    <div class="g-flex g-flex--direction-column g-flex--align-items-center g-flex--gap-xp">
      <destinux-icono-svg [modelo]="iconoModelo()" [talla]="transformTallaIcono[talla]" [urlIcono]="iconoStart"></destinux-icono-svg>
      <span *ngIf="textoInferior" class="c-boton__texto">{{ textoInferior }}</span>    
    </div>
  </ng-container>
  <!-- TEXTO -->
  <span *ngIf="texto" [ngClass]="{'c-boton__texto': true, 'g-padding-right-md': modelo === modelosBoton.auxiliar}">
    <ng-container *ngIf="modelo !== modelosBoton.auxiliar" >
      <span [destinuxTruncarTexto]="maxCaracteresTexto">
          {{ texto }}
      </span>
    </ng-container> 
    <ng-container *ngIf="modelo === modelosBoton.auxiliar">{{ texto | uppercase }}</ng-container> 
  </span>
  <!-- TEXTO COMPLEMENTARIO -->
  <span *ngIf="textoComplementario !== null" class="g-opacity-60">
    {{ textoComplementario }}
  </span>
  <!-- ICONO DER. -->
  <ng-container *ngIf="iconoEnd">
    <destinux-icono-svg [modelo]="iconoModelo()" [talla]="transformTallaIcono[talla]" [urlIcono]="iconoEnd"></destinux-icono-svg>
  </ng-container>
  <!-- GLOBO ALERTAS -->
  <div *ngIf="notificacion !== 0">
    <span [destinux-plus99]="notificacion" class="badge"></span>
  </div>
</button>