
    <div class="g-flex g-flex--direction-row g-flex--gap-xxp g-flex--gap-gd@pq number">
      <button type="button" class="btn rounded-circle center button g-flex g-flex--direction-column" (click)="menos()">
        <destinux-icono-svg [urlIcono]="Icon.Menos" [talla]="enums.ETallaIcono.pq"></destinux-icono-svg>
      </button>
      <div class="g-flex g-flex--direction-column center texto g-font-family-secundaria">
        {{value}}
      </div>
      <button type="button" class="btn rounded-circle center button g-flex g-flex--direction-column" (click)="mas()">
        <destinux-icono-svg [urlIcono]="Icon.Plus" [talla]="enums.ETallaIcono.pq"></destinux-icono-svg>
      </button>
    </div>
  