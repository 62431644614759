import { Injectable } from '@angular/core';
import { ISwalErrores } from '../../models/control-errores/errores';
import { EIcono } from '../../enums/alertas/swal';
import { IMantenimiento } from '../../models/mantenimiento/mantiemiento';

@Injectable({
  providedIn: 'root'
})
export class InicializadorGeneralService {

  constructor() { }

  //#region inicializadores control de errores
  iSwalErrores(): ISwalErrores{
    return {
      icono: EIcono.Success,
      titulo: '',
      mensaje: '',
    };
  }
  //#endregion

  //#region inicilizadores mantenimiento
  iMantenimiento(): IMantenimiento{
    return {
      fechainicio: "",
      fechafin: ""
    }
  }
  //#endregion
}
