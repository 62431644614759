<ng-container *ngIf="!isHorizontal else horizontal">
    <div class="flex flex-wrap gap-3">
        <div *ngFor="let objeto of valores" class="flex align-items-center padding"
            [class]="getClaseRadio(objeto.clave)">
            <ng-container [ngTemplateOutlet]="radio" [ngTemplateOutletContext]="{objeto: objeto}"></ng-container>
        </div>
    </div>
</ng-container>

<ng-template #horizontal>
    <div class="row g-flex--align-items-center g-flex--gap-xxp">
        <div *ngFor="let objeto of valores" class="col-auto"
        [class]="getClaseRadio(objeto.clave)">
            <ng-container [ngTemplateOutlet]="radio" [ngTemplateOutletContext]="{objeto: objeto}"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #radio let-objeto="objeto">
    <p-radioButton [name]="nameGroup" [inputId]="objeto.clave" [value]="objeto.clave" [(ngModel)]="value" [class]="tipo"
        (ngModelChange)="EscribirValor()" [disabled]="disabled"></p-radioButton>
    <label class="label">{{objeto.valor}}</label>
</ng-template>