<div class="g-flex g-flex--justify-content-center g-flex--align-items-center g-flex--gap-column-xxp">
  <span *ngIf="etiquetaInicio" [ngClass]="estiloEtiquetaInicio">{{ etiquetaInicio | uppercase }}</span>
  <a [ngClass]="clases" (click)="hndCambiarEstado(estado)">
    <div [ngClass]="iconoEstilos">
      <destinux-icono-svg *ngIf="marcado" [modelo]="enums.EModeloIcono.destaque" [talla]="enums.ETallaIcono.pq"
       urlIcono="{{ iconoMarcado }}" class="g-margin-top-2"></destinux-icono-svg>
      <destinux-icono-svg *ngIf="!marcado" [modelo]="enums.EModeloIcono.secundario" [talla]="enums.ETallaIcono.xp" urlIcono="{{ iconoDesmarcado }}"  class="g-margin-top-2"></destinux-icono-svg>
    </div>
    <span *ngIf="texto" class="g-padding-6">{{ texto }}</span>
    <span *ngIf="!texto" class="g-margin-horizontal-xxp"></span>
  </a>
  <span *ngIf="etiquetaFinal" [ngClass]="estiloEtiquetaFinal">{{ etiquetaFinal | uppercase }}</span>
</div>


