<div *ngIf="vertical else horizontal">
    <div *ngFor="let valor of valores" [class]="getClaseCheck(valor)" class="padding">
        <ng-container *ngTemplateOutlet="check; context:{valor: valor}"></ng-container>
    </div>
</div>

<ng-template let-valor="valor" #check>
    <p-checkbox [name]="name" [value]="valor" [(ngModel)]="value" [inputId]="name + '-'+ valor" [binary]="binario"
            [class]="tipo" (ngModelChange)="EscribirValor()"
            [disabled]="disabled"></p-checkbox>
        <label [for]="name + '-'+ valor" *ngIf="traducir" [class]="paddingLabel">{{traducir + valor | translate}}</label>
        <label [for]="name + '-'+ valor" *ngIf="!traducir" [class]="paddingLabel">{{valor}}</label>
</ng-template>

<ng-template #horizontal>
    <div class="container">
        <div class="row">
            <div *ngFor="let valor of valores" [class]="getClaseCheck(valor)" class="col-auto padding g-flex g-flex--align-items-center">
                <ng-container *ngTemplateOutlet="check; context:{valor: valor}"></ng-container>
            </div>
        </div>
    </div>
</ng-template>