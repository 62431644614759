import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { firstValueFrom } from 'rxjs';
import { LenguajesDestinux } from '../constants/lenguaje/lenguaje';

export function appInitializerFactory(translate: TranslateService) {
  return () => {
    const langLS = localStorage.getItem('lang');
    const lenguajeNavegadorUsuario = navigator.language.split('-')[0];
    
    console.log('lenguaje navegador: ',lenguajeNavegadorUsuario);
    console.log('localStorage lang pre default: ',langLS);
    // Tiene en cuenta si hay lenguaje en localStorage, para evitar que el refresco de la app dentro de un flujo vuelva a tomar el lenguaje del navegador
    const lenguajePorDefecto = langLS ?? (LenguajesDestinux.find(lenguaje => lenguaje.split('-')[0] == lenguajeNavegadorUsuario) ?? LenguajesDestinux[0]);
    console.log('lenguaje por defecto al comprobar el navegador: ', lenguajePorDefecto);
    localStorage.setItem('lang', lenguajePorDefecto);
    console.log('localStorage lang post default: ', localStorage.getItem('lang'));

    let lenguajeSeleccionado = langLS ?? lenguajePorDefecto;
    console.log('lenguaje por defecto para Translate: ', lenguajeSeleccionado);

    translate.setDefaultLang(lenguajeSeleccionado);
    return firstValueFrom(translate.use(lenguajeSeleccionado)); //CHECK sustitución de .toPromise()
  };
}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    'https://webapp.destinux.com/recursos_online/destinux/traducciones/selfbooking/'
  );
}
