import { Directive, Input, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[destinuxColorPorPorcentaje]',
  standalone: true,
})
export class ColorPorPorcentajeDirective { 
  
  @Input('destinuxColorPorPorcentaje') numero!: number;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnChanges() {
    this.setEstilo();
  }

  private setEstilo(): void {
    let estilo = '';
    if (this.numero <= 70) {
      estilo = 'g-color-alerta-advertencia';
    } else if (this.numero <= 90) {
      estilo = 'g-color-boton-advertencia-activo-variacion-texto';
    } else {
      estilo = 'g-color-boton-exito-400';
    }
    this.renderer.addClass(this.el.nativeElement, estilo);
  }
}