/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpContextToken, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { AlertasSwalService } from '../../utils/alertas/swal/alertas-swal.service';
import { ErroresService } from '../../services/control-errores/errores/errores.service';
import { ISwalErrores } from '../../models/control-errores/errores';

export const BYPASS_LOG = new HttpContextToken(() => false);

@Injectable({
  providedIn: 'root',
})
export class ControlErroresService {
  //#region  constructor
  constructor(
    private _swalAlertaService: AlertasSwalService,
    private _erroresService: ErroresService
  ) {}
  //#endregion

  //#region interceptor
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.context.get(BYPASS_LOG) === true){
      return next.handle(req);
    }
    return next.handle(req).pipe(
      catchError((httpErrorResponse) => {
        let errores: ISwalErrores;
        if (httpErrorResponse.status == 400) {
          errores = this._erroresService.erroresControlados(httpErrorResponse);
        } else {
          errores = this._erroresService.erroresComunes(httpErrorResponse);
        }
        if (errores.mensaje !== undefined) {
          this._swalAlertaService.alerta(
            errores.titulo,
            errores.icono,
            errores.mensaje
          );
        }
        return throwError(() => httpErrorResponse);
      })
    );
  }
  //#endregion
}
