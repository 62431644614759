import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconoSvgComponent } from '../icono-svg/icono-svg.component';
import { IDropdown, IItemDropdown } from '../../interfaces/dropdown';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink, RouterModule } from '@angular/router';
import { Icon } from '../../iconos/iconos';
import { EnumsLib } from '../../utiles/enums';

@Component({
    selector: 'destinux-desplegable-acordeon',
    standalone: true,
    templateUrl: './desplegable-acordeon.component.html',
    styleUrls: ['./desplegable-acordeon.component.scss'],
    imports: [CommonModule, IconoSvgComponent, TranslateModule, RouterLink, RouterModule]
})
export class DesplegableAcordeonComponent {
  //#region inputs
  @Input() desplegable!: IDropdown;
  //#endregion

  //#region output
  @Output() desplegar = new EventEmitter<IDropdown>();
  @Output() seleccionarOpcion = new EventEmitter<IItemDropdown>();
  //#endregion

  //#region variables
  public abierto: boolean = false;
  public Icon = Icon;
  public enums = EnumsLib;
  //#endregion

  hndToggleOpciones() {
    this.abierto = !this.abierto;
  }

  hndOpcionElegida(evt: Event, opcion: IItemDropdown) {
    evt.stopPropagation();
    this.seleccionarOpcion.emit(opcion);
  }
}
