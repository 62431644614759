import { EnvironmentEnum } from "@env/environments.enum";
import { baseEnv } from "@env/environment.common";

const env = {  
  generalURL: 'https://apitest-destinux.consultiatravel.es/',
  recursos: 'https://webapp.destinux.com/recursos_online/destinux_test/',
  entorno: EnvironmentEnum.TEST
};

export const environment = Object.assign(baseEnv, env)
