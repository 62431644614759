import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@destinux/environment';
import { EnvironmentEnum } from '@env/environments.enum';
import { Observable, lastValueFrom, shareReplay } from 'rxjs';
import { IIconos } from '../../models/iconos/iconos';
import { ServiceHttpService } from '@services/service-http.service';
import { iconos } from '@iconos/iconos';

@Injectable({
  providedIn: 'root',
})
export class SvgService {
  public listaSvg: IIconos[] = [];

  constructor(private _httpService: ServiceHttpService) {}

  public async obtenerSVG(rutaIcono: string): Promise<string> {
    let svg: string = rutaIcono;
    const icono = iconos.find((x) => x.url == rutaIcono);

    if (icono) {
      const iconoEncontrado = this.listaSvg.find((x) => x.Nombre == rutaIcono);
      if (icono.estandar) {
        if (
          environment.recursos.includes(environment.baseUrl) &&
          environment.entorno == EnvironmentEnum.PROD
        ) {
          return environment.recursos + environment.baseIconos + svg;
        }

        if (iconoEncontrado) {
          return iconoEncontrado.Url;
        }
      } else {
        if (iconoEncontrado) {
          return iconoEncontrado.Svg;
        }
      }
    }
    if(rutaIcono === undefined)
    {
      return "";
    }
    
    const body = {
      Archivo: this.obtenerArchivo(),
    };

    await lastValueFrom(
      this._httpService.postApi('iconos/diccionario', body, `${environment.baseJsonPruebas}diccionarioIconos/diccionarioIconos.json`).pipe(shareReplay(1))
    ).then((svg_iconos: IIconos[]) => {
      svg_iconos.forEach((svg_icon) => {
        const archivoSvg = iconos.find((icon) => icon.url == svg_icon.Nombre);
        let urlSVG = '';
        if (archivoSvg?.estandar) {
          urlSVG = this.transformarBlob(svg_icon);
        }
        this.listaSvg.push({
          Id: svg_icon.Id,
          Nombre: svg_icon.Nombre,
          Svg: svg_icon.Svg,
          Url: urlSVG,
        });
      });
      const svgRequerido = this.listaSvg.find((x) => x.Nombre == rutaIcono);
      if (icono?.estandar) {
        svg = svgRequerido?.Url || '';
      } else {
        svg = svgRequerido?.Svg || '';
      }
    });
    return svg;
  }

  private transformarBlob(svg: IIconos): string {
    svg.Svg = svg.Svg.replace('\n', '');
    const blob = new Blob([svg.Svg], {
      type: 'image/svg+xml',
    });
    const url = URL.createObjectURL(blob);
    //url = url.replace('blob:', "");
    return url;
  }

  private obtenerArchivo(): string[]{
    let archivo: string[] = [];
    archivo = iconos.map((x) => {
      return x.url;
    });
    return archivo;
  }

  public obtenerIconos(): Observable<IIconos[]> {
    const body = {
      Archivo: this.obtenerArchivo(),
    };
    return this._httpService.postApi('iconos/diccionario', body, `${environment.baseJsonPruebas}diccionarioIconos/diccionarioIconos.json`);
  }
  
}
