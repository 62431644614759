import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberToHours',
  standalone: true,
})
export class NumberToHoursPipe implements PipeTransform {
  transform(value: number): string {
    let running;

    running = value / 60;
    const hours = Math.floor(running);
    running = running - hours;
    const minutes = Math.round(running * 60);
    
    return (hours.toString().length == 1 ? '0'+ hours : hours) + ':' + (minutes.toString().length == 1 ? '0'+ minutes : minutes)
  }
}
