import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DepartamentoComponent } from "../departamento/departamento.component";
import { IEmpleado } from './modelo/empleado';
import { BotonComponent } from "../boton/boton.component";
import { Icon } from '../../iconos/iconos';
import { EnumsLib } from '../../utiles/enums';

@Component({
    selector: 'destinux-tarjeta-empleado',
    standalone: true,
    templateUrl: './tarjeta-empleado.component.html',
    styleUrls: ['./tarjeta-empleado.component.scss'],
    imports: [CommonModule, DepartamentoComponent, BotonComponent]
})
export class TarjetaEmpleadoComponent {
  //#region inputs
  @Input({required: true}) empleado!: IEmpleado;
  @Input() isCerrar: boolean = true;
  //#endregion

  //#region outputs
  @Output() eliminado = new EventEmitter<IEmpleado>();
  //#endregion

  //#region variables
  public Icon = Icon;
  public enums = EnumsLib;
  //#endregion

  //#region metodos usados en el html
  hndEliminar():void {
    this.eliminado.emit(this.empleado);
  }
  //#endregion
}
