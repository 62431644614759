/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostBinding, Input, Renderer2, ViewChild, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputSwitchModule } from 'primeng/inputswitch';
import {
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { ETipoClase } from '@destinux/destinux-enums';
import { ControlValue } from '../../utiles/ControlValue';
import { environment } from '@destinux-components/environment';

@Component({
  selector: 'destinux-switch-prime',
  standalone: true,
  imports: [CommonModule, InputSwitchModule, FormsModule],
  templateUrl: './switch-prime.component.html',
  styleUrls: ['./switch-prime.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchPrimeComponent),
      multi: true,
    },
  ],
})
export class SwitchPrimeComponent extends ControlValue implements AfterViewInit{
  //#region inputs
  /** para saber el tipo de switch que sera si es primario incluye texto
   * campo opcional
   */
  @Input() tipo: ETipoClase = ETipoClase.Secundario;
  /** indica el texto que tendra en caso de ser primario
   * campo opcional
   */
  @Input() texto: string = '';
  /** para indicar si esta deshabilidado el componente o no
   * campo opcional
   */
  @Input() disabled: boolean = false;
  /** agrega texto al inicio del switch
   * opcional
   */
  @Input() textoInicio: string | null = null;
  /** agrega texto al final del switch
   * opcional
   */
  @Input() textoFin: string | null = null;
  private textoSwitch!: ElementRef<any>;
  
  /** agregar clase al texto inicio */
  @Input() claseTextoInicio: string = "";
  /** agrega clase al texto de fin */
  @Input() claseTextoFin: string = "";
  //#endregion

  //#region view child
  @ViewChild('textoSwitch') set content(textoSwitch: ElementRef<HTMLSpanElement>){
    if(textoSwitch){
      this.textoSwitch = textoSwitch;
    }
  }
  //#endregion

  //#region declaracion de variables
  public ETipo = ETipoClase;
  public clases: string = ''
  private ancho_texto: string = '';
  //#endregion

  //#region constructor
 
  //#endregion

  //#region lifecycleHooks
  ngAfterViewInit(): void {
    if(this.textoSwitch){
      this.ancho_texto = this.textoSwitch.nativeElement.offsetWidth.toString() + 'px';
      this.tamanyo_texto = this.ancho_texto;
    }
  }
  //#endregion

  //#region hostbinding
  @HostBinding('style.--cerrarModal') cerrarModal = `url(${environment.recursos}${environment.baseIconosOriginales}flechas_senyales/icono_cerrar_modal.svg)`;
  @HostBinding('style.--check') check = `url(${environment.recursos}${environment.baseIconosOriginales}general/icono_item_lista.svg)`;
  @HostBinding('style.--tamanyo_texto') tamanyo_texto = this.textoSwitch ? this.ancho_texto : '80px';
  //#endregion
}
