import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { EEstadoSwitch } from './models/enums';
import { estadoSwitchAttribute } from '@destinux/destinux-transforms';
import { IconoSvgComponent } from '../icono-svg/icono-svg.component';
import { Icon } from '../../iconos/iconos';
import { EnumsLib } from '../../utiles/enums';

@Component({
  selector: 'destinux-switch',
  standalone: true,
  imports: [CommonModule, IconoSvgComponent],
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
})
export class SwitchComponent implements OnInit, OnChanges {
  //#region variables
  clases: string[] = [];
  iconoMarcado: string = '';
  iconoDesmarcado: string = '';
  icono: string ='';
  iconoEstilos: string = '';
  marcado: boolean = false;
  estiloEtiquetaInicio: string []= [];
  estiloEtiquetaFinal: string []= [];

  public enums = EnumsLib;
  //#endregion

  //#region input
  @Input() texto: string | null = null;
  @Input() etiquetaInicio: string | null = null;
  @Input() etiquetaFinal: string | null = null;
  @Input() estado: EEstadoSwitch;
  //#endregion

  //#region output
  @Output() onEstadoChange = new EventEmitter<EEstadoSwitch>();
  //#endregion

  readonly valoresPorDefecto = {
    estado: EEstadoSwitch.desmarcado,
  };

  constructor() {
    this.estado = this.valoresPorDefecto.estado;
  }

  ngOnInit(): void {
    this.aplicarClases();
    this.descargarIconos();
  }

  descargarIconos() {
    this.iconoMarcado = Icon.CheckBoxActivo;
    this.iconoDesmarcado = Icon.CerrarModal;    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['estado'] && changes['estado'].currentValue !== changes['estado'].previousValue && !changes['estado'].firstChange) {
      this.aplicarClases();
      if (this.estado == EEstadoSwitch.desactivado) {
        this.marcado = false;
      }
    }
  }

  aplicarClases(): void {
    this.clases = ['c-switch', `c-switch--${EEstadoSwitch[this.estado]}`];
    this.iconoEstilos = `c-switch--${EEstadoSwitch[this.estado]}__icono`;
    this.cambiarTipografiaEtiquetas();
  }

  hndCambiarEstado(estado: EEstadoSwitch): void {
    if (this.estado != EEstadoSwitch.desactivado) {
      this.cambiarEstado();
      this.onEstadoChange.emit(estado);
      this.aplicarClases();
    }
  }

  cambiarTipografiaEtiquetas() {
    if(this.etiquetaFinal) {
      this.estiloEtiquetaInicio = (this.estado == EEstadoSwitch.desmarcado) ? ["tipografia","negrita"] : ["tipografia"];      
      this.estiloEtiquetaFinal = (this.estado == EEstadoSwitch.marcado) ? ["tipografia","negrita"] : ["tipografia"];      
    } else {
      this.estiloEtiquetaInicio = ["tipografia","negrita"];
    }
  }

  cambiarEstado(): void {
    if (this.estado == EEstadoSwitch.desmarcado) {
      this.estado = EEstadoSwitch.marcado;
      this.marcado = true;
    } else if (this.estado == EEstadoSwitch.marcado) {
      this.estado = EEstadoSwitch.desmarcado;
      this.marcado = false;
    }
  }
}
