import { EModeloIcono, ETallaIcono } from "../../../components/icono-svg/models/enums";
import { Icon } from "../../../iconos/iconos";

export enum ETipoNotificacion{
    advertencia = 'warn', 
    error = 'error', 
    info = 'info',
    exito = 'success',
  }

  export enum EPosicionNotificacion{
    derecha = "bottom-right",
    izquierda = "bottom-left",
    centro = "bottom-center"
  }
  
  export const IconTipoNotificacion = {
    [ETipoNotificacion.error]: Icon.ToastError,
    [ETipoNotificacion.advertencia]: Icon.ToastAlerta,
    [ETipoNotificacion.exito]: Icon.ToastExito,
    [ETipoNotificacion.info]: Icon.ToastInfo
  }

  export const IconTallaTipoNotificacion = {
    [ETipoNotificacion.error]: ETallaIcono.md,
    [ETipoNotificacion.advertencia]: ETallaIcono.xg,
    [ETipoNotificacion.exito]: ETallaIcono.xg,
    [ETipoNotificacion.info]: ETallaIcono.xg
  }

  export const transformTipoModeloToast = {
    [ETipoNotificacion.exito]: EModeloIcono.exito,
    [ETipoNotificacion.info]: EModeloIcono.info,
    [ETipoNotificacion.advertencia]: EModeloIcono.advertencia, 
    [ETipoNotificacion.error]: EModeloIcono.error
}