<p-dialog [showHeader]="false" [(visible)]="open" [breakpoints]="{ '768px': '100vw' }" [style]="{ width: ancho }">
    <div class="row g-height-fill modal">
        <div class="row g-height-fill@md">
            <div class="col-auto modal__icono g-flex g-flex--justify-content-center">
                <div class="row modal__icono__circulo">
                    <destinux-icono-svg [urlIcono]="iconoModal()" [talla]="tallaIcono"
                        [modelo]="enums.transformTipoModeloIcono[tipo]"></destinux-icono-svg>
                </div>
            </div>
            <div class="col modal__panel">
                <div class="row g-flex--gap-xp g-height-fill">
                    <div #cabecera class="row g-flex--justify-content-space-between click" style="height: min-content;">
                        <div class="col-auto g-font--titulo-gd@md g-font--titulo-pq" [class]="enums.transformTipoColorFuente[tipo]">
                            {{titulo}}
                            <div *ngIf="cantidadPantallas > 0" class="row g-font--titulo-pq"
                                [class]="enums.transformTipoColorFuente[tipo]">
                                {{pantallaActual + '/' + cantidadPantallas}}
                            </div>
                        </div>

                        <div class="col-auto">
                            <destinux-icono-svg [urlIcono]="enums.Icon.CerrarModal" [talla]="enums.ETallaIcono.pq"
                                (click)="cerrarModal()"></destinux-icono-svg>
                        </div>
                    </div>
                    <div *ngIf="!movil" class="row modal__panel__contenido">
                        <ng-container  [ngTemplateOutlet]="content"></ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="movil" class="row g-height-fill modal__panel__contenido">
            <ng-container  [ngTemplateOutlet]="content"></ng-container>
        </div>
    </div>
</p-dialog>