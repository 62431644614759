<div class="precio">
  <ng-container *ngIf="localInglaterra; else europa">
    {{ precioPartido?.parteEntera | number : '1.0' : 'en'
    }}<ng-container *ngIf="decimales"
      >.<span class="centimos">{{
        precioPartido?.parteDecimal
      }}</span></ng-container
    >
  </ng-container>
  <ng-template #europa>
    {{ precioPartido?.parteEntera | number : '1.0' : 'es'
    }}<ng-container *ngIf="decimales"
      >,<span class="centimos">{{
        precioPartido?.parteDecimal
      }}</span></ng-container
    > </ng-template
  >€
</div>
