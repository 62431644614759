import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { image, svg, texto } from './modelo/svg';
import { SvgService } from '../../../../../apps/destinux/src/app/core/services/svg/svg.service';
import { obtenerVariablesCSS } from '../../utiles/variablesScss';

@Component({
  selector: 'destinux-svgEstandar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './svgEstandar.component.svg',
  styleUrls: ['./svgEstandar.component.scss'],
})
export class svgEstandarComponent implements OnInit
{
  //#region inputs
  /** indica la cantidad de paths que tendrá el SVG */
  @Input() cantidadPath: number = 3;
  /** la url donde se encuentra el icono a mostrar */
  @Input({ required: true }) urlIcono: string = '';
  /** si se desea agregar texto se debe indicar cual es el texto a mostrar  */
  @Input() texto: texto | null = null;
  /** en caso de querer indicar el color del texto ingresado anteriormente  */
  @Input() colorTexto: string = obtenerVariablesCSS('--textoLista-boton-primario');

  @Input() disabled: boolean = false;
  /** en caso de necesitar modificar colores fuera de los que están por defectos, estos se deben realizar mediante este parámetro  */
  @Input() urlsPath: svg[] | null = null;
  /** permite ingresar una imagen, indicando posición y tamaño */
  @Input() urlImg: image | null = null;
  /** anchura del svg  */
  @Input() width: string = '100%';
  /** altura del svg  */
  @Input() height: string = '100%';
  /** posición  y dimensiones del svg */
  @Input() viewBox: string = '0 0 48 48';
  /** clase que se quiere agregar al svg  */
  @Input() classSvg: string = '';
  /** clase que se le quiere agregar a la imagen */
  @Input() classImg: string = '';
  /** clase que se le quiere agregar al texto */
  @Input() classTexto: string = '';
  //#endregion

  public url: string = '';

  constructor(private _svgService: SvgService) {}

  async ngOnInit() {
    await this.creaPath();
    if (!this.urlsPath) {
      this.urlsPath = [];
      for (let i = 1; i <= this.cantidadPath; i++) {
        if (i == 2) {
          this.urlsPath.push({
            path: this.url + '#' + i.toString(),
            stroke: obtenerVariablesCSS('--backgroundLista-icono-secundario'),
            fill: 'transparent',
            strokeWidth: 2,
          });
        } else {
          this.urlsPath.push({
            path: this.url + '#' + i.toString(),
            stroke: obtenerVariablesCSS('--backgroundLista-icono-primario'),
            fill: 'transparent',
            strokeWidth: 2,
          });
        }
      }
    }
  }

  /*async verificaUrl() {
    await this._svgService.obtenerSVG(this.urlIcono, true).then(x => {
      this.url = x;
    });
  }*/

  async creaPath() {
    await this.transformarURL();
    if (this.urlsPath) {
      this.urlsPath.forEach(async (x, index) => {
        const split = x.path.split('#');
        if (this.urlsPath) {
          this.urlsPath[index].path = this.url + '#' + split[1];
        }
      });
    }
  }

  async transformarURL() {
    await this._svgService.obtenerSVG(this.urlIcono).then(respuesta => {
      this.url = respuesta;
    });
  }
}
