import { Route } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { PoliticaPrivacidadComponent } from './components/login/politica-privacidad/politica-privacidad.component';
import { CondicionesGeneralesComponent } from './components/login/condiciones-generales/appsdestinuxsrcappcomponentslogincondiciones-generales/condiciones-generales.component';
import { PoliticaSeguridadComponent } from './components/login/politica-seguridad/politica-seguridad.component';
// TODO: canLoad deprecated use instead https://angular.io/api/router/CanMatchFn
export const routes: Route[] = [
  {
    path: '',
    redirectTo: 'login', 
    pathMatch: 'full'
  },
  {
    path: 'app',
    canActivate:[AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./components/pages/pages.routes').then((x)=> x.PagesRoutes)
  },
  {
    path: 'login',
    loadComponent: () => import("./components/login/login/login.component").then((x) => x.LoginComponent)
  },
  {
    path: 'login/r',
    loadComponent: () => import('./components/login/login/login.component').then((x) => x.LoginComponent)
  },
  {
    path: 'login/:dni/:password',
    loadComponent: () => import('./components/login/login/login.component').then((x) => x.LoginComponent)
  },
  {
    path: 'privacy-policy',
    component: PoliticaPrivacidadComponent
  },
  {
    path: 'general-conditions',
    component: CondicionesGeneralesComponent
  },
  {
    path: 'security-policy',
    component: PoliticaSeguridadComponent
  },
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full'
  }
];
