export enum EEstadoSlider {
  activo,
  presionado,
  desactivado
}

export enum EModeloSlider {
  continuo,
  discreto,
  rango,
  tooltip
}

export enum EColorSlider {
  primario,
  secundario,
  advertencia,
  error,
  info,
  exito,
  destaque
}