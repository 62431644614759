<span [ngClass]="{'contenedor-input':true,'p-input-icon-left': !imgDerecha, 'p-input-icon-right': imgDerecha, 'con-texto-superior': texto, 'sin-texto-superior': !texto}">
    <div *ngIf="texto" style="font-size: 10px; position: absolute; top: 15%; padding-left: 16px; padding-bottom: 8px;">{{texto |  uppercase}}</div>
    <i>
        <destinux-icono-svg *ngIf="srcImg" [modelo]="enums.EModeloIcono.login" [talla]="enums.ETallaIcono.md" [urlIcono]="srcImg"></destinux-icono-svg>
    </i>
    <input [type]="tipo" [class]="estilo + ' ' + class" pInputText [(ngModel)]="value" (ngModelChange)="EscribirValor()" (keyup)="borrar($event)"
        [placeholder]="placeholder" [disabled]="disabled" [ngClass]="{'con-texto-superior': texto, 'sin-texto-superior': !texto}" />
    <span class="p-input-icon-right contrasenya" *ngIf="tipoPassword">
        <i (click)="mostrarContrasenya()">
                <destinux-icono-svg *ngIf="tipo === ETipoInputs.password" [modelo]="enums.EModeloIcono.login" [talla]="enums.ETallaIcono.md" [urlIcono]="Icon.VerContrasenya"></destinux-icono-svg>
                <destinux-icono-svg *ngIf="tipo === ETipoInputs.text" [modelo]="enums.EModeloIcono.login" [talla]="enums.ETallaIcono.md" [urlIcono]="Icon.OcultarContrasenya"></destinux-icono-svg>
        </i>
    </span>
    <span *ngIf="hndInfoError()" class="p-input-icon-right" [ngClass]="{'info-derecha': iconErrorDerecha, 'info-izquierda': !iconErrorDerecha}">
        <destinux-informacion [informacion]="mensajeError" [tipo]="enums.ETipoInformacion.error"></destinux-informacion>
    </span>
</span>