<p-toast [position]="posicion">
    <ng-template let-message pTemplate="message">
        <div class="row">
            <div class="col-auto">
                <destinux-icono-svg [urlIcono]="enums.IconTipoNotificacion[tipo]" [talla]="enums.ETallaIcono.md"
                [modelo]="enums.transformTipoModeloToast[tipo]"></destinux-icono-svg>
            </div>
            <div class="col">
                <div class="row g-font--subtitulo-xp">
                    {{titulo}}
                </div>
                <div class="row g-font--parrafo-xp g-color-parrafo">
                    {{mensaje}}
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>