/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { ServiceHttpService } from '../../service-http.service';
import { BehaviorSubject, Observable } from 'rxjs';
import * as dayjs from 'dayjs';
import { environment } from '@env/environment';
import { EnvironmentEnum } from '@env/environments.enum';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  //#region declaracion de variables
  public urlUsuarioIntentaAcceder = '/app';
  #_tiempoSesion = new BehaviorSubject<number | null>(null);
  public tiempoSesion$ = this.#_tiempoSesion.asObservable();

  #_ultimaAccion = new BehaviorSubject<dayjs.Dayjs | null>(null);
  ultimaAccion$ = this.#_ultimaAccion.asObservable();
  //#endregion

  //#region constructor
  constructor(private _httpService: ServiceHttpService) { }
  //#endregion

  //#region metodos getter and setter
  public get tiempoSesion(): number {
    return this.#_tiempoSesion.getValue() || 0;
  }

  public setTiempoSesion(): void {
    const LSTiempoSesion = localStorage.getItem('Sesion_expira_en');
    if (LSTiempoSesion) {
      this.#_tiempoSesion.next(parseInt(LSTiempoSesion) * 1000);
    }
  }

  public getToken(): string | null{
    return localStorage.getItem('user_token');
  }

  public setUltimaAccion(fechaUltimaAccion: dayjs.Dayjs | null = dayjs()): void {
    if (fechaUltimaAccion) {
      localStorage.setItem('ActionTemp', fechaUltimaAccion.format('DD-MM-YYYY HH:mm:ss'));
    }

    this.#_ultimaAccion.next(fechaUltimaAccion);
  }

  public getTiempoSesionGastado(): number {
    return dayjs().diff(this.#_ultimaAccion.getValue());
  }
  //#endregion

  //#region metodos httpService
  public mantenerSesionActiva(): Observable<any> {
    return this._httpService.getApi(`KeepAlive`);
  }
  //#endregion
  
  //#region metodos
  public isAuthenticated(url: string): boolean {
    const token = this.getToken();
    if (!token || this.sesionCaducada) {
      this.urlUsuarioIntentaAcceder = url;
      return false;
    }
    return true;
  }

  public get sesionCaducada(): boolean {
    if ((this.#_ultimaAccion.getValue() && this.getTiempoSesionGastado() <= this.tiempoSesion) || environment.entorno == EnvironmentEnum.DEMO) {
      return false;
    }
    return true;
  }
  //#endregion

}
