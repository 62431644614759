<div class="g-flex g-flex--direction-column empleado">
  <destinux-boton *ngIf="isCerrar" class="g-flex g-flex--align-self-end"
    [iconoStart]="Icon.CerrarModal" [modelo]="enums.EModeloBoton.icono" [color]="enums.EColorBoton.secundario" 
    [talla]="enums.ETallaBoton.xp"
    (botonClick)="hndEliminar()"></destinux-boton> 
  <div class="g-flex g-flex--direction-column g-flex--gap-xxp g-flex--align-items-start g-padding-bottom-xp g-padding-horizontal-xp">
    <div *ngIf="empleado.objeto else datos" [innerHTML]="empleado.objeto.ramaEstilizada"></div>
    <ng-template #datos>
      <span class="empleado__nombre">{{ empleado?.nombre }}</span>
      <destinux-departamento *ngIf="empleado.departamento" [texto]="empleado?.departamento"></destinux-departamento>
      <span class="empleado__empresa">{{ empleado?.empresa }}</span>
    </ng-template>
  </div>
</div>
