import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColorPorSignoDirective } from '@destinux/destinux-directives';

@Component({
  selector: 'destinux-numero-formateado',
  standalone: true,
  imports: [CommonModule, ColorPorSignoDirective],
  templateUrl: './numero-formateado.component.html',
  styleUrls: ['./numero-formateado.component.scss'],
})
export class NumeroFormateadoComponent implements OnInit{

  @Input() numero!: number ;
  @Input() color: boolean = false ;

  public estilo: string[] = [];

  ngOnInit() {
    if(this.color) {
      switch (Math.sign(this.numero)) {
        case -1:
          // Número es negativo
          this.estilo.push('g-color-boton-error');
          break;
        case 0:
          // Número es igual a cero
          this.estilo.push('g-color-boton-info');
          break;
        case 1:
          // Número es positivo
          this.estilo.push('g-color-boton-exito-400');
          break;
      }
    }
  }

}
