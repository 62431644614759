import { Component, ContentChild, Input, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconoSvgComponent } from '../icono-svg/icono-svg.component';
import { Icon } from '../../iconos/iconos';
import { TooltipModule } from 'primeng/tooltip';
import { ETipoInformacion, TransformModeloIconoTipo } from '../../enums/informacion';
import { EnumsLib } from '../../utiles/enums';

@Component({
  selector: 'destinux-informacion',
  standalone: true,
  imports: [CommonModule, IconoSvgComponent, TooltipModule],
  templateUrl: './informacion.component.html',
  styleUrls: ['./informacion.component.scss'],
})
export class InformacionComponent {
  //#region contentChild
  @ViewChild('default') default!: TemplateRef<any>;
  //#endregion
  
  //#region inputs
  @Input() tipo: ETipoInformacion = ETipoInformacion.normal;
  @Input({required:true}) informacion!: string | TemplateRef<any>;
  //#endregion

  //#region variables
  public Icon = Icon;
  public TransformModeloIconoTipo = TransformModeloIconoTipo;
  public enums = EnumsLib;
  //#endregion

  //#region  metodos html
  public hndInfoTooltip(): TemplateRef<any>{    
    if(typeof(this.informacion) === 'string' || !this.informacion){
      return this.default;
    }
    else{
      return this.informacion;
    }
  }
}
