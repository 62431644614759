/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { LoginService } from '../../services/sesion/login/login.service';
import { AuthService } from '../../services/sesion/auth/auth.service';
import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import { slotAPI } from '../../../../environments/VersionAPI';
import { EnvironmentEnum } from '@env/environments.enum';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  //#region declaracion de variables
  #urlsLogin: string[] = ['/api/login', '/api/iconos/diccionario'];
  #urlsVersion: string[] = [
    'api/Alta',
    'version/Select',
    'api/ConfirmCambioPassword',
  ];

  
  //#endregion

  //#region constructor
  constructor(
    private _loginService: LoginService,
    private _authService: AuthService
  ) {
    dayjs.extend(customParseFormat);
  }
  //#endregion

  //#region interceptor
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (this.#urlsLogin.some((x) => x == req.urlWithParams)) {
      /**
     * Si es login, set ultima accion y monta peticion con versiones pero sin headers
     */
      this._authService.setUltimaAccion();
      req = this.montarPeticion(true, req);
    } else if (this.#urlsVersion.some((x) => x == req.urlWithParams)) {
      req = this.montarPeticion(false, req);
    } else {
      /**
       * Restricciones que tiene que cumplir para llamar a la api
       */
      const urlReqMinusculas = req.url.toLowerCase();
      const restricciones = [
        localStorage.getItem('user_token'),
        localStorage.getItem('idsesion'),
        (!urlReqMinusculas.includes('recursos')),
        !req.url.includes('assets'),
      ];

      if (restricciones.every((restriccion) => restriccion)) {
        /**
       * Cualquier otra llamada, monta la peticion con la version y con los headers
       */
        const user_token = 'Bearer ' + localStorage.getItem('user_token');
        const idsesion = localStorage.getItem('idsesion') || '';
        const ultima_accion = localStorage.getItem('ActionTemp');
        if (ultima_accion) {
          this._authService.setTiempoSesion();
          this._authService.setUltimaAccion(
            dayjs(ultima_accion, 'DD-MM-YYYY HH:mm:ss')
          );
        }
        if (this._authService.sesionCaducada) {
          this._loginService.getLogOut(true);
        } else {
          this._authService.setUltimaAccion();
          req = this.montarPeticion(true, req);

          req = req.clone({
            setHeaders: {
              authorization: user_token,
              IDSesion: idsesion,
            },
          });
        }
      }
    }
    return next.handle(req);
  }
  //#endregion

  //#region metodos

  /**
   * 
   * @param version 
   * @param req 
   */
  montarPeticion(version: any, req: any): any {
    /**
     * Genera la url para la api
     */
    let url = '';

    if (environment.entorno == EnvironmentEnum.LOCAL) {
      url = environment.generalURL + req.urlWithParams;
    } else {
      url =
        environment.generalURL +
        this.checkApi(req) +
        this.checkEnvironment() +
        this.checkVersion(version) +
        req.urlWithParams;
    }
    /**
     * se crea el request
     */
    const httpRequest = new HttpRequest(<any>req.method, url, req.body, {
      headers: req.headers,
      responseType: req.responseType,
      context: req.context
    });
    return Object.assign(req, httpRequest);
  };

  checkEnvironment(): string {
    if (environment.entorno == EnvironmentEnum.PROD) {
      return '/';
    } else if(environment.entorno == EnvironmentEnum.DEMO){
      return '.' + EnvironmentEnum.TEST.toUpperCase() + '/';
    }
    else {
      return `.${environment.entorno.toUpperCase()}/`;
    }
  }

  checkApi(req: HttpRequest<any>): string | null {
    return req.headers.get('apiDestino') ? req.headers.get('apiDestino') : 'SB';
  }

  checkVersion(version: any): string | null {
    if ((environment.entorno == EnvironmentEnum.TEST) || (environment.entorno == EnvironmentEnum.DEMO)) {
      return version ? `Test${slotAPI}` : '';
    } else {
      return version ? localStorage.getItem('versionAPIS') : '';
    }
  }
  //#endregion
}
