import { Component, HostBinding, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '@destinux/environment';

@Component({
  selector: 'destinux-condiciones-generales',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './condiciones-generales.component.html',
  styleUrls: ['./condiciones-generales.component.scss'],
})
export class CondicionesGeneralesComponent implements OnInit {
  
  urlLogo = environment.recursos + '/nuevoestiloweb/logos/logo_tipos_destinux.svg';
  
  @HostBinding('style.--fondo')
  fondo = `url(${environment.recursos}${environment.fondoPoliticas}fondo_de_pagina_1920_1200.jpg)`;
  constructor() { }

  ngOnInit(): void {
  }
}
