/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { ETipoErrores } from 'src/app/core/enums/TipoErrores';
import { ISwalErrores } from 'src/app/core/models/control-errores/errores';
import { LoginService } from '../../sesion/login/login.service';
import { TranslateService } from '@ngx-translate/core';
import { EIcono, ETitulos } from 'src/app/core/enums/alertas/swal';
import { InicializadorGeneralService } from '../../inicializadores/inicializador-general.service';

@Injectable({
  providedIn: 'root',
})
export class ErroresService {
  //#region constructor
  constructor(
    private _loginService: LoginService,
    private _translate: TranslateService,
    private _inicializadorGeneral: InicializadorGeneralService
  ) {}
  //#endregion

  //#region metodos
  erroresControlados(error: any): ISwalErrores {
    const errores: ISwalErrores = this._inicializadorGeneral.iSwalErrores();

    if (error?.error && error?.error.DESTINUX && error?.error.DESTINUX == 'Mensaje-usuario') {
      errores.mensaje = error?.error.Message;
      switch (error?.error.Tipo) {
        case ETipoErrores.DatosPeticionNoValidos:
        case ETipoErrores.Prohibido:
        case ETipoErrores.Warning:
          errores.icono = EIcono.Warning;
          errores.titulo = ETitulos.Atencion;
          break;
        case ETipoErrores.Error:
          errores.icono = EIcono.Error;
          errores.titulo = ETitulos.Error;
          break;
        case ETipoErrores.NoEncontrado:
          errores.icono = EIcono.Info;
          errores.titulo = ETitulos.SinResultado;
          break;
      }
    } else {
      errores.icono = EIcono.Error;
      errores.titulo = ETitulos.Error;
      errores.mensaje = this._translate.instant('admin.comun.error-consulta');
    }
    console.log(errores);
    return errores;
  }

  erroresComunes(error: any): ISwalErrores {
    const errores: ISwalErrores = this._inicializadorGeneral.iSwalErrores();

    errores.icono = EIcono.Error;
    errores.titulo = ETitulos.Error;

    if (error.statusText?.includes('AG.000.00d')) {
      this._loginService.getLogOut(true);
    }
    if (error.message.includes('AA.000.005')) {
      errores.mensaje = ETitulos.ErrorPermisos;
    }
    if (error.status === -1) {
      errores.mensaje = ETitulos.ErrorServidor;
    } else {
      if (error.status < 500) {
        errores.mensaje = ETitulos.ErrorPeticion;
        if (error.status === 404) {
          errores.mensaje = ETitulos.ErrorRecurso;
        }
      } else {
        if (
          typeof error.exceptionMessage !== 'undefined' &&
          error.exceptionMessage.includes(':')
        ) {
          const resto_error = error.exceptionMessage
            .split(':')
            .slice(1)
            .join(':');
          if (resto_error[0] != 'expression') {
            errores.mensaje = error[0] + ': ' + error[1];
          }
        } else {
          errores.mensaje = ETitulos.ErrorPeticion;
        }
      }
    }

    return errores;
  }
  //#endregion
}
